/* eslint-disable no-underscore-dangle */

import PropTypes from "prop-types";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveTagFilter } from "store/actions/filterActions";
import ViewEditActions from "components/DetailList/ViewEditActions";
import { getTags, setLoading } from "store/actions/tagActions";
import TagsPicker from "components/AsyncSelect/TagsPicker";
import ExcludeRecommendation from "../ExcludeRecommendation";
import ExcludeTicket from "../ExcludeTicket";

const ByTag = ({ locationId, filters, destination }) => {
  const dispatch = useDispatch();

  const tags = useSelector((state) => state.tags.tags);

  const [detail, setDetail] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);

  // get tags
  useEffect(() => {
    dispatch(setLoading());
    dispatch(getTags());
  }, []);

  useEffect(() => {
    if (filters?.tags?.length >= 0) {
      setSelectedTags(filters.tags);
    }
  }, [filters]);

  const handleTagsChange = (value) => {
    if (value) {
      setSelectedTags(value);
      selectedTags.map((val) => ({
        value: val?._id || val?.value,
        label: val?.title || val?.label,
      }));
    } else {
      setSelectedTags([]);
    }
  };

  const handleSave = (event) => {
    event.preventDefault(); // Prevent form submission
    const newState = selectedTags.map((val) => val.value);
    dispatch(saveTagFilter(newState, locationId));
    setDetail(true);
  };

  return (
    <Card id="tag" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Filter by Tags</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="body2">
                Choose the tags you don’t want your visitors to see:
              </MDTypography>

              <ViewEditActions
                detailPage={detail}
                handleEdit={() => setDetail(false)}
                handleClose={() => {
                  setDetail(true);
                  setSelectedTags(filters.tags);
                }}
                handleSave={handleSave}
              />
            </MDBox>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              alignItems="end"
              sx={{
                width: "100%",
              }}
            >
              <Grid item xs={12}>
                {detail ? (
                  <MDBox style={{ display: "flex", flexDirection: "column" }}>
                    {selectedTags?.length > 0 &&
                      selectedTags?.map((item) => (
                        <MDTypography
                          key={item?.value}
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          - {item?.title || item?.label}
                        </MDTypography>
                      ))}
                  </MDBox>
                ) : (
                  <TagsPicker
                    tags={tags}
                    tagsV={
                      selectedTags?.length > 0 && selectedTags[0]?.value?.length > 0
                        ? selectedTags.map((item) => ({
                            label: item?.label,
                            value: item?.value,
                          }))
                        : selectedTags
                    }
                    setFieldValue={setSelectedTags}
                    handleChange={handleTagsChange}
                  />

                  // <Autocomplete
                  //   id="tags"
                  //   multiple
                  //   filterSelectedOptions
                  //   options={tags}
                  //   onChange={(event, value) => {
                  //     selectedTags.map((val) => ({ value: val, label: val }));
                  //     handleTagChange(value);
                  //   }}
                  //   value={selectedTags}
                  //   getOptionLabel={(option) => option.title || ""}
                  //   isOptionEqualToValue={(option, value) => option?._id === value?._id}
                  //   //   defaultValue={["react", "angular"]}
                  //   renderInput={(params) => (
                  //     <FormField
                  //       {...params}
                  //       InputLabelProps={{ shrink: true }}
                  //       placeholder={selectedTags?.length === 0 ? "Select Tag" : undefined}
                  //     />
                  //   )}
                  // />
                )}
              </Grid>
            </Grid>
          </Grid>
          <ExcludeRecommendation
            locationId={locationId}
            tags={selectedTags}
            filters={filters}
            destination={destination}
          />
          <ExcludeTicket locationId={locationId} filters={filters} destination={destination} />
        </Grid>
      </MDBox>
    </Card>
  );
};

// Setting default props
ByTag.defaultProps = {
  locationId: "",
  destination: "",
  filters: {},
};

// typechecking props for ByTag
ByTag.propTypes = {
  locationId: PropTypes.string,
  destination: PropTypes.string,
  filters: PropTypes.instanceOf(Object),
};
export default ByTag;
