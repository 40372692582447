// prop-type is a library for typechecking of props
import { Autocomplete, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import fontFamilies from "./fontFamilies";

const FontSelector = ({ setFontFamily }) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const handleFontChange = (event, newValue) => {
    setValue(newValue);
    setFontFamily(newValue);
  };

  return (
    <Autocomplete
      value={value}
      onChange={handleFontChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="font-family-autocomplete"
      options={fontFamilies}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} variant="standard" label="Font Family" />}
    />
  );
};
// typechecking props for FontSelector
FontSelector.propTypes = {
  setFontFamily: PropTypes.func.isRequired,
};

export default FontSelector;
