import React from "react";
import PropTypes from "prop-types";

// @mui material components
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// Custom components
import GreenSwitch from "components/AutoComplete/GreenSwitch";

const DynamicSwitch = ({ label, name, value, setFieldValue, location, disabledSwitches }) => (
  <FormGroup row>
    <FormControlLabel
      control={
        <GreenSwitch
          checked={value}
          name={name}
          value={value}
          onChange={(event) => setFieldValue(name, event.target.checked)}
          disabled={!location && disabledSwitches}
        />
      }
      label={label}
    />
  </FormGroup>
);

DynamicSwitch.defaultProps = {
  label: "",
  name: "",
  location: "",
  value: false,
  disabledSwitches: false,
  setFieldValue: () => {},
};

// typechecking props for AdditionalInfo
DynamicSwitch.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  location: PropTypes.string,
  value: PropTypes.bool,
  disabledSwitches: PropTypes.bool,
  setFieldValue: PropTypes.func,
};

export default DynamicSwitch;
