/* eslint-disable  */
// Libraries
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Mui components
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Custom component
import MDTypography from "components/MDTypography";

const CopytoClipboard = (props) => {
  const [copied, setCopied] = useState(false);
  const [bc, setBC] = useState(false);

  const showNotification = () => {
    if (!bc) {
      setBC(true);
      setTimeout(function () {
        setBC(false);
        setCopied(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (copied) {
      showNotification();
    }
  }, [copied]);

  return (
    <div>
      <CopyToClipboard
        options={{ message: "Copied!" }}
        onCopy={() => setCopied(true)}
        text={props.text}
      >
        <div style={{ display: "inline-flex", alignItems: props?.top ? "top" : "center" }}>
          <MDTypography variant="button" fontWeight="medium" style={{ marginRight: "8px" }}>
            {props.text}
          </MDTypography>
          {copied ? (
            <MDTypography variant="button" fontWeight="medium" color="success" style={{ fontSize:"12px"}}>
              Copied!
            </MDTypography>
          ) : (
            <ContentCopyIcon style={{ cursor: "pointer", width:"16px", height:"16px" }} />
          )}
        </div>
      </CopyToClipboard>
      <Snackbar
        // message="Link copied to clipboard"
        open={bc}
        closeNotification={() => setBC(false)}
        close
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={() => {setBC(false); setCopied(false)}} severity="success" sx={{ width: "100%" }}>
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </div>
  );
};

CopytoClipboard.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CopytoClipboard;
