import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, subTitle, images, mobileImages, ctaUrl },
} = checkout;

const validations = [
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [subTitle.name]: Yup.string().matches(/([A-Za-z])/, subTitle.invalidMsg),
    [images.name]: Yup.array().min(1, images.errorMsg).required(),
    [mobileImages.name]: Yup.array().min(1, mobileImages.errorMsg).required(),
    [ctaUrl.name]: Yup.string().matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
      ctaUrl.invalidMsg
    ),
  }),
];

export default validations;
