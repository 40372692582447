import React from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDLoading from "components/MDLoading";

import DashboardLayout from "layouts/DashboardLayout";

const Loading = () => (
  // Create a loop for progress bar value to simulate loading
  // const [progress, setProgress] = useState(0);
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  // console.log(progress);

  <DashboardLayout>
    <MDBox py={3}>
      <MDLoading />
    </MDBox>
  </DashboardLayout>
);
export default Loading;
