// import React from "react";
// import { Column, Img, Row, Section, Text } from "@react-email/components";
// import { Tailwind } from "@react-email/tailwind";

// const IfYouNeedSupportSection = () => {
//   // to prevent blinking when user press on the email to open the external email App
//   const handleEmailClick = (e) => {
//     e.preventDefault();
//     window.location.href = "mailto:cs@lokalee.app";
//   };

//   return (
//     <Tailwind>
//       <Text className="text-[20px] leading-[23px] font-bold text-black text-center mb-2">
//         If you need any assistance:
//       </Text>
//       <Section className="bg-[#BAB9B9] p-0.5 text-center text-white">
//         <div className="flex flex-col items-center my-0">
//           <Img
//             src="https://email-assets.lokalee.app/email/emailImages/inbox.png"
//             alt="Inbox"
//             className="w-[20px] h-[33px] pt-4 my-0"
//           />
//           <Text className="text-[11px] leading-[15px] font-bold text-white my-1">
//             Email us:
//             <Row className="justify-center my-1">
//               <Column>
//                 <a
//                   href="mailto:cs@lokalee.app"
//                   onClick={handleEmailClick}
//                   className="text-[11px] leading-[15px] font-bold text-white"
//                 >
//                   cs@lokalee.app
//                 </a>
//               </Column>
//             </Row>
//           </Text>
//         </div>
//       </Section>
//     </Tailwind>
//   );
// };

// export default IfYouNeedSupportSection;
import React from "react";
import { Text } from "@react-email/components";
import PropTypes from "prop-types";

const IfYouNeedSupportSection = ({ showTitle }) => {
  // To prevent blinking when user clicks to open the external email app
  const handleEmailClick = (e) => {
    e.preventDefault();
    window.location.href = "mailto:cs@lokalee.app";
  };

  return (
    <>
      {showTitle && (
        <Text
          style={{
            fontSize: "20px", // 'text-[20px]'
            lineHeight: "23px", // 'leading-[23px]'
            fontWeight: "700", // 'font-bold'
            color: "#000000", // 'text-black'
            textAlign: "center", // 'text-center'
            marginBottom: "0.5rem", // 'mb-2'
          }}
        >
          If you need any assistance:
        </Text>
      )}

      <table
        width="100%"
        style={{
          backgroundColor: "#BAB9B9",
          padding: "2px",
          textAlign: "center",
          color: "#ffffff",
        }}
        cellPadding="0"
        cellSpacing="0"
        role="presentation"
      >
        <tbody>
          <tr>
            <td style={{ paddingTop: "16px", textAlign: "center" }}>
              <img
                src="https://email-assets.lokalee.app/email/emailImages/inbox.png"
                alt="Inbox"
                style={{
                  width: "25px",
                  height: "25px",
                  display: "block",
                  margin: "0 auto",
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center", paddingTop: "4px" }}>
              <p
                style={{
                  fontSize: "11px",
                  lineHeight: "15px",
                  fontWeight: "700",
                  color: "#ffffff",
                  margin: "0",
                }}
              >
                Email us:
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <a
                href="mailto:cs@lokalee.app"
                onClick={handleEmailClick}
                style={{
                  fontSize: "11px",
                  lineHeight: "15px",
                  fontWeight: "700",
                  color: "#ffffff",
                  textDecoration: "none",
                }}
              >
                cs@lokalee.app
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
IfYouNeedSupportSection.defaultProps = {
  showTitle: true,
};

IfYouNeedSupportSection.propTypes = {
  showTitle: PropTypes.bool,
};
export default IfYouNeedSupportSection;
