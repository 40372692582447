/* eslint-disable no-underscore-dangle */
import { CLEAR_ERRORS, GET_SERVICES_JOBS, SERVICES_JOBS_FAIL } from "../actions/Types";

const initialState = {
  loading: false,
  error: null,
  added: false,
  serviceJobs: [],
};

const variable = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICES_JOBS:
      return {
        ...state,
        loading: false,
        error: null,
        added: false,
        serviceJobs: action.payload.data,
      };

    case SERVICES_JOBS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };

    default:
      return state;
  }
};
export default variable;
