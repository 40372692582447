/* eslint-disable no-underscore-dangle */
import {
  TAG_ADD_SUCCESS,
  CLEAR_ERRORS,
  GET_ONE_TAG,
  TAG_FAIL,
  UPDATE_TAG,
  DELETE_TAG,
  GET_TAGS,
  CLEAR_ONE_TAG,
} from "../actions/Types";

const initialState = {
  loading: false,
  tags: [],
  error: null,
  added: false,
  tag: null,
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case TAG_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        tags: [...state.tags, action.payload.data],
        added: true,
        tag: null,
      };

    case GET_ONE_TAG:
      return {
        ...state,
        loading: false,
        error: null,
        tag: action.payload.data.data,
        added: false,
      };

    case GET_TAGS:
      return {
        ...state,
        loading: false,
        error: null,
        tags: action.payload.data,
        added: false,
      };
    case DELETE_TAG:
      return {
        ...state,
        loading: false,
        tags: state.tags.filter((tag) => tag._id !== action.payload.data.tag._id),
      };
    case UPDATE_TAG:
      return {
        ...state,
        added: true,
        tags: state.tags.map((tag) =>
          tag._id === action.payload.data.tag._id ? action.payload.data.tag : tag
        ),
      };

    case TAG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ONE_TAG:
      return {
        ...state,
        tag: null,
        added: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };

    default:
      return state;
  }
};
export default variable;
