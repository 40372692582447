import { dateFormatWithDash } from "components/helper";
import checkout from "./form";

const {
  formField: {
    // experience page
    title,
    method,
    description,
    code,
    type,
    value,
    destinations,
    locations,
    tickets,
    appliesTo,
    minimumType,
    minimumValue,
    eligibility,
    startDate,
    endDate,
  },
} = checkout;

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

const initialValues = {
  // experience page
  [title?.name]: "",
  [method?.name]: "code",
  [description?.name]: "",
  [code?.name]: "",
  [type?.name]: "percentage",
  [value?.name]: "",
  [appliesTo?.name]: "all",
  [minimumType?.name]: "none",
  [minimumValue?.name]: "10",
  [eligibility?.name]: "all",
  [startDate.name]: dateFormatWithDash(today),
  [endDate.name]: dateFormatWithDash(tomorrow),
  [destinations?.name]: [],
  [locations?.name]: [],
  [tickets?.name]: [],
};

export default initialValues;
