// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";

const ConciergeCard = ({ color, title, count, icon, title2, count2 }) => (
  <Card sx={{ paddingY: "12px" }}>
    <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
      <MDBox
        variant="gradient"
        bgColor={color}
        color={color === "light" ? "dark" : "white"}
        coloredShadow={color}
        borderRadius="xl"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="4rem"
        height="4rem"
        mt={-4}
      >
        <Icon fontSize="medium" color="inherit">
          {icon}
        </Icon>
      </MDBox>
      <MDBox textAlign="right" lineHeight={1.25}>
        <Grid container justifyContent="end">
          <Grid item xs={12} textAlign="center">
            <MDTypography variant="button" fontWeight="light" color="text">
              {title}
            </MDTypography>
            <MDTypography variant="h4">{count}</MDTypography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <MDTypography variant="button" fontWeight="light" color="text">
              {title2}
            </MDTypography>
            <MDTypography variant="h4">{count2}</MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  </Card>
);

// Setting default values for the props of ConciergeCard
ConciergeCard.defaultProps = {
  color: "info",
  count: 0,
  count2: 0,
  title2: "",
};

// Typechecking props for the ConciergeCard
ConciergeCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  title2: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  count2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node.isRequired,
};

export default ConciergeCard;
