/* eslint-disable no-underscore-dangle */
/**
=========================================================
* LCM Dashboard V2
=========================================================

* Product Page: https://www.lcm.lokalee.app
* Copyright 2023 Lokalee (https://www.lokalee.app)

 =========================================================
*/
import PropTypes from "prop-types";

// @material-ui core components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveExRecommendationFilter } from "store/actions/filterActions";
import ViewEditActions from "components/DetailList/ViewEditActions";
import RecommendationsPicker from "components/AsyncSelect/RecommendationsPicker";

const ExcludeRecommendation = ({ locationId, filters, destination, tags }) => {
  const dispatch = useDispatch();

  const [detail, setDetail] = useState(true);
  const [selectedRecommendations, setSelectedRecommendations] = useState([]);

  useEffect(() => {
    if (filters?.excluded?.length >= 0) {
      setSelectedRecommendations(filters?.excluded);
    }
  }, [filters]);

  const handleRecommendationChange = (value) => {
    selectedRecommendations?.map((val) => ({ value: val, label: val }));
    if (value) {
      setSelectedRecommendations(value);
    } else {
      setSelectedRecommendations([]);
    }
  };

  const handleSave = (event) => {
    event.preventDefault(); // Prevent form submission
    const newState = selectedRecommendations?.map((val) => val.value);
    dispatch(saveExRecommendationFilter(newState, locationId));
    setDetail(true);
  };

  return (
    <Grid item xs={12}>
      <MDBox sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="body2">
          Choose the Recommendation you want to exclude from the above filter:
        </MDTypography>

        <ViewEditActions
          detailPage={detail}
          handleEdit={() => setDetail(false)}
          handleClose={() => {
            setDetail(true);
            setSelectedRecommendations(filters.excluded);
          }}
          handleSave={handleSave}
        />
      </MDBox>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        sx={{
          width: "100%",
        }}
      >
        <Grid item xs={12}>
          {detail ? (
            <MDBox style={{ display: "flex", flexDirection: "column" }}>
              {selectedRecommendations?.length > 0 &&
                selectedRecommendations?.map((item) => (
                  <MDTypography
                    key={item?._id || item?.value}
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    - {item?.title || item?.label}
                  </MDTypography>
                ))}
            </MDBox>
          ) : (
            <RecommendationsPicker
              destination={destination}
              locationId={locationId}
              tags={tags}
              recommendations={{ name: "recommendation", Label: "Recommendation" }}
              recommendationsV={
                selectedRecommendations?.length > 0 && selectedRecommendations[0]?._id?.length > 0
                  ? selectedRecommendations?.map((item) => ({
                      label: item?.title,
                      value: item?._id,
                    }))
                  : selectedRecommendations
              }
              setFieldValue={() => {}}
              handleChange={handleRecommendationChange}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

// Setting default props
ExcludeRecommendation.defaultProps = {
  locationId: "",
  // city: "",
  filters: {},
  tags: {},
  destination: "",
};

// typechecking props for ExcludeRecommendation
ExcludeRecommendation.propTypes = {
  locationId: PropTypes.string,
  // city: PropTypes.string,
  destination: PropTypes.string,
  filters: PropTypes.instanceOf(Object),
  tags: PropTypes.instanceOf(Object),
};
export default ExcludeRecommendation;
