/* eslint-disable no-underscore-dangle */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    name,
    role,
    partner,
    bio,
    languages,
    interests,
    destinations,
    media,
    photo,
    location,
    commission,
    balance,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string()
      .matches(/([A-Za-z])/, name.invalidMsg)
      .required(name.errorMsg),
    [role.name]: Yup.string().required(role.errorMsg),
  }),
  Yup.object().shape({
    [partner.name]: Yup.string().required(partner.errorMsg),
  }),
  Yup.object().shape({
    [bio.name]: Yup.string().required(bio.errorMsg),
    [interests.name]: Yup.array().min(1, interests.errorMsg).required(),
    [languages.name]: Yup.array().min(1, languages.invalidMsg).required(),
    [destinations.name]: Yup.array().min(1, destinations.errorMsg).required(),
    [media.name]: Yup.lazy((value, { parent }) => {
      if (parent.type === "image") {
        return Yup.array().min(1, media.errorMsg).required();
      }
      return Yup.string()
        .required("A YouTube link is required.")
        .matches(
          /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=[\w-]{11}(&[\w-]+=[\w%-]*)*)|(youtu\.be\/[\w-]{11}(\?[\w-]+=[\w%-]*)*)$/,
          "Please enter a valid YouTube link."
        );
    }),
    [photo.name]: Yup.string().required(photo.errorMsg).nullable(),
  }),
  Yup.object().shape({
    locDestinations: Yup.object().shape(
      {
        location: Yup.object().when("destinations", {
          is: (desName) => !desName || desName?.length === 0,
          then: () => Yup.object().required(location.errorMsg),
        }),
        destinations: Yup.object().when("location", {
          is: (loc) => !loc || loc?.length === 0,
          then: () => Yup.object().required(destinations.errorMsg),
        }),
      },
      ["destinations", "location"]
    ),
    [balance.name]: Yup.number()
      .min(0, "Initial balance must be at least 0")
      .required(balance.errorMsg),
    [commission.name]: Yup.number()
      .min(0, "Commission must be between 0 and 50")
      .max(50, "Commission must be between 0 and 50")
      .required(commission.errorMsg),
  }),
];

export default validations;
