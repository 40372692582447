/* eslint-disable no-underscore-dangle */
import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";

const TicketsTable = ({ orders, items }) => (
  <Table
    style={{
      marginBottom: "25px",
      borderBottom: "hidden",
    }}
  >
    {items.map(
      (value) => (
        // value.tickets.map((ticket) => (  //removed so that the ticket will be displayed once
        <>
          <TableHead>
            <TableRow>
              <TableCell component="th" style={{ color: "dimgray" }}>
                Ticket
              </TableCell>
              <TableCell component="th" style={{ color: "dimgray" }}>
                Ticket Date
              </TableCell>
              <TableCell component="th" style={{ color: "dimgray" }}>
                Currency
              </TableCell>
              <TableCell component="th" style={{ color: "dimgray" }}>
                Subtotal
              </TableCell>
              <TableCell component="th" style={{ color: "dimgray" }}>
                Discount
              </TableCell>
              <TableCell component="th" style={{ color: "dimgray" }}>
                Total
              </TableCell>
              <TableCell component="th" style={{ color: "dimgray" }}>
                Location
              </TableCell>
              <TableCell component="th" style={{ color: "dimgray" }}>
                City
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={value}>
              <TableCell>{value?.title}</TableCell>
              <TableCell>{value?.date}</TableCell>
              <TableCell>{value?.totalAmount?.currency}</TableCell>
              <TableCell>{value?.totalAmount?.value}</TableCell>
              <TableCell>{value?.discount}</TableCell>
              <TableCell>
                {value && value.totalAmount && value.discount !== undefined
                  ? value.totalAmount.value - value.discount
                  : "N/A"}
              </TableCell>
              <TableCell>{orders?.location}</TableCell>
              <TableCell>{value?.city}</TableCell>
            </TableRow>
          </TableBody>
        </>
      )
      // ))
    )}
  </Table>
);

TicketsTable.defaultProps = {
  items: [],
  orders: {},
};

TicketsTable.propTypes = {
  items: PropTypes.instanceOf(Array),
  orders: PropTypes.instanceOf(Object),
};

export default TicketsTable;
