/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import axios from "axios";
import {
  SET_FACILITY_LOADING,
  CLEAR_ERRORS,
  FACILITY_ADD_SUCCESS,
  FACILITY_FAIL,
  UPDATE_FACILITY,
  SET_FACILITY_EMPTY,
} from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Add facility
export const addFacility = (data, image, loc) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();
  form.append("title", data.title.trim());
  if (data.description) form.append("description", data.description);
  if (data?.location) form.append("location", data?.location);
  if (data.ctaTitle) form.append("ctaTitle", data.ctaTitle);
  if (data.ctaLink) form.append("ctaLink", data.ctaLink);
  if (data.subHeader) form.append("subHeader", data.subHeader);
  if (data.openHours) form.append("openHours", data.openHours);

  if (data?.file === undefined) {
    form.append("file", "");
  } else {
    form.append("file", data.file);
  }
  if (image) form.append("image", image);
  if (data?.image) form.append("image", data?.image);
  if (loc) form.append("location", loc?._id);

  try {
    const res = await axios.post(`${URL}/facilities`, form, config);
    dispatch({
      type: FACILITY_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FACILITY_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

//  Update facility
export const updateFacility = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  // to tell backend that images were cleared
  if (image) form.append("image", image);
  if (data.title) form.append("title", data.title.trim());
  if (data.description) form.append("description", data.description);
  if (data.ctaTitle) form.append("ctaTitle", data.ctaTitle);
  if (data.ctaLink) form.append("ctaLink", data.ctaLink);
  if (data.subHeader) form.append("subHeader", data.subHeader);
  if (data.openHours) form.append("openHours", data.openHours);
  if (data.location) form.append("location", data?.location?._id);
  form.append("file", data?.file);

  try {
    const res = await axios.patch(`${URL}/facilities/${data._id}`, form, config);

    dispatch({
      type: UPDATE_FACILITY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FACILITY_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Delete Facility - set active as false
export const deleteFacility = (id) =>
  factory.patch({ active: false }, `${URL}/facilities/${id}`, "DELETE_FACILITY", "FACILITY_FAIL");

// get One facility
export const getOneFacility = (id) =>
  factory.get(`${URL}/facilities/${id}`, "GET_ONE_FACILITY", "FACILITY_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_FACILITY_LOADING });

export const updateFacilityTranslations = (data, id) =>
  factory.patch(
    {
      translations: {
        ar: data,
      },
    },
    `${URL}/facilities/translations/${id}?language=ar`,
    "UPDATE_FACILITY_TRANSLATION",
    "FACILITY_FAIL"
  );

export const setFacilityEmpty = () => ({ type: SET_FACILITY_EMPTY });

export const getLocationFacilities = (locationSlug) =>
  factory.get(
    `${URL}/facilities/location/${locationSlug}`,
    "GET_LOCATION_FACILITIES",
    "FACILITY_FAIL"
  );
