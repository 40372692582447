const form = {
  formId: "new-user-form",
  formField: {
    // traffic by country page
    country: {
      name: "country",
      label: "Country *",
      type: "text",
      errorMsg: "Country is required!",
    },
    city: {
      name: "city",
      label: "City",
    },
    location: {
      name: "location",
      label: "Location",
    },
  },
};

export default form;
