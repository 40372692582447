/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */

// @mui material components
import { useDispatch, useSelector } from "react-redux";
import ReactTimeAgo from "react-time-ago";
import { formatDistanceToNow } from "date-fns";

import Grid from "@mui/material/Grid";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import Person from "@mui/icons-material/Person";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import ReportsLineChart from "components/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import { ArrowDownward } from "@mui/icons-material";
import ArrowUpward from "@mui/icons-material/ArrowUpward";

// Anaytics dashboard components
// import SalesByCountry from "pages/dashboards/analytics/components/SalesByCountry";

import { useEffect, useState } from "react";
import setAlert from "store/actions/alertActions";
import {
  clearErrors,
  setLoading,
  getDashboardStats,
  getTopViewedItems,
  getPartnerDashboard,
  getOrders,
  getCompanionAnalytics,
} from "store/actions/statsActions";
import { getUserPartnerLocations, setLocation } from "store/actions/locationAction";
import LocationUserPartner from "components/LocationUserPartner";
import form from "pages/users/edit-user/schema/form";
import Loader from "components/Loader";
import MDTypography from "components/MDTypography";
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import OrdersDataTable from "../sales/components/OrdersDataTable";
import TopViewedItems from "../sales/components/TopViewedItems";

const Analytics = () => {
  const { location } = form.formField;

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.users.user);
  const adminDashboard = useSelector((state) => state.stats.adminDashboard);
  const topItems = useSelector((state) => state.stats.topItems);

  const error = useSelector((state) => state.stats.error);
  const locations = useSelector((state) => state.locations.locations);
  const loading = useSelector((state) => state.stats.loading);
  const companionAnalytics = useSelector((state) => state.stats.companionAnalytics);

  const [firstRun, setFirstRun] = useState(false);
  const [locationId, setLocationId] = useState("");

  const renderAudiReportDescription = () => {
    if (adminDashboard.auReport?.totalVisitors) {
      return <p>Number of visitors last month: {adminDashboard.auReport.totalVisitors}</p>;
    }

    if (adminDashboard.auReport?.dataExist) {
      return <p>Last 24 Hours</p>;
    }

    return (
      <p>
        <span style={{ color: "red" }}>No Data Available</span>
      </p>
    );
  };

  const renderDescription = () => {
    if (!adminDashboard.dailySales?.dataExist) {
      return (
        <p>
          <span style={{ color: "red" }}>No Data Available</span>
        </p>
      );
    }

    if (adminDashboard.dailySales.change < 0) {
      return (
        <p>
          <span style={{ color: "red" }}>
            <ArrowDownward /> {Math.abs(adminDashboard.dailySales.change?.toFixed(2))}%
          </span>{" "}
          decrease in today&#39;s sales.
        </p>
      );
    }

    return (
      <p>
        <span>
          <ArrowUpward /> {adminDashboard.dailySales.change?.toFixed(2)}%
        </span>{" "}
        increase in today&#39;s sales.
      </p>
    );
  };

  const handleChange = (option, val) => {
    setLocationId(val?._id);
    dispatch(setLocation(val?._id));
  };
  useEffect(() => {
    if (locations?.length > 0 && !locationId) {
      setLocationId(locations[0]?._id);
    }
  }, [locations]);

  useEffect(() => {
    dispatch(setLoading());
    if (user?.role === "admin" && firstRun) {
      dispatch(getDashboardStats(timeZone));
      dispatch(getTopViewedItems());
    }
    if (user?.role === "partner" && firstRun) {
      dispatch(getUserPartnerLocations());
      dispatch(getTopViewedItems());
    }
  }, [firstRun]);

  useEffect(() => {
    setFirstRun(true);
  }, []);

  useEffect(() => {
    if (locationId && firstRun && user.role === "partner") {
      dispatch(getPartnerDashboard(locationId, timeZone));
      dispatch(getCompanionAnalytics(locationId));
    }
    if (locationId || (user.role === "admin" && firstRun)) {
      dispatch(getOrders(locationId));
    }
  }, [locationId, firstRun]);

  // ------useEffect-------
  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
    // eslint-disable-next-line
  }, [error, setAlert]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              left: 100,
              zIndex: 10,
              backgroundColor: "rgba(211, 211, 211, 0.5)", // Semi-transparent light grey
            }}
          >
            <Loader size={40} />
          </div>
        )}
        <MDBox mt={1.5}>
          <Grid container>
            {user?.role === "partner" && locations.length > 0 && (
              <Grid item xs={4}>
                <LocationUserPartner
                  defaultLocationId={locationId}
                  handleChange={handleChange}
                  locations={locations || {}}
                  location={location}
                />
              </Grid>
            )}
          </Grid>
          {user.role === "partner" && (
            <MDBox style={{ marginBottom: "24px" }}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" mb={2}>
                Guest companion Stats
              </MDTypography>
              <Grid container spacing={3} mb={4}>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    icon="tour"
                    title="Companion Vists"
                    value={companionAnalytics?.totalViews || 0}
                    color="success"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    icon="request_page"
                    title="Requests Created"
                    value={companionAnalytics?.requestCreated || 0}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    icon="request_quote"
                    title="Avg. Requests Per Guest"
                    value={companionAnalytics?.averageRequestPerGuest || 0}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    icon="login"
                    title="Logins"
                    value={companionAnalytics?.logins || 0}
                    color="dark"
                  />
                </Grid>
              </Grid>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Travel Companion stats
              </MDTypography>
            </MDBox>
          )}

          <Grid container spacing={3}>
            {user.role === "admin" && (
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon={<Person />}
                    title="Users"
                    count={adminDashboard.users}
                    subHeader={{
                      color: "success",
                      label: "Add new",
                      action: "/admin/users/new-user",
                      amount: "", // Add the 'amount' property here
                    }}
                  />
                </MDBox>
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5} py={user.role === "partner" ? 3 : 0}>
                <ComplexStatisticsCard
                  icon={user.role === "partner" ? <ManageAccountsIcon /> : <GroupWorkIcon />}
                  title={user.role === "partner" ? "Experiences" : "Partners"}
                  count={
                    user.role === "partner" ? adminDashboard.experiences : adminDashboard.partners
                  }
                  subHeader={{
                    color: "success",
                    label: user.role === "partner" ? "" : "Add new",
                    action: user.role === "partner" ? "" : "/admin/partners/new-partner",
                    amount: "",
                  }}
                />
              </MDBox>
            </Grid>
            {user.role === "admin" ? (
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon={<FlagCircleIcon />}
                    title="Destinations"
                    count={adminDashboard.destinations}
                    subHeader={{
                      color: "success",
                      label: "Add new",
                      action: "/admin/destinations/new-destination",
                      amount: "",
                    }}
                  />
                </MDBox>
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5} py={user.role === "partner" ? 3 : 0}>
                <ComplexStatisticsCard
                  color="dark"
                  icon={<TheaterComedyIcon />}
                  title="Recommendations"
                  count={adminDashboard.recommendations}
                  subHeader={{
                    color: "success",
                    label: "Add new",
                    action: `/${user.role}/recommendations/new-recommendation`,
                    amount: "",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5} py={user.role === "partner" ? 3 : 0}>
                <ComplexStatisticsCard
                  color="success"
                  icon={<LocalActivityIcon />}
                  title="Tickets"
                  count={adminDashboard.tickets}
                  subHeader={{
                    color: "success",
                    label: "Add new",
                    action: `/${user.role}/tickets/new-old-ticket`,
                    amount: "",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon={user.role === "partner" ? <LocalOfferIcon /> : <LocationCityIcon />}
                  title={user.role === "partner" ? "Offers" : "Locations"}
                  count={user.role === "partner" ? adminDashboard.offers : adminDashboard.locations}
                  subHeader={{
                    color: "success",
                    label: user.role === "partner" ? "" : "Add new",
                    action: user.role === "admin" ? "/admin/locations/new-location" : "",
                    amount: "",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={6}>
          {/* <Grid container> */}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Visitors"
                  description={renderAudiReportDescription()}
                  date={
                    <div>
                      updated:{" "}
                      {adminDashboard.auReport?.timeZoneNow && (
                        <ReactTimeAgo
                          date={new Date(adminDashboard.auReport?.timeZoneNow)}
                          locale="en-US"
                          // timeStyle="twitter"
                        />
                      )}
                    </div>
                  }
                  chart={{
                    labels: adminDashboard.auReport?.labels,
                    datasets: { label: "Visitors", data: adminDashboard.auReport?.series[0] },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={renderDescription()}
                  date={
                    adminDashboard.dailySales && adminDashboard.dailySales.dataExist ? (
                      <div>
                        Last Order completed{" "}
                        {adminDashboard.dailySales?.lastOrder &&
                          formatDistanceToNow(new Date(adminDashboard.dailySales.lastOrder), {
                            addSuffix: true,
                          })}
                      </div>
                    ) : (
                      <div>No orders made yet</div>
                    )
                  }
                  chart={{
                    labels: adminDashboard.dailySales?.labels,
                    datasets: { label: "Daily sales", data: adminDashboard.dailySales?.series[0] },
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox py={3}>
            <MDBox mb={3}>
              <OrdersDataTable locationId={user.role === "partner" ? locationId : null} />
            </MDBox>
            <MDBox mb={3}>
              <TopViewedItems topItems={topItems} />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Analytics;
