import checkout from "./form";

const {
  formField: {
    // facility page
    title,
    openHours,
    subHeader,
    ctaTitle,
    ctaLink,
    description,
    location,
    file,
    image,
  },
} = checkout;

const initialValues = {
  // facility page
  [title?.name]: "",
  [openHours?.name]: "",
  [subHeader?.name]: "",
  [ctaTitle?.name]: "",
  [ctaLink?.name]: "",
  [description?.name]: "",
  [location?.name]: "",
  [file?.name]: "",
  [image?.name]: "",
};

export default initialValues;
