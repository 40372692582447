/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import MaterialTable, { MTableBodyRow, MTableToolbar } from "@material-table/core";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getUpdatedFilter } from "store/actions/userActions";
import { Typography } from "@mui/material";
import { setLoading } from "store/actions/exportAction";
import { Delete, Edit, Refresh } from "@mui/icons-material";
import Loader from "components/Loader";
import TableColumns from "./TableColumns";
import { urlApiBuilder } from "./urlApiBuilder";

const DynamicMaterialTable = ({
  title,
  data,
  itineraryType,
  setItineraryType,
  columns,
  searchField,
  components,
  tableRef,
  isLoading,
  style,
  sortField,
  isFreeActions,
  isFreeDownloadAction,
  direction,
  editableDelete,
  deleteRoleAction,
  editableUpdate,
  isRowClickable,
  editableRole,
  idSynonymName,
  actionsOnClick,
  stopsTable,
  usersTable,
  ticketsTable,
  elementsTable,
  locationBoxesTable,
  boxesTable,
  detailPanel,
  itemType,
  onRowClickHandler,
  asyncTable,
  filteringOptions,
  page,
  asyncURL,
  onRowClickURL,
  currentUrl,
  addUrl,
  isGrouping,
  actionsChild,
  actionsRole,
  isSearchable,
  isExportable,
  isMenuDownload,
  isActions,
  actionsName,
  // exportMenuField,
  updateRoleAction,
  onRowClickFn,
  sortFn,
  isOrder,
  locationName,
  tableInside,
  expId,
  widgetId,
  locationId,
  cityName,
  getPdf,
  getCsv,
  onSearchChange,
  invoiceTable,
  showRefresh,
  handleRefresh,
  refreshLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [searchParams] = useSearchParams();
  const row_nb = useRef(parseInt("0", 10) || 10);
  const [sourceName, setSourceName] = useState("");

  const user = useSelector((state) => state.users.user);
  const reduxFilters = useSelector((state) => state.users.filters);
  const exportLoading = useSelector((state) => state.exports.loading);

  const [updatedCol, setUpdatedCol] = useState([]);
  const [filtersSearch, setFiltersSearch] = useState("");
  const [pageValue, setPageValue] = React.useState(parseInt("0", 10));
  const [editableProps, setEditableProps] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBool, setSortBool] = useState(false);
  const [sortedArr, setSortedArr] = useState([]);
  const [queryString, setQueryString] = useState("");
  const [total, setTotal] = useState("");

  const handleFilterChange = (e) => {
    dispatch(
      getUpdatedFilter({
        search: reduxFilters?.search,
        row_nb: reduxFilters?.row_nb,
        pageValue: reduxFilters?.pageValue,
        filters: e,
      })
    );
    navigate(`/${user.role}/${currentUrl}`);
  };

  const handleOnRowClick = (type, rowData) => {
    const obj = {
      filters: filtersSearch,
      search: reduxFilters?.search,
      page: pageValue,
      rows: row_nb.current,
    };
    let url = null;
    if (searchField === "attractionsList") {
      if (type === "admin") {
        url = `/${user.role}/attractions/${rowData._id}?`;
      } else if (type === "partner") {
        url = `/${user.role}/attractions/${rowData._id}?location=${locationName}`;
      }
    } else if (searchField === "attractions") {
      if (type === "admin" || type === "partner") {
        url = `/${user.role}/attraction/${rowData._id}?`;

        if (sourceName) obj.source = sourceName;
        if (reduxFilters?.search) obj.sourceAttraction = reduxFilters?.search;

        delete obj.filters;
        delete obj.search;
      }
    } else if (searchField === "items?itemType=ticket&") {
      if (type === "admin" || type === "partner") {
        url = `/${user.role}/${onRowClickHandler}/${rowData._id}?itemType=${itemType}&`;
      }
    } else if (searchField === "experiences") {
      if (type === "admin" || type === "partner") {
        url = `/${user.role}/experiences/${rowData._id}?`;
      }
    } else if (type === "admin" || type === "partner") {
      url = `${onRowClickURL}/${rowData._id}?`;
    }

    if (url) {
      url += urlApiBuilder(obj);
      navigate(url);
    }
  };

  const handleSearchChange = (e) => {
    dispatch(
      getUpdatedFilter({
        filters: reduxFilters?.filters,
        row_nb: reduxFilters?.row_nb,
        pageValue: reduxFilters?.pageValue,
        search: e,
      })
    );
  };

  const handlePageChange = (e) => {
    dispatch(
      getUpdatedFilter({
        filters: reduxFilters?.filters,
        search: reduxFilters?.search,
        row_nb: reduxFilters?.row_nb,
        pageValue: e,
      })
    );

    if (pageValue !== e && !pageValue.isNaN && e !== 0) {
      setPageValue(e);
      let urlTemp;
      navigate(urlTemp);
    }
  };

  const handleRowPerPage = (e) => {
    dispatch(
      getUpdatedFilter({
        filters: reduxFilters?.filters,
        search: reduxFilters?.search,
        row_nb: e,
        pageValue: reduxFilters?.pageValue,
      })
    );
    // row_nb.current = e;
  };

  const getColumns = () => {
    const colArr = columns.map((item) => {
      const temp = TableColumns.find((col) => col.title === item);

      if (asyncTable && reduxFilters?.filters === undefined) {
        // Clear the default filter value if the condition is true
        temp.tableData = {};
        temp.filterValue = "";
      }

      return temp;
    });
    setUpdatedCol(colArr);
  };

  const tableIcons = {
    Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),

    Export: forwardRef((props, ref) => {
      if (exportLoading) {
        return (
          <CircularProgress
            {...props}
            ref={ref}
            style={{ fontSize: "16px", width: "16px", height: "16px" }}
          />
        );
      }
      return <FileDownloadIcon {...props} ref={ref} />;
    }),
  };

  const asyncDataFn = (query) =>
    new Promise((resolve) => {
      let url = asyncURL;
      url += `limit=${query.pageSize}`;
      url += `&page=${query.page + 1}`;
      url += `&search=${encodeURIComponent(query.search)}`;
      if (query.orderBy && query.orderDirection !== "") {
        if (query.orderBy?.field === "retail_price.formatted_iso_value") {
          url += "&sort=retail_price.value";
        } else {
          url += `&sort=${query.orderBy?.field}`;
          url += `&dir=${query.orderDirection}`;
        }
      } else {
        url += `&sort=${sortField}`;
        url += `&dir=${direction}`;
      }
      const copyFilters = query.filters?.length > 0 ? query.filters : reduxFilters.filters;
      if (copyFilters?.length > 0)
        copyFilters.forEach((item) => {
          let field;
          if (item.column.field === "title") {
            field = "title";
            const itemTitle = item.value;
            item.value = itemTitle;
          } else if (item.column.field === "author") {
            field = "author";
            const author = item.value;
            item.value = author;
          } else if (item.column.field === "retail_price.formatted_iso_value") {
            field = "price";
            const number = Number(item.value.toString().replace(/[^0-9.-]+/g, ""));
            item.value = number;
          } else if (
            item.column.field === "city" &&
            (title === "Attractions" || title === "Experiences" || title === "Jobs")
          ) {
            field = "itemCity";
          } else if (item.column.field === "geoLocationData.city") {
            field = "city";
          } else if (item.column.field === "tag" && ticketsTable) {
            field = "tags";
          } else if (item.column.field === "price.fromPrice" && ticketsTable) {
            field = "price";
          } else if (item.column.field === "category.name") {
            field = "catName";
          } else if (item.column.field === "partner.name") {
            field = "partnerName";
          } else if (
            item.column.field === "source" ||
            item.column.field === "role" ||
            item.column.field === "loginType"
          ) {
            if (item.value === "all") {
              return null;
            }
            field = item.column.field;
          } else if (item.column.field === "country.iso_code") {
            field = "isoCode";
          } else if (item.column.field === "country.name") {
            field = "cityCountry";
          } else if (item.column.field === "top" && item.value === "checked") {
            field = "top";
            item.value = "true";
          } else if (item.column.field === "top" && item.value === "unchecked") {
            field = "top";
            item.value = "false";
          } else if (item.column.field === "travelDesk.name") {
            field = "travelDesk";
          } else if (item.column.field === "location" && usersTable) {
            field = "sourceLocation";
          } else if (item.column.field === "tags") {
            const itemVal = item.value;
            field = item.column.field;
            item.value = itemVal?.trim();
          } else {
            field = item.column.field;
          }
          url += `&${field}=${encodeURIComponent(item.value)}`;
          return null;
        });
      setQueryString(url.replace(/^[^?]*\?/, "?"));
      setFiltersSearch(url.split("search")[1]?.length > 1 && url.split("search")[1]);
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve({
            data: result.data,
            page: result.page - 1,
            totalCount: result.total,
          });
          setTotal(result.total);
          // setQueryString(queryString.replace(/limit=\d+/, `limit=${result.total}`));
        });
      // eslint-disable-next-line no-console
    }).catch((error) => console.log(error.message));

  const handleClickCondition = (rowData, togglePanel) => {
    if (!isRowClickable) {
      return detailPanel ? togglePanel() : {};
    }

    if (searchField === "recommendationsDuplicates" || searchField === "elements") {
      return onRowClickFn(rowData);
    }

    if (searchField === "users" || searchField === "news" || user.role === "admin") {
      return handleOnRowClick("admin", rowData);
    }

    return handleOnRowClick("partner", rowData);
  };

  const DragState = { row: -1, dropIndex: -1 };
  // Reorder the table row
  const offsetIndex = (from, to, arr = []) => {
    if (from < to) {
      const start = arr.slice(0, from);
      const between = arr.slice(from + 1, to + 1);
      const end = arr.slice(to + 1);
      return [...start, ...between, arr[from], ...end];
    }
    if (from > to) {
      const start = arr.slice(0, to);
      const between = arr.slice(to, from);
      const end = arr.slice(from + 1);
      return [...start, arr[from], ...between, ...end];
    }
    return arr;
  };

  const handleStopIndex = (mainStop) => {
    const newStateArray = [];
    mainStop?.map((stop, i) => {
      if (stop.type === "items") {
        let newState = {
          stop: 0,
          order: 0,
          alternative: "",
          type: "",
          duration: "",
          message: "",
        };
        newState = {
          stop: stop?.stop?._id,
          order: i,
          alternative: stop?.alternative,
          type: stop?.type,
          duration: stop?.duration,
          message: stop?.message,
        };
        newStateArray.push(newState);
        return null;
      }
      if (stop.type === "recommendations") {
        let newState = {
          stop: 0,
          order: 0,
          alternative: "",
          type: "",
          duration: "",
          message: "",
        };
        newState = {
          stop: stop?.stop?._id,
          order: i,
          alternative: stop?.alternative,
          type: stop?.type,
          duration: stop?.duration,
          message: stop?.message,
        };
        newStateArray.push(newState);
        return null;
      }
      return null;
    });
    setSortedArr(newStateArray);
  };

  const handleElementIndex = (mainElement) => {
    const newStateArray = [];

    mainElement?.forEach((element, i) => {
      let newState = {
        element: 0,
        order: 0,
        title: "",
        description: "",
        ctaTitle: "",
        ctaUrl: "",
        files: [],
      };
      newState = {
        element: element?._id,
        order: i,
        title: element?.title,
        description: element?.description,
        ctaTitle: element?.ctaTitle,
        ctaUrl: element?.ctaUrl ? element?.ctaUrl : "",
        files: element?.files ? element?.files : [],
      };
      newStateArray.push(newState);
    });
    setSortedArr(newStateArray);
  };

  const handleIndex = (mainMenu) => {
    const newStateArray = [];
    mainMenu.map((item, i) => {
      let newState = { id: 0, order: 0 };
      newState = { id: item?._id, order: i };
      newStateArray.push(newState);
      return item;
    });
    setSortedArr(newStateArray);
  };

  const reOrderRow = (from, to) => {
    const newtableData = offsetIndex(from, to, tableData);
    // Update react state
    setTableData(newtableData);
    if (stopsTable) {
      handleStopIndex(newtableData);
    } else if (elementsTable) handleElementIndex(newtableData);
    else handleIndex(newtableData);
    setSortBool(true);
  };

  useEffect(() => {
    if (sortBool && isOrder) {
      if (stopsTable) {
        dispatch(sortFn(sortedArr, expId));
        setSortBool(false);
      } else if (elementsTable) {
        dispatch(sortFn(sortedArr, widgetId));
        setSortBool(false);
      } else if (locationBoxesTable) {
        dispatch(sortFn(sortedArr, locationId));
        setSortBool(false);
      } else if (boxesTable) {
        dispatch(sortFn(sortedArr, cityName));
        setSortBool(false);
      } else {
        dispatch(sortFn(sortedArr));
        setSortBool(false);
      }
    }
  }, [sortBool]);

  useEffect(() => {
    if (window.location.href.indexOf("travelDesk") > 0) {
      setSourceName("travelDesk");
    } else {
      setSourceName("partner");
    }
  }, []);

  useEffect(() => {
    if (isOrder) {
      setTableData(data);
      if (stopsTable) {
        handleStopIndex(data);
      } else {
        handleIndex(data);
      }
    }
  }, [data]);

  useEffect(() => {
    if (asyncTable && reduxFilters?.filters?.length === 0) tableRef.current.onQueryChange(); // add this to handle the filter clear effect
  }, [reduxFilters?.filters]);

  useEffect(() => {
    if (reduxFilters?.pageValue) setPageValue(parseInt(reduxFilters?.pageValue, 10));
  }, [reduxFilters?.pageValue]);

  useEffect(() => {
    getColumns();
    if (editableRole) {
      if (updateRoleAction && deleteRoleAction) {
        setEditableProps({
          onRowUpdate: (newData) => {
            newData.city = newData.geoLocation?.city || newData.city;
            return new Promise((resolve) => {
              setTimeout(() => {
                editableUpdate(newData);
                resolve();
              }, 1000);
              // eslint-disable-next-line no-console
            }).catch((error) => console.log(error.message));
          },
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                if (searchField === "recommendations" || searchField === "tickets")
                  editableDelete(oldData);
                else editableDelete(oldData?._id);
                resolve();
              }, 1000);
              // eslint-disable-next-line no-console
            }).catch((error) => console.log(error.message)),
        });
      } else if (deleteRoleAction) {
        setEditableProps({
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                if (searchField === "stops" || searchField === "elements") {
                  editableDelete(oldData);
                } else editableDelete(oldData._id);
                resolve();
              }, 1000);
              // eslint-disable-next-line no-console
            }).catch((error) => console.log(error.message)),
        });
      }
    } else {
      // Reset editableProps when editableRole is false
      setEditableProps({});
    }
  }, [editableRole, deleteRoleAction, updateRoleAction]);

  return (
    <MaterialTable
      title=""
      onRowsPerPageChange={handleRowPerPage}
      onSearchChange={currentUrl === "menus" ? onSearchChange : handleSearchChange}
      onFilterChange={handleFilterChange}
      onPageChange={handlePageChange}
      page={page}
      columns={updatedCol}
      // eslint-disable-next-line no-nested-ternary
      data={asyncTable ? (query) => asyncDataFn(query) : !isOrder ? data : tableData}
      tableRef={tableRef}
      components={{
        ...components,
        Toolbar: (props) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #e8eaf5",
              padding: "16px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography variant="h4">{title}</Typography>

              {itineraryType && (
                <div
                  style={{
                    height: "35px",
                    display: "flex",
                    overflow: "hidden",
                    borderRadius: "10px",
                    border: "black solid 1px",
                  }}
                >
                  <button
                    type="button"
                    onClick={() => setItineraryType("Curated")}
                    style={{
                      flex: 1,
                      width: "80px",
                      height: "100%",
                      border: "none",
                      display: "flex",
                      padding: "10px",
                      cursor: "pointer",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: itineraryType === "Curated" ? "#d3d3d3" : "transparent",
                    }}
                  >
                    <p>Curated</p>
                  </button>

                  <button
                    type="button"
                    onClick={() => setItineraryType("AI")}
                    style={{
                      flex: 1,
                      width: "80px",
                      border: "none",
                      height: "100%",
                      padding: "10px",
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: itineraryType === "AI" ? "#d3d3d3" : "transparent",
                    }}
                  >
                    <p>AI</p>
                  </button>
                </div>
              )}
            </div>

            <div>
              <MTableToolbar {...props} />
            </div>
          </div>
        ),
        Row: (props) => {
          if (isOrder) {
            return (
              <MTableBodyRow
                {...props}
                draggable="true"
                onDragStart={() => {
                  DragState.row = props.index;
                }}
                onDragEnter={(e) => {
                  e.preventDefault();
                  if (props.index !== DragState.row) {
                    DragState.dropIndex = props.index;
                  }
                }}
                onDragEnd={() => {
                  if (DragState.dropIndex !== -1) {
                    reOrderRow(DragState.row, DragState.dropIndex);
                  }
                  DragState.row = -1;
                  DragState.dropIndex = -1;
                }}
              />
            );
          }
          return <MTableBodyRow {...props} />;
        },
      }}
      style={style}
      asyncURL={asyncURL}
      detailPanel={detailPanel && detailPanel}
      isLoading={isLoading}
      onRowClick={(event, rowData, togglePanel) => handleClickCondition(rowData, togglePanel)}
      editable={editableProps}
      icons={tableIcons}
      actions={
        isActions && actionsRole
          ? [
              {
                icon:
                  actionsOnClick && (stopsTable || elementsTable)
                    ? "add"
                    : actionsOnClick
                    ? FileDownloadIcon
                    : "add",

                tooltip:
                  actionsOnClick && (stopsTable || elementsTable)
                    ? `Add ${actionsName}`
                    : actionsOnClick
                    ? "Download as PDF"
                    : `Add ${actionsName}`,
                isFreeAction: isFreeActions,
                onClick: (event, item) =>
                  actionsOnClick ? actionsOnClick(item) : navigate(`/${user.role}/${addUrl}`),
                iconProps: {
                  style: {
                    marginLeft: "10px",
                    fontSize: "25px",
                    color: isFreeActions && !isFreeDownloadAction ? "red" : "black",
                    boxShadow: "none", // Remove shadow on hover
                  },
                },
              },
              // Conditionally render the refresh icon
              ...(showRefresh
                ? [
                    {
                      icon: refreshLoading ? () => <Loader size={24} /> : Refresh,
                      tooltip: "Refresh",
                      isFreeAction: true,
                      onClick: () => handleRefresh(),
                      iconProps: {
                        style: {
                          marginLeft: "10px",
                          fontSize: "25px",
                          color: "black",
                        },
                      },
                    },
                  ]
                : []),
              (rowData) => actionsChild(rowData),
            ]
          : []
      }
      options={{
        emptyRowsWhenPaging: false,
        grouping: isGrouping,
        search: isSearchable,
        searchText: reduxFilters?.search,
        idSynonym: `${idSynonymName}`, // location report pageTitle
        initialPage: reduxFilters?.pageValue ? parseInt(reduxFilters?.pageValue, 10) : pageValue,
        actionsColumnIndex: tableInside ? -2 : -1,
        exportAllData: true,
        exportMenu:
          isExportable && isMenuDownload
            ? [
                !invoiceTable && {
                  label: "Export PDF",
                  exportFunc: (option) => {
                    if (option && option.length > 0) {
                      const body = option.map((item) => ({
                        name: item.title,
                        field: item.field,
                      }));
                      // // Additional logic to include location when user role is partner
                      getPdf(body, queryString.replace(/limit=\d+/, `limit=${total}`));
                      dispatch(setLoading());
                    } else {
                      // Handle the case where 'option' is undefined or empty
                    }
                  },
                },
                {
                  label: "Export CSV",
                  exportFunc: (option) => {
                    if (option && option.length > 0) {
                      dispatch(setLoading());
                      getCsv(
                        queryString
                          .replace(/limit=\d+/, `limit=${total}`)
                          .replace(/dir=[^&]+/, "dir=asc")
                      );
                    } else {
                      // Handle the case where 'option' is undefined or empty
                    }
                  },
                },
              ]
            : [],
        exportButton: isExportable,
        pageSize: reduxFilters?.row_nb ? parseInt(reduxFilters?.row_nb, 10) : 10,
        headerStyle: {
          // textAlign: "center",
          fontSize: "15px",
          lineHeight: "1.25rem",
          fontWeight: 600,
          backgroundColor: tableInside && "#BEBEBE",
        },
        rowStyle: {
          backgroundColor: tableInside && "#EEE",
        },
        debounceInterval: 1000,
        filtering: filteringOptions,
      }}
    />
  );
};

DynamicMaterialTable.defaultProps = {
  itineraryType: "",
  setItineraryType: () => {},
  invoiceTable: false,
  isLoading: false,
  tableRef: {},
  actionsName: "",
  components: {},
  style: {},
  columns: [],
  asyncTable: false,
  page: null,
  data: [],
  asyncURL: "",
  sortField: "",
  searchField: "",
  onRowClickURL: "",
  currentUrl: "",
  addUrl: "",
  direction: "asc",
  // exportMenuField: "",
  idSynonymName: "",
  filteringOptions: true,
  isGrouping: false,
  actionsRole: false,
  editableRole: false,
  isActions: true,
  isSearchable: true,
  isExportable: true,
  isMenuDownload: true,
  isRowClickable: true,
  isFreeActions: true,
  stopsTable: false,
  usersTable: false,
  ticketsTable: false,
  elementsTable: false,
  locationBoxesTable: false,
  boxesTable: false,
  expId: "",
  widgetId: "",
  locationId: "",
  cityName: "",
  isFreeDownloadAction: false,
  deleteRoleAction: false,
  updateRoleAction: false,
  editableDelete: () => {},
  editableUpdate: () => {},
  actionsChild: () => {},
  onRowClickFn: () => {},
  sortFn: () => {},
  isOrder: false,
  detailPanel: null,
  // isDetailPanel: false,
  onRowClickHandler: "",
  itemType: "",
  locationName: "",
  tableInside: false,
  getPdf: () => {},
  getCsv: () => {},
  onSearchChange: () => {},
  showRefresh: false,
  handleRefresh: () => {},
  refreshLoading: false,
};

DynamicMaterialTable.propTypes = {
  itineraryType: PropTypes.string,
  setItineraryType: PropTypes.func,
  invoiceTable: PropTypes.bool,
  onRowClickFn: PropTypes.func,
  sortFn: PropTypes.func,
  itemType: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]).isRequired,
  page: PropTypes.number,
  columns: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Array),
  asyncTable: PropTypes.bool,
  filteringOptions: PropTypes.bool,
  tableInside: PropTypes.bool,
  asyncURL: PropTypes.string,
  onRowClickURL: PropTypes.string,
  currentUrl: PropTypes.string,
  addUrl: PropTypes.string,
  isFreeActions: PropTypes.bool,
  isGrouping: PropTypes.bool,
  isFreeDownloadAction: PropTypes.bool,
  sortField: PropTypes.string,
  idSynonymName: PropTypes.string,
  updateRoleAction: PropTypes.bool,
  isActions: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  actionsOnClick: PropTypes.func,
  detailPanel: PropTypes.func,
  direction: PropTypes.string,
  searchField: PropTypes.string,
  deleteRoleAction: PropTypes.bool,
  tableRef: PropTypes.instanceOf(Object),
  isMenuDownload: PropTypes.bool,
  isLoading: PropTypes.bool,
  actionsName: PropTypes.string,
  // exportMenuField: PropTypes.string,
  editableUpdate: PropTypes.func,
  editableDelete: PropTypes.func,
  editableRole: PropTypes.bool,
  components: PropTypes.instanceOf(Object),
  actionsChild: PropTypes.func,
  style: PropTypes.instanceOf(Object),
  isSearchable: PropTypes.bool,
  isOrder: PropTypes.bool,
  isExportable: PropTypes.bool,
  actionsRole: PropTypes.bool,
  stopsTable: PropTypes.bool,
  usersTable: PropTypes.bool,
  ticketsTable: PropTypes.bool,
  elementsTable: PropTypes.bool,
  locationBoxesTable: PropTypes.bool,
  boxesTable: PropTypes.bool,
  expId: PropTypes.string,
  widgetId: PropTypes.string,
  locationId: PropTypes.string,
  cityName: PropTypes.string,
  isRowClickable: PropTypes.bool,
  onRowClickHandler: PropTypes.string,
  locationName: PropTypes.string,
  getPdf: PropTypes.func,
  getCsv: PropTypes.func,
  onSearchChange: PropTypes.func,
  showRefresh: PropTypes.bool,
  handleRefresh: PropTypes.func,
  refreshLoading: PropTypes.bool,
};

export default DynamicMaterialTable;
