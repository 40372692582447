/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select";
import { capitalizeFirstLetter } from "components/helper";

const ServiceJobsSystemPicker = ({
  handleSystemChange,
  setJobSystem,
  systemV,
  setFieldValue,
  company,
}) => {
  useEffect(() => {
    if (company === "sky") {
      // Automatically set the value to "GuestApp" for Sky
      setFieldValue("system", "guestapp");
      setJobSystem("GuestApp");
      handleSystemChange("GuestApp");
    } else if (company === "messagebox") {
      // Automatically set the value to "ServiceRequest" for MessageBox
      setFieldValue("system", "servicerequest");
      setJobSystem("ServiceRequest");
      handleSystemChange("ServiceRequest");
    } else {
      // Clear the system value if no valid company is selected
      setFieldValue("system", "");
      setJobSystem("");
    }
  }, [company, setFieldValue, setJobSystem, handleSystemChange]);

  // Transform status options into the required format
  const systemOptions = [
    { label: "GuestApp", value: "guestapp" },
    { label: "Service Request", value: "servicerequest" },
  ];

  return (
    <div>
      <AsyncSelect
        options={systemOptions}
        value={
          systemV ? { label: capitalizeFirstLetter(systemV), value: systemV.toLowerCase() } : null
        }
        placeholder="Select a system"
        isDisabled // Disable the select, as it will be controlled by the company
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor,
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow,
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor,
            },
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

// Setting default props
ServiceJobsSystemPicker.defaultProps = {
  systemV: "",
  setJobSystem: () => {},
  handleSystemChange: () => {},
  company: "",
};

// Prop-types for ServiceJobsSystemPicker
ServiceJobsSystemPicker.propTypes = {
  systemV: PropTypes.string,
  company: PropTypes.string,
  setJobSystem: PropTypes.func,
  handleSystemChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
};

export default ServiceJobsSystemPicker;
