/* eslint-disable react/require-default-props */
import React, { useState, createRef, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

const Input = styled("input")({
  display: "none",
});

const BigAvatar = styled(Avatar)`
  width: 74px;
  height: 74px;
`;

const SVGPicker = ({ currentImg, edit }) => {
  const [image, _setImage] = useState(null);
  // const [imgerror, _setImageError] = useState(false);
  const { setFieldValue, values } = useFormikContext();
  const inputFileRef = createRef(null);

  useEffect(() => {
    if (image !== currentImg) _setImage(currentImg);
  }, [currentImg]);

  const cleanup = () => {
    URL.revokeObjectURL(image);
    inputFileRef.current.value = null;
    setFieldValue("icon", null);
  };

  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }

    const img = new Image();
    img.src = newImage;

    img.onload = () => {
      // Fetch the SVG content
      fetch(newImage)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
          }
          return response.text();
        })
        .then((content) => {
          if (content?.includes("polygon")) {
            alert("Select the correct form of the SVG");
            // setAlert("Select the correct form of the SVG", "error");
          } else {
            _setImage(newImage);
          }
        })
        .catch((error) => {
          console.error("Error fetching SVG:", error);
        });
    };
  };

  const [errorImg, setErrorImg] = useState(false);

  const errorImage = (val) => {
    if (errorImg) {
      if (!val) {
        return true;
      }
      return false;
    }
    return null;
  };

  const handleOnChange = (event) => {
    const newImage = event.target?.files[0];
    if (newImage) {
      const fileSize = newImage.size;
      if (fileSize > 2000000) {
        setErrorImg(true);
      } else {
        setImage(URL.createObjectURL(newImage));
        // _setImageBuffer(newImage);
        setFieldValue("icon", newImage);
      }
    }
  };

  return (
    <label htmlFor="icon-button-file" style={{ display: "block", width: "fit-content" }}>
      {edit && (
        <>
          <Input
            accept=".svg"
            id="icon-button-file"
            type="file"
            ref={inputFileRef}
            onChange={handleOnChange}
            sx={{ display: "none" }}
          />
          {/* {errorImage(imageType === "photo" ? values?.photo : values?.logo) && (
            <div>File size exceeds 2 MB!</div>
          )} */}
          {errorImage(values?.icon) && <div>File size for icon exceeds 2 MB!</div>}
        </>
      )}
      <Stack direction="row">
        <div>
          {image ? (
            <MDAvatar
              src={image}
              alt="profile-image"
              size="xl"
              sx={{ "& img": { height: "100%" } }}
            />
          ) : (
            <BigAvatar
              alt="Change your profile photo"
              src={image}
              imgProps={{
                style: {
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "cover",
                },
              }}
            />
          )}
        </div>
        {edit && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton color="info" aria-label="upload picture" component="span" size="large">
              <FileUploadIcon />
            </IconButton>
          </div>
        )}
      </Stack>

      <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
        <ErrorMessage name="icon" />
      </MDTypography>
    </label>
  );
};
// Setting default props
SVGPicker.defaultProps = {
  currentImg: "",
  edit: false,
};

SVGPicker.propTypes = {
  // setAlert: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  currentImg: PropTypes.string,
};

export default SVGPicker;
