const form = {
  formId: "new-element-form",
  formField: {
    // element page
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required.",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
    },
    ctaTitle: {
      name: "ctaTitle",
      label: "Call to Action Label: *",
      type: "text",
      errorMsg: "Call to action label is required.",
    },
    ctaUrl: {
      name: "ctaUrl",
      label: "Call to Action (URL):",
      type: "text",
      errorMsg: "cta URL is required.",
      invalidMsg: "Please enter a valid cta url! (http/https url)",
    },
    ctaEmail: {
      name: "ctaEmail",
      label: "Call to Action (Email):",
      type: "text",
      errorMsg: "cta Email is required.",
      invalidMsg: "Please enter a valid email!",
    },
    ctaType: {
      name: "ctaType",
      // label: "Call to Action (URL):",
      type: "text",
      // errorMsg: "cta URL is required.",
      // invalidMsg: "Please enter a valid cta url! (http/https url)",
    },
    files: {
      name: "files",
      label: "Pdfs",
      type: "array",
      errorMsg: "Pdf is required. ",
    },
  },
};

export default form;
