/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Section, Html, Text } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import HeadSection from "../../HeadSection";
import LogoSection from "../../LogoSection";
import IfYouNeedSupportSection from "../../IfYouNeedSupport";
import FooterSection from "../../FooterSection";
import DynamicTicketCard from "../../DynamicTicketCard";
import { defaultProps, propTypes } from "../Proptypes";

const BookingReminderEmail = ({ user, token, data }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100 font-sans leading-relaxed">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-left bg-white p-6 pl-8 text-black">
            <Text className="text-[35px] leading-[26.5px] font-bold mb-2">It's Time!</Text>
            <Text className="text-[25px] leading-[26.5px] font-bold mb-2">
              Your Lokalee booking is scheduled for tomorrow!
            </Text>
            <Text className="text-[15px] leading-[17px] font-normal mb-2">
              If you haven't downloaded your ticket, please download it on your account.
            </Text>
          </Section>
          <DynamicTicketCard
            isMoreInfo
            isProvider
            isReminder
            data={data}
            user={user}
            token={token}
          />
          <hr className="border-t-2  border-[#394A43] mt-4" />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);
BookingReminderEmail.defaultProps = defaultProps;

BookingReminderEmail.propTypes = propTypes;

export default BookingReminderEmail;
