export const urlApiBuilder = (obj) => {
  const { filters, search, page, rows, source, searchAttraction } = obj;

  let url = "";

  if (filters && filters !== "filters=") {
    url += `filters=${filters}`;
  }
  if (page) {
    url += `&page=${page}`;
  }
  if (search) {
    url += `&search=${search}`;
  }

  if (searchAttraction) {
    url += `&searchAttraction=${searchAttraction}`;
  }

  if (rows) {
    url += `&rows=${rows}`;
  }
  if (source) {
    url += `&source=${source}`;
  }

  return url;
};

export const FiltersNameFn = (filtersName, searchParams) => {
  let filtersVal = "filters=";
  filtersName.forEach((filter) => {
    const filterValue = searchParams.get(filter);
    if (filterValue) {
      filtersVal += `&${filter}=${filterValue}`;
    } else return null;
    return null;
  });
  return filtersVal;
};

export const handleEditNavFn = (obj) => {
  const { filteredUrl, cityName, sourceName, searchAttraction } = obj;
  let url = "";

  if (filteredUrl && filteredUrl !== "filters=") {
    url += `filters=${filteredUrl}`;
  }
  if (searchAttraction) {
    url += `&searchAttraction=${searchAttraction}`;
  }
  if (sourceName) {
    url += `&source=${sourceName}`;
  }
  if (cityName) {
    url += `&city=${cityName}`;
  }
  return url;
};
