/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";

const CityFilter = ({ onFilterChanged, columnDef }) => {
  const [selectedCity, setSelectedCity] = useState(null);

  const handleChange = (value) => {
    if (!value) {
      setSelectedCity(null);
    } else {
      setSelectedCity(value);
    }
    const val = value?.label;
    onFilterChanged(columnDef.tableData.id, val);
  };
  // useEffect(() => {
  //   dispatch(getCities());
  //   cities.forEach((city) => {
  //     if (city.name === columnDef.defaultFilter) {
  //       setSelectedCity(city);
  //     }
  //   });
  // }, []);

  const filterRec = (data) =>
    data.map((cityData) => ({
      label: cityData.name,
      value: cityData._id,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      let url = `${process.env.REACT_APP_API}/cities?`;
      if (inputValue) url += `name=${inputValue}`;
      url += "&sort=name&dir=asc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve(filterRec(result.data));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.message);
        });
    });

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: "200px" }}>
            <AsyncSelect
              // ref={selectRef}
              aria-label="City"
              cacheOptions
              defaultOptions
              defaultValue={selectedCity}
              isClearable
              menuPosition="fixed"
              // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              // menuShouldScrollIntoView
              loadOptions={promiseOptions}
              onChange={(option) => {
                handleChange(option);
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  // border: "none!important",
                  // boxShadow: "none",
                  height: "48px",
                  width: "200px",
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                input: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                menuList: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                }),
                placeholder: (base) => ({
                  ...base,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "100%",
                  WebkitLineClamp: 1,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                singleValue: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                menu: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
              }}
            />
          </th>
        </tr>
      </thead>
    </table>
  );
};
// Setting default props
CityFilter.defaultProps = {};

CityFilter.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
  columnDef: PropTypes.instanceOf(Object).isRequired,
};

export default CityFilter;
