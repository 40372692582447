/* eslint-disable no-underscore-dangle */

import * as Yup from "yup";
import checkout from "../../new-location/schemas/form";

const {
  formField: {
    partner,
    address,
    slider,
    slideDuration,
    disclaimerText,
    destinations,
    title,
    subTitle,
    menus,
    exploreUrl,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [partner.name]: Yup.mixed()
      .required(partner.errorMsg)
      .test(
        "is-object-or-string",
        partner.errorMsg,
        (value) => typeof value === "object" || typeof value === "string"
      ),
  }),

  Yup.object().shape({
    [address.name]: Yup.string().required(address.errorMsg),
    [destinations.name]: Yup.array()
      .min(1, destinations?.invalidMsg)
      .required(destinations?.errorMsg),
    [slideDuration.name]: Yup.number()
      .positive("Duration must be a positive number")
      .required("Duration is required"),
  }),

  Yup.object().shape({
    [disclaimerText.name]: Yup.mixed().when("disclaimer", {
      is: true,
      then: () =>
        Yup.mixed()
          .test("editorState", disclaimerText.errorMsg, (value) => {
            let parsedValue = value;
            // Check if the value is a string and try to parse it
            if (typeof value === "string") {
              try {
                parsedValue = JSON.parse(value);
              } catch (error) {
                return false; // Invalid JSON, the test will fail
              }
            }
            // Perform the original check on the parsed object
            if (parsedValue && parsedValue.blocks && parsedValue.blocks[0]?.text?.length) {
              return true;
            }
            return false;
          })
          .required("This field is required."),
    }),
  }),

  Yup.object().shape({
    [slider.name]: Yup.array().when("showSlider", {
      is: true,
      then: () => Yup.array().min(1, slider?.invalidMsg).required(slider?.errorMsg),
    }),
  }),
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [subTitle.name]: Yup.string()
      .matches(/([A-Za-z])/, subTitle.invalidMsg)
      .required(subTitle.errorMsg),
    [menus.name]: Yup.array().min(1, menus.errorMsg).required(menus.errorMsg),
    [exploreUrl.name]: Yup.string()
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
        exploreUrl.invalidMsg
      )
      .required(exploreUrl.errorMsg),
  }),
];

export default validations;
