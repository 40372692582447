/* eslint-disable no-undef */
import { SET_FILTER_LOADING, CLEAR_ERRORS } from "./Types";
// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Save recommendation filter
export const saveExRecommendationFilter = (excluded, locationId) =>
  factory.patch(
    { excluded },
    `${URL}/filters/updateExcludedRecommendation/${locationId}`,
    "UPDATE_FILTER",
    "FILTER_FAIL"
  );

// Save recommendation filter
export const saveRecommendationFilter = (recommendations, locationId) =>
  factory.patch(
    { recommendations },
    `${URL}/filters/updateRecommendation/${locationId}`,
    "UPDATE_FILTER",
    "FILTER_FAIL"
  );

// Save tag filter
export const saveTagFilter = (tags, locationId) =>
  factory.patch(
    { tags },
    `${URL}/filters/updateTags/${locationId}`,
    "UPDATE_FILTER",
    "FILTER_FAIL"
  );

// Save exclude ticket filter
export const saveExTicketFilter = (excludedTickets, locationId) =>
  factory.patch(
    { excludedTickets },
    `${URL}/filters/updateExcludedTicket/${locationId}`,
    "UPDATE_FILTER",
    "FILTER_FAIL"
  );

// Save ticket filter
export const saveTicketFilter = (tickets, locationId) =>
  factory.patch(
    { tickets },
    `${URL}/filters/updateTicket/${locationId}`,
    "UPDATE_FILTER",
    "FILTER_FAIL"
  );

// Save SubCategory filter
export const saveSubCategoryFilter = (subcategories, locationId) =>
  factory.patch(
    { subcategories },
    `${URL}/filters/updateSubCategory/${locationId}`,
    "UPDATE_FILTER",
    "FILTER_FAIL"
  );

// Add Prive FILTER
export const setPriceFilter = (location, minPrice, maxPrice, rating) =>
  factory.post(
    { minPrice, maxPrice, rating },
    `${URL}/filters/price/${location}`,
    "UPDATE_FILTER",
    "FILTER_FAIL"
  );

export const setDistanceFilter = (location, distance, keywords) =>
  factory.post(
    { distance, keywords },
    `${URL}/filters/distance/${location}`,
    "UPDATE_FILTER",
    "FILTER_FAIL"
  );

export const setItemFilter = (location, ticketsOption) =>
  factory.patch(
    { ticketsOption },
    `${URL}/filters/updateTicketOption/${location}`,
    "UPDATE_FILTER",
    "FILTER_FAIL"
  );

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading -- Used for login
export const setLoading = () => ({ type: SET_FILTER_LOADING });
