const form = {
  formId: "new-location-form",
  formField: {
    // location page
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    subHeader: {
      name: "subHeader",
      label: "Sub header",
      type: "text",
    },
    description: {
      name: "description",
      label: "Description *",
      type: "text",
      errorMsg: "Description is required!",
      invalidMsg: "Description should have at least 1 letter!",
    },
    callToAction: {
      name: "callToAction",
      labelName: "label",
      urlName: "url",
      label: "Label *",
      urlLabel: "URL *",
      type: "object",
      labelErrorMsg: "Label is required!",
      labelInvalidMsg: "Label should have at least 1 letter!",
      urlErrorMsg: "URL is required!",
      urlInvalidMsg: "Please enter a valid cta url! (http/https url)!",
    },
    type: {
      name: "type",
      label: "Type",
      type: "text",
    },
    destination: {
      name: "destination",
      label: "Destination *",
      type: "text",
      errorMsg: "Destination is required!",
      invalidMsg: "Destination should have at least 1 letter!",
    },
    travelDesk: {
      name: "travelDesk",
      label: "Travel Desk *",
      type: "text",
      errorMsg: "Travel Desk is required!",
    },
    retail_price: {
      name: "retail_price",
      label: "Retail Price",
      type: "object",
      errorMsg: "Retail Price is required!",
    },
    tag: {
      name: "tag",
      label: "Tag",
      type: "object",
      errorMsg: "Tag is required!",
    },
    newLabel: {
      name: "newLabel",
      label: "New",
      type: "boolean",
    },
    sponsored: {
      name: "sponsored",
      label: "Sponsored",
      type: "boolean",
    },
    currated: {
      name: "currated",
      label: "Curated Experience",
      type: "boolean",
    },
    special: {
      name: "special",
      label: "Special Offer",
      type: "boolean",
    },
    experienceX: {
      name: "experienceX",
      label: "Not to miss",
      type: "boolean",
    },
    exclusive: {
      name: "exclusive",
      label: "Exclusive",
      type: "boolean",
    },
    insider: {
      name: "insider",
      label: "Insider Tip",
      type: "boolean",
    },

    logo: {
      name: "logo",
      label: "Logo *",
      type: "text",
      errorMsg: "Logo is required!",
    },
  },
};

export default form;
