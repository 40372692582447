/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Section, Text } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import LogoSection from "pages/emails/LogoSection";
import IfYouNeedSupportSection from "pages/emails/IfYouNeedSupport";
import FooterSection from "pages/emails/FooterSection";
import DynamicTicketCard from "pages/emails/DynamicTicketCard";
import { defaultProps, propTypes } from "./Proptypes";

const MultiTicketBookingConfirmationEmail = ({ user, token, data }) => (
  <Tailwind>
    <Body className="bg-gray-100 font-sans leading-relaxed">
      <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
        <LogoSection />
        <Section className="text-left bg-white p-6 pl-8 text-black">
          <Text className="text-2xl font-semibold mb-2">Thank you for your booking!</Text>
          <Text className="text-xl font-semibold mb-2">Welcome {user.name},</Text>
          <Text>Good News!</Text>
          <Text>Your voucher(s) can be found attached to this e-mail.</Text>
        </Section>
        <DynamicTicketCard data={data} user={user} token={token} />

        <Text className=" pl-8 mb-2"> {data?.cancellationPolicy?.description || ""}</Text>
        <IfYouNeedSupportSection />
        <FooterSection />
      </Container>
    </Body>
  </Tailwind>
);
MultiTicketBookingConfirmationEmail.defaultProps = defaultProps;

MultiTicketBookingConfirmationEmail.propTypes = propTypes;

export default MultiTicketBookingConfirmationEmail;
