import checkout from "./form";

const {
  formField: {
    // facility page
    title,
    position,
    image,
  },
} = checkout;

const initialValues = {
  // facility page
  [title?.name]: "",
  [position?.name]: "",
  [image?.name]: [],
};

export default initialValues;
