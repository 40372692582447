/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Material UI components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import CardsList from "components/CardsList";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";
import { useHandleError } from "components/helper";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import { getOnePartner, clearErrors, updatePartner, setLoading } from "store/actions/partnerAction";
import setAlert from "store/actions/alertActions";
import {
  getPartnerRecommendations,
  setLoading as setRecommendationLoading,
} from "store/actions/recommendationActions";
import { getPartnerItems, setLoading as setItemLoading } from "store/actions/itemAction";
import {
  getPartnerLocations,
  setLoading as setLocationLoading,
} from "store/actions/locationAction";

// Mui Icons
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";

// Schemas
import validations from "./schemas/validations";
import form from "../new-partner/schemas/form";
import initialValues from "../new-partner/schemas/initialValues";
import PartnerDetail from "./components/PartnerDetail";

const EditPartner = () => {
  const { formId, formField } = form;
  const { partner_id } = useParams();

  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const partner = useSelector((state) => state.partners.partner);
  const error = useSelector((state) => state.partners.error);
  const locations = useSelector((state) => state?.locations?.locations);
  const locationsLoading = useSelector((state) => state?.locations?.loading);
  const items = useSelector((state) => state?.items?.items);
  const itemsLoading = useSelector((state) => state?.items?.loading);
  const recommendations = useSelector((state) => state?.recommendations?.recommendations);
  const recommendationsLoading = useSelector((state) => state?.recommendations?.loading);
  const user = useSelector((state) => state.users.user);
  const added = useSelector((state) => state.partners.added);

  // state
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [image, _setImage] = useState(partner?.logo);
  const [partnerDetailPage, setPartnerDetailPage] = useState(true);
  const [contactDetailPage, setContactDetailPage] = useState(true);
  const [initialState, setInitialState] = useState(initialValues);
  const [tabValue, setTabValue] = useState(0);

  const detailTabs = [
    { id: 1, title: "PARTNER DETAILS", iconComponent: AssignmentIndIcon },
    { id: 2, title: "LOCATIONS", iconComponent: LocationCityIcon },
    { id: 3, title: "ITEMS", iconComponent: LocalActivityIcon },
    { id: 4, title: " RECOMMENDATIONS", iconComponent: TheaterComedyIcon },
  ];

  // useEffect
  useEffect(() => {
    if (partner_id) {
      dispatch(getOnePartner(partner_id));
    }
  }, [partner_id, added]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (partner?.logo) {
      _setImage(partner?.logo);
    }
  }, [partner?.logo]);

  // We cant use the location as is because of address picker and map picker
  useEffect(() => {
    if (partner) {
      const manipulated = {};
      manipulated.name = partner?.name ? partner?.name : "";
      manipulated.website = partner?.website ? partner?.website : "";
      manipulated.logo = partner?.logo ? partner?.logo : "";

      manipulated.address = partner?.address ? partner?.address : "";
      manipulated.contactName = partner?.contactName ? partner?.contactName : "";
      manipulated.contactEmail = partner?.contactEmail ? partner?.contactEmail : "";
      manipulated.contactPhone = partner?.contactPhone ? partner?.contactPhone : "";
      manipulated.licence = partner?.licence ? partner?.licence : 1;
      manipulated.commissionNumber = partner?.commissionNumber ? partner?.commissionNumber : 50;
      manipulated.top = partner?.top ? partner?.top : false;
      manipulated.logo = partner?.logo || null;
      manipulated._id = partner?._id;
      setInitialState(manipulated);
    }
  }, [partner]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values) => {
    const {
      name = "",
      website = "",
      licence = "",
      commissionNumber = "",
      contactEmail = "",
      contactName = "",
      contactPhone = "",
      top = false,
    } = values;

    const baseValues = { _id: values?._id };
    let additionalValues = {};
    if (!partnerDetailPage) {
      additionalValues = { name, website, licence, commissionNumber, top };
    } else if (!contactDetailPage) {
      additionalValues = { contactEmail, contactName, contactPhone };
    }
    const updatedValues = { ...baseValues, ...additionalValues };

    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    dispatch(updatePartner(updatedValues, values?.logo));
    setPartnerDetailPage(true);
    setContactDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOnePartner(partner_id));
    }
    if (newValue === 1) {
      dispatch(setLocationLoading());
      dispatch(getPartnerLocations(partner_id));
    }
    if (newValue === 2) {
      dispatch(setItemLoading());
      dispatch(getPartnerItems(partner_id));
    }
    if (newValue === 3) {
      dispatch(setRecommendationLoading());
      dispatch(getPartnerRecommendations(partner_id));
    }
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return (
          <CardsList data={locations} loading={locationsLoading} url={`/${user.role}/locations`} />
        );
      case 2:
        return <CardsList data={items} loading={itemsLoading} url={`/${user.role}/tickets`} />;
      case 3:
        return (
          <CardsList
            data={recommendations}
            loading={recommendationsLoading}
            url={`/${user.role}/recommendations`}
          />
        );
      default:
        return null;
    }
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/partners?`;
      navigate(url);
    }
  };
  function getStepContent(type, formData) {
    if (type === "partnerInfo") {
      return (
        <PartnerDetail
          type={type}
          formData={formData}
          detailPage={partnerDetailPage}
          setDetailPage={setPartnerDetailPage}
          handleEdit={() => {
            formData.resetForm();
            setPartnerDetailPage(false);
            setContactDetailPage(true);
            setImgDetailPage(true);
          }}
        />
      );
    }
    return (
      <PartnerDetail
        type={type}
        formData={formData}
        detailPage={contactDetailPage}
        setDetailPage={setContactDetailPage}
        handleEdit={() => {
          setPartnerDetailPage(true);
          setContactDetailPage(false);
          setImgDetailPage(true);
        }}
      />
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={validations[0]}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      image={image}
                      imgDetailPage={imgDetailPage}
                      imageType="logo"
                      user={partner}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          stateName="partners"
                          handleEdit={() => {
                            setImgDetailPage(false);
                            setPartnerDetailPage(true);
                            setContactDetailPage(true);
                          }}
                          handleClose={() => {
                            _setImage(partner?.logo);
                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);
                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <>
                        {["partnerInfo", "contactInfo"].map((item, index) => (
                          <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                            <MDBox p={2}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item xs={12}>
                                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    {getStepContent(item, {
                                      values,
                                      touched,
                                      formField,
                                      errors,
                                      setFieldValue,
                                      resetForm,
                                    })}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </Card>
                        ))}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditPartner;
