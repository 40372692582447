import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const CustomAdornmentButton = ({ selectedColor, onClick }) => {
  const StyledButton = styled(Button)(() => ({
    padding: "12px",
    height: "12px",
    minHeight: "12px",
    width: "12px!important",
    minWidth: "12px!important",
    backgroundColor: selectedColor || "transparent", // Set the background color based on the selectedColor prop
    "&:hover": {
      backgroundColor: selectedColor || "transparent", // Override hover background color
    },
    "&:focus": {
      backgroundColor: selectedColor || "transparent", // Override focus background color
    },
  }));

  return <StyledButton onClick={onClick} variant="contained" color="primary" />;
};

CustomAdornmentButton.propTypes = {
  selectedColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired, // Add onClick prop validation
};

export default CustomAdornmentButton;
