import axios from "axios";
import {
  CANCEL_ITEM_REQUEST,
  CANCEL_ITEM_SUCCESS,
  CANCEL_ITEM_FAILURE,
  FETCH_ITEM_QUOTE_REQUEST,
  FETCH_ITEM_QUOTE_SUCCESS,
  FETCH_ITEM_QUOTE_FAILURE,
  FETCH_CANCEL_REASONS_REQUEST,
  FETCH_CANCEL_REASONS_SUCCESS,
  FETCH_CANCEL_REASONS_FAILURE,
} from "./Types";

const URL = process.env.REACT_APP_API;

export const fetchCancelReasons = () => async (dispatch) => {
  dispatch({ type: FETCH_CANCEL_REASONS_REQUEST });
  try {
    const response = await axios.get(`${URL}/order/cancel/reasons`);
    dispatch({ type: FETCH_CANCEL_REASONS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CANCEL_REASONS_FAILURE, error: error.message });
  }
};

export const fetchItemQuote = (id) => async (dispatch) => {
  dispatch({ type: FETCH_ITEM_QUOTE_REQUEST });
  try {
    const response = await axios.get(`${URL}/order/cancel/item/${id}/quote`);
    dispatch({ type: FETCH_ITEM_QUOTE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_ITEM_QUOTE_FAILURE, error: error.message });
  }
};

export const clearItemQuote = () => ({ type: "CLEAR_ITEM_QUOTE" });

export const cancelItem = (id, reasonCode) => async (dispatch) => {
  dispatch({ type: CANCEL_ITEM_REQUEST });

  try {
    const response = await axios.post(
      `${URL}/order/cancel/item/${id}`,
      { reasonCode },
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${userToken}`,
        },
      }
    );

    dispatch({
      type: CANCEL_ITEM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CANCEL_ITEM_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
  }
};
