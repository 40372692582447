import React from "react";
import PropTypes from "prop-types";
import { Grid, Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const SlidersList = ({ data }) => (
  <MDBox>
    {data?.map((item) => (
      <Card key={item?.title} sx={{ height: "100%", padding: "24px", marginTop: "12px" }}>
        <Grid container>
          {Object.entries(item).map(
            ([itemKey, valueItem]) =>
              valueItem && (
                <Grid item xs={6} key={itemKey}>
                  <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                    {itemKey.charAt(0).toUpperCase() + itemKey.slice(1)}:&nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    {valueItem}
                  </MDTypography>
                </Grid>
              )
          )}
        </Grid>
      </Card>
    ))}
  </MDBox>
);
SlidersList.defaultProps = {
  data: [],
};
SlidersList.propTypes = {
  data: PropTypes.instanceOf(Array),
};

export default SlidersList;
