/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Material UI components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Redux Actions
import {
  updateSpecificUser,
  getOneUser,
  resetUserPass,
  clearErrors,
  setUserLoad,
  checkEmail,
} from "store/actions/userActions";
import setAlert from "store/actions/alertActions";

// Custom Components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";

// Schema components
import ConciergeOrdersInDetails from "pages/dashboards/concierge/components/ConciergeOrdersInDetails";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ScienceIcon from "@mui/icons-material/Science";
import CardsList from "components/CardsList";
import validations from "./schema/validations";
import form from "./schema/form";
import initialValues from "./schema/initialValues";
import ViewEditActions from "../../../components/DetailList/ViewEditActions";
import UserDetail from "./components/UserDetail";

const EditUser = () => {
  const { formId, formField } = form;

  const { user_id } = useParams();
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selector

  const user = useSelector((state) => state.users.specificUser);
  const error = useSelector((state) => state.users.error);
  const linkSent = useSelector((state) => state.users.linkSent);
  const emailError = useSelector((state) => state.users.errorEmail);
  const loggedInUser = useSelector((state) => state.users.user);

  // State

  const [formChange, setFormChange] = useState(null);
  const [userValidation, setUserValidation] = useState(validations[0]);
  const [userDetailPage, setUserDetailPage] = useState(true);
  const [additionalDetailPage, setAdditionalDetailPage] = useState(true);
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [isOpenResetPassDialog, setIsOpenResetPassDialog] = useState(false);
  const [image, _setImage] = useState(user?.photo);
  const [initialState, setInitialState] = useState(initialValues);
  const [selectedDestinations, setSelectedDestinations] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPromocodes, setSelectedPromocodes] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  //  UseEffect

  useEffect(() => {
    if (user_id) {
      dispatch(setUserLoad());
      dispatch(getOneUser(user_id));
    }
  }, [user_id]);

  useEffect(() => {
    if (linkSent) {
      dispatch(setAlert(error, "success"));
    } else if (error) {
      dispatch(clearErrors());
    }
  }, [linkSent, clearErrors, error]);

  useEffect(() => {
    if (user?.photo) {
      _setImage(user?.photo);
    }
  }, [user?.photo]);

  useEffect(() => {
    if (formChange?.email) {
      dispatch(clearErrors());
    }
  }, [formChange?.email]);

  // We cant use the location as is because of address picker and map picker
  useEffect(() => {
    if (user) {
      const manipulated = {};
      manipulated.name = user?.name.trim() || "";
      manipulated.email = user?.email || "";
      manipulated.phone = user?.phone || "";
      manipulated.dob = user?.dob || "";
      manipulated.photo = user?.photo ? user?.photo : [];
      manipulated.facebookID = user?.facebookID || "";
      manipulated.googleID = user?.googleID || "";
      manipulated.appleID = user?.appleID || "";

      manipulated.role = user?.role || "";
      manipulated.lastLogin = user?.lastLogin || "";
      manipulated.partner = (user?.role === "partner" && user?.partner) || "";
      manipulated.locations = (user?.role === "partner" && user?.locations) || [];
      manipulated.location = user?.role === "concierge" && user?.location ? user?.location : {};
      manipulated.reference = user?.role === "concierge" && user?.reference ? user?.reference : "";
      manipulated.destinations =
        user?.role === "concierge" && user?.destinations ? user?.destinations : "";

      manipulated.top = (user?.role === "curator" && user?.top) || false;
      // manipulated.destinations = (user?.role === "concierge" && user?.destinations) || [];
      manipulated.balance = (user?.role === "concierge" && user?.balance) || 0;
      manipulated.promoCodes = (user?.role === "concierge" && user?.promoCodes) || [];

      manipulated.commission = (user?.role === "concierge" && user?.commission) || 0;
      manipulated.locDestinations = {
        location: manipulated.location,
        destinations: manipulated.destinations,
      };
      manipulated.bio = user?.bio || "";
      manipulated.interests = user?.role === "curator" ? user?.interests : [];
      manipulated.languages = user?.role === "curator" ? user?.languages : [];
      // manipulated.type = user?.role === "curator" ? user?.type : "";
      if (user?.role === "curator") {
        if (user?.media?.includes("youtube")) {
          manipulated.type = "video";
        } else if (
          user?.media?.includes(".jpeg") ||
          user?.media?.includes(".jpg") ||
          user?.media?.includes(".png") ||
          user?.media?.includes(".jfif") ||
          user?.media?.includes(".gif")
        ) {
          manipulated.type = "image";
        }
      }

      manipulated.destinations =
        user?.role === "curator" || user?.role === "concierge"
          ? user?.destinations?.map((destinationsData) => ({
              label: destinationsData.destinationName,
              value: destinationsData._id,
              type: destinationsData.destinationType,
            }))
          : [];

      // manipulated.recommendedExperiences =
      //   user?.role === "curator"
      //     ? user?.recommendedExperiences?.map((ticketData) => ({
      //         label: ticketData.label,
      //         value: ticketData.value,
      //       }))
      //     : [];

      manipulated.media = user?.role === "curator" ? user?.media : "";
      manipulated._id = user?._id;
      setInitialState(manipulated);
      setSelectedDestinations(
        user?.destinations?.map((destinationsData) => ({
          label: destinationsData.destinationName,
          value: destinationsData._id || destinationsData.value,
        })) || []
      );

      setSelectedLocation({
        name: user?.location?.name,
        slug: user?.location?.slug,
        _id: user?.location?._id,
      });
    }
  }, [user]);

  const emailSchemaValidation = Yup.object().shape({
    email: Yup.string()
      .email("Your email address is invalid!")
      .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, "Your email address is invalid!")
      .required("Email address is required!")
      .test("checkEmail", emailError, async (value) => {
        if (value) {
          // Call your checkPartners function and handle the response
          dispatch(checkEmail({ email: value }));
        }
      }),
  });

  useEffect(() => {
    if (formChange) {
      if (formChange.role === "partner" && !additionalDetailPage) {
        setUserValidation(validations[1]);
      } else if (formChange.role === "curator" && !additionalDetailPage) {
        setUserValidation(validations[2]);
      } else if (formChange.role === "concierge" && !additionalDetailPage) {
        setUserValidation(validations[3]);
      } else if (!userDetailPage) {
        if (user?.email === formChange?.email) setUserValidation(validations[0]);
        else setUserValidation(validations[0].concat(emailSchemaValidation));
      } else {
        setUserValidation(undefined);
      }
    }
  }, [formChange, userDetailPage, additionalDetailPage, emailError]);

  // Functions

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values) => {
    const {
      role,
      name = "",
      phone = "",
      email = "",
      dob = "",
      interests = [],
      bio = "",
      photo = "",
      destinations = [],
      // recommendedExperiences = [],
      media = "",
      languages = [],
      partner = "",
      locations = [],
      location = "",
      reference = "",
      balance = 0,
      commission = 0,
      promoCodes = [],
      sourceLocation,
      top = false,
    } = values;

    const baseValues = { _id: values?._id, role };
    let additionalValues = {};
    if (!userDetailPage || (!additionalDetailPage && role === "admin")) {
      additionalValues = { name, phone, email, dob, location, sourceLocation };
    } else if (!additionalDetailPage) {
      additionalValues =
        // eslint-disable-next-line no-nested-ternary
        role === "curator"
          ? {
              interests,
              top,
              destinations,
              bio,
              languages,
              //    recommendedExperiences,
              photo,
              media,
            }
          : role === "concierge"
          ? { location, destinations, balance, commission, promoCodes, reference }
          : { partner, locations };
    }

    const updatedValues = { ...baseValues, ...additionalValues };

    if (updatedValues && updatedValues?.partner && user.role === "partner") {
      updatedValues.partner = updatedValues.partner._id
        ? updatedValues.partner._id
        : updatedValues?.partner;
    }

    if (updatedValues && updatedValues?.location?.slug && user.role === "concierge") {
      updatedValues.sourceLocation = updatedValues.location.slug;
    }

    if (updatedValues && updatedValues?.promoCodes) {
      updatedValues.promoCodes = updatedValues.promoCodes.map((item) => item.value);
    }
    if (
      updatedValues &&
      Array.isArray(updatedValues?.locations) &&
      updatedValues?.locations?.length > 0
    ) {
      updatedValues.locations = updatedValues.locations.map((item) => item);
    }
    if (updatedValues && updatedValues?.location) {
      updatedValues.location = updatedValues.location._id || updatedValues.location;
    } else delete updatedValues?.location;

    if (
      updatedValues &&
      Array.isArray(updatedValues.destinations) &&
      updatedValues?.destinations?.length > 0
    ) {
      updatedValues.destinations = updatedValues.destinations.map((desti) => desti?.value);
    }
    if (
      user.role === "concierge" &&
      updatedValues &&
      updatedValues?.destinations &&
      typeof updatedValues.destinations === "object"
    ) {
      updatedValues.destinations = updatedValues.destinations.value;
    } else if (user.role !== "curator") delete updatedValues?.destinations;

    if (user.role === "partner") {
      updatedValues.partner = user.partner;
    }

    if (updatedValues && updatedValues?.photo) {
      delete updatedValues?.photo;
    }
    if (updatedValues && updatedValues?.recommendedExperiences) {
      updatedValues.recommendedExperiences = updatedValues.recommendedExperiences.map(
        (ticket) => ticket.value
      );
    }

    await sleep(1000);
    dispatch(setUserLoad());
    dispatch(
      updateSpecificUser(updatedValues, values?.photo !== user?.photo ? values?.photo : undefined)
    );
    dispatch(getOneUser(user?._id));
    setUserDetailPage(true);
    setAdditionalDetailPage(true);
  };

  const handleCloseDialog = () => {
    setIsOpenResetPassDialog(false);
  };

  const handleLocationChange = (value) => {
    if (!value || !value?.name) {
      setSelectedLocation({});
    } else {
      setSelectedLocation(value);
      setSelectedDestinations(null);
    }
  };

  const handlePromocodesChange = (value) => {
    if (value) {
      setSelectedPromocodes(value);
    } else {
      setSelectedPromocodes([]);
    }
  };

  const handleDestinationsChange = (value) => {
    if (!value || !value?.label) {
      setSelectedDestinations({});
    } else {
      setSelectedDestinations(value);
      // setSelectedLocation({});
    }
  };

  const handleDestinationChange = (value) => {
    if (!value || !value?.label) {
      setSelectedDestinations({});
    } else {
      setSelectedDestinations(value);
      setSelectedLocation(null);
    }
  };

  const handlePartnerChange = (e, value) => {
    if (!value || !value._id) {
      setSelectedPartner({});
    } else {
      setSelectedPartner(value);
    }
  };

  const detailTabs =
    user?.role === "curator"
      ? [
          { id: 1, title: "DETAILS", iconComponent: AssignmentIcon },
          { id: 2, title: "TICKETS", iconComponent: LocalActivityIcon },
          { id: 3, title: "EXPERIENCES", iconComponent: ScienceIcon },
        ]
      : [];

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneUser(user._id));
    }
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return <CardsList data={user?.tickets} user={user} />;
      case 2:
        return (
          <CardsList
            data={user?.experiences}
            user={user}
            url="/admin/experiences"
            isExperience
            source="users"
            sourceId={user?._id}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "start",
                width: "100%",
              }}
            >
              <MDButton
                // variant="outlined"
                color="dark"
                iconOnly
                onClick={() =>
                  navigate(`/admin/experiences/new-experience?source=users&sourceId=${user?._id}`)
                }
                style={{ width: "auto", textTransform: "none", boxShadow: "none" }}
              >
                Add Experience
              </MDButton>
            </div>
          </CardsList>
        );
      default:
        return null;
    }
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/users?`;
      navigate(url);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={userValidation}
              onSubmit={submitForm}
              innerRef={(f) => {
                setFormChange(f?.values);
                formRef.current = f;
              }}
            >
              {({ values }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      image={image}
                      imgDetailPage={imgDetailPage}
                      user={user}
                      imageType="photo"
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      handleNavigation={() => handleNavigation(loggedInUser?.role)}
                    >
                      {tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          stateName="users"
                          handleEdit={() => {
                            setUserDetailPage(true);
                            setAdditionalDetailPage(true);
                            setImgDetailPage(false);
                          }}
                          handleClose={() => {
                            _setImage(user?.photo);
                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);
                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <UserDetail
                        user={user}
                        formField={formField}
                        selectedDestinations={selectedDestinations}
                        handleDestinationsChange={handleDestinationsChange}
                        setSelectedDestinations={setSelectedDestinations}
                        handleDestinationChange={handleDestinationChange}
                        selectedPartner={selectedPartner}
                        handlePartnerChange={handlePartnerChange}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        handleLocationChange={handleLocationChange}
                        selectedPromocodes={selectedPromocodes}
                        handlePromocodesChange={handlePromocodesChange}
                        userDetailPage={userDetailPage}
                        setUserDetailPage={setUserDetailPage}
                        additionalDetailPage={additionalDetailPage}
                        setAdditionalDetailPage={setAdditionalDetailPage}
                        setImgDetailPage={setImgDetailPage}
                      />
                    ) : (
                      handleDetailData()
                    )}

                    {user?.role === "concierge" && (
                      <Card id="profile" sx={{ marginTop: "24px" }}>
                        <MDBox p={2}>
                          <ConciergeOrdersInDetails user={user?._id} />
                        </MDBox>
                      </Card>
                    )}

                    <Card id="profile" sx={{ marginTop: "24px" }}>
                      {user?.role !== "guest" && (
                        <MDBox p={2}>
                          <Grid container spacing={3} justifyContent="space-between">
                            <Grid item>
                              <MDBox width="100%" display="flex" justifyContent="space-between">
                                <MDBox />
                                <div>
                                  <MDButton
                                    variant="gradient"
                                    color="dark"
                                    onClick={() => {
                                      setIsOpenResetPassDialog(true);
                                    }}
                                  >
                                    Reset password
                                  </MDButton>
                                </div>
                              </MDBox>
                              {isOpenResetPassDialog && (
                                <div>
                                  <Dialog
                                    open={isOpenResetPassDialog}
                                    onClose={handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                  >
                                    <DialogTitle id="alert-dialog-title">
                                      Reset Password
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText id="alert-dialog-description">
                                        Are you sure you want to reset the password?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <MDButton
                                        onClick={() => {
                                          dispatch(resetUserPass(values?.email));
                                          handleCloseDialog();
                                        }}
                                      >
                                        Yes
                                      </MDButton>
                                      <MDButton onClick={handleCloseDialog} autoFocus>
                                        No
                                      </MDButton>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </MDBox>
                      )}
                    </Card>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditUser;
