import React from "react";
import PropTypes from "prop-types";
import { Body, Container, Section, Head, Html, Text, Button, Img } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import { Font } from "@react-email/font";
import FooterSection from "../FooterSection";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import LogoSection from "../LogoSection";

const ReminderEmail = ({ item }) => (
  <Html>
    <Head>
      <Font
        fontFamily="Helvetica"
        fallbackFontFamily="Arial"
        webFont={{
          url: "https://fonts.gstatic.com/s/helvetica/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2",
          format: "woff2",
        }}
        fontStyle="normal"
      />
    </Head>
    <Tailwind>
      <Body className="bg-gray-100">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-center pb-5 px-8">
            <Text className="text-[25px] leading-[26.5px] font-bold mt-4 my-0">Hello there!</Text>
            <Text className="text-[25px] leading-[26.5px] font-bold my-0">
              Thank you for choosing
            </Text>
            <Text className="text-[25px] leading-[26.5px] font-bold my-0 mb-4">
              Lokalee for your recent booking.
            </Text>
            <Img
              src="https://email-assets.lokalee.app/email/emailImages/cover.png"
              alt="Cover"
              className="w-full h-auto mx-auto"
            />
          </Section>
          <Section className="mb-6 px-8">
            <Text className="text-[20px] leading-[23px] font-bold mb-4 text-center">
              Sign up now to benefit from:
            </Text>
            <ul className="list-none text-gray-700">
              <li className="flex items-start mb-4">
                <Img
                  src="https://email-assets.lokalee.app/email/emailImages/checkbox-list-detail-line.png"
                  alt="Check-Box-List"
                  className="w-12 h-12 mt-1"
                />
                <div className="ml-3">
                  <span className="text-[16px] leading-[18px] font-bold">
                    Personalized Recommendations:
                  </span>
                  <br />
                  <span className="text-[16px] leading-[18px] font-normal">
                    Have access to tailored recommendations for top attractions and local
                    experiences.
                  </span>
                </div>
              </li>
              <li className="flex items-start mb-4">
                <Img
                  src="https://email-assets.lokalee.app/email/emailImages/tag-off-line.png"
                  alt="Tag-off"
                  className="w-12 h-12 mt-1"
                />
                <div className="ml-3">
                  <span className="text-[16px] leading-[18px] font-bold">
                    Exclusive Promotions & Discounts:
                  </span>
                  <br />
                  <span className="text-[16px] leading-[18px] font-normal">
                    Discover special deals and discounts to enhance your Lokalee adventures.
                  </span>
                </div>
              </li>
              <li className="flex items-start mb-4">
                <Img
                  src="https://email-assets.lokalee.app/email/emailImages/book-plus-line.png"
                  alt="Book-plus"
                  className="w-12 h-12 mt-1"
                />
                <div className="ml-3">
                  <span className="text-[16px] leading-[18px] font-bold">
                    Effortless Booking Management:
                  </span>
                  <br />
                  <span className="text-[16px] leading-[18px] font-normal">
                    Easily manage your bookings and get direct assistance when needed.
                  </span>
                </div>
              </li>
            </ul>
          </Section>
          <Section className="text-center mb-6 px-8">
            <Text className="text-[16px] leading-[18px] font-bold mb-2">
              Make the most of your Lokalee experience!
            </Text>
            <Button
              className="bg-[#003005] text-white text-[16px] leading-[16px] font-semibold py-2 px-4 rounded-md hover:bg-green-600 mx-auto"
              href={item.url}
            >
              Create Account
            </Button>
          </Section>
          <hr className="border-t-0  border-[#394A43]" />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);
ReminderEmail.defaultProps = {
  item: {
    url: "https://lokalee.app",
  },
};
ReminderEmail.propTypes = {
  item: PropTypes.shape({
    url: PropTypes.string,
  }),
};
export default ReminderEmail;
