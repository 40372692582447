/* eslint-disable react/no-array-index-key */

// Libraries
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

// Material UI components
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Custom Components
import MDBox from "components/MDBox";
// import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { areAllValuesEmpty } from "components/helper";

const Review = ({ formData, editPage }) => {
  const { values } = formData;

  const [transportationServicesData, settransportationServicesData] = useState({});

  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (values) {
      settransportationServicesData({
        Title: values?.title,
        Location: values?.location,
        LogoName: values?.logoName,
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([{ id: 0, title: "Transportation Services info", data: transportationServicesData }]);
  }, [transportationServicesData]);

  const BigAvatar = styled(Avatar)`
    width: 74px;
    height: 74px;
  `;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {!editPage ? (
            <Grid item xs={12} sm={6}>
              <BigAvatar
                alt="Change your profile photo"
                // src={URL.revokeObjectURL(values?.logo)}
                src={values?.logo}
                imgProps={{
                  style: {
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "cover",
                  },
                }}
              />
              {/* )} */}
            </Grid>
          ) : (
            ""
          )}
          <Grid item xs={12}>
            {fields?.map((field, i) => (
              <Grid
                item
                key={field.id}
                xs={12}
                sx={{
                  display: areAllValuesEmpty(field.data) ? "none" : "flex",
                  flexDirection: "column",
                }}
              >
                <DetailList data={field.data} title={field?.title} />
                {i + 1 !== fields?.length && (
                  <div>
                    <Divider sx={{ height: "1px" }} />
                  </div>
                )}{" "}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// Setting default props for the Review
Review.defaultProps = {
  editPage: false,
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
};

export default Review;
