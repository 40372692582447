/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { Grid } from "@mui/material";

// import axios from "axios";
import { useDispatch } from "react-redux";

// components
import FormField from "components/FormField";
import { Formik } from "formik";
import { addElement } from "store/actions/widgetActions";
import MultiPdf from "components/DynamicMultiPdf";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import initialValues from "./initialValues";
import validations from "./validations";
import form from "./form";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AddElement = ({
  handleClose,
  open,
  widgetId,
  count,
  // setAlert,
}) => {
  const { title, description, ctaTitle, ctaUrl, ctaEmail, ctaType } = form.formField;
  const currentValidation = validations[0];

  const dispatch = useDispatch();

  const callToActionOptions = [
    { value: "ctaUrl", label: "Cta (URL)" },
    { value: "ctaEmail", label: "Cta (Email)" },
    { value: "files", label: "Pdfs" },
  ];

  const clearClose = (setFieldValue) => {
    setFieldValue("title", "");
    setFieldValue("description", "");
    setFieldValue("ctaTitle", "");
    setFieldValue("ctaUrl", "");
    setFieldValue("ctaEmail", "");
    setFieldValue("files", "");

    handleClose();
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitFormFn = async (val, actions) => {
    await sleep(1000);
    dispatch(addElement(val, widgetId, count));
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleSubmit = (values, actions) => {
    submitFormFn(values, actions);
    handleClose();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, touched, errors, submitForm }) => (
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              clearClose(setFieldValue);
            }}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
          >
            <DialogContent sx={{ minHeight: `300px` }}>
              <DialogContentText id="alert-dialog-slide-description">Add Element</DialogContentText>
              <Grid item xs={12}>
                <FormField
                  type={title?.type}
                  label={title?.label}
                  name={title?.name}
                  value={values?.title}
                  error={errors.title && touched.title}
                  //   success={values?.title && !errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={description?.type}
                  label={description?.label}
                  name={description?.name}
                  value={values?.description}
                  error={errors.description && touched.description}
                  //   success={values?.description && !errors.description}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={ctaTitle?.type}
                  label={ctaTitle?.label}
                  name={ctaTitle?.name}
                  value={values?.ctaTitle}
                  //   success={values?.ctaTitle && !errors.ctaTitle}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <DynamicRadioGroup
                    groupLabel={ctaType.label}
                    name={ctaType.name}
                    value={values?.ctaType}
                    options={callToActionOptions}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </Grid>
              {values?.ctaType === "files" && (
                <Grid item xs={12}>
                  <label> Pdfs:</label>
                  <MultiPdf key={open} files={values?.files} setFieldValue={setFieldValue} />
                </Grid>
              )}
              {values?.ctaType === "ctaUrl" && (
                <Grid item xs={12}>
                  <FormField
                    type={ctaUrl?.type}
                    label={ctaUrl?.label}
                    name={ctaUrl?.name}
                    value={values?.ctaUrl}
                    // success={values?.ctaUrl && !errors.ctaUrl}
                  />
                </Grid>
              )}{" "}
              {values?.ctaType === "ctaEmail" && (
                <Grid item xs={12}>
                  <FormField
                    type={ctaEmail?.type}
                    label={ctaEmail?.label}
                    name={ctaEmail?.name}
                    value={values?.ctaEmail}
                    // success={values?.ctaEmail && !errors.ctaEmail}
                  />
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  clearClose(setFieldValue);
                }}
              >
                Cancel
              </Button>
              <Button onClick={submitForm}>Add</Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </div>
  );
};

// Setting default props
AddElement.defaultProps = {
  widgetId: "",
  count: 0,
};

AddElement.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  widgetId: PropTypes.string,
  count: PropTypes.number,
};

export default AddElement;
