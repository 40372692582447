/* eslint-disable no-underscore-dangle */
import {
  SET_NEWS_LOADING,
  NEWS_ADD_SUCCESS,
  NEWS_FAIL,
  GET_ALL_NEWS,
  CLEAR_ERRORS,
  DELETE_NEWS,
  GET_ONE_NEWS,
  UPDATE_NEWS,
  LOGOUT,
  UPDATE_NEWS_TRANSLATION,
} from "../actions/Types";

const initialState = {
  loading: false,
  allNews: [],
  error: null,
  added: false,
  oneNews: {},
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case NEWS_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allNews: [...state.allNews, action.payload.data.data],
        added: true,
        oneNews: {},
      };
    case GET_ONE_NEWS:
      return {
        ...state,
        loading: false,
        error: null,
        oneNews: action.payload.data.data,
        added: false,
      };

    case GET_ALL_NEWS:
      return {
        ...state,
        loading: false,
        error: null,
        allNews: action.payload.data.data,
        added: false,
        oneNews: {},
      };
    case DELETE_NEWS:
      return {
        ...state,
        loading: false,
        allNews: state.allNews.filter((news) => news._id !== action.payload.data.data._id),
      };
    case UPDATE_NEWS:
      return {
        ...state,
        added: true,
        allNews: state.allNews.map((news) =>
          news._id === action.payload.data.data._id ? action.payload.data.data : news
        ),
      };
    case UPDATE_NEWS_TRANSLATION:
      return {
        ...state,
        oneNews: action.payload.data?.news,
      };
    case NEWS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_NEWS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        allNews: [],
        error: null,
        added: false,
        oneNews: {},
      };

    default:
      return state;
  }
};
export default variable;
