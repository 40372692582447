/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";

import { Typography } from "@mui/material";

const PromocodesPicker = ({ promoCodes, promocodesV, handleChange, setFieldValue }) => {
  const filterPromocode = (data) =>
    data.map((promocodeData) => ({
      label: promocodeData.title,
      value: promocodeData.slug,
      amount: promocodeData.value,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/promoCodes?type=percentage&status=active`;

      url += "&dir=asc";
      if (inputValue) url += `&title=${inputValue}`;
      url += "&sort=title";
      setTimeout(() => {
        // resolve();
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            const filteredData = filterPromocode(result.data);
            resolve(filteredData);
          })
          .catch((error) => console.log(error.message));
      }, 1000);
    });

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    promiseOptions(inputValue)
      .then((options) => {
        callback(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, 1000); // Adjust the delay time (in milliseconds) as needed

  return (
    <div>
      <Typography
        style={{
          wordBreak: "break-word",
          fontSize: "0.875rem",
          fontWeight: 400,
        }}
      >
        {promoCodes.Label}
      </Typography>{" "}
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        defaultValue={promocodesV}
        placeholder="Search for a promocode"
        menuPosition="fixed"
        loadOptions={debouncedLoadOptions}
        onChange={(options) => {
          // Handle an array of selected options
          if (!options || options.length === 0) {
            setFieldValue("promoCodes", []);
            handleChange(options);
          } else {
            setFieldValue("promoCodes", options);
            handleChange(options);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            // border: "none!important",
            borderColor: "#00850D", // Set border color based on focus state
            "&:hover": {
              borderColor: "#00850D", // Set border color on hover
            },
            // boxShadow: "none",
            minHeight: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            // overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
            display: "flex",
            alignItems: "center",
          }),
          multiValueLabel: (base) => ({
            ...base,
            maxWidth: "100%",
            lineHeight: "0.7", // Adjust the line height as needed
            padding: "4px 8px",
          }),
        }}
      />
    </div>
  );
};

PromocodesPicker.defaultProps = {
  promocodesV: [],
  handleChange: () => {},
  promoCodes: {},
};

PromocodesPicker.propTypes = {
  promoCodes: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  promocodesV: PropTypes.instanceOf(Array),
};

export default PromocodesPicker;
