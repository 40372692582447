const form = {
  formId: "new-partner-form",
  formField: {
    // traffic by partner page
    partner: {
      name: "partner",
      label: "Partner",
      // type: "text",
      errorMsg: "Partner is required!",
    },
    country: {
      name: "country",
      label: "Country *",
    },
    city: {
      name: "city",
      label: "City",
    },
    location: {
      name: "location",
      label: "Location",
    },
  },
};

export default form;
