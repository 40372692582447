/* eslint-disable no-undef */
import axios from "axios";
import {
  SET_GLOBAL_BOX_LOADING,
  CLEAR_ERRORS,
  GLOBAL_BOX_ADD_SUCCESS,
  GLOBAL_BOX_FAIL,
  ADD_LOCATIONBOX,
  LOCATIONBOXES_FAIL,
} from "./Types";
// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// -----------------Global Box-----------------

export const addGlobalBox = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios.post(`${URL}/boxes/global`, data, config);
    dispatch({
      type: GLOBAL_BOX_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GLOBAL_BOX_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Delete Global Box
export const removeGlobalBox = (id) =>
  factory.deleteById(id, `${URL}/boxes`, "DELETE_GLOBAL_BOX", "GLOBAL_BOX_FAIL");

// Get All GlobalBoxes
export const getGlobalBoxes = () =>
  factory.get(`${URL}/boxes/global?sort=name&dir=asc`, "GET_GLOBAL_BOXES", "GLOBAL_BOX_FAIL");

// Sort Global Box
export const sortGlobalBox = (boxes) =>
  factory.post({ boxes }, `${URL}/menus/order`, "SORT_GLOBAL_BOXES", "GLOBAL_BOX_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_GLOBAL_BOX_LOADING });

// -----------------City Boxes-----------------

export const getCityBoxes = (city) =>
  factory.get(`${URL}/boxes?city=${city}`, "GET_BOXES", "BOXES_FAIL");

// Sort Box
export const sortBox = (boxes) =>
  factory.post({ boxes }, `${URL}/menus/order`, "SORT_BOXES", "BOXES_FAIL");

export const addBox = (data) => factory.post(data, `${URL}/boxes`, "ADD_BOX", "BOXES_FAIL");

export const removeBox = (id) => factory.deleteById(id, `${URL}/boxes`, "REMOVE_BOX", "BOXES_FAIL");

// -----------------Location Boxes-----------------

export const getLocationBoxes = (location) =>
  factory.get(
    `${URL}/boxes/locations?locationId=${location}`,
    "GET_LOCATIONBOXES",
    "LOCATIONBOXES_FAIL"
  );

export const addLocationBox = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("title", data.title);
  form.append("type", data.type);
  form.append("order", data.order);
  if (data.categoryId) form.append("categoryId", data.categoryId);
  if (data.subCategory) form.append("subCategory", data.subCategory);
  if (data.categoryName) form.append("categoryName", data.categoryName);
  if (data.tag) form.append("tag", data.tag);
  form.append("option", data.option);
  form.append("location", data.location);

  if (data.image) {
    // data.image.path = data.image?.name;
    form.append("image", data.image);
  }

  try {
    const res = await axios.post(`${URL}/boxes/locations`, form, config);

    dispatch({
      type: ADD_LOCATIONBOX,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOCATIONBOXES_FAIL,
      payload: err?.response.data.message,
    });
  }
};

export const removeLocationBox = (id) =>
  factory.deleteById(id, `${URL}/boxes`, "REMOVE_LOCATIONBOX", "LOCATIONBOXES_FAIL");

export const sortLocationBox = (locationBoxes) =>
  factory.post(
    { boxes: locationBoxes },
    `${URL}/menus/order`,
    "SORT_LOCATIONBOXES",
    "LOCATIONBOXES_FAIL"
  );
