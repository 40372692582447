/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
// import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import { allDaysToChosen, chosenToAllDays } from "components/helper";
import { findAndReplaceDuplicates } from "assets/theme/functions/openHours";
import OpeningHoursDialog from "./openingHoursDialog";
import ConfirmDialog from "./confirmDialog";

const OpeningHours = ({ setFieldValue, openHours }) => {
  // current time
  let time = new Date();
  const currentTime = time.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  // open/close dialog
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // Opend entry dialog

  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);
  const [openAllDayCheckbox, setOpenAllDayCheckbox] = useState(false);
  const [closedCheckbox, setClosedCheckbox] = useState(false);
  const [start, setStart] = useState(currentTime);
  const [end, setEnd] = useState(currentTime);
  const [arrToBeChecked, setArrToBeChecked] = useState([]);
  const [duplicatesCheck, setDuplicatesCheck] = useState(false);
  const [selectAllDays, setSelectAllDays] = useState(false);
  /// ///////////////////////////////////
  const closeDialog = () => {
    setMonday(false);
    setTuesday(false);
    setWednesday(false);
    setThursday(false);
    setFriday(false);
    setSaturday(false);
    setSunday(false);
    setOpenAllDayCheckbox(false);
    setClosedCheckbox(false);
    setStart(currentTime);
    setEnd(currentTime);
    setOpen(false);
  };

  // open/close overwrite dialog
  const [overwriteModel, setOverwriteModel] = useState({
    active: false,
    duplicates: [],
  });

  // Cancel overwrite
  const closeOverwriteDialog = () => {
    setDuplicatesCheck(false);
    setOverwriteModel({ active: false, duplicates: [] });
  };

  // delete dialog
  const [deleteDialog, setDeleteDialog] = useState({
    active: false,
    index: 0,
    type: "",
  });
  const closeDeleteDialog = () => {
    setDeleteDialog({ active: false, index: 0, type: "" });
  };

  // start/end date

  const handleChange = (event, newValue, item) => {
    if (item === "start") {
      setStart(newValue);
    } else {
      setEnd(newValue);
    }
  };

  // initial value of the opening hour dialog
  const [days, setDays] = useState({
    monday: {
      text: "Monday",
      activated: false,
      open_all_day: false,
      closed: false,
      open: null,
      close: null,
    },
    tuesday: {
      text: "Tuesday",
      activated: false,
      open_all_day: false,
      closed: false,
      open: null,
      close: null,
    },
    wednesday: {
      text: "Wednesday",
      activated: false,
      open_all_day: false,
      closed: false,
      open: null,
      close: null,
    },
    thursday: {
      text: "Thursday",
      activated: false,
      open_all_day: false,
      closed: false,
      open: null,
      close: null,
    },
    friday: {
      text: "Friday",
      activated: false,
      open_all_day: false,
      closed: false,
      open: null,
      close: null,
    },
    saturday: {
      text: "Saturday",
      activated: false,
      open_all_day: false,
      closed: false,
      open: null,
      close: null,
    },
    sunday: {
      text: "Sunday",
      activated: false,
      open_all_day: false,
      closed: false,
      open: null,
      close: null,
    },
  });

  useEffect(() => {
    setDays({
      monday: {
        text: "Monday",
        activated: false,
        open_all_day: false,
        closed: false,
        open: null,
        close: null,
      },
      tuesday: {
        text: "Tuesday",
        activated: false,
        open_all_day: false,
        closed: false,
        open: null,
        close: null,
      },
      wednesday: {
        text: "Wednesday",
        activated: false,
        open_all_day: false,
        closed: false,
        open: null,
        close: null,
      },
      thursday: {
        text: "Thursday",
        activated: false,
        open_all_day: false,
        closed: false,
        open: null,
        close: null,
      },
      friday: {
        text: "Friday",
        activated: false,
        open_all_day: false,
        closed: false,
        open: null,
        close: null,
      },
      saturday: {
        text: "Saturday",
        activated: false,
        open_all_day: false,
        closed: false,
        open: null,
        close: null,
      },
      sunday: {
        text: "Sunday",
        activated: false,
        open_all_day: false,
        closed: false,
        open: null,
        close: null,
      },
    });
  }, [open]);

  // clicking on the fab functionality
  const dayClicked = (item) => {
    switch (item) {
      case "monday": {
        setMonday(!monday);
        if (!monday && selectAllDays) setSelectAllDays(false);
        break;
      }
      case "tuesday": {
        setTuesday(!tuesday);
        if (!tuesday && selectAllDays) setSelectAllDays(false);
        break;
      }
      case "wednesday": {
        setWednesday(!wednesday);
        if (!wednesday && selectAllDays) setSelectAllDays(false);
        break;
      }
      case "thursday": {
        setThursday(!thursday);
        if (!thursday && selectAllDays) setSelectAllDays(false);
        break;
      }
      case "friday": {
        setFriday(!friday);
        if (!friday && selectAllDays) setSelectAllDays(false);
        break;
      }
      case "saturday": {
        setSaturday(!saturday);
        if (!saturday && selectAllDays) setSelectAllDays(false);
        break;
      }
      case "sunday": {
        setSunday(!sunday);
        if (!sunday && selectAllDays) setSelectAllDays(false);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSelectAllDays = (checked) => {
    setSelectAllDays(checked);
    setMonday(checked);
    setTuesday(checked);
    setWednesday(checked);
    setThursday(checked);
    setFriday(checked);
    setSaturday(checked);
    setSunday(checked);
  };

  const openDialog = (e, val) => {
    // for edit
    if (val) {
      setEdit(true);
      dayClicked(val.date?.toLowerCase());
      if (val.time === "Closed") {
        setClosedCheckbox(true);
      } else if (val.time === "Open 24 hours") {
        setOpenAllDayCheckbox(true);
      } else {
        time = val.time.split("-");
        setStart(time[0]);
        setEnd(time[1]);
      }
    } else {
      // For Add
      setEdit(false);
    }
    setOpen(true);
  };
  // open 24 hours / closed checkboxes

  const openAllDay = (val) => {
    setOpenAllDayCheckbox(val);
    setClosedCheckbox(false);
    setStart(currentTime);
    setEnd(currentTime);
  };
  const closed = (val) => {
    setClosedCheckbox(val);
    setOpenAllDayCheckbox(false);
    setStart(currentTime);
    setEnd(currentTime);
  };

  // add hour
  const handleAdd = () => {
    if (monday) {
      const newDays = days;
      newDays.monday.activated = true;
      newDays.monday.open_all_day = openAllDayCheckbox;
      newDays.monday.closed = closedCheckbox;
      if (openAllDayCheckbox || closedCheckbox) {
        newDays.monday.open = null;
        newDays.monday.close = null;
      } else {
        newDays.monday.open = start;
        newDays.monday.close = end;
      }
      setDays(newDays);
    }
    if (tuesday) {
      const newDays = days;
      newDays.tuesday.activated = true;
      newDays.tuesday.open_all_day = openAllDayCheckbox;
      newDays.tuesday.closed = closedCheckbox;
      if (openAllDayCheckbox || closedCheckbox) {
        newDays.tuesday.open = null;
        newDays.tuesday.close = null;
      } else {
        newDays.tuesday.open = start;
        newDays.tuesday.close = end;
      }
      setDays(newDays);
    }
    if (wednesday) {
      const newDays = days;
      newDays.wednesday.activated = true;
      newDays.wednesday.open_all_day = openAllDayCheckbox;
      newDays.wednesday.closed = closedCheckbox;
      if (openAllDayCheckbox || closedCheckbox) {
        newDays.wednesday.open = null;
        newDays.wednesday.close = null;
      } else {
        newDays.wednesday.open = start;
        newDays.wednesday.close = end;
      }
      setDays(newDays);
    }
    if (thursday) {
      const newDays = days;
      newDays.thursday.activated = true;
      newDays.thursday.open_all_day = openAllDayCheckbox;
      newDays.thursday.closed = closedCheckbox;
      if (openAllDayCheckbox || closedCheckbox) {
        newDays.thursday.open = null;
        newDays.thursday.close = null;
      } else {
        newDays.thursday.open = start;
        newDays.thursday.close = end;
      }
      setDays(newDays);
    }
    if (friday) {
      const newDays = days;
      newDays.friday.activated = true;
      newDays.friday.open_all_day = openAllDayCheckbox;
      newDays.friday.closed = closedCheckbox;
      if (openAllDayCheckbox || closedCheckbox) {
        newDays.friday.open = null;
        newDays.friday.close = null;
      } else {
        newDays.friday.open = start;
        newDays.friday.close = end;
      }
      setDays(newDays);
    }
    if (saturday) {
      const newDays = days;
      newDays.saturday.activated = true;
      newDays.saturday.open_all_day = openAllDayCheckbox;
      newDays.saturday.closed = closedCheckbox;
      if (openAllDayCheckbox || closedCheckbox) {
        newDays.saturday.open = null;
        newDays.saturday.close = null;
      } else {
        newDays.saturday.open = start;
        newDays.saturday.close = end;
      }
      setDays(newDays);
    }
    if (sunday) {
      const newDays = days;
      newDays.sunday.activated = true;
      newDays.sunday.open_all_day = openAllDayCheckbox;
      newDays.sunday.closed = closedCheckbox;
      if (openAllDayCheckbox || closedCheckbox) {
        newDays.sunday.open = null;
        newDays.sunday.close = null;
      } else {
        newDays.sunday.open = start;
        newDays.sunday.close = end;
      }
      setDays(newDays);
    }

    if (monday || tuesday || wednesday || thursday || friday || saturday || sunday) {
      const arr = [];
      let timeStr = "";
      // eslint-disable-next-line guard-for-in
      for (const key in days) {
        if (days[key].activated) {
          if (days[key].open_all_day) {
            timeStr = "Open 24 hours";
          } else if (days[key].closed) {
            timeStr = "Closed";
          } else {
            timeStr = `${days[key].open}-${days[key].close}`;
          }
          arr.push({ date: days[key].text, time: timeStr });
        }
        setArrToBeChecked(arr);
        // run duplicate check
        setDuplicatesCheck(true);
      }
      closeDialog();
    }
  };

  function removeDuplicates(arr) {
    return arr.filter(
      (item, index) =>
        index === arr.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(item))
    );
  }

  // on confirm
  const onConfirm = (editData) => {
    const myArr = allDaysToChosen(openHours);

    let testArray = [];

    if (editData && editData?.length > 0) {
      testArray = editData;
    }

    if (overwriteModel.duplicates.length > 0) {
      testArray = overwriteModel.duplicates;
    }

    if (testArray.length === 0) {
      testArray = myArr;
    }
    setFieldValue("openHours", chosenToAllDays(removeDuplicates(testArray)));
  };

  useEffect(() => {
    const common = findAndReplaceDuplicates(allDaysToChosen(openHours), arrToBeChecked);
    if (duplicatesCheck) {
      // no duplicates
      if (openHours.length === 0) {
        setFieldValue("openHours", chosenToAllDays(arrToBeChecked));
      } else if (common.length === 0) {
        setFieldValue(
          "openHours",
          chosenToAllDays(allDaysToChosen(openHours).concat(arrToBeChecked))
        );
      } else if (edit) {
        onConfirm(common);
      } else {
        // in case of duplicates
        setOverwriteModel({
          active: true,
          duplicates: common,
        });
      }
    }
  }, [duplicatesCheck, arrToBeChecked]);
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const [data, setData] = useState(openHours);
  const [boolData, setBoolData] = useState(false);
  const [checkAll, setCheckAll] = useState(false); // check this
  const [checkSingle, setCheckSingle] = useState([]); // { index: 0, check: false }
  const [chosenDays, setChosenDays] = useState([]);
  const deleteAllOpeningHours = () => {
    setCheckAll(false);
    setData([]);
    setFieldValue("openHours", []);
    setCheckSingle([]);
  };
  const deleteOpeningHours = (i, type) => {
    if (i >= 0) {
      const myArr = allDaysToChosen(openHours);
      myArr.splice(i, 1);
      setFieldValue("openHours", chosenToAllDays(myArr));
      setBoolData(false);
    }
    if (type === "all" && i === undefined) {
      deleteAllOpeningHours();
      setBoolData(false);
    } else if (type === "selected" && i === undefined) {
      setData(
        allDaysToChosen(
          openHours.filter((item, index) => !chosenDays.some((day) => day.index === index))
        )
      );
      setBoolData(true);
      setCheckSingle([]);
    }
  };

  useEffect(() => {
    if (boolData) {
      setFieldValue("openHours", chosenToAllDays(data));
      setChosenDays([]);
      setBoolData(false);
      setCheckAll(false);
    }
  }, [boolData, setFieldValue]);

  useEffect(() => {
    if (!boolData) {
      setData(allDaysToChosen(openHours));
    }
  }, [openHours]);

  const checkSelected = (e, index) => {
    const checkIndex = checkSingle.findIndex((item) => item.index === index);

    if (checkIndex === -1) {
      // add to array
      setCheckSingle([...checkSingle, { index, check: true }]);
      setChosenDays([...chosenDays, { ...openHours[index], index }]);
    } else {
      // remove from array
      setCheckSingle(checkSingle.filter((item) => item.index !== index));
      setChosenDays(chosenDays.filter((item) => item.index !== index));
    }
  };
  const deleteDialogTextUpdate = () => {
    if (checkSingle.length === data.length) {
      return ` Do you really want to delete all entries?`;
    }
    if (checkSingle.length === 1 || checkSingle.length === 0) {
      return "Do you really want to delete this entry?";
    }
    if (checkSingle.length < data.length) {
      return ` Do you really want to delete these ${checkSingle.length} entries?`;
    }
    return null;
  };

  return (
    <div>
      <Box
        sx={{
          marginTop: "70px",
          marginBottom: "0px",
        }}
      >
        {/* <label>Time and Date *</label> */}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={data.length > 0 && checkSingle.length === data.length}
                    onClick={() => {
                      if (checkSingle.length < data.length) {
                        setCheckSingle([]);

                        const temp = [];
                        for (let i = 0; i < data.length; i++) {
                          temp.push({ index: i, check: true });
                        }
                        setCheckSingle(temp);
                      } else {
                        setCheckSingle([]);
                      }
                    }}
                  />
                </TableCell>

                <TableCell padding="checkbox">Day</TableCell>
                <TableCell align="center">Time</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, i) => (
                <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={
                        checkAll ? true : checkSingle.filter((item) => item.index === i).length > 0
                      }
                      onClick={(e) => checkSelected(e, i, row)}
                    />
                  </TableCell>

                  <TableCell padding="checkbox">
                    <ListItem role="listitem" button onClick={handleToggle(row.date)}>
                      {row.date}
                    </ListItem>
                  </TableCell>
                  <TableCell align="center">{row.time}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      edge="end"
                      onClick={(e) => {
                        setDeleteDialog({ active: true, index: i, type: "" });
                        e.stopPropagation();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      key="edit"
                      aria-label="Edit"
                      color="inherit"
                      edge="end"
                      onClick={(e) => {
                        openDialog(e, row);
                        e.stopPropagation();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <div style={{ textAlign: "end" }}>
        <Button onClick={openDialog} style={{ margin: 10 }}>
          Add Hours
        </Button>
        <Button
          disabled={checkSingle.length === 0} // Update this line
          onClick={() => {
            if (checkSingle.length === 0) {
              return null; // No action if no items are selected
            }
            setDeleteDialog({
              active: true,
              type: checkAll ? "all" : "",
            });
            return null;
          }}
          style={{ margin: 10 }}
        >
          Delete Hours
        </Button>
      </div>

      {/* Add/Edit dialog */}
      <OpeningHoursDialog
        title="Select days and time"
        onOpen={open}
        onClose={closeDialog}
        monday={monday}
        tuesday={tuesday}
        wednesday={wednesday}
        thursday={thursday}
        friday={friday}
        saturday={saturday}
        sunday={sunday}
        dayClicked={dayClicked}
        handleAdd={handleAdd}
        openAllDayCheckbox={openAllDayCheckbox}
        closedCheckbox={closedCheckbox}
        openAllDay={openAllDay}
        closed={closed}
        start={start}
        end={end}
        handleChange={handleChange}
        edit={edit}
        selectAllDays={selectAllDays}
        handleSelectAllDays={handleSelectAllDays}
      />

      {/* overwrite dialog */}
      <ConfirmDialog
        title="Duplicates found"
        subtitle="The following days already have opening hours. Do you want to overwrite them?"
        onOpen={overwriteModel.active}
        onClose={closeOverwriteDialog}
        onConfirm={() => onConfirm()}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <div>
            <h4>Old</h4>
            {data?.map((arr, index) => (
              <div key={index}>
                {arr.date} <span>{"-->"}</span> {arr.time}
              </div>
            ))}
          </div>
          <div>
            <h4>New</h4>
            {arrToBeChecked.map((arr, index) => (
              <div key={index}>
                {arr.date} <span>{"-->"}</span> {arr.time}
              </div>
            ))}
          </div>
        </div>
      </ConfirmDialog>

      {/* delete dialog */}
      <ConfirmDialog
        title={deleteDialogTextUpdate()}
        onOpen={deleteDialog.active}
        onClose={closeDeleteDialog}
        onConfirm={() =>
          deleteOpeningHours(
            deleteDialog.index,
            checkSingle.length < data.length
              ? "selected"
              : checkSingle.length === data.length
              ? "all"
              : deleteDialog.type
          )
        }
      />
    </div>
  );
};

OpeningHours.defaultProps = {
  openHours: {},
};

OpeningHours.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  openHours: PropTypes.instanceOf(Array),
};

export default OpeningHours;
