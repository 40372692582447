/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";
import PartnerInfo from "pages/content/partners/new-partner/components/PartnerInfo";
import ContactInfo from "pages/content/partners/new-partner/components/ContactInfo";

const PartnerDetail = ({ formData, type, detailPage, setDetailPage, handleEdit }) => {
  const { submitForm, resetForm } = useFormikContext();

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {type === "partnerInfo" ? "Partner info" : "Contact info"}
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          stateName="partners"
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>
        {type === "partnerInfo" ? (
          <PartnerInfo formData={formData} editPage detailPage={detailPage} />
        ) : (
          <ContactInfo formData={formData} editPage detailPage={detailPage} />
        )}
      </MDBox>
    </MDBox>
  );
};

// Setting default props
PartnerDetail.defaultProps = {
  detailPage: true,
  setDetailPage: () => {},
  type: "",
};

// typechecking props for PartnerDetail
PartnerDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default PartnerDetail;
