import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

const Spinner = ({ loading }) => (
  <div>
    <Backdrop style={{ zIndex: 10000 }} open={loading}>
      {/* color was inherit i changed to primary */}
      <CircularProgress color="primaryC" />
    </Backdrop>
  </div>
);

Spinner.defaultProps = { loading: false };

Spinner.propTypes = {
  loading: PropTypes.bool,
};
export default Spinner;
