import React from "react";
import PropTypes from "prop-types";
import { List } from "@mui/material";

const OpeningHoursList = ({ openHoursData }) => {
  const getDayName = (weekDay) => {
    const days = ["", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    return days[weekDay];
  };
  return (
    <List>
      {openHoursData?.map((val, index) => {
        const dayName = getDayName(val.weekDay);
        const key = `opening-hours-${index}`; // Use weekDay as the key
        if (val?.open_all_day) {
          return <div key={key}>{`${dayName}: open 24 Hours`}</div>;
        }
        if (val?.closed) {
          return <div key={key}>{`${dayName}: Closed`}</div>;
        }
        return <div key={key}>{`${dayName}: ${val.open}-${val.close}`}</div>;
      })}
    </List>
  );
};
OpeningHoursList.defaultProps = {
  openHoursData: [],
};
OpeningHoursList.propTypes = {
  openHoursData: PropTypes.instanceOf(Array),
};

export default OpeningHoursList;
