import checkout from "./form";

const {
  formField: {
    // transportationService page
    title,
    location,
    logoName,
    logo,
  },
} = checkout;

const initialValues = {
  // transportationService page
  [title.name]: "",
  [location.name]: "",
  [logoName.name]: "",
  [logo.name]: "",
};

export default initialValues;
