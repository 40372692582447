/* eslint-disable react/no-array-index-key */
import React from "react";
import { Body, Container, Section, Html, Text, Img, Link } from "@react-email/components";

import convertToAMPM from "../../../../utils/function/utilFunctions";
import { defaultProps, propTypes } from "./Proptypes";

import FooterSection from "../../FooterSection";
import LogoSection from "../../LogoSection/index";
import IfYouNeedSupportSection from "../../IfYouNeedSupport";

const UserConfirmation = ({ user, data }) => {
  /* ***** Constants  ****** */
  const { name } = user;
  const { recommendation, time = "", guests = 0, date = "" } = data;
  const { title, logo, geoLocationData } = recommendation;

  const handleDetails = () => {
    const details = [
      date.replace(/\s*(\d{4})$/, ", $1"),
      convertToAMPM(time),
      guests > 1 ? `${guests} guests` : `${guests} guest`,
    ];

    return (
      <div
        style={{
          gap: "8px",
          display: "flex",
        }}
      >
        {details.map(
          (value, index) =>
            value && (
              <Text
                key={index}
                style={{
                  margin: "0",
                  fontSize: "14px",
                  padding: "2px 6px",
                  borderRadius: "2px",
                  border: "1px solid #222D29",
                }}
              >
                {value}
              </Text>
            )
        )}
      </div>
    );
  };

  return (
    <Html>
      <Body
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "Helvetica",
          justifyContent: "center",
          backgroundColor: "#f7fafc",
        }}
      >
        <Container
          style={{
            maxWidth: "600px",
          }}
        >
          <LogoSection />

          <Section
            style={{
              gap: "20px",
              display: "flex",
              color: "#222D29",
              padding: "30px 32px",
            }}
          >
            <Text
              numberOfLines={1}
              style={{
                fontSize: "25px",
                fontWeight: "700",
                lineHeight: "26.5px",
              }}
            >
              Dear {name},
            </Text>

            <Text
              numberOfLines={3}
              style={{
                fontSize: "24px",
                fontWeight: "400",
                lineHeight: "26.5px",
              }}
            >
              Thank you for your reservation at {title}.
            </Text>
          </Section>

          <Section
            style={{
              padding: "0px 24px",
            }}
          >
            <div
              style={{
                padding: "4px 24px 24px",
                borderRadius: "11.54px",
                fontFamily: "Helvetica",
                backgroundColor: "#F2F2F2",
              }}
            >
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: "14.43px",
                }}
              >
                Here are the location details:
              </Text>

              <Img
                src={logo}
                width="100%"
                height="auto"
                alt="Reservation"
                style={{
                  borderRadius: "11.54px",
                }}
              />

              <div
                style={{
                  gap: 16,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ gap: 8 }}>
                  <Text
                    numberOfLines={2}
                    style={{
                      fontSize: "22px",
                      fontWeight: "400",
                      lineHeight: "23.55px",
                    }}
                  >
                    {title}
                  </Text>

                  {handleDetails()}
                </div>

                <div style={{ border: "1px solid #BAB9B9" }} />

                <div>
                  <Text
                    style={{
                      margin: "0",
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "21.46px",
                    }}
                  >
                    Address
                  </Text>

                  <Link
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                    href={`https://www.google.com/maps/search/?api=1&query=${geoLocationData.address}`}
                  >
                    <Text
                      style={{
                        margin: "0",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "19.32px",
                        textDecoration: "underline",
                      }}
                    >
                      {geoLocationData.address}
                    </Text>
                  </Link>
                </div>
              </div>
            </div>
          </Section>

          <Text
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "23px",
              textAlign: "center",
              padding: "0px 24px",
            }}
          >
            We hope you have the best dining experience with us.
          </Text>

          <IfYouNeedSupportSection showTitle={false} />

          <FooterSection />
        </Container>
      </Body>
    </Html>
  );
};

UserConfirmation.defaultProps = defaultProps;

UserConfirmation.propTypes = propTypes;

export default UserConfirmation;
