/* eslint-disable no-underscore-dangle */
import {
  TRENDS_FAIL,
  GET_TRENDS,
  //   SET_TRENDS_LOADING,
  //   CLEAR_ERRORS,
  ADD_TREND,
  REMOVE_TREND_ITEM,
  REMOVE_TREND_RECOMMENDATION,
  GET_UPDATED_DESTINATION_ID,
  //   CITIES_FAIL,
} from "../actions/Types";

const initialState = {
  trends: [],
  loading: false,
  error: null,
  cities: [],
  added: false,
  destinationAndType: [],
};

const variable = (state = initialState, action) => {
  function upsert(array, item) {
    // (1)
    // make a copy of the existing array
    const newArray = array.slice();

    const i = newArray.findIndex((_item) => _item._id === item._id);
    if (i > -1) {
      newArray[i] = item;
      return newArray;
    }
    // (2)

    // newArray.unshift(item);
    return newArray;
  }
  switch (action.type) {
    case GET_TRENDS:
      return {
        ...state,
        loading: false,
        error: null,
        trends: action.payload.data,
        added: false,
      };

    case ADD_TREND:
      return {
        ...state,
        loading: false,
        error: null,
        added: true,
        trends: upsert(
          state.trends,
          action.payload.data.recommendation || action.payload.data.data
        ),
      };

    case REMOVE_TREND_RECOMMENDATION:
      return {
        ...state,
        loading: false,
        error: null,
        added: true,
        trends: state.trends.filter((trend) => trend._id !== action.payload.data._id),
      };
    case REMOVE_TREND_ITEM:
      return {
        ...state,
        loading: false,
        error: null,
        added: true,
        trends: state.trends.filter((trend) => trend._id !== action.payload.data.data.id),
      };

    case TRENDS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        trends: [],
      };
    case GET_UPDATED_DESTINATION_ID:
      return {
        ...state,
        destinationAndType: action.payload,
        added: false,
      };

    default:
      return state;
  }
};
export default variable;
