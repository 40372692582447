/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Section, Html, Text } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import LogoSection from "../LogoSection";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import FooterSection from "../FooterSection";
import DynamicTicketCard from "../DynamicTicketCard";
import HeadSection from "../HeadSection";
import { defaultProps, propTypes } from "./Proptypes";

const AbandonmentEmail = ({ user, token, data }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100 font-sans leading-relaxed">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-left bg-white p-6 pl-8 text-black w-[536px] h-[182px]">
            <Text className="text-[24px] leading-[27.6px] font-bold mb-2">Hello there,</Text>
            <Text className="text-[24px] leading-[29.16px] font-normal mb-2">
              {" "}
              We couldn't help but notice that you've got your eye on the {data.ref.title}!
            </Text>
            <Text className="text-[24px] leading-[24.3px] font-normal mb-2">
              Don't let this golden opportunity slip through your fingers like a soggy french fry.
              It's time to seize the moment and make your booking before it disappears faster than
              your favorite snacks on movie night!
            </Text>
            {/* <Text>Complete Checkout to make some "Wow" and "Whoa" kind of memories.</Text> */}
            <Text>
              <span className="text-[20px] leading-[24.3px] font-bold">Complete Checkout</span>
              <span className="text-[20px] leading-[24.3px] font-normal">
                {" "}
                to make some "Wow" and "Whoa" kind of memories.
              </span>
            </Text>
            <Text className="text-[20px] leading-[24.3px] font-bold mb-2">
              Catch you on the flip side!
            </Text>
          </Section>
          <DynamicTicketCard Abandonment noDescription data={data} user={user} token={token} />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);
AbandonmentEmail.defaultProps = defaultProps;

AbandonmentEmail.propTypes = propTypes;

export default AbandonmentEmail;
