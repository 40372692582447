/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

import React from "react";
import PropTypes from "prop-types";
import { Star, StarBorder } from "@mui/icons-material";
import { Card, CardContent, Typography, Box, Grid } from "@mui/material";

const ItiniraryRating = ({ formData }) => {
  const { reviews = [] } = formData.values || {};

  return (
    <Grid
      container
      spacing={2}
      sx={{ maxHeight: 300, overflowY: "auto", padding: 1, marginTop: 0.1 }}
    >
      {reviews.map((item, index) => {
        const { _id = "", rating = 0, text = "", userName = "Guest" } = item || {};

        return (
          <Grid item xs={12} sm={6} key={_id}>
            <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
              <CardContent>
                <Typography variant="body2" component="div" sx={{ fontSize: "0.875rem" }}>
                  <strong>Name:</strong> {userName}
                </Typography>

                <Box display="flex" alignItems="center" sx={{ fontSize: "0.875rem" }}>
                  <Typography variant="body2" component="div" sx={{ mr: 1, fontSize: "0.875rem" }}>
                    <strong>Stars:</strong>
                  </Typography>

                  {[...Array(5)].map((_, starIndex) =>
                    starIndex < rating ? (
                      <Star key={`star-${index}-${starIndex}`} sx={{ color: "black" }} />
                    ) : (
                      <StarBorder key={`star-border-${index}-${starIndex}`} />
                    )
                  )}
                </Box>

                <Typography variant="body2" component="div" sx={{ fontSize: "0.875rem" }}>
                  <strong>Comment:</strong> {text}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

// typechecking props for ItiniraryRating
ItiniraryRating.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
};

export default ItiniraryRating;
