import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { logo, destinationName, address, timeZone, countryCode, vatRate },
} = checkout;

const validations = [
  Yup.object().shape({
    [logo.name]: Yup.string().required(logo.errorMsg).nullable(),
    [destinationName.name]: Yup.string()
      .matches(/([A-Za-z])/, destinationName.invalidMsg)
      .required(destinationName.errorMsg),
    [vatRate.name]: Yup.number().min(0, vatRate.errorMsg).max(100, vatRate.invalidMsg),
  }),
  Yup.object().shape({
    [address.name]: Yup.string()
      .matches(/([A-Za-z\u0600-\u06FF])/, address.invalidMsg)
      .required(address.errorMsg),

    [timeZone.name]: Yup.object().required(timeZone.errorMsg),
    [countryCode.name]: Yup.string().required(countryCode.errorMsg),
  }),
];

export default validations;
