/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from "axios";

import {
  CLEAR_ERRORS,
  SET_VENUE_LOADING,
  VENUE_ADD_SUCCESS,
  VENUE_FAIL,
  UPDATE_VENUE,
  DELETE_SINGLE_VENUE,
  SET_ITEM_LOADING,
} from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// get all venues
export const getAllVenues = () =>
  factory.get(`${URL}/venues?sort=title&dir=asc`, "GET_ALL_VENUES", "VENUE_FAIL");

// Add Venue
export const addVenue = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  form.append("title", data.title.trim());
  form.append("fomoMessage", data.fomoMessage);
  form.append("top", data.top);
  if (data.address || data.city) form.append("address", data.address || data.city);
  form.append("city", data.city?.trim());
  form.append("region", data.region);
  form.append("country", data.country);
  form.append("lat", data.lat);
  form.append("lng", data.lng);
  form.append("source", "lokalee");
  form.append("primaryDestinationId", data.primaryDestinationId);

  if (image) {
    image.path = image?.name;
    form.append("logo", image);
    form.append("hasLogo", true);
  }
  try {
    const res = await axios.post(`${URL}/venues`, form, config);
    dispatch({
      type: VENUE_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: VENUE_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Update Venue
export const updateVenue = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("title", data.title);
  form.append("fomoMessage", data.fomoMessage);
  form.append("top", data.top);
  if (data.address || data.city) form.append("address", data.address || data.city);
  if (data.city) form.append("city", data.city?.trim());
  if (data.region) form.append("region", data.region);
  if (data.country) form.append("country", data.country);
  if (data.lat) form.append("lat", data.lat);
  if (data.lng) form.append("lng", data.lng);
  form.append("source", "lokalee");
  if (data.primaryDestinationId)
    form.append(
      "primaryDestinationId",
      data.primaryDestinationId?.id ? data.primaryDestinationId?.id : data.primaryDestinationId
    );

  if (image) {
    image.path = image?.name;
    form.append("logo", image);
    form.append("hasLogo", true);
  }
  try {
    const res = await axios.patch(`${URL}/venues/${data._id}`, form, config);

    dispatch({
      type: UPDATE_VENUE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: VENUE_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Delete venue - set active as false
export const deleteVenue = (id) =>
  factory.patch({ active: false }, `${URL}/venues/${id}`, "DELETE_VENUE", "VENUE_FAIL");

// get One Venue
export const getOneVenue = (id) =>
  factory.get(`${URL}/venues/${id}`, "GET_ONE_VENUE", "VENUE_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_VENUE_LOADING });
export const setItemsLoading = () => ({ type: SET_ITEM_LOADING });

export const deleteSingleVenue = () => ({ type: DELETE_SINGLE_VENUE });

export const getVenueTicket = (venue, destination) =>
  factory.get(
    `${URL}/tickets?venues=${venue}&destinations=${destination}`,
    "GET_ITEMS",
    "ITEM_FAIL"
  );
