/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import bookingConfirmationList from "./BookingConfirmationList";

const BookingConfirmationPicker = ({
  handleBookingConfirmationChange,
  bookingConfirmation,
  setFieldValue,
}) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });

  const findConf = (confValue) =>
    bookingConfirmationList.find((confVal) => confVal.value === confValue) || null;
  const confValue =
    typeof bookingConfirmation === "string" ? findConf(bookingConfirmation) : bookingConfirmation;

  return (
    <SelectF
      options={bookingConfirmationList}
      placeholder="Select ..."
      value={confValue}
      onChange={(event) => {
        if (event) {
          setFieldValue("confirmationType", event);
          setFieldValue("bookingConfirmationSettings.confirmationType", event);
          handleBookingConfirmationChange(event);
        } else {
          setFieldValue("confirmationType", {});

          setFieldValue("bookingConfirmationSettings.confirmationType", {});
          handleBookingConfirmationChange(null);
        }
      }}
      getOptionValue={(option) => `${option.value}`}
      getOptionLabel={(option) => `${option.name}`}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      isSearchable
      menuPortalTarget={document.body}
    />
  );
};

// Setting default props
BookingConfirmationPicker.defaultProps = {
  handleBookingConfirmationChange: () => {},
  bookingConfirmation: {},
  setFieldValue: () => {},
};

// typechecking props for BookingConfirmation Picker
BookingConfirmationPicker.propTypes = {
  handleBookingConfirmationChange: PropTypes.func,
  bookingConfirmation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setFieldValue: PropTypes.func,
};

export default BookingConfirmationPicker;
