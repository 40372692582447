import React, { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ShowHideComponentsPicker = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MDBox>
      <MDBox
        lineHeight={0}
        onClick={toggleVisibility}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        <MDTypography variant="h5" style={{ marginRight: "0.5rem" }}>
          {title}
        </MDTypography>
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </MDBox>
      {isOpen && children}
    </MDBox>
  );
};

ShowHideComponentsPicker.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ShowHideComponentsPicker;
