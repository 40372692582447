const formStops = {
  formId: "new-partner-form",
  formField: {
    slot: {
      name: "slot",
      label: "Slot *",
      type: "text",
      errorMsg: "Slot is required!",
    },
    description: {
      name: "description",
      label: "Stop Description *",
      type: "text",
      errorMsg: "Description is required!",
      invalidMsg: "Description should have at least 1 letter!",
    },
    type: {
      name: "type",
      label: "Type",
      type: "text",
    },
    ctaTitle: {
      name: "ctaTitle",
      label: "CTA Title *",
      type: "text",
      errorMsg: "CTA Title is required!",
      invalidMsg: "CTA Title should have at least 1 letter!",
    },
    ctaLabel: {
      name: "ctaLabel",
      label: "CTA Label *",
      type: "text",
      errorMsg: "CTA Label is required!",
      invalidMsg: "CTA Label should have at least 1 letter!",
    },
    ctaUrl: {
      name: "ctaUrl",
      label: "CTA Url *",
      type: "text",
      errorMsg: "CTA Url is required!",
      invalidMsg: "Please enter a valid contact url! (http://www.url.com)!",
    },
    venues: {
      name: "venues",
      label: "Venues *",
      type: "array",
      errorMsg: "Venues is required!",
      invalidMsg: "Should have at least 1 venue!",
    },
    tickets: {
      name: "tickets",
      label: "Tickets *",
      type: "array",
      errorMsg: "Tickets is required!",
      invalidMsg: "Should have at least 1 ticket!",
    },
    media: {
      name: "media",
      label: "Media",
      type: "text",
    },
    video: {
      name: "video",
      label: "Video URL",
      type: "text",
      invalidMsg: "Please enter a valid Youtube url! (http://www.youtube.com)!",
    },

    image: {
      name: "image",
      label: "Image *",
      type: "text",
      errorMsg: "Image is required!",
    },
  },
};

export default formStops;
