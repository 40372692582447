/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ViewEditActions from "components/DetailList/ViewEditActions";
import ItineraryInfo from "pages/content/experiences/new-experience/components/ItineraryInfo";
import ItineraryStops from "pages/content/experiences/new-experience/components/IntineraryStops";
import ItiniraryRating from "../itineraryRating";

const ItineraryDetail = ({
  type,
  expId,
  tabValue,
  formData,
  detailPage,
  handleEdit,
  setTabValue,
  selectedSlot,
  setDetailPage,
  selectedCurator,
  selectedTickets,
  handleSlotChange,
  handleMediaChange,
  handleTicketChange,
  handleCuratorChange,
}) => {
  const { submitForm, resetForm } = useFormikContext();

  const handleCardTitle = () => {
    switch (type) {
      case "itineraryInfo":
        return "Itinerary Info";
      case "rating":
        return "Rating";
      case "stops":
        return "Stops";
      default:
        return null;
    }
  };
  const handleInfoCard = () => {
    switch (type) {
      case "itineraryInfo":
        return (
          <ItineraryInfo
            editPage
            formData={formData}
            detailPage={detailPage}
            selectedCurator={selectedCurator}
            handleCuratorChange={handleCuratorChange}
          />
        );
      case "rating":
        return <ItiniraryRating formData={formData} />;
      case "stops":
        return (
          <ItineraryStops
            editPage
            id={expId}
            formData={formData}
            tabValue={tabValue}
            setTabValue={setTabValue}
            selectedSlot={selectedSlot}
            selectedTickets={selectedTickets}
            handleSlotChange={handleSlotChange}
            handleMediaChange={handleMediaChange}
            handleTicketChange={handleTicketChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>

        <ViewEditActions
          detailPage={detailPage}
          handleEdit={handleEdit}
          handleSave={() => submitForm()}
          noEditIcon={["stops", "rating"].includes(type)}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
          }}
        />
      </MDBox>

      <MDBox>{handleInfoCard()}</MDBox>
    </MDBox>
  );
};

ItineraryDetail.defaultProps = {
  detailPage: true,
  selectedCurator: {},
  selectedTickets: [],
  setDetailPage: () => {},
  handleSlotChange: () => {},
  handleMediaChange: () => {},
  handleTicketChange: () => {},
  handleCuratorChange: () => {},
};
ItineraryDetail.propTypes = {
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleSlotChange: PropTypes.func,
  type: PropTypes.string.isRequired,
  handleMediaChange: PropTypes.func,
  handleTicketChange: PropTypes.func,
  expId: PropTypes.string.isRequired,
  handleCuratorChange: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired,
  setTabValue: PropTypes.func.isRequired,
  selectedCurator: PropTypes.instanceOf(Object),
  selectedTickets: PropTypes.instanceOf(Object),
  selectedSlot: PropTypes.instanceOf(Object).isRequired,
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
};

export default ItineraryDetail;
