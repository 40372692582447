/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Section, Text, Head, Html, Img, Button } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import { Font } from "@react-email/font";
import FooterSection from "../FooterSection";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import LogoSection from "../LogoSection";
import { defaultProps, propTypes } from "./Proptypes";
import DynamicTicketCard from "../DynamicTicketCard";

const OnboardingEmailForWeb = ({ user, token, data }) => {
  // Normalize data to always be an array
  const destinations = Array.isArray(data) ? data : [data];

  return (
    <Html>
      <Head>
        <Font
          fontFamily="Helvetica"
          fallbackFontFamily="Arial"
          webFont={{
            url: "https://fonts.gstatic.com/s/helvetica/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2",
            format: "woff2",
          }}
          fontStyle="normal"
        />
      </Head>
      <Tailwind>
        <Body className="bg-gray-100 ">
          <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
            <LogoSection />
            <Section className="text-center bg-white p-5 text-black mb-0">
              <Img
                src="https://email-assets.lokalee.app/email/emailImages/cover.png"
                alt="Hero Image"
                className="w-full h-auto rounded"
              />
              <Text className="text-[28px] leading-[34px] font-bold mb-2">Hi {user?.name},</Text>
              <Text className="text-[28px] leading-[34px] font-bold mb-2">
                Unlock your desired destination with us!
              </Text>
            </Section>
            <Section className="text-center bg-[#F2F2F2] p-5 text-black mb-0 ">
              <Text className="text-[15px] leading-[17px] font-bold p-0 m-0 ">
                From unique experiences and activities to fun
              </Text>
              <Text className="text-[15px] leading-[17px] font-bold p-0 m-0">
                attractions and top dining recommendations.
              </Text>
              <Img
                src="https://email-assets.lokalee.app/email/emailImages/Vector-Folder-Image.png"
                alt="Book with us your next adventure"
                className="mx-auto my-4 block w-[84px] h-[84px]"
              />
              <Text className="text-[25px] leading-[26.5px] font-bold pb-4 mb-6">
                Book with us your next adventure!
              </Text>
              <Button
                className="bg-[#003005] text-white text-[20px] leading-[23px] font-bold p-4 py-2 px-4 rounded-full w-80  mx-auto"
                href={token}
              >
                EXPLORE NOW
              </Button>
            </Section>
            {/* Top Destinations */}
            <Section className="bg-[#8C8C8C] text-center text-white py-4">
              <Text className="font-semibold text-lg">Top destinations to discover</Text>
            </Section>
            <Section className="bg-white p-5">
              {destinations.length > 0 ? (
                destinations.map((destination) => (
                  <div key={destination?.id} className="mb-4">
                    <a
                      href={`https://lokalee.app/${destination?.slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        src={destination?.coverImage}
                        alt={destination?.destinationName}
                        className="w-full h-auto rounded mb-2"
                      />
                    </a>
                    <Text className="text-center font-semibold">
                      {destination?.destinationName}
                    </Text>
                  </div>
                ))
              ) : (
                <DynamicTicketCard data={data} />
              )}
            </Section>
            <hr className="border-t-2  border-[#394A43] mt-0" />
            <IfYouNeedSupportSection />
            <FooterSection />
          </Container>
        </Body>
      </Tailwind>
    </Html>
  );
};

OnboardingEmailForWeb.defaultProps = defaultProps;

OnboardingEmailForWeb.propTypes = propTypes;

export default OnboardingEmailForWeb;
