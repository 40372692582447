/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Redux Actions
import { addLocation, clearErrors, setLoading } from "store/actions/locationAction";
import setAlert from "store/actions/alertActions";
import { clearErrors as clearCityErrors } from "store/actions/cityActions";
import { checkPartners, clearErrors as clearPartnerErrors } from "store/actions/partnerAction";
import { scrollToTop, useHandleError } from "components/helper";

// Layouts
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";

// Schema
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import LocationInfo from "./components/LocationInfo";
import Address from "./components/Address";
import AdditionalInfo from "./components/AdditionalInfo";
import Review from "./components/Review";

const mainArray = ["Location Info", "Address", "Additional Info", "Review"];

function getSteps() {
  return ["Location Info", "Address", "Additional Info", "Review"];
}

function getStepContent(
  stepIndex,
  formData,
  selectedPartner,
  handlePartnerChange,
  selectedTravelDesk,
  handleTravelDeskChange,
  handlePrimaryDestinationChange,
  selectedPrimaryDestination,
  handleSecondaryDestinationChange,
  selectedSecondaryDestination,
  setSelectedPrimaryDestination
) {
  if (formData) {
    switch (mainArray[stepIndex]) {
      case "Location Info":
        return (
          <LocationInfo
            formData={formData}
            selectedPartner={selectedPartner}
            handleChange={handlePartnerChange}
          />
        );
      case "Address":
        return (
          <Address
            formData={formData}
            handlePrimaryDestinationChange={handlePrimaryDestinationChange}
            selectedPrimaryDestination={selectedPrimaryDestination}
            handleSecondaryDestinationChange={handleSecondaryDestinationChange}
            selectedSecondaryDestination={selectedSecondaryDestination}
            setSelectedPrimaryDestination={setSelectedPrimaryDestination}
          />
        );
      case "Additional Info":
        return (
          <AdditionalInfo
            formData={formData}
            selectedTravelDesk={selectedTravelDesk}
            handleChange={handleTravelDeskChange}
          />
        );
      case "Review":
        return (
          <Review formData={formData} selectedPrimaryDestination={selectedPrimaryDestination} />
        );
      default:
        return null;
    }
  }
  return null;
}

const NewLocation = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const user = useSelector((state) => state.users.user);
  const error = useSelector((state) => state.locations.error);
  const partnerError = useSelector((state) => state.partners.error);

  const partnerSchemaValidation = Yup.object().shape({
    partner: Yup.string()
      .required("Partner is required!")
      .test("checkPartner", partnerError, async (value) => {
        if (value) {
          // Call your checkPartners function and handle the response
          dispatch(checkPartners({ partner: value }));
        }
      }),
  });
  const handleValidation = (activeStep) => {
    switch (mainArray[activeStep]) {
      case "Location Info":
        return validations[0].concat(partnerSchemaValidation);
      case "Address":
        return validations[1];
      default:
        return null;
    }
  };

  // state
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const { formId, formField } = form;
  const [formChange, setFormChange] = useState(null);
  const currentValidation = handleValidation(activeStep);
  const isLastStep = activeStep === steps.length - 1;
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedTravelDesk, setSelectedTravelDesk] = useState(null);
  const [selectedPrimaryDestination, setSelectedPrimaryDestination] = useState(null);
  const [selectedSecondaryDestination, setSelectedSecondaryDestination] = useState([]);

  useEffect(() => {
    setSteps(getSteps("admin"));
  }, []);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (formChange?.city) {
      dispatch(clearCityErrors());
    }
  }, [formChange?.city]);

  useEffect(() => {
    if (formChange?.partner) {
      dispatch(clearPartnerErrors());
    }
  }, [formChange?.partner]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    scrollToTop(); // Scroll to the top
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/locations?`;
      navigate(url);
    } else if (type === "partner") {
      url = `/partner/locations`;
      navigate(url);
    }
  };

  const submitForm = async (values, actions) => {
    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    dispatch(addLocation(values, values?.logo));
    actions.setSubmitting(false);
    // if (!error || error?.length === 0) {
    actions.resetForm();
    // navigate(`/admin/locations`);
    setActiveStep(0);
    // }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
      handleNavigation(user?.role);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      scrollToTop(); // Scroll to the top
    }
  };
  const handlePrimaryDestinationChange = (value) => {
    if (value) {
      setSelectedPrimaryDestination(value);
    } else {
      setSelectedPrimaryDestination({});
    }
  };
  const handleSecondaryDestinationChange = (value) => {
    if (value) {
      setSelectedSecondaryDestination(value);
    } else {
      setSelectedSecondaryDestination([]);
    }
  };
  const handlePartnerChange = (e, value) => {
    if (!value || !value._id) {
      setSelectedPartner({});
    } else {
      setSelectedPartner(value);
    }
  };

  const handleTravelDeskChange = (e, value) => {
    if (value && value?._id) {
      setSelectedTravelDesk(value);
    } else {
      setSelectedTravelDesk(null);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={steps[activeStep] === "Review" ? undefined : currentValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                setFormChange(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          selectedPartner,
                          handlePartnerChange,
                          selectedTravelDesk,
                          handleTravelDeskChange,
                          handlePrimaryDestinationChange,
                          selectedPrimaryDestination,
                          handleSecondaryDestinationChange,
                          selectedSecondaryDestination,
                          setSelectedPrimaryDestination
                        )}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <div>
                            <MDButton
                              onClick={() => handleNavigation(user?.role)}
                              rel="noreferrer"
                              variant="outlined"
                              color="dark"
                              sx={{ marginRight: "8px" }}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "add" : "next"}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewLocation;
