/* eslint-disable no-underscore-dangle */
import {
  SET_EXPERIENCE_LOADING,
  EXPERIENCE_ADD_SUCCESS,
  EXPERIENCE_FAIL,
  GET_EXPERIENCES,
  CLEAR_ERRORS,
  DELETE_EXPERIENCE,
  GET_ONE_EXPERIENCE,
  UPDATE_EXPERIENCE,
  LOGOUT,
  STOP_ADD_SUCCESS,
  SORT_STOPS,
  GET_STOPS,
  STOPS_FAIL,
  DELETE_STOP,
  UPDATE_EXPERIENCE_TRANSLATION,
} from "../actions/Types";

const initialState = {
  loading: false,
  experiences: [],
  stops: {},
  error: null,
  added: false,
  experience: null,
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case EXPERIENCE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        experiences: [...state.experiences, action.payload.data],
        added: true,
        experience: null,
        stops: {},
      };
    case GET_ONE_EXPERIENCE:
      return {
        ...state,
        loading: false,
        error: null,
        experience: action.payload.data.data,
        stops: action.payload.data.data.stops,
        added: false,
      };

    case GET_EXPERIENCES:
      return {
        ...state,
        loading: false,
        error: null,
        experiences: action.payload.data.data,
        added: false,
        experience: null,
        stops: {},
      };
    case DELETE_EXPERIENCE:
      return {
        ...state,
        loading: false,
        experiences: state.experiences.filter(
          (experience) => experience._id !== action.payload.data.data._id
        ),
      };
    case UPDATE_EXPERIENCE:
      return {
        ...state,
        added: true,
        experiences: state.experiences.map((experience) =>
          experience._id === action.payload.data.data._id ? action.payload.data.data : experience
        ),
      };
    case UPDATE_EXPERIENCE_TRANSLATION:
      return {
        ...state,
        experience: action.payload.data?.experience,
      };
    case EXPERIENCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_EXPERIENCE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        experiences: [],
        error: null,
        added: false,
        experience: null,
        stops: {},
      };
    case STOP_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        stops: action.payload.data.data.stops,
      };
    case SORT_STOPS:
      return {
        ...state,
        added: true,
        stops: action.payload.data.data.stops,
      };
    case GET_STOPS:
      return {
        ...state,
        loading: false,
        error: null,
        stops: action.payload.data.data.stops,
        added: false,
        stop: null,
      };
    case DELETE_STOP:
      return {
        ...state,
        loading: false,
        error: null,
        stops: action.payload.data.data.stops,
      };
    case STOPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.data.message,
      };
    default:
      return state;
  }
};
export default variable;
