/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select";
import { capitalizeFirstLetter } from "components/helper";

const ServiceJobsVersionPicker = ({
  handleVersionChange,
  setJobVersion,
  versionV,
  setFieldValue,
  company, // Accept company as a prop to control version value
}) => {
  useEffect(() => {
    // Automatically update the version based on the selected company
    if (company === "sky") {
      setFieldValue("version", "v1");
      setJobVersion("V1");
      handleVersionChange("V1");
    } else if (company === "messagebox") {
      setFieldValue("version", "v0.1");
      setJobVersion("V0.1");
      handleVersionChange("V0.1");
    } else {
      // Clear version if no valid company is selected
      setFieldValue("version", "");
      setJobVersion("");
    }
  }, [company, setFieldValue, setJobVersion, handleVersionChange]);

  // Version options (though this will be auto-filled and disabled)
  const versionOptions = [
    { label: "V1", value: "v1" },
    { label: "V0.1", value: "v0.1" },
  ];

  return (
    <div>
      <AsyncSelect
        options={versionOptions}
        value={
          versionV
            ? { label: capitalizeFirstLetter(versionV), value: versionV.toLowerCase() }
            : null
        }
        placeholder="Select a version"
        isDisabled // Disable this field so the user cannot change it
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor, // Change border color on focus
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow,
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor,
            },
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem", // Customize the selected option text size
          }),
        }}
      />
    </div>
  );
};

// Setting default props
ServiceJobsVersionPicker.defaultProps = {
  versionV: "",
  setJobVersion: () => {},
  handleVersionChange: () => {},
  company: "",
};

// Prop-types for ServiceJobsVersionPicker
ServiceJobsVersionPicker.propTypes = {
  versionV: PropTypes.string,
  setJobVersion: PropTypes.func,
  handleVersionChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  company: PropTypes.string, // Add company as a required prop
};

export default ServiceJobsVersionPicker;
