import React from "react";

const SocialLinks = () => (
  <table
    width="100%"
    style={{
      textAlign: "center",
    }}
    cellPadding="0"
    cellSpacing="0"
    role="presentation"
  >
    <tbody>
      <tr>
        <td style={{ textAlign: "center", paddingTop: "16px" }}>
          <p
            style={{
              fontSize: "17px",
              lineHeight: "19.55px",
              fontWeight: "bold",
              color: "#000000",
              margin: "0",
              marginBottom: "16px",
            }}
          >
            Follow Us
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ textAlign: "center" }}>
          <table
            style={{
              margin: "0 auto",
            }}
            cellPadding="0"
            cellSpacing="0"
            role="presentation"
          >
            <tbody>
              <tr>
                <td style={{ paddingRight: "14.5px" }}>
                  <a href="https://www.instagram.com/lokalee.app/" aria-label="Instagram">
                    <img
                      src="https://email-assets.lokalee.app/email/emailImages/instagram.png"
                      alt="Instagram"
                      style={{ width: "34px", height: "32px", display: "block" }}
                    />
                  </a>
                </td>
                <td style={{ paddingRight: "14.5px" }}>
                  <a
                    href="https://www.linkedin.com/company/linkedin.com-lokalee/mycompany/"
                    aria-label="LinkedIn"
                  >
                    <img
                      src="https://email-assets.lokalee.app/email/emailImages/linkedin.png"
                      alt="LinkedIn"
                      style={{ width: "34px", height: "32px", display: "block" }}
                    />
                  </a>
                </td>
                <td>
                  <a href="https://www.facebook.com/lokaleeapp" aria-label="Facebook">
                    <img
                      src="https://email-assets.lokalee.app/email/emailImages/facebook.png"
                      alt="Facebook"
                      style={{ width: "34px", height: "32px", display: "block" }}
                    />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

export default SocialLinks;
