import checkout from "./form";

const {
  formField: {
    // element page
    title,
    description,
    ctaTitle,
    ctaUrl,
    ctaEmail,
    files,
    ctaType,
  },
} = checkout;

const initialValues = {
  // element page
  [title?.name]: "",
  [description?.name]: "",
  [ctaTitle?.name]: "",
  [ctaUrl?.name]: "",
  [ctaEmail?.name]: "",
  [files?.name]: "",
  [ctaType?.name]: "ctaUrl",
};

export default initialValues;
