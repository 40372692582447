/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable new-cap */
/* eslint-disable no-lone-blocks */
// eslint-disable-next-line no-unused-expressions

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const generateCommissionInvoicePdf = (reportData) => {
  const doc = new jsPDF("p", "mm", "a4");
  const pageWidth = doc.internal.pageSize.getWidth();
  const {
    trn = "",
    endDate = "",
    address = "",
    vatRate = "",
    currency = "",
    totalRevenue = "",
    locationName = "",
    invoiceNumber = "",
    totalComission = "",
    numberOfTicketSales = "",
    totalVatOnCommission = "",
  } = reportData.summary || {};

  function formatDateToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  }

  const printBeneficiaryDetails = (startPoint) => {
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("International Hotel Investment Co LLC", 15, startPoint);
    doc.text(locationName, 15, startPoint + 5);
    doc.setFont("helvetica", "normal");
    doc.text(`${address},`, 15, startPoint + 10);
    doc.text(`TRN: ${trn}`, 15, startPoint + 15);
  };

  /* *********************************** First Page ************************************ */
  doc.setFontSize(20);
  const titleText = "Self-billing Tax Invoice";
  const textWidth = doc.getTextWidth(titleText);
  const xPosition = (pageWidth - textWidth) / 2;
  doc.text(titleText, xPosition, 20);

  printBeneficiaryDetails(50);

  doc.text("Invoice for:", 15, 90);
  doc.setFont("helvetica", "bold");
  doc.text("Advanced Digital Technology DWC-LLC", 15, 95);
  doc.setFont("helvetica", "normal");
  doc.text("Dubai Aviation City", 15, 100);
  doc.text("PO Box 390667 Dubai, U.A.E.", 15, 105);
  doc.text("License Number 11083", 15, 110);
  doc.text("TRN: 100441659800003", 15, 115);

  doc.setFontSize(10);
  doc.text("Invoice details:", 130, 90);
  doc.text(`Invoice Date: ${formatDateToYYYYMMDD(endDate)}`, 130, 95);
  doc.text(`Invoice Number: ${invoiceNumber}`, 130, 100);

  doc.text("Payment details:", 130, 130);
  doc.text(`Beneficiary: ${locationName}`, 130, 135);
  doc.text("VAT number: 100441659800003", 130, 140);

  /* *********************************** Summary Tables ************************************ */
  doc.text("Operations", 15, 150);
  autoTable(doc, {
    startY: 152,
    theme: "grid",
    head: [["Description", "Amount", currency]],
    body: [
      ["Tickets Sale", numberOfTicketSales, totalRevenue],
      ["", "", ""],
      ["Prepaid", "", totalRevenue],
      ["Total to Pay", "", totalRevenue],
    ],
    styles: {
      fontSize: 8,
      cellWidth: (pageWidth - 30) / 3,
    },
    headerStyles: {
      halign: "left",
    },
    didParseCell: (data) => {
      if (data.row.index === 3) {
        data.cell.styles.fontSize = 10;
        data.cell.styles.fontStyle = "bold";
        data.cell.styles.fillColor = [220, 220, 220];
      }
    },
  });
  doc.text("Your partner commission", 15, doc.lastAutoTable.finalY + 10);
  autoTable(doc, {
    theme: "grid",
    startY: doc.lastAutoTable.finalY + 12,
    head: [["Description", "Amount", currency]],
    body: [
      ["Partner Commission", numberOfTicketSales, totalComission],
      ["", "", ""],
      ["VAT included in partner commission", `Reversed charge ${vatRate}%`, totalVatOnCommission],
      ["Total to receive", "", totalComission + totalVatOnCommission],
    ],
    styles: {
      fontSize: 8,
      cellWidth: (pageWidth - 30) / 3,
    },
    headerStyles: {
      halign: "left",
    },
    didParseCell: (data) => {
      if (data.row.index === 3) {
        data.cell.styles.fontSize = 10;
        data.cell.styles.fontStyle = "bold";
        data.cell.styles.fillColor = [220, 220, 220];
      }
    },
  });

  /* *********************************** Footer Section ************************************ */
  doc.text(
    "The total payment amount will be wired to your account within 7 days.",
    doc.internal.pageSize.getWidth() - 15,
    doc.lastAutoTable.finalY + 5,
    { align: "right" }
  );
  const text1 = doc.splitTextToSize(
    `This invoice has been issued by ADT of TRN${trn} on behalf of ${locationName}`
  );
  const text2 = doc.splitTextToSize(
    `in accordance with the procedure accepted by the customer in the service contract signed by ADT and ${locationName}.`,
    pageWidth * 0.8
  );
  const text3 = doc.splitTextToSize(
    `This 'self-invoice' shall be considered as accepted by ${locationName}.`,
    pageWidth * 0.8
  );
  doc.text(text1, pageWidth / 2, doc.internal.pageSize.getHeight() - 30, { align: "center" });
  doc.text(text2, pageWidth / 2, doc.internal.pageSize.getHeight() - 25, { align: "center" });
  doc.text(text3, pageWidth / 2, doc.internal.pageSize.getHeight() - 15, { align: "center" });

  doc.addPage();

  /* *********************************** New Page ************************************ */
  printBeneficiaryDetails(20);

  doc.setFontSize(15);
  doc.text("Specification", 15, 65);

  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text("Invoice details:", 15, 75);
  doc.setFont("helvetica", "normal");
  doc.text(`Invoice Date: ${formatDateToYYYYMMDD(endDate)}`, 15, 80);
  doc.text(`Invoice Number: ${invoiceNumber}`, 15, 85);

  doc.setFontSize(15);
  doc.text("Orders", 15, 100);

  doc.setDrawColor(200);
  doc.setLineWidth(0.1);
  doc.line(14, 105, pageWidth - 15, 105);

  /* ***********************************Order Tables ************************************ */
  reportData.docInvoices?.forEach((order, index) => {
    const {
      items = [],
      discount = 0,
      subTotal = 0,
      quantity = 0,
      orderAmount = 0,
      bookingDate = "",
      customerName = "",
      purchaseDate = "",
      commissionPaid = 0,
      invoiceNumber: singleInvoice = "",
    } = order;
    const { product = "", tickets = [], bookingRef = "", status = "" } = items[0] || {};
    const {
      totalPrice = 0,
      ticketName = "",
      ticketPrice = 0,
      ticketQuantity = 0,
    } = tickets[0] || {};

    autoTable(doc, {
      startX: 15,
      startY: index === 0 ? 107 : doc.lastAutoTable.finalY + 10,
      theme: "grid",
      body: [
        [
          singleInvoice,
          customerName,
          quantity,
          purchaseDate,
          bookingDate,
          subTotal,
          discount,
          orderAmount,
          commissionPaid,
        ],
      ],
      tableWidth: pageWidth - 30,
      styles: {
        fontSize: 8,
      },
      headStyles: {
        halign: "left",
      },
      head: [
        [
          `Invoice Number Order ${index + 1}`,
          "Customer",
          "Quantity",
          "Purchase Date",
          "Booking Date",
          "Subtotal Amount",
          "Discount Amount",
          "Order Total",
          "Commission",
        ],
      ],
    });

    const columnWidths = doc.lastAutoTable.columns.map((col) => col.width);

    autoTable(doc, {
      startX: 15,
      theme: "grid",
      tableWidth: pageWidth - 30,
      startY: doc.lastAutoTable.finalY,
      styles: {
        fontSize: 8,
      },
      headStyles: {
        lineWidth: 0.1,
        lineColor: 200,
        fillColor: "white",
        textColor: "black",
      },
      columnStyles: {
        0: { cellWidth: columnWidths[0] + columnWidths[1] + columnWidths[2] },
        1: { cellWidth: columnWidths[3] },
        2: { cellWidth: columnWidths[4] + columnWidths[5] },
        3: { cellWidth: columnWidths[6] },
        4: { cellWidth: columnWidths[7] },
        5: { cellWidth: columnWidths[8] },
      },
      head: [
        [product, "Quantity", "Viator Booking Reference", "Unit Price", "Total Price", "Status"],
      ],
      body: [[ticketName, ticketQuantity, bookingRef, ticketPrice, totalPrice, status]],
    });
  });

  doc.save("Production Report.pdf");
};

export default generateCommissionInvoicePdf;
