const form = {
  formId: "new-travelDesk-form",
  formField: {
    name: {
      name: "name",
      label: "Name *",
      type: "text",
      errorMsg: "Name is required!",
      invalidMsg: "Name should have at least 1 letter",
    },
    website: {
      name: "website",
      label: "Website",
      type: "text",
      invalidMsg: "Please enter a valid contact website! (http/https url)",
    },
    address: {
      name: "address",
      label: "Company Address",
      type: "text",
    },
    contactName: {
      name: "contactName",
      label: "Contact Name",
      type: "text",
    },
    contactEmail: {
      name: "contactEmail",
      label: "Contact Email",
      type: "text",
      invalidMsg: "Your email address is invalid (email@email.com)!",
    },
    contactPhone: {
      name: "contactPhone",
      label: "Contact Phone",
      type: "number",
    },
  },
};

export default form;
