const form = {
  formId: "new-destination-form",
  formField: {
    // information page
    destinationName: {
      name: "destinationName",
      label: "Name *",
      type: "text",
      errorMsg: "Name is required!",
      invalidMsg: "Name should have at least 1 letter!",
    },
    logo: {
      name: "logo",
      label: "Logo *",
      type: "text",
      errorMsg: "Logo is required!",
    },
    vatRate: {
      name: "vatRate",
      label: "VAT",
      type: "number",
      errorMsg: "VAT percentage cannot be less than 0!",
      invalidMsg: "VAT percentage cannot be more than 100!",
    },
    type: {
      name: "destinationType",
      label: "Type *",
      type: "text",
      errorMsg: "Type is required!",
    },
    parent: {
      name: "parent",
      label: "Parent",
      type: "text",
    },

    highlight: {
      name: "highlight",
      label: "Highlight",
      type: "boolean",
    },
    top: {
      name: "top",
      label: "Top",
      type: "boolean",
    },
    // Address page
    country: {
      name: "country",
      label: "Country",
      type: "text",
    },
    region: {
      name: "region",
      label: "Region",
      type: "text",
    },
    city: {
      name: "city",
      label: "City",
      type: "text",
    },

    iataCode: {
      name: "iataCode",
      label: "IATA Code",
      type: "text",
    },
    timeZone: {
      name: "timeZone",
      label: "Time Zone *",
      type: "object",
      errorMsg: "Time Zone is required!",
    },
    countryCode: {
      name: "countryCode",
      label: "Country Code *",
      type: "text",
      errorMsg: "Country Code is required!",
    },
    defaultCurrencyCode: {
      name: "defaultCurrencyCode",
      label: "Currency Code",
      type: "text",
    },

    address: {
      name: "address",
      label: "Address *",
      type: "text",
      errorMsg: "Address is required!",
      invalidMsg: "Address should have at least 1 letter!",
    },

    lat: {
      name: "lat",
      label: "Latitude",
      type: "text",
      // errorMsg: "Lat is required!",
    },
    lng: {
      name: "lng",
      label: "Longitude",
      type: "text",
      // errorMsg: "Lng is required!",
    },
  },
};

export default form;
