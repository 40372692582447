/* eslint-disable no-underscore-dangle */
import {
  VOUCHER_ADD_SUCCESS,
  VOUCHER_FAIL,
  GET_VOUCHERS,
  CLEAR_ERRORS,
  DELETE_VOUCHER,
  GET_ONE_VOUCHER,
  UPDATE_VOUCHER,
  LOGOUT,
  UPDATE_VOUCHER_TRANSLATION,
  DELETE_SINGLE_VOUCHER,
  SET_VOUCHER_LOADING,
  GET_PARTNER_VOUCHERS,
  VOUCHER_PARTNER_ADD_SUCCESS,
} from "../actions/Types";

const initialState = {
  loading: false,
  vouchers: [],
  error: null,
  added: false,
  voucher: null,
  tabVal: 0,
};

const variable = (state = initialState, action) => {
  switch (action.type) {
    case VOUCHER_PARTNER_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        vouchers: [action.payload, ...state.vouchers],
        added: true,
        voucher: null,
      };
    case VOUCHER_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        vouchers: [action.payload.data, ...state.vouchers],
        added: true,
        voucher: null,
      };
    case GET_ONE_VOUCHER:
      return {
        ...state,
        loading: false,
        error: null,
        voucher: action.payload.data,
        added: false,
      };
    case GET_VOUCHERS:
      return {
        ...state,
        loading: false,
        error: null,
        vouchers: action.payload.data,
        added: false,
        voucher: null,
      };
    case GET_PARTNER_VOUCHERS:
      return {
        ...state,
        loading: false,
        error: null,
        vouchers: action.payload.data.data,
        added: false,
        voucher: null,
      };
    case SET_VOUCHER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_VOUCHER:
      return {
        ...state,
        loading: false,
        vouchers: state.vouchers.filter(
          (voucher) => voucher._id !== action.payload.data.voucher._id
        ),
      };
    case DELETE_SINGLE_VOUCHER:
      return {
        ...state,
        loading: false,
        item: null,
      };
    case UPDATE_VOUCHER:
      return {
        ...state,
        vouchers: state.vouchers.map((voucher) =>
          voucher._id === action.payload.data?.voucher?._id ? action.payload.data?.voucher : voucher
        ),
        loading: false,
        voucher: action.payload.data?.voucher,
        added: true,
      };
    case UPDATE_VOUCHER_TRANSLATION:
      return {
        ...state,
        voucher: action.payload.data?.voucher,
      };
    case VOUCHER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        vouchers: [],
        error: null,
        added: false,
        voucher: null,
      };

    default:
      return state;
  }
};
export default variable;
