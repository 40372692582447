/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import { Grid, Card } from "@mui/material";
import { Formik } from "formik";
import { ContentState, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
// eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default
import DraftJsRichEditorExample from "components/DraftJsRichEditorExample";
import FormField from "components/FormField";
import { capitalizeFirstLetter } from "components/helper";
import MDTypography from "components/MDTypography";
import ViewEditActions from "components/DetailList/ViewEditActions";

const DynamicTranslate = ({ items, handleSubmit, id }) => {
  const [edit, setEdit] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [markup, setMarkup] = useState(null);

  const hashtagConfig = {
    trigger: "#",
    separator: " ",
  };

  const initialValues = items.reduce((obj, item) => {
    obj[item.name] = item.value || item.default || "";
    obj._id = id; // Add the "id" property with a suffix "Id"
    return obj;
  }, {});

  // --------for location page--------
  useEffect(() => {
    if (
      !edit &&
      initialValues.disclaimerText &&
      typeof initialValues.disclaimerText === "string" &&
      initialValues.disclaimerText !== "{}"
    ) {
      setMarkup(
        draftToHtml(
          JSON.parse(initialValues?.disclaimerText),
          hashtagConfig,
          true
          // customEntityTransform
        )
      );
    } else if (
      !edit &&
      initialValues.disclaimerText &&
      typeof initialValues.disclaimerText === "object" &&
      initialValues.disclaimerText?.blocks?.length > 0
    ) {
      const contentState = ContentState.createFromText(
        initialValues?.disclaimerText?.blocks[0]?.text
      );
      // eslint-disable-next-line no-shadow
      const editorState = EditorState.createWithContent(contentState);
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      setMarkup(draftToHtml(rawContentState, hashtagConfig));
    }
  }, [initialValues.disclaimerText]);

  useEffect(() => {
    if (
      edit &&
      initialValues.disclaimerText &&
      typeof initialValues.disclaimerText === "object" &&
      initialValues.disclaimerText?.blocks?.length > 0 &&
      initialValues.disclaimerText?.blocks[0].text?.length > 0
    ) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromText(initialValues.disclaimerText?.blocks[0].text)
        )
      );
    } else if (
      edit &&
      initialValues.disclaimerText &&
      initialValues.disclaimerText?.length > 2 &&
      initialValues.disclaimerText !== "{}"
    ) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(initialValues.disclaimerText)))
      );
    }
  }, [edit, initialValues.disclaimerText]);

  // ---------for new page----------
  useEffect(() => {
    if (
      !edit &&
      initialValues.details &&
      typeof initialValues.details === "string" &&
      initialValues.details !== "{}"
    ) {
      setMarkup(
        draftToHtml(
          JSON.parse(initialValues?.details),
          hashtagConfig,
          true
          // customEntityTransform
        )
      );
    } else if (
      !edit &&
      initialValues.details &&
      typeof initialValues.details === "object" &&
      initialValues.details?.blocks?.length > 0
    ) {
      const contentState = ContentState.createFromText(initialValues?.details?.blocks[0]?.text);
      // eslint-disable-next-line no-shadow
      const editorState = EditorState.createWithContent(contentState);
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      setMarkup(draftToHtml(rawContentState, hashtagConfig));
    }
  }, [initialValues.details]);

  useEffect(() => {
    if (
      edit &&
      initialValues.details &&
      typeof initialValues.details === "object" &&
      initialValues.details?.blocks?.length > 0 &&
      initialValues.details?.blocks[0].text?.length > 0
    ) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromText(initialValues.details?.blocks[0].text)
        )
      );
    } else if (
      edit &&
      initialValues.details &&
      initialValues.details?.length > 2 &&
      initialValues.details !== "{}"
    ) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(initialValues.details)))
      );
    }
  }, [edit, initialValues.details]);

  return (
    <Card sx={{ boxShadow: "none", width: "100%" }}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            handleSubmit(values);
          }, 500);
        }}
      >
        {({ setFieldValue, values, resetForm, submitForm }) => (
          <Grid item mr={2} mt={1}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ViewEditActions
                detailPage={!edit}
                handleEdit={() => {
                  setEdit(true);
                }}
                handleClose={() => {
                  resetForm();
                  setEdit(false);
                }}
                handleSave={() => {
                  submitForm();
                  setEdit(false);
                }}
              />
            </div>

            <Grid item xs={10} sm={10} md={10} ml={2}>
              {items?.map((item) => (
                <div key={item?.id} style={{ marginBottom: edit ? 10 : 20 }}>
                  {" "}
                  {!edit && (
                    <MDTypography style={{ fontSize: "15px", fontWeight: "bold" }}>
                      {item.title}:{" "}
                    </MDTypography>
                  )}
                  {edit ? (
                    item.name === "disclaimerText" || item.name === "details" ? (
                      <DraftJsRichEditorExample
                        editorState={editorState}
                        onChange={(event) => {
                          setEditorState(event);
                          setFieldValue(
                            item.name === "disclaimerText" ? "disclaimerText" : "details",
                            convertToRaw(event.getCurrentContent())
                          );
                        }}
                        placeholder="Editor content is empty..."
                      />
                    ) : (
                      <FormField
                        type={item.type}
                        label={item.label}
                        name={item.name}
                        // value={itemV}
                        placeholder={item.placeholder}
                        inputProps={{
                          value: values[item?.name]
                            ? item?.name === "callToAction"
                              ? capitalizeFirstLetter(
                                  values?.callToAction?.label || values?.callToAction
                                )
                              : capitalizeFirstLetter(values[item?.name])
                            : "",
                          onChange: (event) =>
                            setFieldValue(
                              item?.name === "callToAction" ? "callToAction.label" : item?.name,
                              event.target.value
                            ),
                        }}
                      />

                      //   <CustomInput
                      //     name={item.name}
                      //     type="text"
                      //     labelText={item.title}
                      //     id={item.name}
                      //     formControlProps={{
                      //       fullWidth: true,
                      //     }}
                      //     inputProps={{
                      //       value: values[item?.name]
                      //         ? item?.name === "callToAction"
                      //           ? capitalizeFirstLetter(
                      //               values?.callToAction?.label || values?.callToAction
                      //             )
                      //           : capitalizeFirstLetter(values[item?.name])
                      //         : "",
                      //       onChange: (event) =>
                      //         setFieldValue(
                      //           item?.name === "callToAction" ? "callToAction.label" : item?.name,
                      //           event.target.value
                      //         ),
                      //     }}
                      //     style={{ margin: 0 }}
                      //   />
                    )
                  ) : (item.name === "disclaimerText" || item.name === "details") && markup ? (
                    // <DisclaimerTextRenderer disclaimerText={item?.value} />
                    // eslint-disable-next-line react/no-danger
                    <div dangerouslySetInnerHTML={{ __html: markup }} />
                  ) : (
                    item.name !== "disclaimerText" &&
                    item.name !== "details" && (
                      <MDTypography
                        style={{
                          wordBreak: "break-word",
                          maxWidth: "50ch",
                          fontSize: "15px",
                        }}
                      >
                        {item.value}
                      </MDTypography>
                    )
                  )}
                </div>
              ))}
            </Grid>
          </Grid>
        )}
      </Formik>
    </Card>
  );
};

export default DynamicTranslate;
