/* eslint-disable no-underscore-dangle */

import PropTypes from "prop-types";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
// import KeywordsPicker from "components/AutoComplete/KeywordsPicker";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AutocompleteTags from "components/AutoCompleteTags";
import { setDistanceFilter } from "store/actions/filterActions";
import { Slider } from "@mui/material";
import SavedButton from "components/SavedButton";

const ByKeyword = ({ locationId, filters }) => {
  const dispatch = useDispatch();
  const tags = {
    label: "Keywords",
    name: "keywords",
  };

  const [keyWords, setKeywords] = useState([]);
  const [distance, setDistance] = useState(20);

  useEffect(() => {
    if (filters.distance) setDistance(filters.distance);
    else setDistance(20);
    if (filters.keywords) setKeywords(filters.keywords);
    else setKeywords([]);
  }, [filters]);

  const setTags = (type, val) => {
    val.map((e) => e.toLowerCase());
    setKeywords(val);
  };

  const handleChange = (event, newValue) => {
    setDistance(newValue);
  };

  const handleSave = () => {
    dispatch(setDistanceFilter(locationId, distance, keyWords));
  };

  return (
    <Card id="geolocation-keywords" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Filter by GeoLocation & Keywords</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDTypography variant="body2">
              Hide attractions that includes the following words
            </MDTypography>
            <AutocompleteTags setFieldValue={setTags} tags={tags} tagsV={keyWords} />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12}>
            <MDTypography variant="body2">
              Max distance for recommendations you want to show
            </MDTypography>
            <MDTypography variant="caption">
              Show attractions only within a radius of: {distance} Kms
            </MDTypography>
            <Slider
              getAriaLabel={() => "Price range"}
              value={distance}
              onChange={handleChange}
              valueLabelDisplay="auto"
              // getAriaValueText={valuetext}
              style={{
                marginTop: "10px",
              }}
              min={1}
              max={100}
              sx={{
                color: "#003005", // This will set the color for the active and default states
                "& .MuiSlider-track": {
                  backgroundColor: "#003005",
                  border: "none",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} justifyContent="end" display="flex" mt={2}>
            <SavedButton handleSave={handleSave} />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

// Setting default props
ByKeyword.defaultProps = {
  locationId: "",
  filters: {},
};

// typechecking props for ByKeyword
ByKeyword.propTypes = {
  locationId: PropTypes.string,
  filters: PropTypes.instanceOf(Object),
};
export default ByKeyword;
