const jobOptions = [
  {
    label: "Maintenance",
    options: [
      { label: "Guest – Engineering", value: "guest-engineering-1", id: 1 },
      { label: "Guest – Information Technology", value: "guest-it-2", id: 2 },
      { label: "Internal – Engineering", value: "internal-engineering-3", id: 3 },
      { label: "Internal – Information Technology", value: "internal-it-4", id: 4 },
      { label: "Complaint – Engineering", value: "complaint-engineering-5", id: 5 },
      { label: "Complaint – Information Technology", value: "complaint-it-6", id: 6 },
    ],
  },
  {
    label: "Luggage",
    options: [
      { label: "Guest - Front Office", value: "guest-front-office-7", id: 7 },
      { label: "Internal - Front Office", value: "internal-front-office-8", id: 8 },
      { label: "Internal - Concierge1", value: "internal-concierge1-9", id: 9 },
    ],
  },
  {
    label: "Laundry",
    options: [
      { label: "Guest – Laundry", value: "guest-laundry-10", id: 10 },
      { label: "Internal – Laundry", value: "internal-laundry-11", id: 11 },
      { label: "Complaint - Laundry", value: "complaint-laundry-12", id: 12 },
    ],
  },
  {
    label: "Housekeeping",
    options: [
      { label: "Guest - Minibar", value: "guest-minibar-13", id: 13 },
      { label: "Internal – Housekeeping", value: "internal-housekeeping-14", id: 14 },
      { label: "Internal – Minibar", value: "internal-minibar-15", id: 15 },
      { label: "Guest - Room Service", value: "guest-room-service-16", id: 16 },
      { label: "Internal - Room Service", value: "internal-room-service-17", id: 17 },
      { label: "Complaint – Room Service", value: "complaint-room-service-18", id: 18 },
      { label: "Long form – Complaint", value: "long-form-complaint-19", id: 19 },
    ],
  },
];

export default jobOptions;
