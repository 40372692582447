import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  background-color: ${() => "#00acc1"};
  color: ${() => "white"};
  border: 1px solid ${() => "#00acc1"};
  &:hover {
    background-color: ${() => "#00acc1"};
  }
`;

const ConfirmDialog = ({ title, subtitle, children, onOpen, onClose, onConfirm }) => (
  <Dialog
    open={onOpen}
    onClose={onClose}
    aria-labelledby="confirm-dialog"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="confirm-dialog">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{subtitle}</DialogContentText>
      {children}
    </DialogContent>
    <StyledDialogActions>
      <StyledButton variant="contained" onClick={onClose} isNegative>
        No
      </StyledButton>
      <StyledButton
        variant="contained"
        onClick={() => {
          onClose();
          onConfirm();
        }}
      >
        Yes
      </StyledButton>
    </StyledDialogActions>
  </Dialog>
);
// Setting default props
ConfirmDialog.defaultProps = {
  title: "",
  subtitle: "",
};

ConfirmDialog.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmDialog;
