const convertToAMPM = (time24) => {
  if (!time24) {
    return "";
  }

  const timeSplit = time24.split(":");
  let hours = parseInt(timeSplit[0], 10);
  const minutes = timeSplit[1];
  const period = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12;
  return `${hours}:${minutes} ${period}`;
};

export default convertToAMPM;
