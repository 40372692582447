/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import DetailList from "components/DetailList";
import ShowHideComponentsPicker from "components/ShowHideComponentsPicker";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import FormField from "components/FormField";
import ActivityPicker from "components/AutoComplete/ActivityPicker";
import TimePicker from "components/AutoComplete/TimePicker";
import BookingConfirmationPicker from "components/AutoComplete/BookingConfirmationPicker";

const BookingAndTickets = ({
  formData,
  editPage,
  detailPage,
  handleActivityChange,
  selectedActivity,
  handleTimeActChange,
  selectedTimeAct,
  handleBookingConfirmationChange,
  selectedBookingConfirmation,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    cancellationPolicy,
    cancelIfBadWeather,
    // dayRangeMax,
    // percentageRefundable,
    ticketType,
    ticketsPerBooking,
    bookingCutoffType,
    hoursAct,
  } = formField;
  const {
    cancellationPolicy: cancellationPolicyV,
    cancelIfBadWeather: cancelIfBadWeatherV,
    // percentageRefundable: percentageRefundableV,
    // dayRangeMax: dayRangeMaxV,
    ticketType: ticketTypeV,
    ticketsPerBooking: ticketsPerBookingV,
    bookingCutoffType: bookingCutoffTypeV,
    hoursAct: hoursActV,
  } = values;

  const policyOptions = [
    { value: true, label: "Bad Weather" },
    { value: false, label: "Not enough travellers" },
  ];

  const cutOffOptions = [
    {
      value: "FIXED_TIME",
      label: "Set time",
    },
    {
      value: "START_TIME",
      label: "Relative to start time",
    },
  ];

  const cancellationPolicyOptions = [
    {
      value: "STANDARD",
      label: "Standard( Travellers may cancel up to 24 hours before the experience)",
    },
    {
      value: "ALL_SALES_FINAL",
      label:
        "All Sales final(Travellers will not receive any refund regardless of the cancellation status)",
    },
    // {
    //   value: "CUSTOM",
    //   label: "Custom",
    // },
  ];

  const ticketTypeOptions = [
    {
      value: "MOBILE_ONLY",
      label: "Mobile or paper ticket accepted",
    },
    {
      value: "PAPER",
      label: "Only paper ticket accepted",
    },
  ];

  const ticketsPerBookingOptions = [
    {
      value: "ONE_PER_BOOKING",
      label: "One per booking",
    },
    {
      value: "ONE_PER_TRAVELER",
      label: "One per traveller",
    },
  ];

  return (
    <>
      <ShowHideComponentsPicker title="Booking Process">
        {" "}
        {detailPage && editPage ? (
          <DetailList
            data={{
              bookingCutoffType:
                values?.bookingConfirmationSettings?.bookingCutoffType === "FIXED_TIME"
                  ? "Fixed time"
                  : "Relative to start time",
              bookingCutoffFixedTime: values?.bookingConfirmationSettings?.bookingCutoffFixedTime,
              hours:
                values?.bookingConfirmationSettings?.bookingCutoffType === "START_TIME" &&
                values?.bookingConfirmationSettings?.hour,
              cutoffDay:
                values?.bookingConfirmationSettings?.bookingCutoffType === "FIXED_TIME" &&
                values?.bookingConfirmationSettings?.days === 0
                  ? "On day of Activity"
                  : values?.bookingConfirmationSettings?.days === 1 &&
                    values?.bookingConfirmationSettings?.bookingCutoffType === "FIXED_TIME"
                  ? "1 day before Activity"
                  : values?.bookingConfirmationSettings?.bookingCutoffType === "FIXED_TIME" &&
                    "2 day of Activity",
              confirmationType:
                values?.bookingConfirmationSettings?.confirmationType === "INSTANT"
                  ? "Instant confirmation"
                  : values?.bookingConfirmationSettings?.confirmationType === "INSTANT_THEN_MANUAL"
                  ? "Instant confirmation then becomes manual"
                  : "Manual confirmation",
            }}
          />
        ) : (
          <MDBox mt={1.625}>
            <Grid container>
              <Grid item xs={12}>
                <DynamicRadioGroup
                  value={
                    bookingCutoffTypeV || values?.bookingConfirmationSettings?.bookingCutoffType
                  }
                  options={cutOffOptions}
                  name={bookingCutoffType.name}
                  setFieldValue={setFieldValue}
                  handleChange={() => setFieldValue("bookingConfirmationSettings", {})}
                  groupLabel="Cut -off time by:"
                  disabled={false}
                />
              </Grid>
              {bookingCutoffTypeV === "FIXED_TIME" ||
              (values?.bookingConfirmationSettings?.bookingCutoffType === "FIXED_TIME" &&
                bookingCutoffTypeV !== "START_TIME") ? (
                <Grid item xs={12} container spacing={3}>
                  <Grid item xs={4}>
                    <TimePicker
                      setFieldValue={setFieldValue}
                      time={
                        values?.timeCutoff?.label
                          ? values?.timeCutoff
                          : values?.bookingConfirmationSettings?.bookingCutoffFixedTime ||
                            selectedTimeAct
                      }
                      handleTimeChange={handleTimeActChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ActivityPicker
                      setFieldValue={setFieldValue}
                      // act={
                      //   values?.daysCutoff?.value
                      //     ? values?.daysCutoff
                      //     : values?.bookingConfirmationSettings?.days || selectedActivity
                      // }
                      act={selectedActivity}
                      handleActivityChange={handleActivityChange}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <FormField
                    type={hoursAct.type}
                    label={hoursAct.label}
                    name={hoursAct.name}
                    value={
                      hoursActV ||
                      (values?.bookingConfirmationSettings?.bookingCutoffInMinutes || 0) / 60
                    }
                    placeholder={hoursAct.placeholder}
                    error={errors.hoursAct && touched.hoursAct}
                    success={hoursActV?.length > 0 && !errors?.hoursAct}
                  />
                </Grid>
              )}
              <Grid item xs={4} mt={2} mb={2}>
                <BookingConfirmationPicker
                  setFieldValue={setFieldValue}
                  bookingConfirmation={
                    values?.bookingConfirmationSettings?.confirmationType ||
                    selectedBookingConfirmation
                  }
                  handleBookingConfirmationChange={handleBookingConfirmationChange}
                />
              </Grid>
            </Grid>
          </MDBox>
        )}
      </ShowHideComponentsPicker>
      <ShowHideComponentsPicker title="Cancellation Policy">
        {" "}
        {detailPage && editPage ? (
          <DetailList
            data={{
              cancellationPolicy: values?.cancellationPolicy?.description,
              cancelIfBadWeather: values?.cancellationPolicy?.cancelIfBadWeather,
            }}
          />
        ) : (
          <MDBox mt={1.625}>
            <Grid item xs={12}>
              <DynamicRadioGroup
                isColumn
                value={
                  cancellationPolicyV?.type
                    ? cancellationPolicyV?.type
                    : cancellationPolicyV || values?.cancellationPolicy?.type
                }
                options={cancellationPolicyOptions}
                name={cancellationPolicy.name}
                setFieldValue={setFieldValue}
                groupLabel="Select cancellation policy:*"
                disabled={false}
              />
            </Grid>
            {/* {cancellationPolicyV === "CUSTOM" && (
              <Grid container xs={12} spacing={3}>
                <Grid item xs={4}>
                  <FormField
                    type={percentageRefundable.type}
                    label={percentageRefundable.label}
                    name={percentageRefundable.name}
                    value={percentageRefundableV}
                    placeholder={percentageRefundable.placeholder}
                    error={errors.percentageRefundable && touched.percentageRefundable}
                    success={percentageRefundableV?.length > 0 && !errors.percentageRefundable}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormField
                    type={dayRangeMax.type}
                    label={dayRangeMax.label}
                    name={dayRangeMax.name}
                    value={dayRangeMaxV}
                    placeholder={dayRangeMax.placeholder}
                    error={errors.dayRangeMax && touched.dayRangeMax}
                    success={dayRangeMaxV?.length > 0 && !errors.dayRangeMax}
                  />
                </Grid>
              </Grid>
            )} */}
            <Grid item xs={12}>
              <DynamicRadioGroup
                isColumn
                value={
                  cancelIfBadWeatherV === undefined
                    ? values?.cancellationPolicy?.cancelIfBadWeather
                    : cancelIfBadWeatherV || values?.cancellationPolicy?.cancelIfBadWeather
                }
                options={policyOptions}
                name={cancelIfBadWeather.name}
                setFieldValue={setFieldValue}
                groupLabel={`Add to your policy:\n(You may reserve the right to cancel a customer's booking for a full refund in case of:)`}
                disabled={false}
              />
            </Grid>
          </MDBox>
        )}
      </ShowHideComponentsPicker>
      <ShowHideComponentsPicker title="Ticket Builder">
        {" "}
        {detailPage && editPage ? (
          <DetailList
            data={{
              ticketType:
                values?.ticketInfo?.ticketTypes?.length > 0 &&
                values?.ticketInfo?.ticketTypes[0] === "MOBILE_ONLY"
                  ? "Mobile or paper ticket accepted"
                  : "Only paper ticket accepted",
              ticketPerBooking:
                values?.ticketInfo?.ticketsPerBooking === "ONE_PER_BOOKING"
                  ? "One per booking"
                  : "One per traveller",
            }}
          />
        ) : (
          <MDBox mt={1.625}>
            <Grid item xs={12}>
              <DynamicRadioGroup
                key={ticketTypeV}
                isColumn
                value={
                  ticketTypeV ||
                  (values?.ticketInfo?.ticketTypes?.length > 0 &&
                    values?.ticketInfo?.ticketTypes[0])
                }
                options={ticketTypeOptions}
                name={ticketType.name}
                setFieldValue={setFieldValue}
                groupLabel="Select Ticket type:*"
                disabled={false}
              />
            </Grid>
            <Grid item xs={12}>
              <DynamicRadioGroup
                isColumn
                value={ticketsPerBookingV || values?.ticketInfo?.ticketsPerBooking}
                options={ticketsPerBookingOptions}
                name={ticketsPerBooking.name}
                setFieldValue={setFieldValue}
                groupLabel="How many tickets created per booking:"
                disabled={false}
              />
            </Grid>
          </MDBox>
        )}
      </ShowHideComponentsPicker>
    </>
  );
};

BookingAndTickets.defaultProps = {
  editPage: false,
  detailPage: true,
  handleActivityChange: () => {},
  handleTimeActChange: () => {},
  selectedActivity: {},
  selectedTimeAct: {},
  handleBookingConfirmationChange: () => {},
  selectedBookingConfirmation: {},
};

BookingAndTickets.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  handleActivityChange: PropTypes.func,
  handleTimeActChange: PropTypes.func,
  selectedActivity: PropTypes.instanceOf(Object),
  selectedTimeAct: PropTypes.instanceOf(Object),
  handleBookingConfirmationChange: PropTypes.func,
  selectedBookingConfirmation: PropTypes.instanceOf(Object),
};

export default BookingAndTickets;
