/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import DetailList from "components/DetailList";
import UploadImage from "pages/content/locations/new-location/components/Sliders/UploadImage";
import Danger from "components/Danger";
import PositionPicker from "components/AutoComplete/PositionPicker";

const Information = ({
  formData,
  editPage,
  detailPage,
  handlePositionChange,
  selectedPosition,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { title, image } = formField;
  const { title: titleV, image: imageV } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Information</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          bannerDetailsPage
          data={{
            bannerText: values?.title,
            position: values?.position?.label,
            image: values?.image,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                // type={title?.type}
                label={title.label}
                name={title.name}
                value={titleV}
                placeholder={title.placeholder}
                error={errors?.title && touched?.title}
                success={titleV?.length > 0 && !errors?.title}
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="h6" color="text" fontWeight="regular">
                Position *
              </MDTypography>
              <MDBox
                style={{
                  marginTop: "5px",
                  backgroundColor: "white",
                }}
              >
                <PositionPicker
                  setFieldValue={setFieldValue}
                  position={selectedPosition}
                  handlePositionChange={handlePositionChange}
                />
              </MDBox>
              {errors?.position && touched?.position && <Danger validation={errors?.position} />}
            </Grid>
            <Grid item sm={12}>
              <MDTypography variant="button" fontWeight="regular" color="text">
                {image?.label}
              </MDTypography>
              <UploadImage
                key={values?.image}
                defaultImage={imageV?.[0]}
                setFieldValue={setFieldValue}
                fieldName="image"
              />
              {errors?.image && touched?.image && <Danger validation={errors?.image} />}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};
Information.defaultProps = {
  editPage: false,
  detailPage: true,
  handlePositionChange: () => {},
  selectedPosition: {},
};
// typechecking props for Information
Information.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  handlePositionChange: PropTypes.func,
  selectedPosition: PropTypes.instanceOf(Object),
};

export default Information;
