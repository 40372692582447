/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useState, createRef, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { Input } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import { ErrorMessage, useFormikContext } from "formik";
import MDTypography from "components/MDTypography";
import Danger from "components/Danger";

const ImagePicker = ({ currentImg, edit, imageType, squareImg, textAdded }) => {
  const [image, _setImage] = useState(currentImg);
  const [errorImgType, setErrorImgType] = useState(false);
  const [errorImg, setErrorImg] = useState(false);

  const inputFileRef = createRef(null);
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    if (currentImg) {
      _setImage(currentImg);
    } else if (typeof values?.[imageType] === "string") {
      _setImage(values?.[imageType]);
    } else if (values?.[imageType] instanceof File) {
      _setImage(URL.createObjectURL(values?.[imageType]));
    }
  }, [currentImg]);

  const cleanup = () => {
    URL.revokeObjectURL(image);
    inputFileRef.current.value = null;
    if (imageType === "photo") {
      setFieldValue("photo", null);
    } else if (imageType === "logo") {
      setFieldValue("logo", null);
    }
    // else if (imageType === "images") {
    //   setFieldValue("images", null);
    // }
    else {
      setFieldValue("thumbnailHiResURL", null);
    }
  };

  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
  };

  const handleOnChange = (event) => {
    const newImage = event.target?.files[0];

    if (newImage) {
      const fileSize = newImage.size;

      // Check if the file is an image
      if (!newImage.type.startsWith("image/")) {
        setErrorImgType(true);
        inputFileRef.current.value = "";
        return;
      }

      // Check file size
      if (fileSize > 921600) {
        setErrorImg(true);
        setErrorImgType(false);
      } else {
        setImage(URL.createObjectURL(newImage));
        setFieldValue(imageType, newImage);
        setErrorImg(false);
        setErrorImgType(false);
      }
    }
  };

  const BigAvatar = styled(Avatar)`
    width: 74px;
    height: 74px;
  `;

  return (
    <label htmlFor={imageType} style={{ display: "block", width: "fit-content" }}>
      {edit && (
        <Input
          accept="image/*"
          id={imageType} // Use imageType as a unique id
          name={imageType} // Also use imageType for the name
          type="file"
          ref={inputFileRef}
          onChange={handleOnChange}
          sx={{ display: "none" }}
        />
      )}
      <Stack direction="row">
        <div>
          {image ? (
            <MDAvatar
              src={image}
              alt="profile-image"
              size="xl"
              variant={squareImg ? "square" : null}
              style={{
                height: squareImg ? "12em" : null,
                width: squareImg ? "12em" : null,
              }}
              bgColor="light"
              sx={{ "& img": { height: "100%", background: "rgba(0, 0, 0, 0.1)" } }}
            />
          ) : (
            <BigAvatar
              alt="Change your profile photo"
              variant={squareImg ? "square" : null}
              style={{
                height: squareImg ? "12em" : null,
                width: squareImg ? "12em" : null,
              }}
              src={image}
              imgProps={{
                style: {
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "cover",
                },
              }}
            />
          )}
        </div>

        {edit && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton color="secondary" aria-label="upload picture" component="span" size="large">
              <FileUploadIcon />
              <MDTypography fontSize="small" marginLeft="5px" fontWeight="regular">
                {textAdded}
              </MDTypography>
            </IconButton>
          </div>
        )}
      </Stack>
      {errorImg && <Danger validation={`File size for ${imageType || "logo"} exceeds 900 KB!`} />}
      {errorImgType && <Danger validation="Please select an image file!" />}

      <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
        <ErrorMessage
          name={
            imageType === "photo"
              ? "photo"
              : imageType === "cover_image_url"
              ? "cover_image_url"
              : imageType === "icon" || imageType === "images"
              ? "images"
              : imageType === "thumbnailHiResURL"
              ? "thumbnailHiResURL"
              : "logo"
          }
        />
      </MDTypography>
    </label>
  );
};

// Setting default props
ImagePicker.propTypes = {
  currentImg: PropTypes.string,
  imageType: PropTypes.string,
  squareImg: PropTypes.bool,
  edit: PropTypes.bool,
  textAdded: PropTypes.string,
};

export default ImagePicker;
