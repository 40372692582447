// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";

const SidenavList = ({ children }) => (
  <List
    sx={{
      px: 2,
      my: 0.3,
    }}
  >
    {children}
  </List>
);

// Setting default props for the DynamicTabs
SidenavList.defaultProps = {
  children: null,
};

// Typechecking props for the SidenavItem
SidenavList.propTypes = {
  children: PropTypes.node,
};

export default SidenavList;
