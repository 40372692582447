/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Section, Html, Text } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import DiscoverAlternativePlacesSection from "../../DynamicDiscoverAlternativePlaces";
import FooterSection from "../../FooterSection";
import IfYouNeedSupportSection from "../../IfYouNeedSupport";
import LogoSection from "../../LogoSection/index";
import HeadSection from "../../HeadSection";
import { defaultProps, propTypes } from "./Proptypes";

const RejectedBookingEmail = ({ alternativeItems, data }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100 font-sans leading-relaxed">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-start bg-white p-5 px-8 text-black">
            <Text className="text-[35px] leading-[26.5px] font-bold mb-2">Oops!</Text>
            <Text className="text-[24px] leading-[34px] font-normal mb-2">
              Booking of {data.ref.title} was unsuccessful due to the following reason:
            </Text>
            <Text className="text-[24px] leading-[34px] font-normal mb-0">
              This bookable item is no longer available.
            </Text>
          </Section>
          <DiscoverAlternativePlacesSection alternativeItems={alternativeItems} />
          <hr className="border-t-2  border-[#394A43] mt-0" />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);
RejectedBookingEmail.defaultProps = defaultProps;

RejectedBookingEmail.propTypes = propTypes;
export default RejectedBookingEmail;
