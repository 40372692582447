// import { subDays } from "date-fns";
import checkout from "./form";

const {
  formField: { partner, country, city, location },
} = checkout;

const initialValues = {
  [partner.name]: "",
  [country.name]: "",
  [city.name]: {},
  [location.name]: {},
};

export default initialValues;
