/* eslint-disable no-plusplus */
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef } from "react";
import Geosuggest from "react-geosuggest";
import "./addressPickerStyle.css";
import RoomIcon from "@mui/icons-material/Room";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
// import { useSelector } from "react-redux";

const AddressPicker = ({ setFieldValue, disabled, currentLocation }) => {
  const geosuggestEl = useRef(null);
  // const user = useSelector((state) => state.users.user);

  useEffect(() => {
    if (disabled) {
      geosuggestEl.current.clear();
    }
  }, [disabled]);

  const { google } = window;

  const getCity = (results) => {
    let city;
    let region;
    let country;
    if (results?.length > 0) {
      for (let i = 0; i < results?.length; i++) {
        if (results[i].types[0] === "locality" || results[i].types[0] === "postal_town") {
          // this is the object you are looking for City
          city = results[i];
        }
        if (
          results[i].types[0] === "administrative_area_level_1" ||
          results[i].types[0] === "administrative_area_level_2"
        ) {
          // this is the object you are looking for State
          region = results[i];
        }
        if (results[i].types[0] === "country") {
          // this is the object you are looking for country
          country = results[i];
        }
      }
      // const setFieldValue = props;
      setFieldValue("city", city ? city?.long_name : "");
      setFieldValue("region", region ? region?.long_name : "");
      setFieldValue("country", country ? country?.long_name : "");
      setFieldValue("country_code", country ? country?.short_name : "");
      setFieldValue("countryCode", country ? country?.short_name : "");
    } else {
      // eslint-disable-next-line no-console
      console.log("No results found");
    }
    // }
  };

  /**
   * When a suggest got selected
   */
  const onSuggestSelect = (suggest) => {
    // to avoid undefined on backspace
    if (suggest) {
      setFieldValue("lat", suggest?.location?.lat);
      setFieldValue("lng", suggest?.location?.lng);
      setFieldValue("address", suggest?.description ? suggest?.description : "");
      setFieldValue("placeId", suggest?.placeId);
      getCity(suggest?.gmaps?.address_components);
    }
  };

  return (
    <Stack direction="row" alignItems="flex-end">
      <Box sx={{ width: "100%" }}>
        <Geosuggest
          ref={geosuggestEl}
          placeholder="Search for an address!"
          // label="Search"
          id="picker"
          initialValue=""
          // fixtures={fixtures}
          onSuggestSelect={onSuggestSelect}
          location={new google.maps.LatLng(`${currentLocation?.lat}`, `${currentLocation?.lng}`)}
          radius="20"
          disabled={disabled}
        />
      </Box>
      <Box sx={{ margin: "0px 10px" }}>
        <RoomIcon color="success" />
      </Box>
      {/* Buttons to trigger exposed component functions 
      <button onClick={() => geosuggestEl.current.focus()}>Focus</button>
      <button onClick={() => geosuggestEl.current.update('New Zealand')}>
        Update
      </button>
      <button onClick={() => geosuggestEl.current.clear()}>Clear</button>
      <button onClick={() => geosuggestEl.current.selectSuggest()}>
        Search
      </button> */}
    </Stack>
  );
};
// Setting default props
AddressPicker.defaultProps = {
  lat: "",
  lng: "",
  disabled: false,
  currentLocation: {},
};

AddressPicker.propTypes = {
  lat: PropTypes.string,
  lng: PropTypes.string,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
  currentLocation: PropTypes.instanceOf(Object),
};
export default AddressPicker;
