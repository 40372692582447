import React from "react";
import PropTypes from "prop-types";
import { List, ListItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const ItineraryItemsList = ({ data }) => {
  const listItemStyle = {
    display: "flex",
  };

  const columnStyle = {
    flex: "0.5", // Equal distribution of width
    padding: "3px", // Adjust the padding as needed for space between columns
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 165,
  };

  return (
    <List>
      <ListItem style={listItemStyle}>
        <div style={{ ...columnStyle }}>
          <strong>Stops</strong>
        </div>
        <div style={{ ...columnStyle }}>
          <strong>Venue</strong>
        </div>
        <div style={{ ...columnStyle, width: 150 }}>
          <strong>Duration</strong>
        </div>
        <div style={{ ...columnStyle }}>
          <strong>Description</strong>
        </div>
      </ListItem>
      {data?.itineraryItems?.map((item) => (
        <ListItem key={item?.pointOfInterestLocation?.attractionId?.title} style={listItemStyle}>
          <div style={{ ...columnStyle }}>
            <Tooltip title={item?.pointOfInterestLocation?.attractionId?.title}>
              <span>{item?.pointOfInterestLocation?.attractionId?.title}</span>
            </Tooltip>
          </div>
          <div style={{ ...columnStyle }}>
            <span>{item?.pointOfInterestLocation?.pin?.id?.name}</span>
          </div>
          <div style={{ ...columnStyle, width: 150 }}>
            {item?.duration?.day ? (
              <span>
                {item?.duration?.day}d, {item?.duration?.hour}h, {item?.duration?.minute}min
              </span>
            ) : (
              <span>
                {item?.duration?.variableDurationFromMinutes} -{" "}
                {item?.duration?.variableDurationToMinutes} min
              </span>
            )}
          </div>
          <div style={{ ...columnStyle }}>
            <span>{item?.description}</span>
          </div>
        </ListItem>
      ))}
    </List>
  );
};

ItineraryItemsList.defaultProps = {
  data: [],
};

ItineraryItemsList.propTypes = {
  data: PropTypes.shape({
    itineraryItems: PropTypes.arrayOf(
      PropTypes.shape({
        pointOfInterestLocation: PropTypes.shape({
          attractionId: PropTypes.shape({
            title: PropTypes.string,
          }),
          pin: PropTypes.shape({
            id: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        }),
        duration: PropTypes.shape({
          fixedDurationInMinutes: PropTypes.number,
        }),
        description: PropTypes.string,
      })
    ),
  }),
};

export default ItineraryItemsList;
