// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import { capitalizeFirstLetter } from "components/helper";
import PropTypes from "prop-types";

const TypePicker = ({ type, typeV, setFieldValue, ...rest }) => (
  <Autocomplete
    id="roles"
    // defaultValue={typeV}
    options={["Recommendation", "Item"]}
    onChange={(e, value) => {
      if (value) {
        setFieldValue("type", value.toLowerCase());
      } else {
        setFieldValue("type", "recommendation");
      }
    }}
    defaultValue="Recommendation"
    value={typeV ? capitalizeFirstLetter(typeV) : "Recommendation"}
    // value={capitalizeFirstLetter(typeV)}
    getOptionLabel={(option) => option || "Recommendation"}
    isOptionEqualToValue={(option, value) => option.toLowerCase() === value.toLowerCase()}
    renderInput={(params) => (
      <FormField
        {...params}
        {...rest}
        // InputLabelProps={{ shrink: true }}
        label={type?.label}
        name={type?.name}
        // onChange={() => null}
        onChange={params.onChange}
      />
    )}
  />
);

// typechecking props for TypePicker
TypePicker.propTypes = {
  type: PropTypes.instanceOf(Object).isRequired,
  typeV: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default TypePicker;
