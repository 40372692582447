// import React from "react";
// import { Row, Section } from "@react-email/components";
// import { Tailwind } from "@react-email/tailwind";
// // import AppLinks from "./AppLinks/index";
// import SocialLinks from "./SocialLinks/index";
// import FooterAddress from "./Address/index";

// const FooterSection = () => (
//   <Tailwind>
//     <Section className="w-full text-center text-xs text-[#888]">
//       <Row className="w-full mb-4 justify-center">
//         {/* <AppLinks /> */}
//         <SocialLinks />
//       </Row>
//       <FooterAddress />
//     </Section>
//   </Tailwind>
// );

// export default FooterSection;

import React from "react";
import PropTypes from "prop-types";
import SocialLinks from "./SocialLinks/index";
import FooterAddress from "./Address/index";

const FooterSection = ({ hasSocialLinks }) => (
  <table
    width="100%"
    style={{
      textAlign: "center",
      fontSize: "12px",
      color: "#888888",
    }}
    cellPadding="0"
    cellSpacing="0"
    role="presentation"
  >
    <tbody>
      {hasSocialLinks && (
        <tr>
          <td style={{ paddingBottom: "16px", textAlign: "center" }}>
            {/* Include SocialLinks component */}
            <SocialLinks />
          </td>
        </tr>
      )}
      <tr>
        <td style={{ textAlign: "center" }}>
          {/* Include FooterAddress component */}
          <FooterAddress />
        </td>
      </tr>
    </tbody>
  </table>
);
FooterSection.defaultProps = {
  hasSocialLinks: true,
};

FooterSection.propTypes = {
  hasSocialLinks: PropTypes.bool,
};
export default FooterSection;
