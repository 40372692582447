// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";
import { styled } from "@mui/material/styles";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";
import { FormControl } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ErrorMessage } from "formik";

const MDInputF = styled(MDInput)(({ disabled }) => ({
  backgroundColor: "transparent!important",
  opacity: disabled ? 0.6 : 1,
  "& label": {
    fontWeight: 400,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#00850D", // Change the active (focused) border color here
  },
}));

const MDDatePicker = ({ input, error, success, ...rest }) => (
  <FormControl style={{ width: "100%" }}>
    <Flatpickr
      {...rest}
      render={({ defaultValue }, ref) => (
        <MDInputF {...input} defaultValue={defaultValue} inputRef={ref} />
      )}
    />
    <MDBox mt={0.75}>
      <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
        <ErrorMessage name={input?.name} />
      </MDTypography>
    </MDBox>
  </FormControl>
);

// Setting default values for the props of MDDatePicker
MDDatePicker.defaultProps = {
  input: {},
  error: false,
  success: false,
};

// Typechecking props for the MDDatePicker
MDDatePicker.propTypes = {
  input: PropTypes.instanceOf(Object),
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default MDDatePicker;
