import checkout from "./form";

const {
  formField: {
    // information page
    logo,
    destinationName,
    type,
    parent,
    highlight,
    top,
    vatRate,
    // address page
    address,
    city,
    region,
    country,
    lat,
    lng,
    defaultCurrencyCode,
    countryCode,
    timeZone,
    iataCode,
  },
} = checkout;

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

const initialValues = {
  // information page
  [logo?.name]: "",
  [destinationName?.name]: "",
  [type?.name]: "REGION",
  [parent?.name]: "",
  [highlight.name]: false,
  [top.name]: false,
  [vatRate.name]: "",

  // user address
  [address.name]: "",
  [city.name]: "",
  [region.name]: "",
  [country.name]: "",
  [lat.name]: "",
  [lng.name]: "",
  [defaultCurrencyCode.name]: { code: "USD", name: "USD" },
  [countryCode.name]: "",
  [timeZone.name]: null,
  [iataCode.name]: "",
};

export default initialValues;
