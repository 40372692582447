import checkout from "./form";

const {
  formField: {
    // travelDesk page
    name,
    website,
    address,
    contactName,
    contactEmail,
    contactPhone,
  },
} = checkout;

const initialValues = {
  // travelDesk page
  [name?.name]: "",
  [website?.name]: "",
  [address?.name]: "",
  [contactName?.name]: "",
  [contactEmail?.name]: "",
  [contactPhone?.name]: "",
};

export default initialValues;
