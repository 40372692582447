import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "components/Tables/DataTable";

const TopViewedItems = ({ topItems }) => {
  const [state, setState] = useState({
    columns: [
      { Header: "Title", accessor: "title", width: "55%" },
      { Header: "Destination", accessor: "destination" },
      { Header: "Price", accessor: "price" },
      { Header: "Source", accessor: "source" },
      { Header: "Status", accessor: "status" },
      { Header: "Number of Visits", accessor: "numberOfVisits" },
    ],
    rows: [],
  });

  useEffect(() => {
    if (topItems?.length > 0) {
      const transformedData = topItems?.map((item) => ({
        title: item.title,
        destination: item.primaryDestination?.destinationName,
        price: item.price?.fromPrice,
        source: item.source,
        status: item.status,
        numberOfVisits: item.visits,
      }));
      setState({ ...state, rows: transformedData });
    }
  }, [topItems]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <MDBox pt={3} px={3}>
            <MDTypography variant="h6" fontWeight="medium">
              Top Viewed Items
            </MDTypography>
          </MDBox>
          <MDBox py={1}>
            <DataTable
              table={state}
              showTotalEntries={false}
              isSorted={false}
              entriesPerPage={false}
            />
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
};

// Setting default props
TopViewedItems.defaultProps = {
  topItems: [],
};

// typechecking props for TopViewedItems
TopViewedItems.propTypes = {
  topItems: PropTypes.instanceOf(Array),
};

export default TopViewedItems;
