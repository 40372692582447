// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import { capitalizeFirstLetter } from "components/helper";
import PropTypes from "prop-types";

const TemplatePicker = ({ template, templateV, setFieldValue, ...rest }) => (
  <Autocomplete
    id="template"
    // defaultValue={templateV}
    options={["Default", "Hayakum", "Pilot"]}
    onChange={(e, value) => {
      if (value) {
        setFieldValue("template", value.toLowerCase());
      } else {
        setFieldValue("template", "default");
      }
    }}
    defaultValue="Default"
    value={templateV ? capitalizeFirstLetter(templateV) : "Default"}
    // value={capitalizeFirstLetter(templateV)}
    getOptionLabel={(option) => option || "Default"}
    isOptionEqualToValue={(option, value) => option.toLowerCase() === value.toLowerCase()}
    renderInput={(params) => (
      <FormField
        {...params}
        {...rest}
        // InputLabelProps={{ shrink: true }}
        label={template?.label}
        name={template?.name}
        // onChange={() => null}
        onChange={params.onChange}
      />
    )}
  />
);

// typechecking props for TemplatePicker
TemplatePicker.propTypes = {
  template: PropTypes.instanceOf(Object).isRequired,
  templateV: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default TemplatePicker;
