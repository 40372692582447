// // LogoSection.js
// import React from "react";
// import { Img, Section } from "@react-email/components";
// import { Tailwind } from "@react-email/tailwind";

// const LogoSection = () => (
//   <Tailwind>
//     <Section className="bg-[#F2F2F2] flex justify-center pb-5 mt-8">
//       <Img
//         src="https://email-assets.lokalee.app/email/emailImages/logo.png"
//         alt="Logo"
//         className="w-full h-auto mx-auto mt-5"
//       />
//     </Section>
//   </Tailwind>
// );

// export default LogoSection;
import React from "react";
import { Img, Section } from "@react-email/components";

const LogoSection = () => (
  <Section
    style={{
      display: "flex",
      marginTop: "2rem",
      justifyContent: "center",
      paddingBottom: "1.25rem",
      backgroundColor: "#F2F2F2",
    }}
  >
    <Img
      alt="Logo"
      src="https://email-assets.lokalee.app/email/emailImages/logo.png"
      style={{
        width: "100%",
        height: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "1.25rem",
      }}
    />
  </Section>
);

export default LogoSection;
