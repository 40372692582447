/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import axios from "axios";
import {
  SET_VOUCHER_LOADING,
  CLEAR_ERRORS,
  VOUCHER_ADD_SUCCESS,
  VOUCHER_FAIL,
  UPDATE_VOUCHER,
  DELETE_SINGLE_VOUCHER,
  VOUCHER_PARTNER_ADD_SUCCESS,
} from "./Types";

// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Add Voucher
export const addVoucher = (data, image, user) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("title", data.title.trim());
  form.append("description", data.description.trim());
  form.append("type", data.type);
  form.append("value", data.value);
  form.append("maxUsage", data.maxUsage);

  form.append("openEnded", data.openEnded);
  form.append("limit", data.limit);
  if (data.limit) {
    form.append("dayRestriction", data.dayRestriction);
    if (data.dayRestriction) {
      form.append("quantityPerDay", data.quantityPerDay);
    }
    if (!data.dayRestriction) {
      form.append("quantity", data.quantity);
    }
  }
  if (data.startDate) form.append("startDate", data?.startDate);
  if (data.openEnded === false) {
    if (data.endDate) form.append("endDate", data?.endDate);
  }
  if (data?.location) form.append("location", data?.location);
  if (data?.location?.name) {
    form.append("recommendation", data?.recommendation?._id ? data?.recommendation?._id : "");
  } else {
    form.append(
      "recommendation",
      data?.recommendation?._id
        ? data?.recommendation?._id
        : data?.recommendation
        ? data?.recommendation
        : ""
    );
  }
  if (image) form.append("image", image);

  try {
    const res = await axios.post(`${URL}/vouchers`, form, config);
    // Dispatch based on user role
    if (user === "admin") {
      dispatch({
        type: VOUCHER_ADD_SUCCESS,
        payload: res.data,
      });
    } else if (user === "partner") {
      dispatch({
        type: VOUCHER_PARTNER_ADD_SUCCESS,
        payload: res.data.data.data,
      });
    }
  } catch (err) {
    dispatch({
      type: VOUCHER_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Update Voucher
export const updateVoucher = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("title", data.title.trim());
  if (data.description) form.append("description", data.description.trim());
  if (data.type) form.append("type", data.type);
  if (data.value) form.append("value", data.value);
  if (data.maxUsage) form.append("maxUsage", data.maxUsage);

  if (data.openEnded) form.append("openEnded", data.openEnded);
  form.append("limit", data.limit);
  if (data.limit) {
    form.append("dayRestriction", data.dayRestriction);
    if (data.dayRestriction) {
      form.append("quantityPerDay", data.quantityPerDay);
    }
    if (!data.dayRestriction) {
      form.append("quantity", data.quantity);
    }
  }
  if (data.startDate) form.append("startDate", data?.startDate);
  if (data.openEnded === false) {
    if (data.endDate) form.append("endDate", data?.endDate);
  }
  if (data?.location?._id) form.append("location", data?.location?._id);
  if (data?.location?.name) {
    form.append("recommendation", data?.recommendation?._id ? data?.recommendation?._id : "");
  } else {
    form.append(
      "recommendation",
      data?.recommendation?._id
        ? data?.recommendation?._id
        : data?.recommendation
        ? data?.recommendation
        : ""
    );
  }
  if (image) form.append("image", image);

  try {
    const res = await axios.patch(`${URL}/vouchers/${data._id}`, form, config);

    dispatch({
      type: UPDATE_VOUCHER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: VOUCHER_FAIL,
      payload: err?.response.data.message,
    });
  }
};

export const updateVoucherTranslations = (data, id) =>
  factory.patch(
    {
      translations: {
        ar: data,
      },
    },
    `${URL}/vouchers/translations/${id}?language=ar`,
    "UPDATE_VOUCHER_TRANSLATION",
    "VOUCHER_FAIL"
  );

// Delete Voucher - set active as false
export const deleteVoucher = (id) =>
  factory.patch({ active: false }, `${URL}/vouchers/${id}`, "DELETE_VOUCHER", "VOUCHER_FAIL");

// Get All Vouchers

export const getAllVouchers = () =>
  factory.get(`${URL}/vouchers?sort=createdAt&dir=desc`, "GET_VOUCHERS", "VOUCHER_FAIL");

// get One Voucher
export const getOneVoucher = (id) =>
  factory.get(`${URL}/vouchers/${id}`, "GET_ONE_VOUCHER", "VOUCHER_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_VOUCHER_LOADING });

// Get Partner Vouchers

export const getRecommednationVoucher = (recommendation) =>
  factory.get(`${URL}/vouchers/recommendation/${recommendation}`, "GET_VOUCHERS", "VOUCHER_FAIL");

export const getLocationVoucher = (location) =>
  factory.get(`${URL}/locations/${location}/vouchers`, "GET_PARTNER_VOUCHERS", "VOUCHER_FAIL");

// Clear single Voucher
export const deleteSingleVoucher = () => ({ type: DELETE_SINGLE_VOUCHER });
