/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/** 
=========================================================
* LCM Dashboard V2
=========================================================

* Product Page: https://www.lcm.lokalee.app
* Copyright 2023 Lokalee (https://www.lokalee.app)

 =========================================================
*/

import { Avatar, Box, Grid, Autocomplete } from "@mui/material";
import styled from "@emotion/styled";
import Taxi from "assets/images/Taxi.svg";
import Train from "assets/images/TrainMetro.svg";
import Bus from "assets/images/Bus.svg";
import FormField from "components/FormField";
// import { useState } from "react";
import PropTypes from "prop-types";

const BigAvatar = styled(Avatar)`
  width: 74px;
  height: 74px;
  margin: 20px;
`;

const IconsPicker = ({ logoName, setFieldValue, selectedIcon, handleIconChange, ...rest }) => {
  const iconsList = [
    { value: <Train />, name: "Train" },
    { value: <Bus />, name: "Bus" },
    { value: <Taxi />, name: "Taxi" },
  ];

  return (
    <Grid container>
      <Grid item xs={6}>
        <Box sx={{ marginTop: "30px" }}>
          <Autocomplete
            id="logoName"
            options={[...iconsList, {}]}
            value={selectedIcon}
            onChange={(event, value) => {
              handleIconChange(value);
              if (value) {
                setFieldValue("logoName", value?.name);
                setFieldValue("logo", value?.value.type);
              } else {
                setFieldValue("logoName", "");
                setFieldValue("logo", "");
              }
            }}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option?.name === value?.name}
            renderInput={(params) => (
              <FormField
                {...params}
                {...rest}
                label={logoName.label}
                name={logoName.name}
                onChange={params.onChange}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ display: "flex" }}>
          <div>
            <BigAvatar
              sx={{ width: 100, height: 100, marginTop: 3, marginBottom: 1 }}
              alt="Logo"
              src={
                selectedIcon.name === "Bus"
                  ? Bus
                  : selectedIcon?.name === "Train"
                  ? Train
                  : selectedIcon?.name === "Taxi"
                  ? Taxi
                  : undefined
              }
              imgProps={{
                style: {
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "cover",
                },
              }}
            />
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};
// Setting default props
IconsPicker.defaultProps = {
  selectedIcon: {},
  handleIconChange: () => {},
};
// typechecking props for IconsPicker
IconsPicker.propTypes = {
  logoName: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  selectedIcon: PropTypes.instanceOf(Object),
  handleIconChange: PropTypes.func,
};

export default IconsPicker;
