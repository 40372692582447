/* eslint-disable camelcase */

import checkout from "./form";

const {
  formField: {
    // voucher Info page
    title,
    description,
    recommendation,
    location,
    logo,
    // additional info page
    openEnded,
    limit,
    type,
    value,
    maxUsage,
    quantityPerDay,
    quantity,
    dayRestriction,
    startDate,
    endDate,
  },
} = checkout;

const initialValues = {
  // voucher Info page
  [title.name]: "",
  [description.name]: "",
  [location.name]: "",
  [recommendation.name]: "",
  [logo.name]: "",

  //  additional info page
  [openEnded.name]: false,
  [limit.name]: false,
  [type.name]: "percentage",
  [dayRestriction.name]: true,
  [value.name]: "",
  [maxUsage.name]: 1,
  [quantityPerDay.name]: 1,
  [quantity.name]: 1,
  [startDate.name]: "",
  [endDate.name]: "",
};

export default initialValues;
