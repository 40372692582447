import React from "react";
import PropTypes from "prop-types";

const Danger = ({ validation }) => (
  <div
    style={{
      margin: 0,
      fontFamily: "Helvetica",
      //   "Roboto","Helvetica","Arial",sans-serif;
      fontSize: "0.75rem",
      fontWeight: 300,
      lineHeight: 1.25,
      opacity: 1,
      textTransform: "none",
      verticalAlign: "unset",
      textDecoration: "none",
      marginTop: "6px",
      color: " #C60024",
    }}
  >
    {validation}
  </div>
);

Danger.defaultProps = {
  validation: "",
};

// Typechecking props for the DynamicCard
Danger.propTypes = {
  validation: PropTypes.string,
};

export default Danger;
