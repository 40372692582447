import checkout from "./form";

const {
  formField: {
    // user page
    name,
    email,
    role,
    phone,
    dob,
    photo,
    // user additional info
    partner,
    locations,
    location,
    balance,
    commission,
    promoCodes,
    locDestinations,
    reference,
    // additional curator info
    // photo,
    bio,
    languages,
    interests,
    // destinations,
    destinations,
    media,
    tickets,
    type,
  },
} = checkout;

const initialValues = {
  // user page
  [name.name]: "",
  [email.name]: "",
  [role.name]: "",
  [phone.name]: "",
  [dob.name]: "",
  [photo.name]: "",

  // user additional info
  [partner.name]: "",
  [bio.name]: "",
  [languages.name]: [],
  [locations.name]: [],
  [location.name]: "",
  [balance.name]: 0,
  [commission.name]: 0,
  [promoCodes.name]: [],
  [locDestinations.name]: { location: "", destinations: "" || {} },
  // additional curator info
  // [photo.name]: "",
  [bio?.name]: "",
  [languages?.name]: [],
  [interests?.name]: [],
  [destinations?.name]: {},
  [reference.name]: "",
  [media?.name]: undefined,
  [tickets?.name]: [],
  [type?.name]: "image",
};

export default initialValues;
