import * as Yup from "yup";
import checkout from "../../new-attraction/schemas/form";

const {
  formField: { title, travelDesk, destination, callToAction, description },
} = checkout;

const validations = [
  Yup.object().shape({
    // [logo.name]: Yup.string().required(logo.errorMsg).nullable(),
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [travelDesk.name]: Yup.string().required(travelDesk.errorMsg),
    [destination.name]: Yup.object().required(destination.errorMsg),
  }),
  Yup.object().shape({
    [callToAction.name]: Yup.object().shape({
      label: Yup.string()
        .matches(/([A-Za-z])/, callToAction.labelInvalidMsg)
        .required(callToAction.labelErrorMsg),
      url: Yup.string().when("urlType", {
        is: true,
        then: () =>
          Yup.string()
            .matches(
              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
              callToAction.urlInvalidMsg
            )
            .required(callToAction.urlErrorMsg),
      }),
    }),
    [description.name]: Yup.string().when("type", {
      is: "msg",
      then: () =>
        Yup.string()
          .matches(/([A-Za-z])/, description.invalidMsg)
          .required(description.errorMsg),
    }),
  }),
];

export default validations;
