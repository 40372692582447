/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";

const PinsPicker = ({ pins, setFieldValue, pinsV, fieldName }) => {
  const filterRec = (data) =>
    data.map((pinData) => ({
      label: pinData.name,
      value: pinData._id,
    }));

  const transformPinsV = (pinsArray) =>
    pinsArray.map((pin) => ({
      label: pin.name || pin.label,
      value: pin.id || pin.value,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/pins?`;
      if (inputValue) url += `name=${inputValue}`;
      url += "&sort=name&dir=asc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve(filterRec(result.data));
        })
        .catch((error) => {
          console.log(error.message);
        });
    });

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {pins.label}
      </Typography>{" "}
      <AsyncSelect
        key={pinsV}
        isMulti
        cacheOptions
        defaultOptions
        defaultValue={pinsV.length > 0 ? transformPinsV(pinsV) : null}
        placeholder="Search ..."
        menuPosition="fixed"
        loadOptions={promiseOptions}
        onChange={(options) => {
          if (!options || (options.length === 0 && fieldName?.length > 0)) {
            setFieldValue(`${fieldName}`, []);
          } else {
            setFieldValue(`${fieldName}`, options);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            height: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

PinsPicker.defaultProps = {
  setFieldValue: () => {},
  pinsV: [],
  fieldName: [],
};

PinsPicker.propTypes = {
  pins: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func,
  pinsV: PropTypes.instanceOf(Array),
  fieldName: PropTypes.instanceOf(Array),
};

export default PinsPicker;
