/* eslint-disable no-underscore-dangle */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, ctaTitle, ctaLink },
} = checkout;

const validations = [
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [ctaTitle?.name]: Yup.string()
      .matches(/([A-Za-z])/, ctaTitle?.invalidMsg)
      .required(ctaTitle?.errorMsg),
    [ctaLink?.name]: Yup.string()
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
        ctaLink?.invalidMsg
      )
      .required(ctaLink.errorMsg),
  }),
];

export default validations;
