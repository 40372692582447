// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import PropTypes from "prop-types";
import pickupTypesList from "./pickupTypesList";

const PickupTypePicker = ({ pickupType, pickupTypeV, setFieldValue, ...rest }) => (
  <Autocomplete
    id="roles"
    defaultValue={pickupTypeV || null}
    options={pickupTypesList}
    onChange={(e, value) => {
      if (value) {
        setFieldValue("pickupType", value);
      } else {
        setFieldValue("pickupType", "");
      }
    }}
    value={pickupTypeV || null}
    getOptionLabel={(option) => option?.label || ""}
    isOptionEqualToValue={(option, value) => option?.value === value?.value}
    renderInput={(params) => (
      <FormField
        {...params}
        {...rest}
        label={pickupType?.label}
        name={pickupType?.name}
        onChange={params.onChange}
      />
    )}
  />
);

// typechecking props for PickupTypePicker
PickupTypePicker.propTypes = {
  pickupType: PropTypes.instanceOf(Object).isRequired,
  pickupTypeV: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default PickupTypePicker;
