const titleList = [
  { label: "Fuel surcharge", value: "FUEL_SURCHARGE" },
  { label: "Landing and facility fees", value: "LANDING_AND_FACILITY_FEES" },
  { label: "Airport/Departure Tax", value: "AIRPORT_AND_DEPARTURE_TAX" },
  { label: "GST (Goods and Services Tax)", value: "GST" },
  { label: "Gratuities", value: "GRATUITIES" },
  { label: "Parking Fees", value: "PARKING_FEES" },
  { label: "All Fees and Taxes", value: "ALL_FEES_AND_TAXES" },
  { label: "Use of bicycle", value: "USE_OF_BICYCLE" },
  { label: "Use of SCUBA equipment", value: "USE_OF_SCUBA_EQUIPMENT" },
  { label: "Use of Snorkelling equipment", value: "USE_OF_SNORKELING_EQUIPMENT" },
  { label: "Use of Trikke", value: "USE_OF_TRIKKE" },
  { label: "Use of Segway", value: "USE_OF_SEGWAY" },
  { label: "Use of other equipment", value: "USE_OF_OTHER_EQUIPMENT" },
  { label: "Use of cooking utensils", value: "USE_OF_COOKING_UTENSILS" },
  { label: "All ingredients", value: "ALL_INGREDIENTS" },
  { label: "Breakfast", value: "BREAKFAST" },
  { label: "Brunch", value: "BRUNCH" },
  { label: "Lunch", value: "LUNCH" },
  { label: "Dinner", value: "DINNER" },
  { label: "Snacks", value: "SNACKS" },
  { label: "Bottled water", value: "BOTTLED_WATER" },
  { label: "Coffee and/or Tea", value: "COFFEE_AND_TEA" },
  { label: "Soda/Pop", value: "SODA_POP" },
  { label: "Alcoholic Beverages", value: "ALCOHOLIC_BEVRAGES" },
  { label: "Refreshments", value: "REFRESHMENTS" },
  { label: "Air-conditioned vehicle", value: "AIR_CONDITIONED_VEHICLE" },
  { label: "Restroom on board", value: "RESTROOM_ON_BOARD" },
  { label: "Private transportation", value: "PRIVATE_TRANSPORTATION" },
  { label: "WiFi on board", value: "WIFI_ONBOARD" },
  { label: "Certificate", value: "CERTIFICATE" },
  { label: "Recipe booklet", value: "RECIPE_BOOKLET" },
  { label: "Other", value: "OTHER" },
];

export default titleList;
