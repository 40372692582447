/* eslint-disable no-underscore-dangle */
import {
  UPDATE_DESTINATION,
  DELETE_DESTINATION,
  DESTINATION_ADD_SUCCESS,
  SET_DESTINATION_LOADING,
  GET_ALL_DESTINATION,
  DESTINATION_FAIL,
  CLEAR_DESTINATIONS_ERRORS,
  UPDATE_DESTINATION_TRANSLATION,
  GET_ONE_DESTINATION,
} from "store/actions/Types";

const initialState = {
  loading: false,
  destinations: [],
  error: null,
  added: false,
  destination: null,
};

const variable = (state = initialState, action) => {
  switch (action.type) {
    case DESTINATION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        destinations: [action.payload.data, ...state.destinations],
        added: true,
        destination: null,
      };
    case GET_ONE_DESTINATION:
      return {
        ...state,
        loading: false,
        error: null,
        destination: action.payload.data,
        added: false,
      };
    case GET_ALL_DESTINATION:
      return {
        ...state,
        loading: false,
        error: null,
        destinations: action.payload.data,
        added: false,
        destination: null,
      };
    case DELETE_DESTINATION:
      return {
        ...state,
        loading: false,
        destinations: state.destinations.filter(
          (destination) => destination._id !== action.payload.data.destination._id
        ),
      };
    case UPDATE_DESTINATION:
      return {
        ...state,
        added: true,
        destinations: state.destinations.map((destination) =>
          destination._id === action.payload.data.data._id ? action.payload.data.data : destination
        ),
      };
    case DESTINATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_DESTINATIONS_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case SET_DESTINATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DESTINATION_TRANSLATION:
      return {
        ...state,
        destination: action.payload.data?.destination,
      };

    default:
      return state;
  }
};
export default variable;
