/* eslint-disable react/no-array-index-key */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Divider } from "@mui/material";
// import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { areAllValuesEmpty } from "components/helper";

const Review = ({ formData }) => {
  const { values } = formData;

  const [menuInfoData, setMenuInfoData] = useState({});
  const [filterData, setFilterData] = useState({});
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (values) {
      setMenuInfoData({
        title: values?.title,
        type: values?.type,
        destinations: values?.destinations,
        locations: values?.locations,
        option: values?.option,
        tags: values?.tags,
        tickets: values?.filterBy === "tickets" ? values?.tickets : [],
        url: values?.url,
        top: values?.top,
        hidden: values?.hidden,
        expandable: values?.expandable,
        icon: values?.icon,
      });

      setFilterData({
        filters: values?.filters,
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([
      { id: 0, title: "Menu info", data: menuInfoData },
      { id: 1, title: "Filters", data: filterData },
    ]);
  }, [menuInfoData, filterData]);

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {fields?.map((field, i) => (
            <Grid
              item
              key={field.id}
              xs={12}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList data={field.data} title={field?.title} />
              {i + 1 !== fields?.length && (
                <div>
                  <Divider sx={{ height: "1px" }} />
                </div>
              )}{" "}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// Setting default props for the Review
Review.defaultProps = {
  //   editPage: false,
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  //   editPage: PropTypes.bool,
};

export default Review;
