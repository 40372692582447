/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, Radio, RadioGroup, FormControlLabel, FormGroup } from "@mui/material";
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import { capitalizeFirstLetter } from "components/helper";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import LocationsPicker from "components/AutoComplete/LocationsPicker";
import Danger from "components/Danger";
import TagsPicker from "components/AsyncSelect/TagsPicker";
import DestinationsPicker from "components/AutoComplete/DestinationsPicker";
import DetailList from "components/DetailList";
import TicketsPicker from "components/AsyncSelect/TicketsPicker";

const MenuInfo = ({
  formData,
  selectedDestinations,
  handleDestinationsChange,
  editPage,
  detailPage,
  selectedLocations,
  handleLocationsChange,
  selectedTags,
  handleTagsChange,
  setSelectedTags,
  setSelectedDestinations,
  setSelectedLocations,
  selectedTickets,
  setSelectedTickets,
  handleTicketChange,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    title,
    type,
    tags,
    destinations,
    locations,
    option,
    top,
    hidden,
    expandable,
    url,
    filterBy,
    tickets,
  } = formField;
  const {
    title: titleV,
    top: topV,
    hidden: hiddenV,
    expandable: expandableV,
    type: typeV,
    option: optionV,
    locations: locationsV,
    url: urlV,
    filterBy: filterByV,
  } = values;

  function renderPicker() {
    if (typeV === "destinations") {
      return (
        <Grid item xs={12} sx={{ mt: "16px" }}>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <DestinationsPicker
              setFieldValue={setFieldValue}
              handleChange={handleDestinationsChange}
              destinations={destinations}
              destinationsV={selectedDestinations}
            />
          </div>
          <Danger validation={errors?.destinations} />
        </Grid>
      );
    }
    if (typeV === "location") {
      return (
        <Grid item xs={12}>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <LocationsPicker
              location={locations}
              setFieldValue={setFieldValue}
              locationArr={selectedLocations}
              handleChange={handleLocationsChange}
              success={locationsV?.length > 0 && !errors.locations}
            />
          </div>
        </Grid>
      );
    }
    return null;
  }

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Menu info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            title: values?.title,
            logo: values?.logo,
            type: values?.type,
            destinations: values?.destinations,
            locations: values?.locations,
            option: values?.option,
            tags: values?.tags,
            tickets: values?.tickets,
            url: values?.url,
            hidden: values?.hidden,
            top: values?.top,
            expandable: values?.expandable,
            icon: values?.icon,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3} alignItems="end">
            {!editPage ? (
              <Grid container>
                <Grid item xs={4} marginLeft={5} marginTop={3}>
                  <ImagePicker edit imageType="logo" textAdded="Upload Icon" />
                </Grid>
                <Grid item xs={4} marginTop={3}>
                  <ImagePicker
                    edit
                    imageType="images"
                    textAdded="Upload Image"
                    currentImg={values?.icon}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={4} marginTop={3}>
                <ImagePicker edit imageType="images" textAdded="Upload Image" menuPage />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors.name && touched.name}
                success={titleV.length > 0 && !errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="caption" textTransform="uppercase" fontWeight="medium">
                {type?.label}
              </MDTypography>
              <RadioGroup
                defaultValue={typeV || "global"}
                value={typeV}
                onChange={(event, value) => {
                  if (typeV !== "location") {
                    setFieldValue("locations", []); // Clear selected locations
                    setSelectedLocations([]);
                  }
                  if (typeV !== "destinations") {
                    setFieldValue("destinations", []); // Clear selected destinations
                    setSelectedDestinations([]);
                  }
                  setFieldValue("type", value);
                }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="global" control={<Radio />} label="Global" />
                <FormControlLabel value="destinations" control={<Radio />} label="Destinations" />
                <FormControlLabel value="location" control={<Radio />} label="Locations" />
              </RadioGroup>
            </Grid>

            {renderPicker()}

            <Grid item xs={12}>
              <MDTypography variant="caption" textTransform="uppercase" fontWeight="medium">
                {option?.label}
              </MDTypography>
              <RadioGroup
                defaultValue={optionV || "Recommendation"}
                value={optionV}
                onChange={(event, value) => {
                  if (optionV !== "URL") {
                    setFieldValue("url", "");
                  }
                  if (optionV !== "Ticket" || optionV !== "Recommendation") {
                    setFieldValue("tags", []);
                    setSelectedTags([]);
                  }
                  setFieldValue("filterBy", "tags");
                  setFieldValue("option", value);
                }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Recommendation"
                  control={<Radio />}
                  label="Recommendation"
                />
                <FormControlLabel value="Ticket" control={<Radio />} label="Ticket" />
                <FormControlLabel value="URL" control={<Radio />} label="External URL" />
              </RadioGroup>
            </Grid>
            {optionV === "URL" ? (
              <Grid item xs={12}>
                <FormField
                  type={url.type}
                  label={url.label}
                  name={url.name}
                  value={urlV}
                  error={errors.url && touched.url}
                  success={titleV.length > 0 && !errors.url}
                />
              </Grid>
            ) : (
              <Grid item container xs={12}>
                {optionV === "Ticket" && (
                  <Grid item xs={12}>
                    <MDTypography variant="caption" textTransform="uppercase" fontWeight="medium">
                      {filterBy?.label}
                    </MDTypography>
                    <RadioGroup
                      defaultValue={filterByV || "Recommendation"}
                      value={filterByV}
                      onChange={(event, value) => {
                        setFieldValue("tags", []);
                        setSelectedTags([]);
                        setFieldValue("tickets", []);
                        setSelectedTickets([]);
                        setFieldValue("filterBy", value);
                      }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel value="tags" control={<Radio />} label="Tags" />
                      <FormControlLabel value="tickets" control={<Radio />} label="Tickets" />
                    </RadioGroup>
                  </Grid>
                )}
                {filterByV === "tickets" ? (
                  <Grid item xs={12}>
                    <TicketsPicker
                      tickets={tickets}
                      ticketsV={
                        selectedTickets?.length > 0 && selectedTickets[0]?._id?.length > 0
                          ? selectedTickets?.map((item) => ({
                              label: item?.title,
                              value: item?._id,
                            }))
                          : selectedTickets
                      }
                      setFieldValue={setFieldValue}
                      handleChange={handleTicketChange}
                      isMenuPage
                      selectedType={typeV}
                      destinations={selectedDestinations}
                      locations={selectedLocations}
                    />
                    {errors?.tickets && <Danger validation={errors?.tickets} />}
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <TagsPicker
                      tags={tags}
                      tagsV={
                        selectedTags?.length > 0 && selectedTags[0]?._id?.length > 0
                          ? selectedTags.map((item) => ({
                              label: item?.title,
                              value: item?._id,
                            }))
                          : selectedTags
                      }
                      setFieldValue={setFieldValue}
                      handleChange={handleTagsChange}
                      type={optionV === "Recommendation" ? "RECOMMENDATIONS" : "TICKETS"}
                    />
                    <Danger validation={errors?.tags} />
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={topV}
                      name={top.name}
                      onChange={(event) => setFieldValue(top.name, event.target.checked)}
                    />
                  }
                  label={top?.label}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={hiddenV}
                      name={hidden.name}
                      onChange={(event) => setFieldValue(hidden.name, event.target.checked)}
                    />
                  }
                  label={hidden?.label}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={expandableV}
                      name={expandable.name}
                      onChange={(event) => setFieldValue(expandable.name, event.target.checked)}
                    />
                  }
                  label={expandable?.label}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// Setting default props
MenuInfo.defaultProps = {
  handleDestinationsChange: () => {},
  selectedDestinations: [],
  handleLocationsChange: () => {},
  selectedLocations: [],
  handleTagsChange: () => {},
  selectedTags: [],
  editPage: false,
  detailPage: true,
  setSelectedLocations: () => {},
  setSelectedDestinations: () => {},
  setSelectedTags: () => {},
  selectedTickets: [],
  setSelectedTickets: () => {},
  handleTicketChange: () => {},
};

// typechecking props for MenuInfo
MenuInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  selectedDestinations: PropTypes.instanceOf(Object),
  handleDestinationsChange: PropTypes.func,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  selectedLocations: PropTypes.instanceOf(Object),
  handleLocationsChange: PropTypes.func,
  selectedTags: PropTypes.instanceOf(Object),
  setSelectedTags: PropTypes.func,
  handleTagsChange: PropTypes.func,
  setSelectedLocations: PropTypes.func,
  setSelectedDestinations: PropTypes.func,
  selectedTickets: PropTypes.instanceOf(Object),
  setSelectedTickets: PropTypes.func,
  handleTicketChange: PropTypes.func,
};

export default MenuInfo;
