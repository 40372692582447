/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import FormField from "components/FormField";

const CustomInput = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <FormField
      disabled={props?.disabled}
      onChange={props.onChange}
      label={props.retailPrice?.label}
      variant="outlined"
      name={props.retailPrice?.name}
      value={props.value}
      fullWidth
      // {...props.rest}
    />
  </div>
));

const Currencyfield = ({
  setFieldValue,
  retailPriceObj,
  setDatePrice,
  retailPrice,
  disabled,
  ...rest
}) => {
  const item = useSelector((state) => state.items.item);
  const [musement, setMeusement] = useState(false);
  const prefix = "$";
  const [price, setPrice] = useState({});

  useEffect(() => {
    if (item?.source === "musement") setMeusement(true);
    else setMeusement(false);
  }, []);

  /**
   * Handle validation
   */
  const [onChange, setOnChange] = useState(false);
  const handleOnValueChange = (value, test, values) => {
    setPrice(values);
    setOnChange(true);
  };
  useEffect(() => {
    if (retailPriceObj?.formatted_value || retailPriceObj?.formatted_value === "") {
      setPrice(retailPriceObj);
    }
    // eslint-disable-next-line
  }, [retailPriceObj?.formatted_value]);

  useEffect(() => {
    if (onChange) {
      if (price?.formatted) {
        const retail_price = {
          currency: "USD",
          formatted_iso_value: parseFloat(price?.value).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
          formatted_value: price.formatted,
          value: price?.value,
        };
        if (setFieldValue) {
          setFieldValue("retail_price", retail_price);
        } else {
          setDatePrice(retail_price);
        }
      } else if (setFieldValue) {
        setFieldValue("retail_price", {});
      } else {
        setDatePrice({});
      }
      setOnChange(false);
    }
    // eslint-disable-next-line
  }, [onChange]);

  return (
    <div>
      <CurrencyInput
        disabled={disabled || musement}
        allowNegativeValue={false}
        id="currency"
        value={price?.value}
        onValueChange={handleOnValueChange}
        prefix={prefix}
        step={1}
        inputMode="decimal"
        retailPrice={retailPrice}
        {...rest}
        customInput={CustomInput}
      />
    </div>
  );
};

// Setting default props
Currencyfield.defaultProps = {
  item: {},
  retailPrice: {},
  retailPriceObj: {},
};

Currencyfield.propTypes = {
  setFieldValue: PropTypes.func,
  setDatePrice: PropTypes.func,
  retailPrice: PropTypes.instanceOf(Object),
  retailPriceObj: PropTypes.instanceOf(Object),
  item: PropTypes.instanceOf(Object),
};

export default Currencyfield;
