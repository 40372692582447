/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import axios from "axios";
import {
  SET_TAG_LOADING,
  CLEAR_ERRORS,
  UPDATE_TAG,
  TAG_FAIL,
  TAG_ADD_SUCCESS,
  CLEAR_ONE_TAG,
} from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Add Tag
export const addTag = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const payload = {
    title: data?.title,
    top: data?.top,
    type: data?.type,
    interests: data?.interests,
  };

  if (Array.isArray(data?.parents)) {
    const parentTagIds = data?.parents.map((obj) => obj.value);
    payload.parentTagIds = parentTagIds;
  }

  if (Array.isArray(data?.persona)) {
    const personasValues = data?.persona.map((obj) => obj.value);
    payload.persona = personasValues;
  }

  try {
    const res = await axios.post(`${URL}/tags`, payload, config);

    dispatch({
      type: TAG_ADD_SUCCESS,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: TAG_FAIL,
      payload: err?.response.data.message,
    });
  }
};

//  Update Tag
export const updateTag = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const payload = {
    title: data?.title,
    top: data?.top,
    type: data?.type,
    interests: data?.interests,
  };

  if (Array.isArray(data.parents)) {
    const parentTagIds = data.parents.map((obj) => obj.value);
    payload.parentTagIds = parentTagIds;
  }
  if (Array.isArray(data.parents) && data.parents.length === 0) {
    payload.parentTagIds = [];
  }
  if (Array.isArray(data?.persona)) {
    const personasValues = data?.persona.map((obj) => obj.value || obj);
    payload.persona = personasValues;
  }
  if (Array.isArray(data.persona) && data.persona?.length === 0) {
    payload.persona = [];
  }

  try {
    const res = await axios.patch(`${URL}/tags/${data._id}`, payload, config);

    dispatch({
      type: UPDATE_TAG,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TAG_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Delete Tag - set active as false
export const deleteTag = (id) =>
  factory.patch({ active: false }, `${URL}/tags/${id}`, "DELETE_TAG", "TAG_FAIL");

// Get All tags

export const getTags = () => factory.get(`${URL}/tags?sort=name&dir=asc`, "GET_TAGS", "TAG_FAIL");

// get One Tag
export const getOneTag = (id) => factory.get(`${URL}/tags/${id}`, "GET_ONE_TAG", "TAG_FAIL");

export const clearTag = () => ({ type: CLEAR_ONE_TAG });

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_TAG_LOADING });
