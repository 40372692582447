import React from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";

const InterestsPicker = ({ setFieldValue, interests, interestsV, ...rest }) => {
  const interestsOptions = [
    "Extreme Sports",
    "Sports Enthusiast",
    "Fashion & Beauty",
    "Wellness & Health",
    "Food Culture",
    "Nightlife",
    "Adventures & Exploration",
    "Arts & Culture",
    "Remote Work",
    "Romantic Experiences",
    "Sightseeing & Landmarks",
    "Nature & Outdoors",
  ];

  return (
    <div>
      <Autocomplete
        id="interests"
        multiple
        filterSelectedOptions
        //   renderTags={() => null}
        options={interestsOptions}
        onChange={(event, selectedOptions) => {
          setFieldValue("interests", selectedOptions || []);
        }}
        value={interestsV}
        getOptionLabel={(option) => option || ""}
        // isOptionEqualToValue={(option, value) => option._id === value._id}
        renderInput={(params) => (
          <FormField
            {...params}
            {...rest}
            label={interests.label}
            name={interests?.name}
            onChange={() => null}
            error={interestsV.length === 0} // Display error if no options are selected
            // value={interestsV}
          />
        )}
      />
    </div>
  );
};

InterestsPicker.propTypes = {
  interests: PropTypes.instanceOf(Object).isRequired,
  interestsV: PropTypes.instanceOf(Array).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default InterestsPicker;
