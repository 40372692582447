import PropTypes from "prop-types";

export const defaultProps = {
  alternativeItems: [
    {
      title: " Lake boat ride with Dubai Fountain show Standard price",
      slug: "lake-boat-ride-with-dubai-fountain-show-standard-price",
      coverImage: "https://email-assets.lokalee.app/email/emailImages/fullWidth-cover.png",
      reviews: { combinedAverageRating: 3, totalReviews: 10 },
    },
    {
      title: " Lake boat ride with Dubai Fountain show Standard price",
      slug: "lake-boat-ride-with-dubai-fountain-show-standard-price",
      coverImage: "https://email-assets.lokalee.app/email/emailImages/fullWidth-cover.png",
      reviews: { combinedAverageRating: 3, totalReviews: 10 },
    },
    {
      title: " Lake boat ride with Dubai Fountain show Standard price",
      slug: "lake-boat-ride-with-dubai-fountain-show-standard-price",
      coverImage: "https://email-assets.lokalee.app/email/emailImages/fullWidth-cover.png",
      reviews: { combinedAverageRating: 3, totalReviews: 10 },
    },
  ],
  data: {
    ref: {
      title: " Lake boat ride with Dubai Fountain show Standard price",
    },
  },
};

export const propTypes = {
  alternativeItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      coverImage: PropTypes.string.isRequired,
      reviews: PropTypes.shape({
        combinedAverageRating: PropTypes.number.isRequired,
        totalReviews: PropTypes.number.isRequired,
      }).isRequired,
    })
  ).isRequired,
  data: PropTypes.shape({
    ref: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
