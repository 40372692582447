/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import {
  UPDATE_DESTINATION,
  //   DELETE_DESTINATION,
  SET_DESTINATION_LOADING,
  //   GET_ALL_DESTINATION,
  CLEAR_DESTINATIONS_ERRORS,
  DESTINATION_FAIL,
  DESTINATION_ADD_SUCCESS,
} from "store/actions/Types";
import axios from "axios";
import { createFormData } from "components/helper";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Get All Destinations
export const getAllDestinations = () =>
  factory.get(`${URL}/destinations?`, "GET_ALL_DESTINATION", "DESTINATION_FAIL");

// Add Destination
export const addDestination = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("destinationName", data?.destinationName.trim());
  form.append("address", data.address.trim());
  form.append("city", data.city.trim());
  form.append("region", data.region);
  form.append("iataCode", data.iataCode);
  form.append("countryCode", data.countryCode);
  if (data.country_code) form.append("country_code", data.country_code);
  form.append("timeZone", data.timeZone.value);
  form.append("country", data.country);
  form.append("destinationType", data.destinationType.toUpperCase());
  if (data.defaultCurrencyCode) form.append("defaultCurrencyCode", data.defaultCurrencyCode?.name);
  if (data.parentId)
    form.append("parentId", data?.parentId?.value ? data?.parentId?.value : data?.parentId);
  form.append("lat", data.lat);
  form.append("lng", data.lng);
  form.append("vatRate", data.vatRate);
  form.append("top", data.top);
  form.append("highlight", data.highlight);
  form.append("source", "lokalee");
  if (image) {
    form.append("logo", image);
  }

  try {
    const res = await axios.post(`${URL}/destinations`, form, config);
    dispatch({
      type: DESTINATION_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DESTINATION_FAIL,
      payload: err?.response?.data.message,
    });
  }
};
//  Update Destination
export const updateDestination = (data, logo) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const updatedData = {
    ...data,
    destinationName: data?.destinationName.trim(),
    destinationType: data.destinationType.toUpperCase(),
    timeZone: data?.timeZone?.value,
    defaultCurrencyCode: data?.defaultCurrencyCode?.name,
    countryCode: data?.country_code || data?.countryCode,
  };
  if (data.destinationType !== "COUNTRY") {
    updatedData.parentId = data?.parentId?.value || data?.parentId?._id || data?.parentId;
  } else {
    updatedData.parentId = "";
  }
  const formData = createFormData({ ...updatedData, logo });

  try {
    const res = await axios.patch(`${URL}/destinations/${data._id}`, formData, config);

    dispatch({
      type: UPDATE_DESTINATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DESTINATION_FAIL,
      payload: err?.response.data.message,
    });
  }
};

export const updateDestinationTranslations = (data, id) =>
  factory.patch(
    {
      translations: {
        ar: data,
      },
    },
    `${URL}/destinations/translations/${id}?language=ar`,
    "UPDATE_DESTINATION_TRANSLATION",
    "DESTINATION_FAIL"
  );

// Delete Destination - set active as false
export const deleteDestination = (id) =>
  factory.patch(
    { active: false },
    `${URL}/destinations/${id}`,
    "DELETE_DESTINATION",
    "DESTINATION_FAIL"
  );

// get One Destination
export const getOneDestination = (id) =>
  factory.get(`${URL}/destinations/${id}`, "GET_ONE_DESTINATION", "DESTINATION_FAIL");
// Clear Errors
export const clearDestinationsErrors = () => ({ type: CLEAR_DESTINATIONS_ERRORS });

// Set Loading
export const setDestinationsLoading = () => ({ type: SET_DESTINATION_LOADING });
