/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MDSnackbar from "components/MDSnackbar";
import { useSelector } from "react-redux";

const Alerts = () => {
  const alerts = useSelector((state) => state.alerts);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (alerts) {
      handleClick();
    }
  }, [alerts]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    alerts?.length > 0 &&
    alerts.map((alert) => (
      <MDSnackbar
        color={alert.type}
        icon="check"
        title={
          alert.type === "success" ? "Success" : alert.type === "warning" ? "Warning" : "Error"
        }
        content={alert.msg}
        dateTime="Just now"
        open={open}
        onClose={handleClose}
        key={alert.id}
        close={handleClose}
        bgWhite
      />
    ))
  );
};
Alerts.propTypes = {
  alerts: PropTypes.instanceOf(Array),
};

export default Alerts;
