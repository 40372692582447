import React from "react";
import PropTypes from "prop-types";
import { Body, Container, Section, Html, Text, Button } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import FooterSection from "../../FooterSection";
import IfYouNeedSupportSection from "../../IfYouNeedSupport";
import LogoSection from "../../LogoSection";
import HeadSection from "../../HeadSection";

const AdminResetPasswordEmail = ({ token }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-center bg-white p-5 text-black rounded-md mb-6">
            <Text className="text-[35px] leading-[26.5px] font-bold text-black  mb-2">
              Hello there!
            </Text>
            <Text className="mb-4 text-black text-[25px] leading-[26.5px] font-bold ">
              Your password has been reset by one of our Lokalee admins.
            </Text>
            <Text className="mb-4 text-black text-[20px] leading-[23px] font-normal">
              {" "}
              Click the button below to change your password.
            </Text>
            <Button
              className="bg-[#003005] text-white text-[16px] leading-[16px] font-semibold py-2 px-4 rounded-md  mx-auto"
              href={token}
            >
              Change Password
            </Button>
          </Section>
          <hr className="border-t-0  border-[#394A43]" />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);
AdminResetPasswordEmail.defaultProps = {
  token: "htttps://lokalee.app",
};

AdminResetPasswordEmail.propTypes = {
  token: PropTypes.string,
};
export default AdminResetPasswordEmail;
