import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TablePagination } from "@mui/material";

const theme = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        select: {
          fontSize: "0.7rem",
          padding: "0",
        },
        selectIcon: {
          fontSize: "0.7rem",
          padding: "0",
        },

        displayedRows: {
          fontSize: "0.7rem",
          padding: "0",
        },
      },
    },
  },
});

const CustomPagination = (props) => (
  <ThemeProvider theme={theme}>
    <TablePagination {...props} labelRowsPerPage={null} />
  </ThemeProvider>
);

export default CustomPagination;
