/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
// React
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "store/actions/boxActions";
import setAlert from "store/actions/alertActions";
import { deleteMenu, sortMenu } from "store/actions/menuActions";
// Components:
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";
import { getCsv, getPdf } from "store/actions/exportAction";

const MenuMaterialTable = ({
  list,
  data,
  loading,
  tabValue,
  location,
  selectedLocations,
  selectedDestinations,
}) => {
  const dispatch = useDispatch();
  const tableRef = createRef();
  const user = useSelector((state) => state.users.user);
  const error = useSelector((state) => state.boxes.error);
  const added = useSelector((state) => state.boxes.added);

  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState(""); // State to manage search input

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    setSearch(""); // Reset search input when tab changes
  }, [tabValue]);

  const deleteMenuFn = (oldData) => {
    dispatch(deleteMenu(oldData));
  };

  return (
    <MDBox>
      <MaterialTablePicker
        isOrder
        menuTable
        key={added}
        deleteRoleAction
        data={tableData}
        sortFn={sortMenu}
        currentUrl="menus"
        title={list.title}
        tableRef={tableRef}
        isLoading={loading}
        searchValue={search}
        screen={list.screen}
        locationId={location}
        columns={list.columns}
        addUrl="menus/new-menu"
        filteringOptions={false}
        boxesTable={tabValue === 1}
        exportMenuField="globalBoxes"
        actionsRole={user.role === "admin"}
        locationBoxesTable={tabValue === 2}
        editableRole={user.role === "admin"}
        onRowClickURL={`/${user.role}/menus`}
        onSearchChange={(event) => setSearch(event)}
        editableDelete={(oldData) => deleteMenuFn(oldData)}
        getPdf={(value) => {
          if (value) {
            dispatch(
              getPdf(
                value,
                tabValue === 1
                  ? `stats/pdf?destinations=${selectedDestinations[0]?.value}&type=destinations`
                  : tabValue === 2
                  ? `stats/pdf?locations=${selectedLocations[0]?._id}&type=location`
                  : `stats/pdf?page=1&type=global`,
                "Menus"
              )
            );
          } else {
            // Handle the case where the value is empty
          }
        }}
        getCsv={() => {
          if (user?.role === "admin") {
            dispatch(
              getCsv(
                tabValue === 1
                  ? `stats/csv?destinations=${selectedDestinations[0]?.value}&type=destinations`
                  : tabValue === 2
                  ? `stats/csv?locations=${selectedLocations[0]?._id}&type=location`
                  : `stats/csv?page=1&type=global`,
                "Menus"
              )
            );
          } else {
            // Handle the case where the user role is not admin
          }
        }}
      />
    </MDBox>
  );
};

// Setting default props
MenuMaterialTable.defaultProps = {
  data: [],
  list: {},
  location: [],
  selectedLocations: {},
  selectedDestinations: {},
};

// typechecking props for GlobalMenusList
MenuMaterialTable.propTypes = {
  list: PropTypes.instanceOf(Object),
  loading: PropTypes.bool.isRequired,
  tabValue: PropTypes.number.isRequired,
  location: PropTypes.instanceOf(Object),
  selectedLocations: PropTypes.instanceOf(Object),
  selectedDestinations: PropTypes.instanceOf(Object),
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

export default MenuMaterialTable;
