/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Material UI components
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DetailList from "components/DetailList";
import LocationPicker from "components/AutoComplete/LocationPicker";
import FormField from "components/FormField";
import Danger from "components/Danger";
import PromocodesPicker from "components/AsyncSelect/PromocodesPicker";
import { formatDateTime } from "components/helper";
import DestinationPicker from "components/AsyncSelect/DestinationPicker";

const AdditionalConciergeInfo = ({
  formData,
  detailPage,
  edit,
  selectedLocation,
  handleLocationChange,
  selectedDestinations,
  setSelectedDestinations,
  handleDestinationChange,
  handlePromocodesChange,
  setSelectedLocation,
}) => {
  const { formField, values, setFieldValue, errors, touched } = formData;
  const { location, destinations, balance, commission, promoCodes, reference } = formField;
  const {
    location: locationV,
    balance: balanceV,
    commission: commissionV,
    promoCodes: promoCodesV,
    destinations: destinationsV,
    reference: referenceV,
  } = values;

  const [locRadio, setLocRadio] = useState(!(values?.destinations?.length > 0));

  useEffect(() => {
    if (locationV && locationV?._id?.length > 0) {
      setFieldValue("locDestinations.location", locationV?._id);
    } else {
      setFieldValue("locDestinations.location", "");
      setFieldValue("location", "");
    }
  }, []);

  useEffect(() => {
    if (destinationsV && destinationsV?.length > 0) {
      setFieldValue("locDestinations.destinations", destinationsV);
      setFieldValue("destinations", destinationsV);
      setSelectedDestinations(destinationsV[0]);
    } else {
      setFieldValue("locDestinations.destinations", "");
      setFieldValue("destinations", []);
      setSelectedDestinations([]);
    }
  }, []);

  return (
    <MDBox>
      {!edit && (
        <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
          <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
            Concierge Info
          </MDTypography>
        </MDBox>
      )}
      {detailPage ? (
        <DetailList
          conciergeDetailsPage
          data={{
            location:
              locRadio && values?.location?.name && Object.keys(values?.location).length > 0
                ? values?.location?.name
                : values?.location,
            destination:
              !locRadio && values?.destinations?.length > 0
                ? values?.destinations[0]?.label
                : values?.destinations?.length > 0 && values?.destinations[0]?.destinationName,
            balance: values?.balance > 0 ? values?.balance : 0,
            reference: values?.reference,
            commission: values?.commission > 0 ? values?.commission : 0,
            lastLogin: values?.lastLogin && formatDateTime(values?.lastLogin),
            Promocodes: values?.promoCodes?.length > 0 ? values?.promoCodes : [],
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={locRadio ? "location" : "destination"}
                  name="radio-buttons-group"
                  value={locRadio ? "location" : "destination"}
                  onChange={(event, value) => {
                    if (value === "location") {
                      setLocRadio(true);
                      setFieldValue("destinations", []);
                      setSelectedDestinations([]);
                      setSelectedLocation("");
                      setFieldValue("locDestinations.destinations", "");
                    } else {
                      setLocRadio(false);
                      setFieldValue("location", "");
                      setSelectedLocation("");
                      setSelectedDestinations([]);
                      setFieldValue("locDestinations.location", "");
                    }
                  }}
                >
                  <FormControlLabel value="location" control={<Radio />} label="Location" />
                  <FormControlLabel value="destination" control={<Radio />} label="Destination" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {locRadio ? (
              <Grid item xs={12} sm={6}>
                <LocationPicker
                  conciergeUserPage
                  setFieldValue={setFieldValue}
                  location={{ label: location.label, name: "locDestinations.location" }}
                  locationOnly
                  selectedLocation={selectedLocation?.name && selectedLocation}
                  handleLocationChange={handleLocationChange}
                  error={errors.locDestinations?.location && touched.location}
                  success={locationV?.name && !errors?.locDestinations?.location}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <DestinationPicker
                  isUser
                  destination={destinations}
                  handleChange={handleDestinationChange}
                  setFieldValue={setFieldValue}
                  selectedDestination={selectedDestinations || {}}
                />

                {errors?.locDestinations?.destinations && touched.destinations && (
                  <Danger validation={errors?.locDestinations?.destinations} />
                )}
              </Grid>
            )}
          </Grid>

          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={balance.type}
                label={balance.label}
                name={balance.name}
                value={balanceV}
                placeholder={balance.placeholder}
                error={errors.balance && touched.balance}
                success={balanceV?.length > 0 && !errors.balance}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={commission.type}
                label={commission.label}
                name={commission.name}
                value={commissionV}
                placeholder={commission.placeholder}
                error={errors.commission && touched.commission}
                success={commissionV?.length > 0 && !errors.commission}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PromocodesPicker
                promoCodes={{ name: "promocode", Label: "Promocode" } || promoCodes}
                promocodesV={promoCodesV}
                setFieldValue={setFieldValue}
                handleChange={handlePromocodesChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={reference.type}
                label={reference.label}
                name={reference.name}
                value={referenceV}
                placeholder={reference.placeholder}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// Setting default props
AdditionalConciergeInfo.defaultProps = {
  edit: false,
  selectedLocation: {},
  handleLocationChange: () => {},
  handlePromocodesChange: () => {},
  selectedDestinations: [],
  handleDestinationChange: () => {},
  setSelectedDestinations: () => {},
  setSelectedLocation: () => {},
};

// typechecking props for AdditionalCuratorInfo
AdditionalConciergeInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  selectedLocation: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  handlePromocodesChange: PropTypes.func,
  selectedDestinations: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
  ]),
  handleDestinationChange: PropTypes.func,
  setSelectedDestinations: PropTypes.func,
  setSelectedLocation: PropTypes.func,
};

export default AdditionalConciergeInfo;
