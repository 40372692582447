/* eslint-disable no-underscore-dangle */
import {
  SET_GLOBAL_BOX_LOADING,
  GLOBAL_BOX_ADD_SUCCESS,
  GLOBAL_BOX_FAIL,
  GET_GLOBAL_BOXES,
  CLEAR_ERRORS,
  DELETE_GLOBAL_BOX,
  SORT_GLOBAL_BOXES,
  LOGOUT,
  GET_BOXES,
  GET_LOCATIONBOXES,
  ADD_LOCATIONBOX,
  REMOVE_LOCATIONBOX,
  SORT_LOCATIONBOXES,
  ADD_BOX,
  SORT_BOXES,
  BOXES_FAIL,
  LOCATIONBOXES_FAIL,
  REMOVE_BOX,
} from "../actions/Types";

const initialState = {
  loading: false,
  globalBoxes: [],
  error: null,
  added: false,
  globalBox: null,
  locationBoxes: [],
  boxes: [],
  boxType: {},
};
const variable = (state = initialState, action) => {
  // function upsert(array, item) {
  //   // (1)
  //   // make a copy of the existing array
  //   const newArray = array.slice();

  //   const i = newArray.findIndex((_item) => _item._id === item._id);
  //   if (i > -1) {
  //     newArray[i] = item;
  //     return newArray;
  //   }
  //   // (2)

  //   newArray.unshift(item);
  //   return newArray;
  // }

  switch (action.type) {
    case GLOBAL_BOX_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        globalBoxes: [...state.globalBoxes, action.payload.data],
      };
    case SORT_GLOBAL_BOXES:
      return {
        ...state,
        added: true,
        globalBoxes: action.payload.data,
      };
    case GET_GLOBAL_BOXES:
      return {
        ...state,
        loading: false,
        error: null,
        globalBoxes: action.payload.data,
        added: false,
        globalBox: null,
      };
    case DELETE_GLOBAL_BOX:
      return {
        ...state,
        loading: false,
        error: null,
        added: true,
        globalBoxes: state.globalBoxes.filter((box) => box._id !== action.payload),
      };
    case GLOBAL_BOX_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_GLOBAL_BOX_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_BOXES:
      return {
        ...state,
        loading: false,
        error: null,
        boxes: action.payload.data,
      };
    case GET_LOCATIONBOXES:
      return {
        ...state,
        loading: false,
        error: null,
        locationBoxes: action.payload.data,
      };

    case ADD_LOCATIONBOX:
      return {
        ...state,
        loading: false,
        error: null,
        locationBoxes: [...state.locationBoxes, action.payload.data],
      };
    case REMOVE_LOCATIONBOX:
      return {
        ...state,
        loading: false,
        error: null,
        added: true,
        locationBoxes: state.locationBoxes.filter((locBox) => locBox._id !== action.payload),
      };
    case SORT_LOCATIONBOXES:
      return {
        ...state,
        added: true,
        locationBoxes: action.payload.data,
      };
    case ADD_BOX:
      return {
        ...state,
        loading: false,
        error: null,
        boxes: [...state.boxes, action.payload.data],
      };
    case SORT_BOXES:
      return {
        ...state,
        added: true,
        boxes: action.payload.data,
      };

    case BOXES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        boxes: [],
      };
    case LOCATIONBOXES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        locationBoxes: [],
      };

    case REMOVE_BOX:
      return {
        ...state,
        loading: false,
        error: null,
        added: true,
        boxes: state.boxes.filter((box) => box._id !== action.payload),
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        globalBoxes: [],
        error: null,
        added: false,
        globalBox: null,
      };

    default:
      return state;
  }
};
export default variable;
