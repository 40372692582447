/* eslint-disable react/no-unescaped-entities */
// import React from "react";
// import { Body, Container, Section, Html, Text } from "@react-email/components";
// import { Tailwind } from "@react-email/tailwind";

// import FooterSection from "../FooterSection";
// import IfYouNeedSupportSection from "../IfYouNeedSupport";
// import LogoSection from "../LogoSection/index";
// import DynamicTicketCard from "../DynamicTicketCard";
// import { defaultProps, propTypes } from "./Proptypes";
// import HeadSection from "../HeadSection";

// const BookingConfirmationEmail = ({ user, token, data }) => (
//   <Html>
//     <HeadSection />
//     <Tailwind>
//       <Body className="bg-gray-100 font-sans leading-relaxed">
//         <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg mb-2">
//           <LogoSection />
//           <Section className="text-left bg-white p-6 pl-8 text-black">
//             <Text className="text-[35px] leading-[26.5px] font-bold mb-2">
//               Thank you for your booking!
//             </Text>
//             <Text className="text-[24px] leading-[26.5px] font-normal mb-2">
//               Welcome {user.name},
//             </Text>
//             <Text className="text-[24px] leading-[26.5px] font-normal mb-0">Good News!</Text>
//             <Text className="text-[24px] leading-[26.5px] font-normal mt-0 mb-4">
//               Your booking is confirmed
//             </Text>
//             <Text className="text-[20px] leading-[23px] font-normal mb-1">
//               Your voucher(s) can be found attached to this e-mail.
//             </Text>
//           </Section>
//           <DynamicTicketCard
//             isMoreInfo
//             isProvider
//             isReminder
//             data={data}
//             token={token}
//             user={user}
//             noDescription
//             isDiscount
//           />
//           <Text className="text-[14px] leading-[16px] font-normal pl-8 mb-4">
//             {data?.cancellationPolicy?.description || ""}
//           </Text>
//           <hr className="border-t-0  border-[#394A43]" />
//           <IfYouNeedSupportSection />
//           <FooterSection />
//         </Container>
//       </Body>
//     </Tailwind>
//   </Html>
// );

// BookingConfirmationEmail.defaultProps = defaultProps;

// BookingConfirmationEmail.propTypes = propTypes;

// export default BookingConfirmationEmail;
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Section, Html, Text } from "@react-email/components";

import FooterSection from "../FooterSection";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import LogoSection from "../LogoSection/index";
import DynamicTicketCard from "../DynamicTicketCard";
import { defaultProps, propTypes } from "./Proptypes";
import HeadSection from "../HeadSection";

const BookingConfirmationEmail = ({ user, token, data }) => (
  <Html>
    <HeadSection />
    <Body
      style={{
        backgroundColor: "#f7fafc", // Equivalent to 'bg-gray-100'
        fontFamily: "Arial, sans-serif", // Equivalent to 'font-sans'
        lineHeight: "1.625", // Equivalent to 'leading-relaxed'
        margin: "0",
        padding: "0",
      }}
    >
      <Container
        style={{
          backgroundColor: "#ffffff", // 'bg-white'
          borderRadius: "6px", // 'rounded-md'
          marginLeft: "auto", // 'mx-auto'
          marginRight: "auto", // 'mx-auto'
          padding: "0", // No padding, directly contained
          maxWidth: "600px", // 'max-w-lg'
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // 'shadow-md'
          marginBottom: "0.5rem", // 'mb-2'
        }}
      >
        <LogoSection />
        <Section
          style={{
            textAlign: "left", // 'text-left'
            backgroundColor: "#ffffff", // 'bg-white'
            padding: "1.5rem 0 1.5rem 2rem", // 'p-6' and 'pl-8'
            color: "#000000", // 'text-black'
          }}
        >
          <Text
            style={{
              fontSize: "35px", // 'text-[35px]'
              lineHeight: "26.5px", // 'leading-[26.5px]'
              fontWeight: "bold", // 'font-bold'
              marginBottom: "0.5rem", // 'mb-2'
            }}
          >
            Thank you for your booking!
          </Text>
          <Text
            style={{
              fontSize: "24px", // 'text-[24px]'
              lineHeight: "26.5px", // 'leading-[26.5px]'
              fontWeight: "normal", // 'font-normal'
              marginBottom: "0.5rem", // 'mb-2'
            }}
          >
            Welcome {user.name},
          </Text>
          <Text
            style={{
              fontSize: "24px", // 'text-[24px]'
              lineHeight: "26.5px", // 'leading-[26.5px]'
              fontWeight: "normal", // 'font-normal'
              marginBottom: "0",
            }}
          >
            Good News!
          </Text>
          <Text
            style={{
              fontSize: "24px", // 'text-[24px]'
              lineHeight: "26.5px", // 'leading-[26.5px]'
              fontWeight: "normal", // 'font-normal'
              marginTop: "0",
              marginBottom: "1rem", // 'mb-4'
            }}
          >
            Your booking is confirmed.
          </Text>
          <Text
            style={{
              fontSize: "20px", // 'text-[20px]'
              lineHeight: "23px", // 'leading-[23px]'
              fontWeight: "normal", // 'font-normal'
              marginBottom: "0.25rem", // 'mb-1'
            }}
          >
            Your voucher(s) can be found attached to this e-mail.
          </Text>
        </Section>

        <DynamicTicketCard
          isMoreInfo
          isProvider
          isReminder
          data={data}
          token={token}
          user={user}
          noDescription
          isDiscount
        />

        <Text
          style={{
            fontSize: "14px", // 'text-[14px]'
            lineHeight: "16px", // 'leading-[16px]'
            fontWeight: "normal", // 'font-normal'
            paddingLeft: "2rem", // 'pl-8'
            marginBottom: "1rem", // 'mb-4'
          }}
        >
          {data?.cancellationPolicy?.description || ""}
        </Text>

        <hr
          style={{
            borderTop: "none", // 'border-t-0'
            borderBottom: "1px solid #394A43", // 'border-[#394A43]'
            margin: "0 0.5rem",
          }}
        />

        <IfYouNeedSupportSection />
        <FooterSection />
      </Container>
    </Body>
  </Html>
);

BookingConfirmationEmail.defaultProps = defaultProps;

BookingConfirmationEmail.propTypes = propTypes;

export default BookingConfirmationEmail;
