import React from "react";
import PropTypes from "prop-types";
import ForgotPasswordEmail from "..";

const LcmForgetPassword = ({ token, user }) => (
  <ForgotPasswordEmail isCode={false} user={user} token={token} />
);

LcmForgetPassword.defaultProps = {
  user: {},
  token: "https://lokalee.app",
};

LcmForgetPassword.propTypes = {
  user: PropTypes.instanceOf(Object),
  token: PropTypes.string,
};

export default LcmForgetPassword;
