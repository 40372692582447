import React from "react";
import PropTypes from "prop-types";
import ForgotPasswordEmail from "..";

const WebForgetPassword = ({ token, user }) => (
  <ForgotPasswordEmail isCode user={user} token={token} />
);
WebForgetPassword.defaultProps = {
  user: {},
  token: "961",
};

WebForgetPassword.propTypes = {
  user: PropTypes.instanceOf(Object),
  token: PropTypes.string,
};

export default WebForgetPassword;
