// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect } from "react";

// formik components
// import { ErrorMessage, Field } from "formik";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";

import { useDispatch, useSelector } from "react-redux";
import { getPartners, setLoading } from "store/actions/partnerAction";
import MyAutoComplete from ".";

const PartnerPicker = ({
  partner,
  handleChange,
  setFieldValue,
  selectedPartner,
  disabled,
  ...rest
}) => {
  const dispatch = useDispatch();

  const partners = useSelector((state) => state.partners.partners);

  // get partners
  useEffect(() => {
    dispatch(setLoading());
    dispatch(getPartners());
  }, []);

  return (
    <MyAutoComplete
      {...rest}
      data={partners}
      // val={partnerV?.name || partnerV}
      idName="partner"
      label={partner?.label}
      name={partner?.name}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      selectedOption={selectedPartner}
      disabled={disabled}
    />
  );
};

// Setting default props
PartnerPicker.defaultProps = {
  selectedPartner: {},
  disabled: false,
};

// typechecking props for PartnerPicker
PartnerPicker.propTypes = {
  partner: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  selectedPartner: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
};

export default PartnerPicker;
