/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Html, Section, Text, Button, Img } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import PropTypes from "prop-types";
import FooterSection from "../FooterSection";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import LogoSection from "../LogoSection";
import HeadSection from "../HeadSection";

const ForgotPasswordEmail = ({ isCode, token }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100">
        <Container className="bg-white rounded-md shadow-md mx-auto  max-w-lg">
          <LogoSection />
          <Section className="text-center p-0 rounded-none">
            <Img
              src="https://email-assets.lokalee.app/email/emailImages/cover-zoomed.png"
              alt="Reset Password"
              className="w-full h-auto mx-auto m-0 rounded-none"
            />
          </Section>
          <Section className="text-center bg-[#8C8C8C] p-5 text-white mb-6">
            <Text className="text-[25px] leading-[26.5px] font-bold text-white mb-2">
              Forgot your password?
            </Text>
            <Text className="text-[14px] leading-[18px] font-normal mb-4 text-white">
              {isCode ? (
                <>
                  That's okay! It happens.
                  <br />
                  Please enter the code below to reset your password.
                </>
              ) : (
                <>
                  That's okay! It happens.
                  <br />
                  Click on the button below to reset your password.
                </>
              )}
            </Text>
            {isCode ? (
              <Text className="text-[20px] leading-[12px] font-normal mb-4">{token}</Text>
            ) : (
              <Button
                className="bg-white text-black text-[14px] leading-[14px] font-semibold  py-2 px-4 rounded-md  mx-auto mb-4"
                href={token}
              >
                Reset Password
              </Button>
            )}
            <Text className="text-[14px] leading-[18px] font-normal text-white">
              If you did not request a password reset, please just ignore this email.
            </Text>
          </Section>
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);

ForgotPasswordEmail.defaultProps = {
  isCode: false,
  token: "https://lokalee.app",
};

ForgotPasswordEmail.propTypes = {
  isCode: PropTypes.bool,
  token: PropTypes.string,
};

export default ForgotPasswordEmail;
