/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import setAlert from "store/actions/alertActions";

import {
  getOnePromoCode,
  clearErrors,
  updatePromoCode,
  setLoading,
} from "store/actions/promoCodeActions";
import { dateFormatWithDash, useHandleError } from "components/helper";
import form from "../new-promoCodes/schemas/form";
import initialValues from "../new-promoCodes/schemas/initialValues";
import validations from "../new-promoCodes/schemas/validations";
import PromoCodeDetail from "./promoCodesDetail";

const EditPromoCode = () => {
  const { formId, formField } = form;
  const { promoCode_id } = useParams();
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // selector
  const promoCode = useSelector((state) => state.promoCodes.promoCode);
  const error = useSelector((state) => state.promoCodes.error);
  const added = useSelector((state) => state.promoCodes.added);
  const user = useSelector((state) => state.users.user);

  // state
  const [initialState, setInitialState] = useState(initialValues);
  const [promoCodeDetailPage, setPromoCodeDetailPage] = useState(true);
  const [selectedLocations, setSelectedLocations] = useState(promoCode?.locations);
  const [tabValue, setTabValue] = useState(0);

  // useEffect
  useEffect(() => {
    if (promoCode_id) {
      dispatch(getOnePromoCode(promoCode_id));
    }
  }, [promoCode_id, added]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  // We cant use the campaign as is because of address picker and map picker
  useEffect(() => {
    if (promoCode) {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      const manipulated = {};
      manipulated.title = promoCode.title;
      manipulated.description = promoCode.description ? promoCode.description : "";
      manipulated.method = promoCode.method ? promoCode.method : "code";
      manipulated.code = promoCode.method === "auto" ? undefined : promoCode.code;
      manipulated.type = promoCode.type ? promoCode.type : "percentage";
      manipulated.value = promoCode.value ? promoCode.value : "";
      manipulated.destinations = promoCode.destinations
        ? promoCode.destinations?.map((destination) => ({
            label: destination?.destinationName,
            value: destination?._id,
          }))
        : [];
      manipulated.locations = promoCode.locations ? promoCode.locations : [];
      manipulated.tickets = promoCode.tickets ? promoCode.tickets : [];
      manipulated.appliesTo = promoCode.appliesTo ? promoCode.appliesTo : "all";
      manipulated.minimumType = promoCode.minimumType ? promoCode.minimumType : "none";
      manipulated.minimumValue = promoCode.minimumValue ? promoCode.minimumValue : "";
      manipulated.eligibility = promoCode.eligibility ? promoCode.eligibility : "all";
      // enDate.setDate(enDate.getDate() - 1);
      manipulated.startDate = promoCode?.startDate
        ? dateFormatWithDash(promoCode?.startDate)
        : dateFormatWithDash(today);
      manipulated.endDate = promoCode?.endDate
        ? dateFormatWithDash(promoCode?.endDate)
        : dateFormatWithDash(tomorrow);
      manipulated._id = promoCode._id;
      setInitialState(manipulated);
      setSelectedLocations(promoCode?.locations);
      //   if (promoCode.logo) _setImage(promoCode.logo);
    }
  }, [promoCode]);
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values) => {
    // const updatedValues = { ...values };

    const {
      title = "",
      description = "",
      method = "code",
      code = "",
      type = "percentage",
      value = "",
      destinations = [],
      locations = [],
      tickets = [],
      appliesTo = "all",
      minimumType = "none",
      minimumValue = "",
      eligibility = "all",
      startDate = "",
      endDate = "",
    } = values;

    const destinationsV = destinations
      .filter((destination) => destination && destination.value)
      .map((destination) => destination.value);

    const locationsV = locations
      .filter((location) => location && location?._id)
      .map((location) => location?._id);

    const ticketsV = tickets
      .filter((item) => item && (item._id || item.value)) // Check for _id or value property
      .map((item) => item._id || item.value);
    const baseValues = { _id: values?._id, title };
    let additionalValues = {};
    if (!promoCodeDetailPage) {
      additionalValues = {
        description,
        method,
        code,
        type,
        value,
        destinations: destinationsV,
        locations: locationsV,
        tickets: ticketsV,
        appliesTo,
        minimumType,
        minimumValue,
        eligibility,
        startDate,
        endDate,
      };
    }
    const updatedValues = { ...baseValues, ...additionalValues };
    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    dispatch(updatePromoCode(updatedValues));
    setPromoCodeDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleLocationChange = (value) => {
    if (value) {
      setSelectedLocations(value);
    } else {
      setSelectedLocations([]);
    }
  };

  function getStepContent(type, formData) {
    if (type === "promoCodeInfo") {
      return (
        <PromoCodeDetail
          type={type}
          formData={formData}
          detailPage={promoCodeDetailPage}
          setDetailPage={setPromoCodeDetailPage}
          handleEdit={() => {
            formData.resetForm();
            setPromoCodeDetailPage(false);
          }}
          handleLocationChange={handleLocationChange}
          selectedLocations={selectedLocations}
          setSelectedLocations={setSelectedLocations}
          destinationsV={promoCode?.destination}
        />
      );
    }
    return null;
  }
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOnePromoCode(promoCode_id));
    }
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/promoCodes?`;
      navigate(url);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={validations[0]}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      noImg
                      user={promoCode}
                      //   detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      handleNavigation={() => handleNavigation(user?.role)}
                    />
                    <>
                      {["promoCodeInfo"].map((item, index) => (
                        <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                          <MDBox p={2}>
                            <Grid container spacing={3} alignItems="center">
                              <Grid item xs={12}>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                  {getStepContent(item, {
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                    setFieldValue,
                                    resetForm,
                                  })}
                                </MDBox>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </Card>
                      ))}
                    </>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditPromoCode;
