/* eslint-disable no-underscore-dangle */
import {
  SET_BANNER_LOADING,
  CLEAR_ERRORS,
  GET_BANNERS,
  ADD_BANNER,
  REMOVE_BANNER,
  GET_ALL_BANNERS,
  LOGOUT,
  GET_ONE_BANNER,
  UPDATE_BANNER,
  BANNER_FAIL,
} from "../actions/Types";

const initialState = {
  loading: false,
  banners: [],
  error: null,
  added: false,
  banner: {},
};
const variable = (state = initialState, action) => {
  function upsert(array, item) {
    // (1)
    // make a copy of the existing array
    const newArray = array.slice();

    const i = newArray.findIndex((_item) => _item._id === item._id);
    if (i > -1) {
      newArray[i] = item;
      return newArray;
    }
    // (2)

    // newArray.unshift(item);
    return newArray;
  }
  switch (action.type) {
    case GET_ALL_BANNERS:
      return {
        ...state,
        loading: false,
        error: null,
        added: false,
        banners: action.payload.data,
      };
    case GET_BANNERS:
      return {
        ...state,
        loading: false,
        error: null,
        banners: action.payload.data,
      };
    case BANNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ONE_BANNER:
      return {
        ...state,
        loading: false,
        error: null,
        banner: action.payload.data,
        added: false,
      };
    case ADD_BANNER:
      return {
        ...state,
        loading: false,
        added: true,
        error: null,
        banners: upsert(state.banners, action.payload.data),
      };
    case REMOVE_BANNER:
      return {
        ...state,
        loading: false,
        error: null,
        banners: state.banners.filter((banner) => banner._id !== action.payload),
      };
    case UPDATE_BANNER:
      return {
        ...state,
        banners: state.banners.map((banner) =>
          banner._id === action.payload.data.data._id ? action.payload.data.data : banner
        ),
        loading: false,
        added: true,
      };
    case SET_BANNER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        cities: [],
        error: null,
        added: false,
        banner: null,
      };

    default:
      return state;
  }
};
export default variable;
