/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import timeList from "../TimesPicker/TimesList";

const TimePicker = ({ handleTimeChange, time, setFieldValue }) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });

  const findTime = (timeValue) => timeList.find((timeVal) => timeVal.value === timeValue) || null;

  const timeValue = typeof time === "string" ? findTime(time) : time;

  return (
    <SelectF
      key={time}
      options={timeList}
      placeholder="Select ..."
      value={typeof time === "string" ? timeValue : time}
      onChange={(event) => {
        if (event) {
          setFieldValue("timeCutoff", event);
          handleTimeChange(event);
        } else {
          setFieldValue("timeCutoff", {});
          handleTimeChange(event);
        }
      }}
      getOptionValue={(option) => `${option.value}`}
      getOptionLabel={(option) => `${option.label}`}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      isSearchable
      menuPortalTarget={document.body}
    />
  );
};

// Setting default props
TimePicker.defaultProps = {
  handleTimeChange: () => {},
  time: {},
  setFieldValue: () => {},
};

// typechecking props for TimePicker
TimePicker.propTypes = {
  handleTimeChange: PropTypes.func,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setFieldValue: PropTypes.func,
};

export default TimePicker;
