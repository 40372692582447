/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewPartner page components
import FormField from "components/FormField";
import DetailList from "components/DetailList";

const ContactInfo = ({ formData, detailPage, editPage }) => {
  const { formField, values, errors, touched } = formData;
  const { contactEmail, contactName, contactPhone } = formField;
  const {
    contactEmail: contactEmailV,
    contactName: contactNameV,
    contactPhone: contactPhoneV,
  } = values;

  return (
    <MDBox>
      {!editPage ? (
        <MDBox lineHeight={0}>
          <MDTypography variant="h5">Contact info</MDTypography>
        </MDBox>
      ) : (
        ""
      )}
      {detailPage && editPage ? (
        <DetailList
          data={{
            contactEmail: values?.contactEmail,
            contactName: values?.contactName,
            contactPhone: values?.contactPhone,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={contactName.type}
                label={contactName.label}
                name={contactName.name}
                value={contactNameV}
                placeholder={contactName.placeholder}
                error={errors.contactName && touched.contactName}
                success={contactNameV.length > 0 && !errors.contactName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={contactEmail.type}
                label={contactEmail.label}
                name={contactEmail.name}
                value={contactEmailV}
                placeholder={contactEmail.placeholder}
                error={errors.contactEmail && touched.contactEmail}
                success={contactEmailV.length > 0 && !errors.contactEmail}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={contactPhone.type}
                label={contactPhone.label}
                name={contactPhone.name}
                value={contactPhoneV}
                placeholder={contactPhone.placeholder}
                error={errors.contactPhone && touched.contactPhone}
                success={contactPhoneV.length > 0 && !errors.contactPhone}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

ContactInfo.defaultProps = {
  detailPage: true,
  editPage: false,
};

// typechecking props for ContactInfo
ContactInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  editPage: PropTypes.bool,
};

export default ContactInfo;
