// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import { capitalizeFirstLetter } from "components/helper";
import PropTypes from "prop-types";

const StopsTypePicker = ({ type, typeV, setFieldValue, ...rest }) => (
  <Autocomplete
    id="roles"
    // defaultValue={typeV}
    options={["Venues", "Tickets"]}
    onChange={(e, value) => {
      if (value) {
        setFieldValue("type", value.toLowerCase());
      } else {
        setFieldValue("type", null);
      }
    }}
    defaultValue={null}
    value={typeV ? capitalizeFirstLetter(typeV) : null}
    // value={capitalizeFirstLetter(typeV)}
    getOptionLabel={(option) => option || null}
    isOptionEqualToValue={(option, value) => option.toLowerCase() === value.toLowerCase()}
    renderInput={(params) => (
      <FormField
        {...params}
        {...rest}
        label={type?.label}
        name={type?.name}
        onChange={params.onChange}
      />
    )}
  />
);

// Setting default props
StopsTypePicker.defaultProps = {
  typeV: null,
};

// typechecking props for StopsTypePicker
StopsTypePicker.propTypes = {
  type: PropTypes.instanceOf(Object).isRequired,
  typeV: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

export default StopsTypePicker;
