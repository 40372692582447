/* eslint-disable no-underscore-dangle */
import {
  SET_JOB_LOADING,
  JOB_ADD_SUCCESS,
  JOB_FAIL,
  CLEAR_ERRORS,
  DELETE_JOB,
  GET_ONE_JOB,
  UPDATE_JOB,
  LOGOUT,
  SET_JOB_EMPTY,
} from "../actions/Types";

const initialState = {
  loading: false,
  jobs: [],
  error: null,
  added: false,
  job: {},
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case JOB_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        jobs: [...state.jobs, action.payload.data.data],
        added: true,
        job: {},
      };
    case GET_ONE_JOB:
      return {
        ...state,
        loading: false,
        error: null,
        job: action.payload.data.data,
        added: false,
      };

    case DELETE_JOB:
      return {
        ...state,
        loading: false,
        jobs: state.jobs.filter((job) => job._id !== action.payload.data.data._id),
      };
    case SET_JOB_EMPTY:
      return {
        ...state,
        loading: false,
        error: null,
        job: {},
      };
    case UPDATE_JOB:
      return {
        ...state,
        added: true,
        jobs: state.jobs.map((job) =>
          job._id === action.payload.data.data._id ? action.payload.data.data : job
        ),
      };
    case JOB_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_JOB_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        jobs: [],
        error: null,
        added: false,
        job: {},
      };

    default:
      return state;
  }
};
export default variable;
