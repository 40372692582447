/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import { List, ListItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { dateFormatWithDash } from "components/helper";

const AvailabilitieSchedulesDetailsList = ({ data }) => {
  const listItemStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px",
  };

  const columnStyle = {
    padding: "3px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "85px",
  };

  const renderHeader = () => (
    <ListItem style={listItemStyle}>
      <div style={columnStyle}>
        <strong>Age Band</strong>
      </div>
      <div style={columnStyle}>
        <strong>Min Qty</strong>
      </div>
      <div style={columnStyle}>
        <strong>Max Qty</strong>
      </div>
      <div style={columnStyle}>
        <strong>Retail Price</strong>
      </div>
      <div style={columnStyle}>
        <strong>Net Price</strong>
      </div>
      <div style={columnStyle}>
        <strong>Start Date</strong>
      </div>
      <div style={columnStyle}>
        <strong>End Date</strong>
      </div>
      <div style={columnStyle}>
        <strong>Days</strong>
      </div>
      <div style={columnStyle}>
        <strong>Times</strong>
      </div>
    </ListItem>
  );

  const renderData = (pricingDetail, season, pricingRecord) => (
    <ListItem
      key={`detail-${pricingDetail.ageBand}-${pricingDetail.startDate}`}
      style={listItemStyle}
    >
      <Tooltip title={pricingDetail.ageBand} key={`ageBand-${pricingDetail.ageBand}`}>
        <span style={columnStyle}>{pricingDetail.ageBand}</span>
      </Tooltip>
      <Tooltip
        title={pricingDetail.minTravelers}
        key={`minTravelers-${pricingDetail.minTravelers}`}
      >
        <span style={columnStyle}>{pricingDetail.minTravelers}</span>
      </Tooltip>
      <Tooltip
        title={pricingDetail.maxTravelers}
        key={`maxTravelers-${pricingDetail.maxTravelers}`}
      >
        <span style={columnStyle}>{pricingDetail.maxTravelers}</span>
      </Tooltip>
      <Tooltip
        title={pricingDetail?.price?.original?.recommendedRetailPrice}
        key={`recommendedRetailPrice-${pricingDetail?.price?.original?.recommendedRetailPrice}`}
      >
        <span style={columnStyle}>{pricingDetail?.price?.original?.recommendedRetailPrice}</span>
      </Tooltip>
      <Tooltip
        title={pricingDetail?.price?.original?.partnerNetPrice}
        key={`partnerNetPrice-${pricingDetail?.price?.original?.partnerNetPrice}`}
      >
        <span style={columnStyle}>{pricingDetail?.price?.original?.partnerNetPrice}</span>
      </Tooltip>
      <Tooltip
        title={dateFormatWithDash(season.startDate)}
        key={`startDate-${dateFormatWithDash(season.startDate)}`}
      >
        <span style={columnStyle}>{dateFormatWithDash(season.startDate)}</span>
      </Tooltip>
      <Tooltip
        title={dateFormatWithDash(season.endDate)}
        key={`endDate-${dateFormatWithDash(season.endDate)}`}
      >
        <span style={columnStyle}>{dateFormatWithDash(season.endDate)}</span>
      </Tooltip>

      <Tooltip
        title={pricingRecord?.daysOfWeek.join(", ")}
        key={`daysOfWeek-${pricingRecord?.daysOfWeek.join(", ")}`}
      >
        <span style={columnStyle}> {pricingRecord.daysOfWeek.join(", ")}</span>
      </Tooltip>

      <Tooltip
        title={pricingRecord.timedEntries.map((timeEntry) => timeEntry.startTime).join(", ")}
        key={`time-${pricingRecord.timedEntries
          .map((timeEntry) => timeEntry.startTime)
          .join(", ")}`}
      >
        <span style={columnStyle}>
          {pricingRecord.timedEntries.map((timeEntry) => timeEntry.startTime).join(", ")}
        </span>
      </Tooltip>
    </ListItem>
  );

  return (
    <List>
      {renderHeader()}
      {data?.map((item) =>
        item?.seasons?.map((season) =>
          season?.pricingRecords?.map((pricingRecord) =>
            pricingRecord?.pricingDetails?.map((pricingDetail) =>
              renderData(pricingDetail, season, pricingRecord)
            )
          )
        )
      )}
    </List>
  );
};

AvailabilitieSchedulesDetailsList.defaultProps = {
  data: [],
};

AvailabilitieSchedulesDetailsList.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string,
      type: PropTypes.string,
      seasons: PropTypes.arrayOf(
        PropTypes.shape({
          pricingRecords: PropTypes.arrayOf(
            PropTypes.shape({
              pricingDetails: PropTypes.arrayOf(
                PropTypes.shape({
                  ageBand: PropTypes.string,
                  minTravelers: PropTypes.number,
                  maxTravelers: PropTypes.number,
                  price: PropTypes.shape({
                    original: PropTypes.shape({
                      recommendedRetailPrice: PropTypes.number,
                      partnerNetPrice: PropTypes.number,
                    }),
                  }),
                  startDate: PropTypes.string,
                  endDate: PropTypes.string,
                })
              ),
            })
          ),
        })
      ),
    })
  ),
};

export default AvailabilitieSchedulesDetailsList;
