import {
  SET_FILTER_LOADING,
  CLEAR_ERRORS,
  // UPDATE_FILTER,
  FILTER_FAIL,
  LOGOUT,
} from "../actions/Types";

const initialState = {
  loading: false,
};

const variable = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_FILTER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case LOGOUT:
      return {
        loading: false,
      };

    default:
      return state;
  }
};
export default variable;
