/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";

import { Typography } from "@mui/material";

const ItemPicker = ({
  items,
  itemsV,
  handleChange,
  setFieldValue,
  generate,
  // city,
  // inWidgetDialog,
}) => {
  const filterRec = (data) =>
    data.map((itemData) => ({
      label: itemData.title,
      value: itemData._id,
    }));
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/tickets?`;
      // if (inWidgetDialog) url += `status=ACTIVE`;
      url += `&search=${inputValue}`;
      url += "&sort=title";
      url += "&dir=asc";
      setTimeout(() => {
        // resolve();
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            const filteredData = filterRec(result.data);
            resolve(filteredData);
          })
          .catch((error) => console.log(error.message));
      }, 1000);
    });

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    promiseOptions(inputValue)
      .then((options) => {
        callback(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, 1000); // Adjust the delay time (in milliseconds) as needed

  return (
    <div>
      <Typography
        style={{
          wordBreak: "break-word",
          fontSize: "0.875rem",
          fontWeight: 400,
        }}
      >
        {items.label}
      </Typography>{" "}
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        defaultValue={itemsV}
        placeholder="Search for an Ticket"
        menuPosition="fixed"
        loadOptions={debouncedLoadOptions}
        onChange={(options) => {
          // Handle an array of selected options
          if (!options || options.length === 0) {
            setFieldValue(generate ? "itemsList" : "tickets", []);
          } else {
            // const selectedValues = options.map((option) => option.value);
            setFieldValue(generate ? "itemsList" : "tickets", options);
            handleChange(options);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            // border: "none!important",
            // boxShadow: "none",
            minHeight: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

ItemPicker.defaultProps = {
  itemsV: [],
  handleChange: () => {},
  items: {},
  generate: false,
  // city: "",
  // inWidgetDialog: false,
};

ItemPicker.propTypes = {
  items: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  itemsV: PropTypes.instanceOf(Array),
  generate: PropTypes.bool,
  // inWidgetDialog: PropTypes.bool,
  // city: PropTypes.string,
};

export default ItemPicker;
