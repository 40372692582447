const form = {
  formId: "new-locationReport-form",
  formField: {
    // location report page

    location: {
      name: "location",
      label: "Location",
      errorMsg: "Location is required!",
    },
  },
};

export default form;
