/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Section, Html, Text } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import LogoSection from "../../LogoSection";
import IfYouNeedSupportSection from "../../IfYouNeedSupport";
import FooterSection from "../../FooterSection";
import DynamicTicketCard from "../../DynamicTicketCard";
import { defaultProps, propTypes } from "./Proptypes";
import HeadSection from "../../HeadSection";

const ManualBookingConfirmationEmail = ({ user, token, data }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100 font-sans leading-relaxed">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-left bg-white p-6 pl-8 text-black">
            <Text className="text-[35px] leading-[26.5px] font-bold mb-2">
              Booking Pending Confirmation
            </Text>
            <Text className="text-[24px] leading-[26.5px] font-normal mb-2">
              Welcome {user.name},
            </Text>
            <Text className="text-[24px] leading-[26.5px] font-normal">
              You will receive a confirmation email when your booking is confirmed.
            </Text>
            <Text className="text-[20px] leading-[23px] font-normal mb-1">
              A preview of your bookings can be found below:
            </Text>
          </Section>
          <DynamicTicketCard isMoreInfo isCancel data={data} totalPrice token={token} />
          <Text className="text-[14px] leading-[16px] font-normal pl-8 mb-4">
            {data?.cancellationPolicy?.description || ""}
          </Text>
          <hr className="border-t-0  border-[#394A43]" />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);
ManualBookingConfirmationEmail.defaultProps = defaultProps;

ManualBookingConfirmationEmail.propTypes = propTypes;

export default ManualBookingConfirmationEmail;
