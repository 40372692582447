import React from "react";

// react-router components
import { Navigate, Routes, Route } from "react-router-dom";

// LCM admin routes - user role = admin required
import routes from "admin.routes";
import Admin from "./Admin";

const AdminRoutes = () => {
  // const isAuthenticated = useSelector((state) => state.users.isAuthenticated);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={<Admin>{route.component}</Admin>}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <Routes>
      {getRoutes(routes)}
      <Route path="*" element={<Navigate to="/dashboards" />} />
    </Routes>
  );
};

export default AdminRoutes;
