/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Section, Html, Text } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import FooterSection from "../FooterSection";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import LogoSection from "../LogoSection";
import DynamicTicketCard from "../DynamicTicketCard";
import { defaultProps, propTypes } from "./Proptypes";
import HeadSection from "../HeadSection";

const EmailToProvider = ({ user, token, data }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100 font-sans leading-relaxed">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-start bg-white p-5 pl-8 text-black mb-6">
            <Text className="text-[25px] leading-[26.5px] font-bold mb-2">
              Greetings {user.name},
            </Text>
            <Text className="text-[20px] leading-[23px] font-bold mb-2">
              A customer just made a reservation.
            </Text>
          </Section>
          <DynamicTicketCard isGuest isMoreInfo isProvider data={data} user={user} token={token} />
          <hr className="border-t-2  border-[#394A43] mt-4" />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);
EmailToProvider.defaultProps = defaultProps;

EmailToProvider.propTypes = propTypes;

export default EmailToProvider;
