/* eslint-disable no-underscore-dangle */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { location },
} = checkout;

const validations = [
  Yup.object().shape({
    [location.name]: Yup.object().required(location.errorMsg),
  }),
];

export default validations;
