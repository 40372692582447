/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { getBanners } from "store/actions/bannerActions";
import { useEffect } from "react";
import positionsList from "./PositionsList";

const PositionPicker = ({
  handlePositionChange,
  position,
  setFieldValue,
  isDisabledPositionPicker,
}) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  const dispatch = useDispatch();
  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });
  useEffect(() => {
    dispatch(getBanners());
  }, []);

  return (
    <SelectF
      options={positionsList}
      placeholder="Select a position"
      value={position}
      onChange={(event) => {
        if (event) {
          setFieldValue("position", event);
          handlePositionChange(event);
        } else {
          setFieldValue("position", {});
          handlePositionChange(event);
        }
      }}
      getOptionValue={(option) => `${option.value}`}
      getOptionLabel={(option) => `${option.label}`}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      isSearchable
      isDisabled={isDisabledPositionPicker}
    />
  );
};

// Setting default props
PositionPicker.defaultProps = {
  handlePositionChange: () => {},
  position: {},
  setFieldValue: () => {},
  isDisabledPositionPicker: false,
};

// typechecking props for PositionPicker
PositionPicker.propTypes = {
  handlePositionChange: PropTypes.func,
  position: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func,
  isDisabledPositionPicker: PropTypes.bool,
};

export default PositionPicker;
