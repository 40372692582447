const form = {
  formId: "new-location-form",
  formField: {
    // location page
    name: {
      name: "name",
      label: "Name *",
      type: "text",
      errorMsg: "Name is required!",
      invalidMsg: "Name should have at least 1 letter!",
    },

    partner: {
      name: "partner",
      label: "Partner *",
      type: "text",
      errorMsg: "Partner is required!",
      //   invalidMsg: "Phone number is not valid / should start with (00) or (+)",
    },

    email: {
      name: "email",
      label: "Location Email",
      type: "array",
      invalidMsg: "Invalid email address!",
    },
    primaryDestination: {
      name: "primaryDestination",
      label: "Primary Destination *",
      type: "text",
      errorMsg: "Primary Destination is required!",
    },
    destinations: {
      name: "destinations",
      label: "Destinations *",
      type: "text",
      errorMsg: "Destination is required!",
      invalidMsg: "Should have at least 1 destination!",
    },
    address: {
      name: "address",
      label: "Address",
      type: "text",
      errorMsg: "Address is required!",
      invalidMsg: "Address should have at least 1 letter!",
    },
    country: {
      name: "country",
      label: "Country",
      type: "text",
    },
    serviceJobs: {
      name: "serviceJobs",
      label: "Service Jobs",
      type: "text",
    },
    jobName: {
      name: "jobName",
      label: "Job Name",
      type: "text",
    },
    jobCode: {
      name: "jobCode",
      label: "Job Code",
      type: "text",
    },

    company: {
      name: "company",
      label: "Company",
      type: "text",
    },
    system: {
      name: "system",
      label: "System",
      type: "text",
    },
    version: {
      name: "version",
      label: "Version",
      type: "text",
    },
    city: {
      name: "city",
      label: "City",
      type: "text",
      // errorMsg: "City is required!",
      // invalidMsg: "City should have at least 1 letter!",
    },
    region: {
      name: "region",
      label: "Region",
      type: "text",
    },
    lat: {
      name: "lat",
      label: "Latitude",
      type: "text",
      // errorMsg: "Lat is required!",
    },
    lng: {
      name: "lng",
      label: "Longitude",
      type: "text",
      // errorMsg: "Lng is required!",
    },
    travelDesk: {
      name: "travelDesk",
      label: "Travel Desk",
      type: "text",
    },
    numRooms: {
      name: "numRooms",
      label: "Number of Rooms",
      type: "number",
    },
    trn: {
      name: "trn",
      label: "TRN",
      type: "number",
    },

    top: {
      name: "top",
      label: "Top",
      type: "boolean",
    },

    disclaimer: {
      name: "disclaimer",
      label: "Disclaimer",
      type: "boolean",
    },
    disclaimerText: {
      name: "disclaimerText",
      label: "Editor content is empty...",
      type: "object",
      errorMsg: "Cannot save an empty disclaimer!",
    },
    showSlider: {
      name: "showSlider",
      label: "Show Sliders",
      type: "boolean",
    },
    showGuestCompanion: {
      name: "showGuestCompanion",
      label: "show Guest Companion",
      type: "boolean",
    },
    serviceJobsObj: {
      name: "serviceJobsObj",
      label: "service JobsV",
      type: "array",
    },
    slider: {
      name: "slider",
      label: "Slider",
      type: "text",
      errorMsg: "Slider must have at least 1 item!",
    },
    slideDuration: {
      name: "slideDuration",
      label: "Duration",
      type: "number",
    },
    enableServices: {
      name: "enableServices",
      label: "Facilities and services",
      type: "boolean",
    },
    dinningLabel: {
      name: "dinningLabel",
      label: "In-house dining title",
      type: "text",
    },
    serviceTitle: {
      name: "serviceTitle",
      label: "Transportation Services title",
      type: "text",
    },
    logo: {
      name: "logo",
      label: "Logo *",
      type: "text",
      errorMsg: "Logo is required!",
    },
    primary: {
      name: "primary",
      label: "Primary color",
      type: "text",
    },
    background: {
      name: "background",
      label: "Background color",
      type: "text",
    },
    button: {
      name: "button",
      label: "Button color",
      type: "text",
    },
    template: {
      name: "template",
      label: "Choose your template",
      type: "text",
    },
    images: {
      name: "images",
      label: "Images",
      type: "text",
    },
    trendTitle: {
      name: "trendTitle",
      label: "Trend Title",
      type: "text",
    },
    trendSubTitle: {
      name: "trendSubTitle",
      label: "Trend SubTitle",
      type: "text",
    },
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter",
    },
    subTitle: {
      name: "subTitle",
      label: "Subtitle *",
      type: "text",
      errorMsg: "Subtitle is required!",
      invalidMsg: "Subtitle should have at least 1 letter",
    },
    // logo: {
    //   name: "logo",
    //   label: "Logo *",
    //   type: "text",
    //   errorMsg: "Logo is required!",
    // },
    menus: {
      name: "menus",
      label: "Categories *",
      type: "array",
      errorMsg: "At least 1 category should be selected!",
    },
    chat: {
      name: "chat",
      label: "Whatsapp chat",
      type: "boolean",
    },
    exploreUrl: {
      name: "exploreUrl",
      label: "Explore Url *",
      type: "text",
      errorMsg: "Explore url is required!",
      invalidMsg: "Explore url should be a valid url!",
    },
    showWifi: {
      name: "showWifi",
      label: "Wifi",
      type: "boolean",
    },
  },
};

export default form;
