/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import axios from "axios";
import {
  SET_CITY_LOADING,
  CLEAR_ERRORS,
  CITY_ADD_SUCCESS,
  CITY_FAIL,
  UPDATE_CITY,
  SLIDE_CITY_ADD_SUCCESS,
  CITY_SLIDERS_FAIL,
  SLIDE_CITY_UPDATE,
  CHECK_CITIES,
  CHECK_CITIES_FAIL,
  CHECK_COUNTRIES,
  CHECK_COUNTRIES_FAIL,
} from "./Types";
// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Add City
export const addCity = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  form.append("top", data.top);
  form.append("name", data.city.trim());
  form.append("latitude", data.lat);
  form.append("longitude", data.lng);
  form.append("country", data.country);
  form.append("trendTitle", data.trendTitle);
  form.append("trendSubTitle", data.trendSubTitle);
  form.append("country_code", data.country_code);
  form.append("source", "lokalee");
  if (image) form.append("cover_image_url", image);
  try {
    const res = await axios.post(`${URL}/cities`, form, config);

    dispatch({
      type: CITY_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CITY_FAIL,
      payload: err?.response.data.message,
    });
  }
};

//  Update City
export const updateCity = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  if (data.top !== undefined) form.append("top", data.top);
  form.append("name", data?.city ? data?.city?.trim() : data?.name?.trim());
  if (data.latitude) form.append("latitude", data.lat ? data.lat : data.latitude);
  if (data.longitude) form.append("longitude", data.lng ? data.lng : data.longitude);
  if (data.country_name)
    form.append(
      "country_name",
      typeof data.country === "string" ? data.country : data.country.name
    );
  if (data.country)
    form.append(
      "country_name",
      typeof data.country === "string" ? data.country : data.country.name
    );
  if (data.country_code)
    form.append("country_code", data.country_code ? data.country_code : data.country.iso_code);
  if (data.source) form.append("source", "lokalee");
  if (data) form.append("cover_image_url", image);
  if (data.showSlider) form.append("showSlider", data.showSlider);
  if (data.slideDuration) form.append("slideDuration", data.slideDuration);
  if (data.trendTitle) form.append("trendTitle", data.trendTitle);
  if (data.trendSubTitle) form.append("trendSubTitle", data.trendSubTitle);

  try {
    const res = await axios.patch(`${URL}/cities/${data._id}`, form, config);

    dispatch({
      type: UPDATE_CITY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CITY_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Delete City - set active as false
export const deleteCity = (id) =>
  factory.patch({ active: false }, `${URL}/cities/${id}`, "DELETE_CITY", "CITY_FAIL");

// Get All Cities

export const getCities = () =>
  factory.get(`${URL}/cities?sort=name&dir=asc`, "GET_CITIES", "CITY_FAIL");

// get One City
export const getOneCity = (id) => factory.get(`${URL}/cities/${id}`, "GET_ONE_CITY", "CITY_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_CITY_LOADING });

export const updateCityTranslations = (data, id) =>
  factory.patch(
    {
      translations: {
        ar: data,
      },
    },
    `${URL}/cities/translations/${id}?language=ar`,
    "UPDATE_CITY_TRANSLATION",
    "CITY_FAIL"
  );

// Add City Slide
export const addCitySlide = (data, id) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("title", data.title.trim());
  form.append("subTitle", data.subTitle.trim());
  form.append("ctaLabel", data.ctaLabel.trim());
  form.append("ctaUrl", data.ctaUrl.trim());
  form.append("startDate", data.startDate ? data.startDate.split("T")[0] : "");
  form.append("endDate", data.endDate ? data.endDate.split("T")[0] : "");
  form.append("slideDuration", data.slideDuration);
  data.images?.map((file) => {
    form.append("images", file);
    return file;
  });

  try {
    const res = await axios.post(`${URL}/cities/slide/${id}`, form, config);
    dispatch({
      type: SLIDE_CITY_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CITY_SLIDERS_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Update City Slide
export const updateCitySlide = (data, id) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("title", data.title.trim());
  form.append("subTitle", data.subTitle.trim());
  form.append("ctaLabel", data.ctaLabel.trim());
  form.append("ctaUrl", data.ctaUrl.trim());
  form.append("startDate", data.startDate ? data.startDate.split("T")[0] : "");
  form.append("endDate", data.endDate ? data.endDate.split("T")[0] : "");
  form.append("slideDuration", data.slideDuration);
  form.append("index", data._id);
  data.images?.map((file) => {
    form.append("images", file);
    return file;
  });

  try {
    const res = await axios.patch(`${URL}/cities/slide/${id}`, form, config);
    dispatch({
      type: SLIDE_CITY_UPDATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CITY_SLIDERS_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Delete City Slider
export const deleteCitySlider = (id, index) =>
  factory.patch(
    { index },
    `${URL}/cities/slide/${id}?delete=true`,
    "DELETE_CITY_SLIDER",
    "CITY_SLIDERS_FAIL"
  );

export const checkCities = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios.post(`${URL}/cities/checkCity`, data, config);

    dispatch({
      type: CHECK_CITIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CHECK_CITIES_FAIL,
      payload: err?.response.data.message,
    });
  }
};

export const checkCountries = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios.post(`${URL}/cities/checkCityCountry`, data, config);
    dispatch({
      type: CHECK_COUNTRIES,
      payload: res.data,
    });
    return Promise.resolve(res.data); // Assuming res.data is meaningful for success validation
  } catch (err) {
    dispatch({
      type: CHECK_COUNTRIES_FAIL,
      payload: err?.response?.data?.message || "Error checking country", // Ensure you handle undefined cases
    });
    return Promise.reject(err?.response?.data?.message || "Error checking country");
  }
};
