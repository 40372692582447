import checkout from "./form";
import { dateFormatWithDash } from "../../../../../components/helper";

const {
  formField: { title, type, recommendation, item, trendingStartD, trendingEndD },
} = checkout;
const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

const initialValues = {
  // unique experience page
  [title.name]: "",
  [type.name]: "recommendation",
  [recommendation.name]: "",
  [item.name]: "",
  [trendingStartD.name]: dateFormatWithDash(today),
  [trendingEndD.name]: dateFormatWithDash(tomorrow),
};

export default initialValues;
