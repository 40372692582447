/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";
import Address from "pages/content/destinations/new-destination/components/Address";
import Information from "pages/content/destinations/new-destination/components/Information";

const DestinationDetail = ({
  formData,
  type,
  detailPage,
  setDetailPage,
  handleEdit,
  selectedParent,
  handleParentChange,
  handleRadioGroupChange,
  handleCurrencyChange,
  selectedCurrency,
  selectedTimeZone,
  handleTimeZoneChange,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { submitForm, resetForm, values, setFieldValue } = useFormikContext();
  const handleCardTitle = () => {
    if (type === "destinationInfo") {
      return "Destination info";
    }
    // if (type === "address") {
    return "Address";
    // }
  };

  const handleInfoCard = () => {
    if (type === "destinationInfo") {
      return (
        <Information
          formData={formData}
          editPage
          detailPage={detailPage}
          handleParentChange={handleParentChange}
          handleRadioGroupChange={handleRadioGroupChange}
          selectedParent={selectedParent}
        />
      );
    }
    // if (type === "address") {
    return (
      <Address
        formData={formData}
        editPage
        detailPage={detailPage}
        handleCurrencyChange={handleCurrencyChange}
        selectedCurrency={selectedCurrency}
        selectedTimeZone={selectedTimeZone}
        handleTimeZoneChange={handleTimeZoneChange}
      />
    );
    // }
  };

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>{handleInfoCard()}</MDBox>
    </MDBox>
  );
};

// Setting default props
DestinationDetail.defaultProps = {
  detailPage: true,
  setDetailPage: () => {},
  type: "",
  handleParentChange: () => {},
  handleRadioGroupChange: () => {},
  selectedParent: {},
  selectedCurrency: {},
  selectedTimeZone: {},
  handleCurrencyChange: () => {},
  handleTimeZoneChange: () => {},
};

// typechecking props for DestinationDetail
DestinationDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleParentChange: PropTypes.func,
  handleRadioGroupChange: PropTypes.func,
  selectedParent: PropTypes.instanceOf(Object),
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
  selectedCurrency: PropTypes.instanceOf(Object),
  handleCurrencyChange: PropTypes.func,
  handleTimeZoneChange: PropTypes.func,
  selectedTimeZone: PropTypes.instanceOf(Object),
};

export default DestinationDetail;
