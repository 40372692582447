/* eslint-disable no-underscore-dangle */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, position, image },
} = checkout;

const validations = [
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [position?.name]: Yup.object().required(position?.errorMsg),
    [image.name]: Yup.array().min(1, image.errorMsg).required(),
  }),
];

export default validations;
