import * as XLSX from "xlsx";

const CsvLocationReport = (titlesData) => {
  // Extract titles and data arrays from the titlesData object
  const { titles, data } = titlesData;
  const workbook = XLSX.utils.book_new();
  const sheetData = [];

  // Iterate over each set of data
  data?.forEach((tableData, index) => {
    // Add title for the table
    sheetData.push([titles[index] || ""]);

    // Add table headers
    const headers = [
      "Title",
      "Views",
      "Engagement",
      "Page views per session",
      "Users",
      "Abandon rate",
    ];
    sheetData.push(headers);

    // Add table rows
    tableData?.forEach((row) => {
      const rowData = [
        row?.pageTitle || "",
        row?.views || "",
        row?.engagementRate || "",
        row?.screenPageViewsPerSession || "",
        row?.totalUsers || "",
        row?.bounceRate || "",
      ];
      sheetData.push(rowData);
    });

    // Add empty rows after the table
    sheetData.push(["", "", "", "", "", ""]); // Adjust the number of empty rows as needed
  });

  // Convert data to Excel sheet
  const sheet = XLSX.utils.aoa_to_sheet(sheetData);
  XLSX.utils.book_append_sheet(workbook, sheet, "Location Reports");

  // Convert workbook to Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Download the file
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(excelFile);
  downloadLink.download = "location_reports.xlsx";
  downloadLink.click();
};

export default CsvLocationReport;
