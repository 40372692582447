import React from "react";
import { Section, Text, Link } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";

const FooterAddress = () => (
  <Tailwind>
    <Section className="bg-[#F2F2F2] text-center text-black text-[11px] leading-[15px] font-normal text-black w-full py-4">
      <span className="underline underline-offset-2">
        <Link href="https://www.business.lokalee.app/aboutus" className="text-black mx-2 underline">
          About
        </Link>
      </span>
      {" | "}
      <span className="underline underline-offset-2">
        <Link href="https://www.business.lokalee.app/careers" className="text-black mx-2 underline">
          Careers
        </Link>
      </span>
      {" | "}
      <span className="underline underline-offset-2">
        <Link href="https://www.business.lokalee.app/" className="text-black mx-2 underline">
          Partner
        </Link>
      </span>
      {" | "}
      <span className="underline underline-offset-2">
        <Link href="https://www.business.lokalee.app/" className="text-black mx-2 underline">
          Hero
        </Link>
      </span>
      <Text className="text-black text-[11px] leading-[15px] font-normal mt-2 mx-auto w-full max-w-[414px]">
        Advanced Digital Technology DWC-LLC Solutions - Dubai Hills Business Park - Building 1 -
        Office 406 – PO.BOX 390667 Dubai - United Arab Emirates
      </Text>
      <Text className="mt-2 text-black text-[11px] leading-[15px] font-normal">
        Copyright © {new Date().getFullYear()} Lokalee, All rights reserved.
      </Text>
    </Section>
    <Section className="bg-white text-center text-black text-[11px] leading-[15px] font-bold text-black w-full py-4">
      <span className="underline underline-offset-2">
        <Link
          href="https://www.business.lokalee.app/privacy-policy"
          className="text-[#394A43] mx-2"
        >
          Privacy Policy
        </Link>
      </span>
      {" | "}
      <span className="underline underline-offset-2">
        <Link
          href="https://www.business.lokalee.app/terms-conditions"
          className="text-[#394A43] mx-2"
        >
          Terms & Conditions
        </Link>
      </span>
      {" | "}
      <span className="underline underline-offset-2">
        <Link href="https://www.business.lokalee.app/contactus" className="text-[#394A43] mx-2">
          Contact Us
        </Link>
      </span>
    </Section>
  </Tailwind>
);

export default FooterAddress;
