/* eslint-disable no-underscore-dangle */

// libraries
import PropTypes from "prop-types";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";

// custom components
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& label": {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 0.9,
    height: "-webkit-fill-available",
  },
  "& .MuiInputLabel-shrink": {
    lineHeight: 1.5,
    fontSize: "1rem",
    fontWeight: 400,
  },
  "& .css-1yoptbl-MuiInputBase-root-MuiInput-root:before, &:hover": {
    borderColor: "#D2D2D2!important",
    borderWidth: "1px !important",
  },
});

const LocationUserPartner = ({
  defaultLocationId,
  location,
  handlePage,
  handleChange,
  locations,
  disabled,
  ...rest
}) => (
  <Autocomplete
    // id={idName}
    options={[...locations, {}]}
    disableClearable
    disabled={disabled}
    value={locations.find((loc) => loc._id === defaultLocationId) || null}
    onChange={(option, val) => {
      handlePage(0);
      handleChange(option, val);
    }}
    getOptionLabel={(option) => option.name || ""}
    isOptionEqualToValue={(option, value) => option?._id === value?._id}
    placeholder={locations.filter((loc) => loc._id === defaultLocationId)[0]?.name}
    renderInput={(params) => (
      <CssTextField
        {...params}
        {...rest}
        name={location.name}
        variant="outlined"
        label={location.label}
        margin="normal"
        fullWidth
      />
    )}
  />
);
// Setting default props
LocationUserPartner.defaultProps = {
  itemType: "",
  handlePage: () => {},
  handleChange: () => {},
  defaultLocationId: "",
  location: {},
  locations: [],
  disabled: false,
};

// typechecking props for LocationUserPartner
LocationUserPartner.propTypes = {
  defaultLocationId: PropTypes.string,
  itemType: PropTypes.string,
  disabled: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  locations: PropTypes.instanceOf(Array),
  handlePage: PropTypes.func,
  handleChange: PropTypes.func,
};

export default LocationUserPartner;
