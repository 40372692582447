/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import PaletteIcon from "@mui/icons-material/Palette";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import TranslateIcon from "@mui/icons-material/Translate";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { Icon, Tooltip } from "@mui/material";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDButton from "components/MDButton";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";
import CardsList from "components/CardsList";
import DynamicTranslate from "components/DynamicTranslate";
import { useHandleError } from "components/helper";
import Loader from "components/Loader";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import {
  getOneLocation,
  clearErrors,
  updateLocation,
  updateLocationTranslations,
  setLoading,
  getLocationTranslations,
  refreshLocation,
  setRefreshLoading,
  addLocationWifi,
} from "store/actions/locationAction";
import setAlert from "store/actions/alertActions";
import {
  setLoading as setRecommendationLoading,
  getLocationRecommendations,
} from "store/actions/recommendationActions";
import { setLoading as setItemLoading, getLocationTickets } from "store/actions/itemAction";

import {
  setLoading as setPromotionLoading,
  getLocationPromotions,
} from "store/actions/promotionActions";
import {
  setLoading as setAttractionLoading,
  getLocationAttractions,
} from "store/actions/attractionActions";
import {
  setLoading as setFacilitiesLoading,
  getLocationFacilities,
} from "store/actions/facilityActions";
import { setLoading as setWidgetLoading, getLocationWidgets } from "store/actions/widgetActions";
import * as Yup from "yup";

// Schemas
import MDTypography from "components/MDTypography";
import validations from "./schemas/validations";
import form from "../new-location/schemas/form";
import initialValues from "../new-location/schemas/initialValues";
import LocationDetail from "./components/LocationDetail";
import LocationFilters from "./components/LocationFilter";
import GenerateTickets from "./components/GenerateTickets";
import WidgetDisplay from "./components/GenerateTickets/WidgetDisplay";
import AddTransportationServiceDialog from "../../../views/transportationServices/new-transportationService/components/AddTranportationServiceDialog";
import AddFacilityDialog from "../../otherFacilities/new-facility/components/AddFacilityDialog";
import GuestCompanion from "../new-location/Guest-Companion";

const EditLocation = () => {
  const { formId, formField } = form;
  const { location_id } = useParams();

  const env = process.env.REACT_APP_ENV;

  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const expUrl = env === "production" ? "https://lokalee.app" : "https://staging.lokalee.app";

  // selector
  const location = useSelector((state) => state.locations.location);
  const translationLocation = useSelector((state) => state.locations.translationLocation);
  const error = useSelector((state) => state.locations.error);
  const added = useSelector((state) => state.locations.added);
  const user = useSelector((state) => state.users.user);
  const items = useSelector((state) => state?.items?.items);
  const itemsLoading = useSelector((state) => state?.items?.loading);
  const promotions = useSelector((state) => state?.promotions?.promotions);
  const promotionsLoading = useSelector((state) => state?.promotions?.loading);
  const recommendations = useSelector((state) => state?.recommendations?.recommendations);
  const recommendationsLoading = useSelector((state) => state?.recommendations?.loading);
  const attractions = useSelector((state) => state?.attractions?.attractions);
  const attractionsLoading = useSelector((state) => state?.attractions?.loading);
  const facilities = useSelector((state) => state?.facilities?.facilities);
  const facilitiesLoading = useSelector((state) => state?.facilities?.loading);
  const widgets = useSelector((state) => state?.widgets?.widgets);
  const widgetsLoading = useSelector((state) => state?.widgets?.loading);
  const widget = useSelector((state) => state?.items?.widget);
  const refreshLoading = useSelector((state) => state?.locations?.refreshLoading);
  const formRefVal = formRef?.current?.values;
  const emailSchemaValidation = Yup.object().shape({
    email: Array.isArray(formRefVal?.email)
      ? Yup.array().of(
          Yup.string()
            .email("invalid email address")
            .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, "invalid email address")
        )
      : Yup.string()
          .email("invalid email address")
          .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, "invalid email address"),
  });
  // state
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [disabledTab, setDisabledTab] = useState(true);
  const [initialState, setInitialState] = useState(initialValues);
  const [image, _setImage] = useState(location?.logo);
  const [tabValue, setTabValue] = useState(0);
  const [locationDetailPage, setLocationDetailPage] = useState(true);
  const [additionalDetailPage, setAdditionalDetailPage] = useState(true);
  const [addressDetailPage, setAddressDetailPage] = useState(true);
  const [slidersDetailPage, setSlidersDetailPage] = useState(true);
  const [wifiDetailPage, setWifiDetailPage] = useState(true);
  const [guestCompanionDetailPage, setGuestCompanionDetailPage] = useState(true);
  const [tripPlannerDetailPage, setTripPlannerDetailPage] = useState(true);

  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedTravelDesk, setSelectedTravelDesk] = useState(null);
  const [translateItems, setTranslateItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState({});
  const [openTransportationServiceDialog, setOpenTransportationServiceDialog] = useState(false);
  const [openFacilityDialog, setOpenFacilityDialog] = useState(false);
  const [selectedPrimaryDestination, setSelectedPrimaryDestination] = useState(null);
  const [selectedSecondaryDestination, setSelectedSecondaryDestination] = useState([]);

  const detailTabs = [
    { id: 1, title: "LOCATION DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "INTEGRATIONS", iconComponent: HomeWorkIcon },
    { id: 3, title: "FILTERS", iconComponent: PaletteIcon },
    { id: 4, title: "RECOMMENDATIONS", iconComponent: TheaterComedyIcon },
    { id: 5, title: "ITEMS", iconComponent: LocalActivityIcon },
    { id: 6, title: "ATTRACTIONS", iconComponent: EngineeringIcon },
    { id: 7, title: "HOME FACILITIES", iconComponent: HomeWorkIcon },
    { id: 8, title: "TRANSLATE", iconComponent: TranslateIcon },
    // { id: 6, title: "OTHER FACILITIES", iconComponent: HomeWorkIcon },
    // { id: 7, title: "TRANSPORTATION SERVICES", iconComponent: EmojiTransportationIcon },
  ];

  // useEffect
  useEffect(() => {
    if (detailTabs[5]?.id === 6 && !location?.enableServices) {
      setDisabledTab(true);
    } else setDisabledTab(false);
  }, [detailTabs[5]?.id === 6, location_id, location?.enableServices]);

  useEffect(() => {
    if (location_id) {
      dispatch(getLocationTranslations(location_id));
    }
  }, [detailTabs[6]?.id === 7, location_id]);

  useEffect(() => {
    if (location_id) {
      dispatch(getOneLocation(location_id));
    }
  }, [location_id, added]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (location?.logo) {
      _setImage(location?.logo);
    }
  }, [location?.logo]);

  useEffect(() => {
    if (user?.role !== "admin" && user?.role !== "curator" && location?.partner?._id !== user._id) {
      <Navigate to="/" />;
    }

    if (location) {
      const arr = [
        {
          id: 1,
          name: "name",
          title: "Name",
          value: translationLocation?.name || "",
          default: "",
          style: { fontSize: "15px" },
          label: "Name",
        },

        {
          id: 2,
          name: "trendTitle",
          title: "Trend Title",
          value: translationLocation?.trendTitle || "",
          default: "",
          style: { fontSize: "15px" },
          label: "Trend Title",
        },
        {
          id: 3,
          name: "trendSubTitle",
          title: "Trend SubTitle",
          value: translationLocation?.trendSubTitle || "",
          default: "",
          style: { fontSize: "15px" },
          label: "Trend SubTitle",
        },
      ];
      setTranslateItems(arr);
    }
  }, [location, translationLocation]);
  // We cant use the location as is because of address picker and map picker
  useEffect(() => {
    if (location) {
      const manipulated = {};
      manipulated.name = location?.name ? location?.name : "";
      manipulated.partner = location?.partner ? location?.partner : "";
      manipulated.email = location?.email ? location?.email : "";
      manipulated.primary = location?.theme?.primary ? location?.theme?.primary : "#00acc1";
      manipulated.background = location?.theme?.background
        ? location?.theme?.background
        : "#ccc9c9";
      manipulated.button = location?.theme?.button ? location?.theme?.button : "#efebeb";
      manipulated.top = location?.top ? location?.top : false;
      manipulated.logo = location?.logo ? location?.logo : "";
      manipulated.address = location?.geoLocationData?.address;
      manipulated.city = location?.geoLocationData?.city;
      manipulated.region = location.geoLocationData?.region ? location.geoLocationData?.region : "";
      manipulated.country = location.geoLocationData?.country
        ? location.geoLocationData?.country
        : "";
      manipulated.lat = location.geoLocation?.coordinates[1]
        ? location.geoLocation?.coordinates[1]
        : "";
      manipulated.lng = location.geoLocation?.coordinates[0]
        ? location.geoLocation?.coordinates[0]
        : "";
      manipulated.travelDesk = location?.travelDesk ? location?.travelDesk : {};
      manipulated.serviceJobs = location?.serviceJobs ? location?.serviceJobs : {};
      manipulated.serviceJobsObj = location?.serviceJobsObj ? location?.serviceJobsObj : [];

      manipulated.primaryDestination = location?.primaryDestination
        ? location?.primaryDestination
        : "";
      manipulated.destinations = location?.destinations
        ? location?.destinations?.map((destination) => ({
            label: destination?.destinationName,
            value: destination?._id,
            type: destination.destinationType,
          }))
        : [];
      manipulated.trendTitle = location?.trendTitle ? location?.trendTitle : "";
      manipulated.trendSubTitle = location?.trendSubTitle ? location?.trendSubTitle : "";

      manipulated.numRooms = location?.numRooms ? location?.numRooms : "";
      manipulated.trn = location?.trn ? location?.trn : "";
      manipulated.disclaimer = location?.disclaimer ? location?.disclaimer : false;
      manipulated.disclaimerText = location?.disclaimerText ? location?.disclaimerText : "";
      manipulated.showSlider = location?.showSlider ? location?.showSlider : false;
      manipulated.showGuestCompanion = location?.showGuestCompanion
        ? location?.showGuestCompanion
        : false;

      manipulated.slideDuration = location?.slideDuration ? location?.slideDuration : 7000;
      manipulated.slider = location?.slider ? location?.slider : [];
      manipulated.enableServices = location?.enableServices ? location?.enableServices : false;
      manipulated.dinningLabel = location?.dinningLabel ? location?.dinningLabel : "";
      manipulated.serviceTitle = location?.serviceTitle ? location?.serviceTitle : "";
      manipulated.images = location?.images ? location?.images : [];
      manipulated.template = location?.template ? location?.template : "default";
      manipulated._id = location?._id;
      manipulated.slug = location?.slug;
      manipulated.currency = location?.currency;
      manipulated.tripPlanner = location?.tripPlanner;
      manipulated.title = location?.wifiInfo?.title ? location?.wifiInfo?.title : "";
      manipulated.subTitle = location?.wifiInfo?.subTitle ? location?.wifiInfo?.subTitle : "";
      manipulated.chat = location?.wifiInfo?.chat ? location?.wifiInfo?.chat : false;
      manipulated.menus = location?.wifiInfo?.menus ? location?.wifiInfo?.menus : [];
      manipulated.exploreUrl = location?.wifiInfo?.exploreUrl
        ? location?.wifiInfo?.exploreUrl
        : location?.slug
        ? `${expUrl}/locations/${location?.slug}`
        : "";

      manipulated.logo = location?.wifiInfo?.logo ? location?.wifiInfo?.logo : "";
      manipulated.wifiInfo = location?.wifiInfo ? location?.wifiInfo : {};

      setInitialState(manipulated);
    }
  }, [location]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values) => {
    // const updatedValues = { ...values };
    const {
      name = "",
      partner = "",
      primary = "#00acc1",
      background = "#999999",
      button = "#ffffff",
      top = false,
      email = [],
      address = "",
      city = "",
      region = "",
      country = "",
      lat = "",
      lng = "",
      travelDesk = "",
      numRooms = "",
      trn = "",
      primaryDestination = "",
      destinations = [],
      tags = [],
      disclaimer = "",
      disclaimerText = "",
      showSlider = false,
      showGuestCompanion = false,
      enableServices = false,
      serviceJobs = {},
      serviceJobsObj = [],
      dinningLabel = "",
      serviceTitle = "",
      slideDuration = 7000,
      trendTitle = "",
      trendSubTitle = "",
      template,
      images,
      title = "",
      logo = "",
      exploreUrl = "",
      chat = false,
      menus = [],
      subTitle = "",
    } = values;

    const baseValues = { _id: values?._id, name, city };
    let additionalValues = {};
    if (!locationDetailPage) {
      additionalValues = {
        partner: partner?._id || partner,
        email,
        top,
        trn,
        primary,
        background,
        button,
        disclaimer,
        disclaimerText,
      };
    } else if (!addressDetailPage) {
      additionalValues = { address, country, region, lat, lng, primaryDestination, destinations };
    } else if (!slidersDetailPage) {
      additionalValues = {
        showSlider,
        slideDuration,
      };
    } else if (!wifiDetailPage) {
      additionalValues = {
        title,
        logo,
        subTitle,
        exploreUrl,
        chat,
        menus,
      };
    } else if (!guestCompanionDetailPage) {
      additionalValues = {
        showGuestCompanion,
        serviceJobs,
        serviceJobsObj,
      };
    } else if (!additionalDetailPage) {
      additionalValues = {
        numRooms,
        tags,
        enableServices,
        dinningLabel,
        serviceTitle,
        template,
        trendTitle,
        trendSubTitle,
        images,
        travelDesk,
      };
    } else if (!tripPlannerDetailPage) {
      additionalValues = {
        tripPlanner: values.tripPlanner,
      };
    }
    const updatedValues = { ...baseValues, ...additionalValues };

    await sleep(1000);
    dispatch(setLoading());
    if (!wifiDetailPage) {
      dispatch(addLocationWifi(updatedValues, location?._id));
    } else
      dispatch(
        updateLocation(updatedValues, values?.logo !== location?.logo ? values?.logo : undefined)
      );
    // eslint-disable-next-line no-alert

    setLocationDetailPage(true);
    setAdditionalDetailPage(true);
    setAddressDetailPage(true);
    setSlidersDetailPage(true);
    setGuestCompanionDetailPage(true);
    setTripPlannerDetailPage(true);
    setWifiDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleSubmitTranslate = async (values) => {
    dispatch(updateLocationTranslations(values, location?._id));
  };

  const handlePrimaryDestinationChange = (value) => {
    if (!value || !value.label) {
      setSelectedPrimaryDestination({});
    } else {
      setSelectedPrimaryDestination(value);
    }
  };

  const handleSecondaryDestinationChange = (value) => {
    if (value) {
      setSelectedSecondaryDestination(value);
    } else {
      setSelectedSecondaryDestination([]);
    }
  };
  const handlePartnerChange = (e, value) => {
    if (!value || !value._id) {
      setSelectedPartner({});
    } else {
      setSelectedPartner(value);
    }
  };

  const handleTravelDeskChange = (e, value) => {
    if (value && value?._id) {
      setSelectedTravelDesk(value);
    } else {
      setSelectedTravelDesk(null);
    }
  };

  function getStepContent(type, formData) {
    let detailPage;
    let setDetailPage;

    switch (type) {
      case "locationInfo":
        detailPage = locationDetailPage;
        setDetailPage = setLocationDetailPage;
        break;
      case "address":
        detailPage = addressDetailPage;
        setDetailPage = setAddressDetailPage;
        break;
      case "sliders":
        detailPage = slidersDetailPage;
        setDetailPage = setSlidersDetailPage;
        break;
      case "tripPlanner":
        detailPage = tripPlannerDetailPage;
        setDetailPage = setTripPlannerDetailPage;
        break;
      case "wifi":
        detailPage = wifiDetailPage;
        setDetailPage = setWifiDetailPage;
        break;
      // case "guestCompanion":
      //   detailPage = guestCompanionDetailPage;
      //   setDetailPage = setGuestCompanionDetailPage;
      //   break;
      default:
        detailPage = additionalDetailPage;
        setDetailPage = setAdditionalDetailPage;
    }

    const handleEdit = () => {
      formData.resetForm();
      if (type === "locationInfo") {
        setLocationDetailPage(false);
        setAddressDetailPage(true);
        setSlidersDetailPage(true);
        setAdditionalDetailPage(true);
        setGuestCompanionDetailPage(true);
        setWifiDetailPage(true);
      }
      if (type === "address") {
        setLocationDetailPage(true);
        setAddressDetailPage(false);
        setSlidersDetailPage(true);
        setAdditionalDetailPage(true);
        setGuestCompanionDetailPage(true);
        setWifiDetailPage(true);
      }
      if (type === "sliders") {
        setLocationDetailPage(true);
        setAddressDetailPage(true);
        setSlidersDetailPage(false);
        setAdditionalDetailPage(true);
        setGuestCompanionDetailPage(true);
        setWifiDetailPage(true);
      }
      if (type === "wifi") {
        setLocationDetailPage(true);
        setAddressDetailPage(true);
        setSlidersDetailPage(true);
        setAdditionalDetailPage(true);
        setGuestCompanionDetailPage(true);
        setWifiDetailPage(false);
      }
      if (type === "tripPlanner") {
        setSlidersDetailPage(true);
        setAddressDetailPage(true);
        setLocationDetailPage(true);
        setAdditionalDetailPage(true);
        setTripPlannerDetailPage(false);
        setGuestCompanionDetailPage(true);
      }
      // if (type === "guestCompanion") {
      //   setGuestCompanionDetailPage(false);
      //   setLocationDetailPage(true);
      //   setAddressDetailPage(true);
      //   setSlidersDetailPage(true);
      //   setAdditionalDetailPage(true);
      // }

      if (type === "disclaimer") {
        setLocationDetailPage(true);
        setAddressDetailPage(true);
        setSlidersDetailPage(true);
        setAdditionalDetailPage(true);
        setGuestCompanionDetailPage(true);
        setWifiDetailPage(true);
      }
      if (type === "additionalInfo") {
        setLocationDetailPage(true);
        setAddressDetailPage(true);
        setSlidersDetailPage(true);
        setAdditionalDetailPage(false);
        setGuestCompanionDetailPage(true);
        setWifiDetailPage(true);
      }
      setImgDetailPage(true);
    };

    return (
      <LocationDetail
        locationId={location?._id}
        type={type}
        formData={formData}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        handleEdit={handleEdit}
        handlePartnerChange={handlePartnerChange}
        selectedPartner={selectedPartner}
        partnerObj={location?.partner}
        travelDeskObj={location?.travelDesk}
        handleTravelDeskChange={handleTravelDeskChange}
        selectedTravelDesk={selectedTravelDesk}
        handlePrimaryDestinationChange={handlePrimaryDestinationChange}
        handleSecondaryDestinationChange={handleSecondaryDestinationChange}
        selectedPrimaryDestination={selectedPrimaryDestination}
        selectedSecondaryDestination={selectedSecondaryDestination}
        setSelectedPrimaryDestination={setSelectedPrimaryDestination}
        setSelectedPartner={setSelectedPartner}
        setSelectedTravelDesk={setSelectedTravelDesk}
      />
    );
  }
  const handleIconChange = (value) => {
    if (!value || !value.name) {
      setSelectedIcon({});
    } else {
      setSelectedIcon(value);
    }
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneLocation(location_id));
    }
    if (newValue === 3) {
      dispatch(setRecommendationLoading());
      dispatch(getLocationRecommendations(location_id));
    }
    if (newValue === 4) {
      dispatch(setItemLoading());
      dispatch(getLocationTickets(location?.partner?._id));
      dispatch(setPromotionLoading());
      dispatch(getLocationPromotions(location_id));
    }
    if (newValue === 5) {
      dispatch(setAttractionLoading());
      dispatch(getLocationAttractions(location_id));
    }
    if (newValue === 6 && location?.enableServices) {
      dispatch(setFacilitiesLoading());
      dispatch(getLocationFacilities(location?.slug));
      dispatch(setWidgetLoading());
      dispatch(getLocationWidgets(location?.slug));
    }
  };

  const handleDetailData = (formData) => {
    switch (tabValue) {
      case 1:
        return (
          <GuestCompanion
            formData={formData}
            editPage
            detailPage={guestCompanionDetailPage}
            setDetailPage={setGuestCompanionDetailPage}
            locationId={location?._id}
            location={location}
          />
        );
      case 2:
        return (
          <LocationFilters
            selectedLocation={location}
            id={location_id}
            filters={location?.filters}
          />
        );
      case 3:
        return (
          <CardsList
            data={recommendations}
            loading={recommendationsLoading}
            url={`/${user.role}/recommendations`}
          />
        );
      case 4:
        return (
          <>
            <div style={{ marginBottom: "24px" }}>
              <CardsList
                data={items}
                loading={itemsLoading}
                url={`/${user.role}/tickets`}
                cardTitle="Tickets"
              />
            </div>
            <div style={{ marginBottom: "24px" }}>
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "24px",
                    alignItems: "start",
                  }}
                >
                  <MDTypography component="p" variant="body1">
                    Widgets
                  </MDTypography>

                  <MDButton
                    // variant="outlined"
                    color="dark"
                    iconOnly
                    onClick={() => setOpen(true)}
                    style={{ width: "auto", textTransform: "none", boxShadow: "none" }}
                  >
                    Add Widget
                  </MDButton>
                </div>
                {widget && (
                  <div
                    style={{
                      padding: "24px",
                    }}
                  >
                    <WidgetDisplay htmlString={widget} />
                  </div>
                )}
              </Card>
            </div>
            <GenerateTickets
              open={open}
              handleClose={() => setOpen(false)}
              itemsList={items}
              locationSlug={location?.slug}
              city={location?.geoLocationData?.city}
              locationPartner={location?.partner}
            />
            <div style={{ marginBottom: "24px" }}>
              <CardsList
                data={promotions}
                loading={promotionsLoading}
                url={`/${user.role}/promotions`}
                cardTitle="Promotions"
              />
            </div>
          </>
        );

      case 5:
        return (
          <CardsList
            data={attractions}
            loading={attractionsLoading}
            url={`/${user.role}/attractions`}
          />
        );
      case 6:
        return (
          <>
            <div style={{ marginBottom: "24px" }}>
              <Card>
                <div style={{ display: "flex", alignItems: "end" }}>
                  <MDButton
                    // variant="outlined"
                    color="dark"
                    iconOnly
                    onClick={() => setOpenFacilityDialog(true)}
                    style={{
                      width: "100px",
                      textTransform: "none",
                      marginLeft: "auto",
                      marginRight: "15px",
                      marginTop: "15px",
                      boxShadow: "none",
                    }}
                  >
                    Add Facility
                  </MDButton>
                </div>
                <CardsList
                  source="location"
                  data={facilities}
                  loading={facilitiesLoading}
                  url={`/${user.role}/otherFacilities`}
                  cardTitle="Facilities"
                />
              </Card>
            </div>
            <AddFacilityDialog
              openDialog={openFacilityDialog}
              handleClose={() => {
                setOpenFacilityDialog(false);
              }}
            />
            <div>
              <Card>
                <MDButton
                  // variant="outlined"
                  color="dark"
                  iconOnly
                  onClick={() => setOpenTransportationServiceDialog(true)}
                  style={{
                    width: "190px",
                    textTransform: "none",
                    marginLeft: "auto",
                    marginRight: "15px",
                    marginTop: "15px",
                    boxShadow: "none",
                  }}
                >
                  Add Transportation Service
                </MDButton>
                <CardsList
                  source="location"
                  data={widgets}
                  loading={widgetsLoading}
                  url={`/${user.role}/widgets`}
                  cardTitle="Transportation Services"
                />
              </Card>
            </div>
            <AddTransportationServiceDialog
              openDialog={openTransportationServiceDialog}
              handleClose={() => {
                setSelectedIcon({});
                setOpenTransportationServiceDialog(false);
              }}
              selectedIcon={selectedIcon}
              handleIconChange={handleIconChange}
            />
          </>
        );

      case 7:
        return (
          <DynamicTranslate
            items={translateItems}
            handleSubmit={handleSubmitTranslate}
            id={location?._id}
          />
        );
      default:
        return null;
    }
  };

  const handleNavigation = () => {
    let url = null;
    url = `/${user.role}/locations?`;
    navigate(url);
  };

  const handleRefresh = () => {
    dispatch(setRefreshLoading());
    dispatch(refreshLocation(location?.slug));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={
                !slidersDetailPage
                  ? validations[3]
                  : !wifiDetailPage
                  ? validations[4]
                  : !additionalDetailPage
                  ? validations[2]
                  : !addressDetailPage
                  ? validations[1]
                  : !locationDetailPage
                  ? validations[0]
                  : emailSchemaValidation
              }
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => {
                // Construct formData
                const formData = {
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  resetForm,
                };

                return (
                  <Form id={formId} autoComplete="off">
                    <MDBox>
                      <DynamicHeader
                        image={image}
                        imgDetailPage={imgDetailPage}
                        user={location}
                        detailTabs={detailTabs}
                        handleSetTabValue={handleSetTabValue}
                        tabValue={tabValue}
                        imageType="logo"
                        disabledTab={disabledTab}
                        handleNavigation={() => handleNavigation(user?.role)}
                      >
                        {user.role === "admin" && (
                          <MDButton
                            variant="text"
                            color="dark"
                            iconOnly
                            onClick={() => handleRefresh()}
                            style={{ padding: 0, minHeight: "16px", height: "16px" }}
                          >
                            <Tooltip title="Refresh" placement="top">
                              {refreshLoading ? <Loader /> : <Icon>refresh</Icon>}
                            </Tooltip>
                          </MDButton>
                        )}
                        {tabValue === 0 ? (
                          <ViewEditActions
                            detailPage={imgDetailPage}
                            stateName="locations"
                            handleEdit={() => {
                              setImgDetailPage(false);
                              setLocationDetailPage(true);
                              setAddressDetailPage(true);
                              setSlidersDetailPage(true);
                              setAdditionalDetailPage(true);
                              setGuestCompanionDetailPage(true);
                            }}
                            handleClose={() => {
                              _setImage(location?.logo);
                              setImgDetailPage(true);
                            }}
                            handleSave={() => {
                              submitForm(values);
                              setImgDetailPage(true);
                            }}
                          />
                        ) : null}
                      </DynamicHeader>

                      {tabValue === 0 ? (
                        <>
                          {[
                            "locationInfo",
                            "address",
                            "sliders",
                            "tripPlanner",
                            "wifi",
                            // "guestCompanion",
                            "additionalInfo",
                          ].map((item, index) => (
                            <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                              <MDBox p={2}>
                                <Grid container spacing={3} alignItems="center">
                                  <Grid item xs={12}>
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                      {getStepContent(
                                        item,
                                        {
                                          values,
                                          touched,
                                          formField,
                                          errors,
                                          setFieldValue,
                                          resetForm,
                                        },
                                        selectedIcon,
                                        handleIconChange
                                      )}
                                    </MDBox>
                                  </Grid>
                                </Grid>
                              </MDBox>
                            </Card>
                          ))}
                        </>
                      ) : (
                        handleDetailData(formData) // Pass formData here as well
                      )}
                    </MDBox>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditLocation;
