/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Redux Actions
import setAlert from "store/actions/alertActions";

// Layouts
import DashboardLayout from "layouts/DashboardLayout";
import { scrollToTop, useHandleError } from "components/helper";

// Custom Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";

// Schema
import { addVenue, clearErrors, setLoading } from "store/actions/venuesActions";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import Address from "./components/Address";
import Review from "./components/Review";
import Information from "./components/Information";

const mainArray = ["Information", "Address", "Review"];

function getSteps() {
  return ["Information", "Address", "Review"];
}

function getStepContent(
  stepIndex,
  formData,
  handlePrimaryDestinationChange,
  selectedPrimaryDestination
) {
  if (formData) {
    switch (mainArray[stepIndex]) {
      case "Information":
        return (
          <Information
            formData={formData}
            handlePrimaryDestinationChange={handlePrimaryDestinationChange}
            selectedPrimaryDestination={selectedPrimaryDestination}
          />
        );
      case "Address":
        return <Address formData={formData} />;
      case "Review":
        return (
          <Review formData={formData} selectedPrimaryDestination={selectedPrimaryDestination} />
        );
      default:
        return null;
    }
  }
  return null;
}

const NewVenue = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const user = useSelector((state) => state.users.user);
  const error = useSelector((state) => state.locations.error);

  const handleValidation = (activeStep) => {
    switch (mainArray[activeStep]) {
      case "Information":
        return validations[0];
      case "Address":
        return validations[1];
      default:
        return null;
    }
  };

  // state
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const { formId, formField } = form;
  const currentValidation = handleValidation(activeStep);
  const isLastStep = activeStep === steps.length - 1;
  const [selectedPrimaryDestination, setSelectedPrimaryDestination] = useState(null);
  useEffect(() => {
    setSteps(getSteps("admin"));
  }, []);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    scrollToTop(); // Scroll to the top
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/venues?`;
      navigate(url);
    } else if (type === "partner") {
      url = `/partner/venues`;
      navigate(url);
    }
  };

  const submitForm = async (values, actions) => {
    await sleep(1000);
    dispatch(setLoading());
    const updatedValues = { ...values };

    if (updatedValues && updatedValues?.thumbnailHiResURL) {
      delete updatedValues?.thumbnailHiResURL;
    }
    if (updatedValues && updatedValues?.destinations) {
      delete updatedValues?.destinations;
    }
    // eslint-disable-next-line no-alert
    dispatch(addVenue(updatedValues, values?.thumbnailHiResURL));
    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
    // }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
      handleNavigation(user?.role);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      scrollToTop(); // Scroll to the top
    }
  };
  const handlePrimaryDestinationChange = (value) => {
    if (value) {
      setSelectedPrimaryDestination(value);
    } else {
      setSelectedPrimaryDestination({});
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={steps[activeStep] === "Review" ? undefined : currentValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                // setFormChange(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          handlePrimaryDestinationChange,
                          selectedPrimaryDestination
                        )}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <div>
                            <MDButton
                              onClick={() => handleNavigation(user?.role)}
                              rel="noreferrer"
                              variant="outlined"
                              color="dark"
                              sx={{ marginRight: "8px" }}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "add" : "next"}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewVenue;
