import checkout from "./form";

const {
  formField: {
    // information page
    title,
    subHeader,
    description,
    logo,
    category,
    subcategories,
    cuisines,
    features,
    bookable,
    bookingEmail,
    minGuests,
    maxGuests,
    needsConfirmation,
    // address page
    primaryDestination,
    destinations,
    location,
    address,
    city,
    region,
    country,
    lat,
    lng,
    // additional  information page
    tags,
    email,
    phone,
    webAddresses,
    callToAction,
    callToAction2,
    badges,
    price,
    highlight,
    currated,
    special,
    experienceX,
    exclusivePartner,
    exclusiveLocation,
    top,
    traveltype,
    // Date and time page
    openHours,
    // Trip planner
    kidsFriendly,
    style,
    interests,
    timeSlotPreference,
    seasonality,
    genderPreference,
  },
} = checkout;

const initialValues = {
  // recommendation page
  [title.name]: "",
  [subHeader.name]: "",
  [description.name]: "",
  [location.name]: "",
  [primaryDestination.name]: "",
  [destinations.name]: [],
  [logo.name]: "",
  [category.name]: "",
  [subcategories.name]: [],
  [cuisines.name]: [],
  [features.name]: [],
  [bookable?.name]: false,
  [bookingEmail?.name]: "",
  [minGuests?.name]: null,
  [maxGuests?.name]: null,
  [needsConfirmation?.name]: false,
  // address page
  [address.name]: "",
  [city.name]: "",
  [region.name]: "",
  [country.name]: "",
  [lat.name]: "",
  [lng.name]: "",
  // additional info
  [tags.name]: [],
  [email.name]: "",
  [phone.name]: "",
  [webAddresses.name]: "",
  [callToAction.name]: {
    label: "",
    url: "",
  },
  [callToAction2.name]: {
    label: "",
    url: "",
  },

  [badges.name]: null,
  [price.name]: 3,
  [currated.name]: false,
  [highlight.name]: false,
  [special.name]: false,
  [experienceX.name]: false,
  [exclusiveLocation.name]: false,
  [exclusivePartner.name]: false,
  [top.name]: false,
  [traveltype.name]: "leisure",
  // date and Time page
  [openHours.name]: [],
  // Trip planner
  [kidsFriendly.name]: false,
  [style?.name]: [],
  [interests?.name]: [],
  [timeSlotPreference?.name]: "Afternoon",
  [seasonality?.name]: "Winter",
  [genderPreference?.name]: "Men",
};

export default initialValues;
