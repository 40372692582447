/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";
import PromoCodeInfo from "../../new-promoCodes/components/PromoCodeInfo";

const PromoCodeDetail = ({
  formData,
  type,
  detailPage,
  setDetailPage,
  handleEdit,
  handleLocationChange,
  selectedLocations,
  setSelectedLocations,
}) => {
  const { submitForm, resetForm } = useFormikContext();

  const handleCardTitle = () => {
    if (type === "promoCodeInfo") {
      return "PromoCode info";
    }
    return "";
  };

  const handleInfoCard = () => {
    if (type === "promoCodeInfo") {
      return (
        <PromoCodeInfo
          formData={formData}
          editPage
          detailPage={detailPage}
          handleLocationChange={handleLocationChange}
          selectedLocations={selectedLocations}
          setSelectedLocations={setSelectedLocations}
        />
      );
    }
    return null;
  };
  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          stateName="promoCodes"
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>{handleInfoCard()}</MDBox>
    </MDBox>
  );
};

// Setting default props
PromoCodeDetail.defaultProps = {
  detailPage: true,
  setDetailPage: () => {},
  type: "",
  selectedLocations: {},
  setSelectedLocations: () => {},
  handleLocationChange: () => {},
};
// typechecking props for PromoCodeDetail
PromoCodeDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
  selectedLocations: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  setSelectedLocations: PropTypes.func,
};

export default PromoCodeDetail;
