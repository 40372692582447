import React from "react";
import PropTypes from "prop-types";
import { List, ListItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const LanguagesDetailsList = ({ data }) => {
  const listItemStyle = {
    display: "flex",
  };

  const columnStyle = {
    flex: "0.5", // Equal distribution of width
    padding: "3px", // Adjust the padding as needed for space between columns
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 180,
  };

  return (
    <List>
      <ListItem style={listItemStyle}>
        <div style={{ ...columnStyle }}>
          <strong>Language</strong>
        </div>
        <div style={{ ...columnStyle }}>
          <strong>Type</strong>
        </div>
      </ListItem>
      {data?.map((item) => (
        <ListItem key={item.language} style={listItemStyle}>
          <div style={{ ...columnStyle }}>
            <Tooltip title={item.language}>
              <span>{item.language}</span>
            </Tooltip>
          </div>
          <div style={{ ...columnStyle }}>
            <span>{item.type}</span>
          </div>
        </ListItem>
      ))}
    </List>
  );
};

LanguagesDetailsList.defaultProps = {
  data: [],
};

LanguagesDetailsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
};

export default LanguagesDetailsList;
