/* eslint-disable no-underscore-dangle */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, ctaTitle, files, ctaEmail, ctaUrl },
} = checkout;

const validations = [
  Yup.object().shape({
    [title?.name]: Yup.string().required(title?.errorMsg),
    [ctaTitle?.name]: Yup.string().required(ctaTitle?.errorMsg),
    // [ctaEmail.name]: Yup.string().email(ctaEmail.invalidMsg).required(ctaEmail?.errorMsg),
    [ctaEmail.name]: Yup.string()
      .email(ctaEmail.invalidMsg)
      .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, ctaEmail.invalidMsg)
      .when("ctaType", {
        is: "ctaEmail",
        then: () => Yup.string().required(ctaEmail?.errorMsg),
      }),
    // [ctaUrl?.name]: Yup.string()
    //   .matches(
    //     /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
    //     ctaUrl.invalidMsg
    //   )
    //   .required(ctaUrl?.errorMsg),
    [ctaUrl?.name]: Yup.string().when("ctaType", {
      is: "ctaUrl",
      then: () =>
        Yup.string()
          .matches(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
            ctaUrl.invalidMsg
          )
          .required(ctaUrl?.errorMsg),
    }),

    [files?.name]: Yup.array().when("ctaType", {
      is: "files",
      then: () => Yup.array().min(1, files?.invalidMsg).required(files?.errorMsg),
    }),
  }),
];

export default validations;
