/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Material UI components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import AssignmentIcon from "@mui/icons-material/Assignment";
import EngineeringIcon from "@mui/icons-material/Engineering";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
// import CardsList from "components/CardsList";
import DynamicHeader from "components/DynamicHeader";
// import ViewEditActions from "components/DetailList/ViewEditActions";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";
import { useHandleError } from "components/helper";

// Redux Actions
import {
  updateTravelDesk,
  clearErrors,
  getOneTravelDesk,
  setLoading,
} from "store/actions/travelDeskActions";
import setAlert from "store/actions/alertActions";
// Schemas
import ViewEditActions from "components/DetailList/ViewEditActions";
import validations from "../new-travelDesk/schemas/validations";
import form from "../new-travelDesk/schemas/form";
import initialValues from "../new-travelDesk/schemas/initialValues";
import TravelDeskDetail from "./components/TravelDeskDetail";
import AttractionsTravelDeskList from "./components/AttractionsTravelDeskList";

const EditTravelDesk = () => {
  const { formId, formField } = form;
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   const loading = useSelector((state) => state.travelDesks.loading);
  const travelDesk = useSelector((state) => state.travelDesks.travelDesk);
  //   const travelDesks = useSelector((state) => state.travelDesks.travelDesks);
  const error = useSelector((state) => state.travelDesks.error);
  const added = useSelector((state) => state.travelDesks.added);
  const user = useSelector((state) => state.users.user);
  //   const [tabValue, setTabValue] = useState(0);

  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [image, _setImage] = useState(travelDesk?.logo);

  const [travelDeskPage, setTravelDeskPage] = useState(true);
  const [initialState, setInitialState] = useState(initialValues);
  const [tabValue, setTabValue] = useState(0);

  const { travelDesk_id } = useParams();

  const detailTabs = [
    { id: 1, title: "TRAVELDESK DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "ATTRACTIONS", iconComponent: EngineeringIcon },
  ];

  useEffect(() => {
    if (travelDesk_id) {
      dispatch(getOneTravelDesk(travelDesk_id));
    }
  }, [travelDesk_id, added]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook
  useEffect(() => {
    if (travelDesk?.logo) {
      _setImage(travelDesk?.logo);
    }
  }, [travelDesk?.logo]);

  useEffect(() => {
    if (travelDesk) {
      const manipulated = {};
      manipulated.name = travelDesk?.name ? travelDesk?.name : "";
      manipulated.website = travelDesk?.website ? travelDesk?.website : "";
      manipulated.address = travelDesk?.address ? travelDesk?.address : "";
      manipulated.contactName = travelDesk?.contactName ? travelDesk?.contactName : "";
      manipulated.contactEmail = travelDesk?.contactEmail ? travelDesk?.contactEmail : "";
      manipulated.contactPhone = travelDesk?.contactPhone ? travelDesk?.contactPhone : "";
      manipulated.logo = travelDesk?.logo ? travelDesk?.logo : "";
      manipulated._id = travelDesk?._id;
      setInitialState(manipulated);
    }
  }, [travelDesk]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm = async (values) => {
    const {
      name = "",
      contactName = "",
      contactPhone = "",
      contactEmail = "",
      website = "",
      address = "",
    } = values;

    const baseValues = { _id: values?._id };
    let additionalValues = {};

    additionalValues = { name, contactName, contactPhone, contactEmail, website, address };

    const updatedValues = { ...baseValues, ...additionalValues };
    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    dispatch(updateTravelDesk(updatedValues, values?.logo));
    setTravelDeskPage(true);
    setImgDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneTravelDesk(travelDesk_id));
    }
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/travelDesks?`;
      navigate(url);
    }
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return <AttractionsTravelDeskList travelDeskId={travelDesk_id} />;

      default:
        return null;
    }
  };

  function getStepContent(type, formData) {
    if (type === "Information") {
      return (
        <TravelDeskDetail
          type={type}
          formData={formData}
          detailPage={travelDeskPage}
          setDetailPage={setTravelDeskPage}
          handleEdit={() => {
            formData.resetForm();
            setTravelDeskPage(false);
            setImgDetailPage(true);
          }}
        />
      );
    }
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          {" "}
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={validations[0]}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      user={travelDesk}
                      imageType="logo"
                      image={image}
                      imgDetailPage={imgDetailPage}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          stateName="travelDesks"
                          handleEdit={() => {
                            setImgDetailPage(false);
                            setTravelDeskPage(true);
                          }}
                          handleClose={() => {
                            _setImage(travelDesk?.logo || travelDesk?.cover_image_url);

                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);

                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <>
                        {["Information"].map((item, index) => (
                          <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                            <MDBox p={2}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item xs={12}>
                                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    {getStepContent(item, {
                                      values,
                                      touched,
                                      formField,
                                      errors,
                                      setFieldValue,
                                      resetForm,
                                    })}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </Card>
                        ))}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditTravelDesk;
