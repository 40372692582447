/* eslint-disable no-undef */
import axios from "axios";
import { SET_CATEGORY_LOADING, CLEAR_ERRORS, CATEGORY_ADD_SUCCESS, CATEGORY_FAIL } from "./Types";

// import { factory } from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;
// Add Category
export const addCategory = (name) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(`${URL}/categories`, { name }, config);

    dispatch({
      type: CATEGORY_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CATEGORY_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Add Subcategory
export const addSubCategory = (data, cat) =>
  factory.patch(
    { subcategories: data },
    `${URL}/categories/${cat}`,
    "UPDATE_CATEGORY",
    "CATEGORY_FAIL"
  );

// Delete Subcategory
export const deleteSubCategory = (data, cat) =>
  factory.patch(
    { subToDelete: data },
    `${URL}/categories/${cat}`,
    "UPDATE_CATEGORY",
    "CATEGORY_FAIL"
  );

// Get All Categories

export const getCategories = () =>
  factory.get(`${URL}/categories?sort=name&dir=asc`, "GET_CATEGORIES", "CATEGORY_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_CATEGORY_LOADING });
