const form = {
  formId: "new-facility-form",
  formField: {
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    subHeader: {
      name: "subHeader",
      label: "SubHeader",
      type: "text",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
      invalidMsg: "Description should have at least 1 letter!",
    },
    openHours: {
      name: "openHours",
      label: "Opening hours",
      type: "text",
    },
    ctaTitle: {
      name: "ctaTitle",
      label: "Call to action *",
      type: "text",
      invalidMsg: "Call to action should have at least 1 letter!",
    },
    ctaLink: {
      name: "ctaLink",
      label: "Call to action (URL) *",
      type: "text",
      invalidMsg: "Please enter a valid cta url! (http/https url)!",
    },
    location: {
      name: "location",
      label: "Location *",
      errorMsg: "Location is required!",
      invalidMsg: "Please select a location!",
    },
    file: {
      name: "Pdf",
      label: "Pdf *",
      type: "file",
      // invalidMsg: "Title should have at least 1 letter",
    },
    image: {
      name: "image",
      label: "Image *",
      type: "text",
      errorMsg: "Image is required!",
    },
  },
};

export default form;
