const form = {
  formId: "new-uniqueExperience-form",
  formField: {
    // unique experience page
    title: {
      name: "title",
      label: "Title",
      type: "text",
    },
    type: {
      name: "type",
      label: "Type *",
      type: "text",
      errorMsg: "Type is required!",
    },
    recommendation: {
      name: "recommendation",
      label: "Recommendation *",
      errorMsg: "Recommendation is required!",
      type: "text",
    },
    item: {
      name: "item",
      label: "Item *",
      type: "text",
      errorMsg: "Item is required!",
    },
    trendingStartD: {
      name: "trendingStart",
      label: "Trending Start *",
      type: "text",
      errorMsg: "Start Date is required!",
    },
    trendingEndD: {
      name: "trendingEnd",
      label: "Trending End",
      type: "text",
      //   errorMsg: "Date is required!",
    },
  },
};

export default form;
