import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import PropTypes from "prop-types";

const TextAlignmentSelector = ({ alignment, setAlignment }) => {
  const handleChange = (event) => {
    setAlignment(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: 300 }}>
      <InputLabel id="text-alignment-label">Text Alignment</InputLabel>
      <Select
        labelId="text-alignment-label"
        id="text-alignment-select"
        value={alignment}
        onChange={handleChange}
        label="Text Alignment"
        sx={{
          borderBottom: "1px solid #ccc", // Add a bottom border to the label
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <MenuItem value="left">Left</MenuItem>
        <MenuItem value="center">Center</MenuItem>
        <MenuItem value="right">Right</MenuItem>
      </Select>
    </FormControl>
  );
};

TextAlignmentSelector.propTypes = {
  alignment: PropTypes.string.isRequired,
  setAlignment: PropTypes.func.isRequired,
};

export default TextAlignmentSelector;
