import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, logoName },
} = checkout;

const validations = [
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [logoName.name]: Yup.string().required(logoName.errorMsg),
  }),
];

export default validations;
