const form = {
  formId: "new-recommendation-form",
  formField: {
    // recommendation page
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    subHeader: {
      name: "subHeader",
      label: "Sub header",
      type: "text",
    },
    description: {
      name: "description",
      label: "Description *",
      type: "text",
      errorMsg: "Description is required!",
    },
    category: {
      name: "category",
      label: "Category *",
      type: "text",
      errorMsg: "Category is required!",
      invalidMsg: "Category should have at least 1 letter!",
    },
    subcategories: {
      name: "subcategories",
      label: "Subcategories",
      type: "array",
    },
    logo: {
      name: "logo",
      label: "Logo *",
      type: "text",
      errorMsg: "Logo is required!",
    },
    cuisines: {
      name: "cuisines",
      label: "Cuisines",
      type: "array",
      placeHolder: "Select a cuisine",
    },
    features: {
      name: "features",
      label: "Features",
      type: "array",
      placeHolder: "Select a feature",
    },
    bookable: {
      type: "boolean",
      name: "bookable",
      label: "Bookable",
    },
    bookingEmail: {
      type: "text",
      name: "bookingEmail",
      label: "Booking Email: *",
      errorMsg: "Email is required!",
      invalidMsg: "Please enter a valid email!",
      placeholder: "Enter an email",
    },
    minGuests: {
      type: "text",
      name: "minGuests",
      label: "Minumum Cover:",
      placeholder: "Minimum number of guests",
      errorMsg: "Minimum guests should be a number greater than 0!",
    },
    maxGuests: {
      type: "text",
      name: "maxGuests",
      label: "Maximum Cover:",
      placeholder: "Maximum number of guests",
      errorMsg: "Maximum guests should be a number greater than 0!",
    },
    needsConfirmation: {
      type: "boolean",
      name: "needsConfirmation",
      label: "Needs Confirmation",
    },
    // address page
    primaryDestination: {
      name: "primaryDestination",
      label: "Primary Destination *",
      type: "text",
      errorMsg: "Primary Destination is required!",
    },
    destinations: {
      name: "destinations",
      label: "Destinations *",
      type: "text",
      errorMsg: "Destination is required!",
      invalidMsg: "Should have at least 1 destination!",
    },
    location: {
      name: "location",
      label: "Location",
      type: "text",
    },
    address: {
      name: "address",
      label: "Address *",
      type: "text",
      errorMsg: "Address is required!",
      invalidMsg: "Address should have at least 1 letter!",
    },
    country: {
      name: "country",
      label: "Country",
      type: "text",
    },
    city: {
      name: "city",
      label: "City",
      type: "text",
    },
    region: {
      name: "region",
      label: "Region",
      type: "text",
    },
    lat: {
      name: "lat",
      label: "Latitude",
      type: "text",
    },
    lng: {
      name: "lng",
      label: "Longitude",
      type: "text",
    },
    // additional page
    email: {
      name: "email",
      label: "Email",
      type: "email",
      invalidMsg: "Your email address is invalid (email@email.com)!",
    },
    phone: {
      name: "phone",
      label: "Phone",
      type: "text",
      // invalidMsg: "Phone number is not valid / should start with (00) or (+)!",
    },
    webAddresses: {
      name: "webAddresses",
      label: "Website",
      type: "text",
      invalidMsg: "Please enter a valid contact website! (http://www.url.com)!",
    },
    tags: {
      name: "tags",
      label: "Tags *",
      type: "array",
      errorMsg: "Tag is required!",
      invalidMsg: "Should have at least 1 tag!",
    },
    callToAction: {
      name: "callToAction",
      labelName: "label",
      urlName: "url",
      label: "Label",
      urlLabel: "URL",
      type: "object",
      labelInvalidMsg: "Label should have at least 1 letter!",
      urlInvalidMsg: "Please enter a valid cta url! (http/https url)!",
    },
    callToAction2: {
      name: "callToAction2",
      labelName: "label",
      urlName: "url",
      label: "Label",
      urlLabel: "URL",
      type: "object",
      labelInvalidMsg: "Label should have at least 1 letter!",
      urlInvalidMsg: "Please enter a valid cta url! (http/https url)!",
    },

    badges: {
      name: "badges",
      label: "Badges",
      type: "string",
    },

    price: {
      name: "price",
      label: "Price range",
      type: "number",
    },

    currated: {
      name: "currated",
      label: "Currated",
      type: "boolean",
    },
    highlight: {
      name: "highlight",
      label: "Highlight",
      type: "boolean",
    },
    special: {
      name: "special",
      label: "Special Offer",
      type: "boolean",
    },
    experienceX: {
      name: "experienceX",
      label: "Not to miss",
      type: "boolean",
    },

    exclusiveLocation: {
      name: "exclusiveLocation",
      label: "Exclusive For Location",
      type: "boolean",
    },
    exclusivePartner: {
      name: "exclusivePartner",
      label: "Exclusive For Partner",
      type: "boolean",
    },
    top: {
      name: "top",
      label: "Top",
      type: "boolean",
    },
    traveltype: {
      name: "traveltype",
      label: "Travel Type",
      type: "text",
    },

    // date and time info
    openHours: {
      name: "openHours",
      label: "Open Hours",
      type: "array",
      invalidMsg: "please select at least one day!",
    },
    // Trip planner
    style: {
      name: "style",
      label: "Styles *",
      type: "array",
      errorMsg: "Style is required!",
      invalidMsg: "Please add at least one style!",
    },
    interests: {
      name: "interests",
      label: "Interests *",
      type: "array",
      errorMsg: "Interest is required!",
      invalidMsg: "Please add at least one interest!",
    },
    kidsFriendly: {
      name: "kidsFriendly",
      label: "kids Friendly",
      type: "boolean",
    },
    timeSlotPreference: {
      name: "timeSlotPreference",
      label: "Time Slot *",
      type: "text",
    },
    seasonality: {
      name: "seasonality",
      label: "Seasonality *",
      type: "text",
    },
    genderPreference: {
      name: "genderPreference",
      label: "Gender *",
      type: "text",
    },
  },
};

export default form;
