const form = {
  formId: "new-producionReport-form",
  formField: {
    // production report page

    location: {
      name: "location",
      label: "Location",
      errorMsg: "Location is required!",
    },
  },
};

export default form;
