/* eslint-disable no-underscore-dangle */
import {
  SET_ATTRACTION_LOADING,
  ATTRACTION_ADD_SUCCESS,
  ATTRACTION_FAIL,
  GET_ATTRACTIONS,
  CLEAR_ERRORS,
  DELETE_ATTRACTION,
  GET_ONE_ATTRACTION,
  UPDATE_ATTRACTION,
  LOGOUT,
  UPDATE_ATTRACTION_TRANSLATION,
  DELETE_SINGLE_ATTRACTION,
} from "../actions/Types";

const initialState = {
  loading: false,
  attractions: [],
  error: null,
  added: false,
  attraction: null,
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case ATTRACTION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        attractions: [...state.attractions, action.payload.data],
        added: true,
        attraction: null,
      };
    case GET_ONE_ATTRACTION:
      return {
        ...state,
        loading: false,
        error: null,
        attraction: action.payload.data,
        added: false,
      };

    case GET_ATTRACTIONS:
      return {
        ...state,
        loading: false,
        error: null,
        attractions: action.payload.data,
        added: false,
        attraction: null,
      };
    case DELETE_ATTRACTION:
      return {
        ...state,
        loading: false,
        attractions: state.attractions.filter(
          (attraction) => attraction._id !== action.payload.data.attraction._id
        ),
      };
    case DELETE_SINGLE_ATTRACTION:
      return {
        ...state,
        loading: false,
        item: null,
      };
    case UPDATE_ATTRACTION:
      return {
        ...state,
        added: true,
        attractions: state.attractions.map((attraction) =>
          attraction._id === action.payload.data.attraction._id
            ? action.payload.data.attraction
            : attraction
        ),
      };
    case UPDATE_ATTRACTION_TRANSLATION:
      return {
        ...state,
        attraction: action.payload.data?.attraction,
      };
    case ATTRACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_ATTRACTION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        attractions: [],
        error: null,
        added: false,
        attraction: null,
      };

    default:
      return state;
  }
};
export default variable;
