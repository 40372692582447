import checkout from "./formCompanion";

const {
  formField: {
    url,
    apikey,
    system,
    company,
    jobName,
    version,
    jobCode,
    portalId,
    diningId,
    propertyCode,
    inRoomDiningPropertyId,
    inRoomDining,
  },
} = checkout;

const initialValuesCompanion = {
  [jobName.name]: "",
  [jobCode.name]: "",
  [propertyCode.name]: "",
  [inRoomDiningPropertyId.name]: "",
  [company.name]: "",
  [system.name]: "",
  [version.name]: "",
  [apikey.name]: "",
  [url.name]: "",
  [portalId.name]: "",
  [diningId.name]: "",
  [inRoomDining.name]: false,
};

export default initialValuesCompanion;
