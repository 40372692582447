const form = {
  formId: "invoices-form",
  formField: {
    // Invoices page
    city: {
      name: "city",
      label: "City",
    },
    type: {
      name: "type",
      label: "Status",
    },
  },
};

export default form;
