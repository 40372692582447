/* eslint-disable no-underscore-dangle */
import {
  CLEAR_ERRORS,
  SET_VENUE_LOADING,
  VENUE_ADD_SUCCESS,
  VENUE_FAIL,
  LOGOUT,
  UPDATE_VENUE,
  GET_ALL_VENUES,
  GET_ONE_VENUE,
  DELETE_SINGLE_VENUE,
} from "../actions/Types";

const initialState = {
  loading: false,
  venues: [],
  error: null,
  added: false,
  venue: null,
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONE_VENUE:
      return {
        ...state,
        loading: false,
        error: null,
        venue: action.payload.data,
        added: false,
      };
    case VENUE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        venues: [...state.venues, action.payload.data.data],
        added: true,
        venue: null,
      };
    case GET_ALL_VENUES:
      return {
        ...state,
        loading: false,
        error: null,
        venues: action.payload.data,
        added: false,
        venue: null,
      };
    case DELETE_SINGLE_VENUE:
      return {
        ...state,
        loading: false,
        venues: state.venues.filter((venue) => venue._id !== action.payload.data._id),
      };
    case UPDATE_VENUE:
      return {
        ...state,
        added: true,
        venues: state.venues.map((venue) =>
          venue._id === action.payload.data._id ? action.payload.data : venue
        ),
      };
    case VENUE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_VENUE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        venues: [],
        error: null,
        added: false,
        venue: null,
      };

    default:
      return state;
  }
};
export default variable;
