// import React from "react";
// import { Head } from "@react-email/components";
// import { Font } from "@react-email/font";

// const HeadSection = () => {
//   <Head>
//     <Font
//       fontFamily="Helvetica"
//       fallbackFontFamily="Arial"
//       webFont={{
//         url: "https://fonts.gstatic.com/s/helvetica/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2", // Example link, replace with a valid one if available
//         format: "woff2",
//       }}
//       fontStyle="normal"
//     />
//   </Head>;
// };

// export default HeadSection;
import React from "react";
import { Head } from "@react-email/components";
import { Font } from "@react-email/font";

const HeadSection = () => (
  <Head>
    <Font
      fontFamily="Helvetica"
      fallbackFontFamily="Arial"
      webFont={{
        url: "https://fonts.gstatic.com/s/helvetica/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2", // Example link, replace with a valid one if available
        format: "woff2",
      }}
      fontStyle="normal"
    />
  </Head>
);

export default HeadSection;
