/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { Grid } from "@mui/material";

// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { addFacility } from "store/actions/facilityActions";

// components
import FormField from "components/FormField";
import { Formik } from "formik";
import ImagePicker from "components/AutoComplete/ImagePicker";
import DynamicSinglePdf from "components/DynamicSinglePdf";
import initialValues from "../../schemas/initialValues";
import validations from "../../schemas/validations";
import form from "../../schemas/form";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AddFacilityDialog = ({ handleClose, openDialog }) => {
  const { title, openHours, subHeader, ctaTitle, ctaLink, description } = form.formField;
  const currentValidation = validations[0];

  const dispatch = useDispatch();

  // selector
  const location = useSelector((state) => state.locations.location);

  const [pdfFile, setPdfFile] = useState(null);

  const clearClose = () => {
    setPdfFile(null);
    handleClose();
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitFormFn = async (val, actions) => {
    await sleep(1000);
    dispatch(addFacility(val, val?.logo, location));
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleSubmit = (values, actions) => {
    submitFormFn(values, actions);
    handleClose();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, touched, errors, submitForm, resetForm }) => (
          <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              clearClose();
              resetForm();
            }}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
          >
            <DialogContent sx={{ minHeight: `300px` }}>
              <DialogContentText id="alert-dialog-slide-description">
                Add Facility
              </DialogContentText>
              <Grid item xs={12}>
                <ImagePicker key={values?.logo?.length} edit imageType="logo" />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={title.type}
                  label={title?.label}
                  name={title.name}
                  value={values?.title}
                  placeholder={title.placeholder}
                  error={errors.title && touched.title}
                  success={values?.title.length > 0 && !errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={subHeader?.type}
                  label={subHeader?.label}
                  name={subHeader?.name}
                  value={values?.subHeader}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={description?.type}
                  label={description?.label}
                  name={description?.name}
                  multiline
                  outlined
                  rows={5}
                  value={values?.description}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={openHours?.type}
                  label={openHours?.label}
                  name={openHours?.name}
                  value={values?.openHours}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={ctaTitle?.type}
                  label={ctaTitle?.label}
                  name={ctaTitle?.name}
                  value={values?.ctaTitle}
                  error={errors.ctaTitle && touched.ctaTitle}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={ctaLink?.type}
                  label={ctaLink?.label}
                  name={ctaLink?.name}
                  value={values?.ctaLink}
                  error={errors.ctaLink && touched.ctaLink}
                />
              </Grid>
              <Grid item xs={12}>
                <DynamicSinglePdf
                  values={values}
                  setFieldValue={setFieldValue}
                  setPdfFile={setPdfFile}
                  pdfFile={pdfFile}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  clearClose();
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button onClick={submitForm}>Add</Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </div>
  );
};

// Setting default props
AddFacilityDialog.defaultProps = {
  openDialog: false,
};

AddFacilityDialog.propTypes = {
  handleClose: PropTypes.func,
  openDialog: PropTypes.bool,
};

export default AddFacilityDialog;
