import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, address, thumbnailHiResURL, primaryDestinationId },
} = checkout;

const validations = [
  Yup.object().shape({
    [thumbnailHiResURL.name]: Yup.string().required(thumbnailHiResURL.errorMsg).nullable(),
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [primaryDestinationId?.name]: Yup.string().required(primaryDestinationId.errorMsg),
  }),
  Yup.object().shape({
    [address.name]: Yup.string()
      .matches(/([A-Za-z\u0600-\u06FF])/, address.invalidMsg)
      .required(address.errorMsg),
  }),
];

export default validations;
