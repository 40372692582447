import checkout from "./form";

const {
  formField: {
    // information page
    title,
    fomoMessage,
    thumbnailHiResURL,
    top,
    primaryDestinationId,
    // address page
    address,
    city,
    region,
    country,
    lat,
    lng,
  },
} = checkout;

const initialValues = {
  // venue page
  [title.name]: "",
  [fomoMessage.name]: "",
  [thumbnailHiResURL.name]: "",
  [top.name]: false,
  [primaryDestinationId.name]: "",
  // address page
  [address.name]: "",
  [city.name]: "",
  [region.name]: "",
  [country.name]: "",
  [lat.name]: "",
  [lng.name]: "",
};

export default initialValues;
