/* eslint-disable no-underscore-dangle */

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDestinationsTrends,
  setLoading,
  getUpdatedDestinationId,
} from "store/actions/uniqueExperienceActions";
import { useSearchParams } from "react-router-dom";
import DestinationsPicker from "components/AutoComplete/DestinationsPicker";
import { parseISO } from "date-fns";
import UniqueExperiencesList from "./components/UniqueExperiencesList";

const AllUniqueExperiences = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const destinationId = searchParams.get("destinationId");
  const destinationtype = searchParams.get("destinations");

  const uniqueExperiences = useSelector((state) => state?.trends?.trends);
  const loading = useSelector((state) => state.trends.loading);
  const added = useSelector((state) => state.trends.added);
  const destinations = useSelector((state) => state.trends.destinationAndType);

  const [uniqueExperiencesList, setUniqueExperiencesList] = useState([]);

  const data = {
    title: "Unique Experiences",
    columns: [
      "Title",
      "Type",
      "Trending Start",
      "Trending End",
      // "Approval Status",
    ],
    screen: "Unique Experiences",
  };

  const [selectedDestinations, setSelectedDestinations] = useState([]);

  const handleDestinationChange = (value) => {
    if (value?.length > 0) {
      dispatch(setLoading());
      dispatch(getDestinationsTrends(value));
      setSelectedDestinations(value);
    } else {
      setSelectedDestinations([]);
    }
  };

  useEffect(() => {
    setSelectedDestinations(destinations);
  }, [destinations]);

  useEffect(() => {
    if (selectedDestinations?.length > 0) {
      dispatch(getUpdatedDestinationId(selectedDestinations));
    }
  }, [dispatch, added, selectedDestinations]);

  useEffect(() => {
    if (uniqueExperiences?.length > 0 && selectedDestinations?.length > 0) {
      setUniqueExperiencesList(uniqueExperiences);
    } else {
      setUniqueExperiencesList([]);
    }
  }, [uniqueExperiences, selectedDestinations]);

  useEffect(() => {
    if (destinationtype) {
      handleDestinationChange({ label: destinationtype, value: destinationId });
    }
  }, [destinationtype]);

  useEffect(() => {
    if (uniqueExperiences?.length > 0 && selectedDestinations?.length > 0) {
      const modifiedUniqueExperiences = uniqueExperiences.map((experience) => {
        // Parse the "Trending End" date
        const trendingEndDate = parseISO(experience.trendingEnd);

        // Subtract one day from the parsed date
        trendingEndDate.setDate(trendingEndDate.getDate() - 1);

        // Update the experience object with the modified date
        return {
          ...experience,
          trendingEnd: trendingEndDate,
        };
      });

      setUniqueExperiencesList(modifiedUniqueExperiences);
    } else {
      setUniqueExperiencesList([]);
    }
  }, [uniqueExperiences, selectedDestinations]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <MDBox
            style={{
              marginTop: "16px",
            }}
          >
            <Grid item xs={12} sx={{ mt: "16px" }}>
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                <DestinationsPicker
                  // setFieldValue={setFieldValue}
                  handleChange={handleDestinationChange}
                  destinations={{ label: "Destinations" }}
                  destinationsV={selectedDestinations}
                />
              </div>
            </Grid>
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          {selectedDestinations?.length > 0 && (
            <UniqueExperiencesList
              list={data}
              data={uniqueExperiencesList}
              loading={loading}
              selectedDestinations={selectedDestinations}
            />
          )}
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default AllUniqueExperiences;
