// import { subDays } from "date-fns";
import checkout from "./form";

const {
  formField: { city, type },
} = checkout;

const initialValues = {
  [city.name]: {},
  [type.name]: [],
};

export default initialValues;
