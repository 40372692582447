const form = {
  formId: "change-password-form",
  formField: {
    // change pass page
    currentPassword: {
      name: "currentPassword",
      label: "Current Password",
      type: "password",
      errorMsg: "Current password is required!",
    },
    newPassword: {
      name: "newPassword",
      label: "New Password",
      type: "password",
      errorMsg: "New password is required!",
    },
    passwordConfirm: {
      name: "passwordConfirm",
      label: "Confirm Password",
      type: "password",
      errorMsg: "Confirm password is required!",
    },
  },
};

export default form;
