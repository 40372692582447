/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-alert */
import React from "react";
import PropTypes from "prop-types";
import { DropzoneArea } from "react-mui-dropzone";

// eslint-disable-next-line no-unused-vars
const DynamicMultiPdf = (props) => {
  const handleChange = (e) => {
    props.setFieldValue("files", e);
  };
  // };
  return (
    <div>
      <DropzoneArea
        onChange={handleChange}
        filesLimit={6}
        initialFiles={props.files}
        acceptedFiles={[".pdf"]}
      />
    </div>
  );
};
// Setting default props
DynamicMultiPdf.defaultProps = {
  files: [],
};
DynamicMultiPdf.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  files: PropTypes.instanceOf(Array),

  // files: PropTypes.instanceOf(Array),
};

export default DynamicMultiPdf;
