/* eslint-disable react/no-array-index-key */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Avatar, Divider } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { useEffect, useState } from "react";
import { areAllValuesEmpty } from "components/helper";

const Review = ({ formData, editPage }) => {
  const { values } = formData;
  const [PromotionInfoData, setPromotionInfoData] = useState({});
  const [dateAndTimeData, setDateAndTimeData] = useState({});
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (values) {
      setPromotionInfoData({
        title: values?.title,
        summary: values?.summary,
        description: values?.description,
        bookingEmail: values?.bookingEmail,
        recommendation: values?.recommendation?.title
          ? values?.recommendation?.title
          : values?.recommendation?.label,
        location: values?.location,
        minCover: values?.minCover,
        maxCover: values?.maxCover,
        needsConfirmation: values?.needsConfirmation,
      });

      setDateAndTimeData({
        openEnded: values?.openEnded,
        openHours: values?.openHours,
        startDate: values?.startDate,
        endDate: !values?.openEnded && values?.endDate,
      });
    }
  }, [values]);
  useEffect(() => {
    setFields([
      { id: 0, title: "Promotion Info", data: PromotionInfoData },
      { id: 1, title: "Date And Time Info", data: dateAndTimeData },
    ]);
  }, [PromotionInfoData, dateAndTimeData]);

  const BigAvatar = styled(Avatar)`
    width: 74px;
    height: 74px;
  `;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {!editPage ? (
            <Grid item xs={12} sm={6}>
              {values?.logo ? (
                <MDAvatar
                  src={URL.createObjectURL(values?.logo)}
                  alt="profile-image"
                  size="xl"
                  sx={{ "& img": { height: "100%" } }}
                />
              ) : (
                <BigAvatar
                  alt="Change your profile photo"
                  src={URL.revokeObjectURL(values?.logo)}
                  imgProps={{
                    style: {
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              )}
            </Grid>
          ) : (
            ""
          )}
          {fields?.map((field, i) => (
            <Grid
              item
              key={field.id}
              xs={12}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList data={field.data} title={field?.title} />
              {i + 1 !== fields?.length && (
                <div>
                  <Divider sx={{ height: "1px" }} />
                </div>
              )}{" "}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// Setting default props for the Review
Review.defaultProps = {
  editPage: false,
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
};

export default Review;
