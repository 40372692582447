/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import activityList from "./ActivityList";

const ActivityPicker = ({ handleActivityChange, act, setFieldValue }) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });

  const findAct = (actValue) => activityList.find((actVal) => actVal.value === actValue) || null;

  const actValue = typeof act === "number" ? findAct(act) : act;

  return (
    <SelectF
      key={actValue}
      options={activityList}
      placeholder="Select ..."
      value={typeof act === "number" ? actValue : act}
      onChange={(event) => {
        if (event) {
          setFieldValue("daysCutoff", event);
          handleActivityChange(event);
        } else {
          setFieldValue("daysCutoff", {});
          handleActivityChange(event);
        }
      }}
      getOptionValue={(option) => `${option.value}`}
      getOptionLabel={(option) => `${option.name}`}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      isSearchable
      menuPortalTarget={document.body}
    />
  );
};

// Setting default props
ActivityPicker.defaultProps = {
  handleActivityChange: () => {},
  act: {},
  setFieldValue: () => {},
};

// typechecking props for ActivityPicker
ActivityPicker.propTypes = {
  handleActivityChange: PropTypes.func,
  act: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  setFieldValue: PropTypes.func,
};

export default ActivityPicker;
