/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from "axios";
import { createFormData } from "components/helper";

import {
  PARTNER_FAIL,
  SET_PARTNER_LOADING,
  CLEAR_ERRORS,
  UPDATE_PARTNER,
  PARTNER_ADD_SUCCESS,
  CHECK_PARTNERS_FAIL,
  CHECK_PARTNERS,
} from "./Types";

// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Set Loading
export const setLoading = () => ({ type: SET_PARTNER_LOADING });

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Get All Partners
export const getPartners = () =>
  factory.get(`${URL}/partners?sort=createdAt&dir=dsc`, "GET_PARTNERS", "PARTNER_FAIL");

// Add Partner
export const addPartner = (data, logo) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const formData = createFormData({ ...data, logo });
  try {
    const res = await axios.post(`${URL}/partners`, formData, config);

    dispatch({
      type: PARTNER_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PARTNER_FAIL,
      payload: err?.response.data.message,
    });
  }
};
//  Update Partner
export const updatePartner = (data, logo) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const updatedData = {
    ...data,
    name: data?.name?.trim(),
  };

  const formData = createFormData({ ...updatedData, logo });
  if (data?.website?.length === 0) {
    formData.append("website", "");
  }
  if (data?.contactEmail?.length === 0) {
    formData.append("contactEmail", "");
  }
  if (data?.contactName?.length === 0) {
    formData.append("contactName", "");
  }
  if (data?.contactPhone?.length === 0) {
    formData.append("contactPhone", "");
  }

  try {
    const res = await axios.patch(`${URL}/partners/${data._id}`, formData, config);

    dispatch({
      type: UPDATE_PARTNER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PARTNER_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Delete Partner - set active as false
export const deletePartner = (id) =>
  factory.patch({ active: false }, `${URL}/partners/${id}`, "DELETE_PARTNER", "PARTNER_FAIL");

// get One Partner
export const getOnePartner = (id) =>
  factory.get(`${URL}/partners/${id}`, "GET_ONE_PARTNER", "PARTNER_FAIL");

export const checkPartners = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios.post(`${URL}/locations/liscenceCheck`, data, config);

    dispatch({
      type: CHECK_PARTNERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CHECK_PARTNERS_FAIL,
      payload: err?.response.data.message,
    });
  }
};
