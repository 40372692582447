import React, { useEffect, useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";
import MDTypography from "components/MDTypography";

const MDLoading = () => {
  // Create a loop for progress bar value to simulate loading
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <MDBox py={3}>
        <MDTypography variant="h4">Loading...</MDTypography>
      </MDBox>
      <MDBox py={3} pr={32}>
        <MDProgress value={progress} />
      </MDBox>
    </>
  );
};

export default MDLoading;
