/* eslint-disable camelcase */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { newPassword, passwordConfirm, currentPassword },
} = checkout;

const validations = [
  // validation for Ticket Info page
  Yup.object().shape({
    [newPassword.name]: Yup.string()
      .required("New password is required!")
      .notOneOf(
        [Yup.ref("currentPassword")],
        "New password must be different from current password!"
      )
      .min(8, "Password should be at least 8 characters!"),
    [currentPassword.name]: Yup.string().required(currentPassword.errorMsg),
    [passwordConfirm.name]: Yup.string()
      .required(passwordConfirm.errorMsg)
      .oneOf([Yup.ref("newPassword")], "Passwords do not match!"),
  }),
];

export default validations;
