import {
  GET_DASHBOARD_STATS,
  GET_TOPITEMS,
  GET_DASHBOARD_ORDERS,
  GET_DASHBOARD_BOOKINGS,
  STATS_FAIL,
  SET_STATS_LOADING,
  CLEAR_ERRORS,
  LOGOUT,
  GET_CONCIERGE_ORDERS,
  GET_COMPANION_ANALYTICS,
} from "../actions/Types";

const initialState = {
  adminDashboard: {},
  loading: false,
  error: null,
  conciergeOrders: {},
  topItems: [],
  companionAnalytics: {},
};

const variable = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_STATS:
      return {
        ...state,
        loading: false,
        error: null,
        adminDashboard: action.payload.data,
      };
    case GET_TOPITEMS:
      return {
        ...state,
        // loading: false,
        error: null,
        topItems: action.payload.data,
      };
    case GET_DASHBOARD_ORDERS:
      return {
        ...state,
        loading: false,
        error: null,
        orders: action.payload.data.data,
      };
    case GET_DASHBOARD_BOOKINGS:
      return {
        ...state,
        loading: false,
        error: null,
        bookings: action.payload.data.data,
      };
    case GET_CONCIERGE_ORDERS:
      return {
        ...state,
        loading: false,
        error: null,
        conciergeOrders: action.payload.data.data,
      };
    case GET_COMPANION_ANALYTICS:
      return {
        ...state,
        loading: false,
        error: null,
        companionAnalytics: action.payload.data,
      };
    case STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        adminDashboard: {},
      };
    case SET_STATS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case LOGOUT:
      return {
        loading: false,
        error: null,
        adminDashboard: {},
      };

    default:
      return state;
  }
};
export default variable;
