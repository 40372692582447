// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme/base/colors";

const { info, dark } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
  },
  ".dropzoneArea-root .MuiDropzonePreviewList-root": {
    width: "auto",
    margin: 0,
  },
  ".dropzoneArea-root .MuiDropzonePreviewList-imageContainer": {
    padding: "24px 12px!important",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  ".delete-icon-hover:hover": {
    transform: "scale(1.2)",
    transition: "transform 0.2s",
  },
};

export default globals;
