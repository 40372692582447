/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { Formik } from "formik";
import FormField from "components/FormField";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";
// import MultiImage from "components/MultiImage";
import Danger from "components/Danger";

import { addLocationSlide, updateLocationSlide, setLoading } from "store/actions/locationAction";
import { addCitySlide, updateCitySlide } from "store/actions/cityActions";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid"; // Import UUID library
import form from "./form";
import initialValues from "./initialValues";
import validations from "./validations";
import UploadImage from "./UploadImage";

const SliderDialog = ({ open, onClose, id, data, cityBool }) => {
  const dispatch = useDispatch();
  const {
    title,
    subTitle,
    ctaLabel,
    ctaUrl,
    startDate,
    endDate,
    slideDuration,
    images,
    mobileImages,
  } = form.formField;
  const startDateKey = uuidv4();

  const [startDateProm, setStartDateProm] = useState("");
  const [endDateProm, setEndDateProm] = useState("");
  const [initialState, setInitialState] = useState(initialValues);
  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    if (data) {
      const manipulated = {};
      manipulated.title = data?.title || "";
      manipulated.subTitle = data?.subTitle || "";
      manipulated.ctaLabel = data?.ctaLabel || "";
      manipulated.ctaUrl = data?.ctaUrl || "";
      manipulated.slideDuration = data?.slideDuration || 7;
      manipulated.images = data?.image ? [data?.image] : [];
      manipulated.mobileImages = data?.mobileImage ? [data?.mobileImage] : [];
      manipulated._id = data?._id;

      // Set startDate and endDate directly, using null if they are falsy
      manipulated.startDate = data?.startDate || null;
      manipulated.endDate = data?.endDate || null;

      // Set the date strings for display
      setStartDateProm(manipulated.startDate ? manipulated.startDate.split("T")[0] : "");
      setEndDateProm(manipulated.endDate ? manipulated.endDate.split("T")[0] : "");
      setInitialState(manipulated);
    } else {
      // If creating a new slider, set default values
      setStartDateProm("");
      setEndDateProm("");
      setInitialState(initialValues);
    }
  }, [data]);
  const urlToBinary = async (url) => {
    const response = await fetch(`${url}?additional-param`);
    if (!response.ok) {
      throw new Error(
        `Failed to fetch image from ${url}. Status: ${response.status} ${response.statusText}`
      );
    }
    const arrayBuffer = await response.arrayBuffer();
    return new Uint8Array(arrayBuffer);
  };

  const blobToBinary = async (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(new Uint8Array(reader.result));
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });

  const convertImage = async (source) => {
    try {
      let binaryData;

      if (typeof source === "string") {
        // Source is a URL
        binaryData = await urlToBinary(source);
      } else if (source instanceof File) {
        // Source is a File object
        const blob = await blobToBinary(source);
        binaryData = blob;
      } else {
        throw new Error("Unsupported image source type");
      }

      // Create a File object
      const fileName = source instanceof File ? source.name : "defaultImage.jpg";
      const file = new File([binaryData], fileName, { type: "image/jpeg" }); // Update type accordingly

      return file;
    } catch (err) {
      console.error(`Error loading image from ${source}:`, err);
      throw err;
    }
  };

  const processImages = async (imageSources) => {
    try {
      const binaryImages = await Promise.all(imageSources.map(convertImage));
      return binaryImages;
    } catch (err) {
      console.error("Error processing images:", err);
      throw err;
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      dispatch(setLoading());

      // Combine all image sources
      const imageSources = [
        ...(values.images || []),
        ...(values.mobileImages || []),
        ...(values.previousImages || []),
        ...(values.previousMobileImages || []),
      ];

      // Process all images
      const processedImages = await processImages(imageSources);

      // Update values with processed images
      const updatedValues = {
        ...values,
        images: processedImages.filter((image) => image != null),
        // startDate: values.startDate === "" ? null : values.startDate,
        // endDate: values.endDate === "" ? null : values.endDate,
      };
      delete updatedValues.mobileImages;

      // Dispatching the action
      if (Object.keys(data).length > 0) {
        dispatch(
          cityBool ? updateCitySlide(updatedValues, id) : updateLocationSlide(updatedValues, id)
        );
      } else {
        dispatch(cityBool ? addCitySlide(updatedValues, id) : addLocationSlide(updatedValues, id));
      }

      // Reset form state if actions are provided
      if (actions) {
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    } catch (err) {
      console.error("Error processing images:", err);
      // Handle the error appropriately, e.g., show a message to the user
      dispatch(setLoading());
      if (actions) {
        actions.setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validations[0]}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(() => {
          setSubmitting(false);
          handleSubmit(values);
          onClose();
          resetForm(initialValues);
          setStartDateProm("");
          setEndDateProm("");
        }, 500);
      }}
    >
      {({ setFieldValue, values, submitForm, errors, touched, resetForm }) => (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          keepMounted
          onClose={() => {
            onClose();
            resetForm(initialValues);
            setStartDateProm("");
            setEndDateProm("");
          }}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-paper": {
              height: "auto",
              width: { sm: "100%", md: "760px" },
              minWidth: { sm: "100%", md: "760px" },
            },
          }}
        >
          <DialogTitle style={{ textAlign: "center" }}>
            {data.title ? "Edit Slider" : "Add Slider"}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={title.type}
                  label={title.label}
                  name={title.name}
                  value={values?.title}
                  placeholder={title.placeholder}
                  error={errors?.title && touched?.title}
                  success={values?.title?.length > 0 && !errors?.title}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={subTitle.type}
                  label={subTitle.label}
                  name={subTitle.name}
                  value={values?.subTitle}
                  placeholder={subTitle.placeholder}
                  success={values?.subTitle?.length > 0 && !errors?.subTitle}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={ctaLabel.type}
                  label={ctaLabel.label}
                  name={ctaLabel.name}
                  value={values?.ctaLabel}
                  placeholder={ctaLabel.placeholder}
                  error={errors?.ctaLabel && touched?.ctaLabel}
                  success={values?.ctaLabel?.length > 0 && !errors?.ctaLabel}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={ctaUrl.type}
                  label={ctaUrl.label}
                  name={ctaUrl.name}
                  value={values?.ctaUrl}
                  placeholder={ctaUrl.placeholder}
                  error={errors?.ctaUrl && touched?.ctaUrl}
                  success={values?.ctaUrl?.length > 0 && !errors?.ctaUrl}
                />
              </Grid>
              <Grid item xs={6}>
                <MDDatePicker
                  key={today || startDateKey}
                  input={{
                    type: startDate.type,
                    label: startDate.label,
                    name: startDate.name,
                    variant: "standard",
                    width: "100%",
                    value: startDateProm || "",
                  }}
                  onChange={(event, val) => {
                    if (val !== null && val !== "") {
                      setFieldValue("startDate", val);
                      setStartDateProm(val);
                    } else {
                      setFieldValue("startDate", "");
                      setStartDateProm("");
                    }
                  }}
                  inputFormat="dd/MM/yyyy"
                  error={errors.startDate && touched.startDate}
                  success={values?.startDate?.length > 0 && !errors.startDate}
                  options={{
                    minDate: new Date(),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <MDDatePicker
                  key={endDateProm === today ? today : endDateProm?.length}
                  input={{
                    type: endDate.type,
                    label: endDate.label,
                    name: endDate.name,
                    variant: "standard",
                    width: "100%",
                    value: endDateProm || "",
                  }}
                  onChange={(event, val) => {
                    if (val !== null && val !== "") {
                      setFieldValue("endDate", val);
                      setEndDateProm(val);
                    } else {
                      setFieldValue("endDate", "");
                      setEndDateProm("");
                    }
                  }}
                  error={errors.startDate && touched.startDate}
                  success={values?.endDate?.length > 0 && !errors.endDate}
                  options={{
                    minDate: values.startDate || new Date(),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField
                  type={slideDuration.type}
                  label={slideDuration.label}
                  name={slideDuration.name}
                  value={values?.slideDuration}
                  placeholder={slideDuration.placeholder}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={6}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {images.label}
                </MDTypography>
                <UploadImage
                  key={values?.images[0]}
                  defaultImage={values?.images?.[0]}
                  setFieldValue={setFieldValue}
                  fieldName="images"
                />

                {errors?.images && touched?.images && <Danger validation={errors?.images} />}
              </Grid>
              <Grid item sm={6}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {mobileImages.label}
                </MDTypography>
                <UploadImage
                  key={values?.mobileImages[0]}
                  defaultImage={values?.mobileImages[0]}
                  setFieldValue={setFieldValue}
                  fieldName="mobileImages"
                />

                {errors?.mobileImages && touched?.mobileImages && (
                  <Danger validation={errors?.mobileImages} />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onClose();
                resetForm(initialValues);
                setStartDateProm("");
                setEndDateProm("");
              }}
            >
              Cancel
            </Button>
            <Button onClick={submitForm}>{data ? "Save" : "Add"}</Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
// Setting default props
SliderDialog.defaultProps = {
  id: "",
  data: {},
  cityBool: false,
};
SliderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  cityBool: PropTypes.bool,
};

export default SliderDialog;
