import PropTypes from "prop-types";

// Default props
export const defaultProps = {
  data: {
    guests: 2,
    time: "08:00",
    date: "2 Apr 2024",
    name: "John Doe",
    phone: "+123456789",
    email: "john.doe@example.com",
  },
};

// PropTypes validation
export const propTypes = {
  data: PropTypes.shape({
    guests: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    time: PropTypes.string,
    date: PropTypes.string,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};
