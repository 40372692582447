import * as XLSX from "xlsx";

const CsvTrafficReportByCountry = (arr) => {
  const data = arr?.arr;
  const generateExcelFile = () => {
    const workbook = XLSX.utils.book_new();
    const sheetData = [];

    data.forEach((cityData) => {
      const { cityName, cityResults } = cityData;

      // Add table title
      sheetData.push([cityName]);

      // Add table headers
      sheetData.push([
        "Partner",
        "Location",
        "Total views",
        "Total users",
        "New users",
        "Number of sign ups",
        "Number of logins",
        "Revenue",
        "Tickets sold",
        "Conversion ratio (purchases/users)",
      ]);

      // Add table rows
      cityResults.forEach((result) => {
        sheetData.push([
          result.partner,
          result.name,
          parseInt(result.traffic?.screenPageViews, 10),
          parseInt(result?.traffic?.totalUsers, 10),
          parseInt(result?.traffic?.newUsers, 10),
          parseInt(result?.events?.signups, 10),
          parseInt(result?.events?.logins, 10),
          parseInt(result?.traffic?.purchaseRevenue, 10),
          parseInt(result?.totalTickets, 10),
          parseInt(result?.traffic?.averagePurchaseRevenuePerUser, 10),
        ]);
      });

      // Add empty row as separator between tables
      sheetData.push([]);
    });

    const sheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, sheet, `${data[0].cityResults[0].country}`);

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(excelFile);
    downloadLink.download = "traffic_report_by_country.xlsx";
    downloadLink.click();
  };

  return <>{generateExcelFile()}</>;
};

export default CsvTrafficReportByCountry;
