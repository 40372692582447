/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from "axios";

// import { createFormData } from "components/helper";
import {
  CLEAR_ERRORS,
  SET_USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_VERIFICATION_REQUIRED,
  LOGIN_FAIL,
  UPDATE_USER,
  EDIT_ERROR,
  UPDATE_SPECIFIC_USER,
  GET_UPDATED_FILTER,
  CLEAR_FILTER,
  DEACTIVATE,
  ACTIVATE,
  SET_USERS_LOADING,
  CLEAR_ONE_USER,
  SET_UPDATE_LOADING_USERS,
  SET_USER_LOAD,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
} from "./Types";

import { post, patch, get } from "./actionsFactory";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;
// console.log("URL::", URL);
// Logout
export const logout = () => async (dispatch) => {
  try {
    await axios.get(`${URL}/users/deleteCookie`);
    dispatch({
      type: LOGOUT,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

// Change password

export const changePass = (values) =>
  factory.patch(
    {
      passwordCurrent: values.currentPassword,
      password: values.newPassword,
      passwordConfirm: values.passwordConfirm,
    },
    `${URL}/users/updatePassword`,
    "UPDATE_USER",
    "USER_ERROR"
  );

// Login User
export const loginUser = (user) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`${URL}/users/login`, user, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data, // User
    });
  } catch (err) {
    if (err.response.status === 402) {
      dispatch({
        type: LOGIN_VERIFICATION_REQUIRED,
        payload: user.email, // continue here
      });
    } else {
      dispatch({
        type: LOGIN_FAIL,
        payload: err?.response.data.message,
      });
      dispatch(logout());
    }
  }
};

// Check user used to get user info on first load
export const checkUser = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(`${URL}/users/me?isLCM=true`, config);
    dispatch({
      type: USER_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Check token in email url to confirm email
export const checkToken = (emailToken) =>
  get(`${URL}/users/checkResetToken/${emailToken}`, "TOKEN_CONFIRMED", "USER_ERROR");

// Reset User Password
export const resetUserPass = (email) =>
  post({ email }, `${URL}/users/newForgotPassword`, "LINK_SENT", "AUTH_ERROR");

// Forget Password
export const forgotPass = (email) =>
  post({ email }, `${URL}/users/newForgotPassword`, "LINK_SENT", "USER_ERROR");

// Reset Password
export const resetPass = (newPass, token) =>
  patch(
    { password: newPass, passwordConfirm: newPass },
    `${URL}/users/resetPassword/${token}`,
    "RESET_PASS_SUCCESSS",
    "USER_ERROR"
  );

export const registerUser = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("name", data.name.trim());
  form.append("email", data.email);
  form.append("role", data.role);
  if (data?.role === "curator") form.append("top", data.top);

  if (data?.role === "partner") form.append("partner", data.partner);
  if (data?.role === "partner")
    data.locations?.map((location) => {
      form.append("locations", location);
      return location;
    });

  if (data?.role === "concierge" && data.location) form.append("location", data.location);
  if (data?.role === "concierge" && data.destinations?.value)
    form.append("destinations", data.destinations.value);
  if (data?.role === "concierge" && data?.balance > 0) form.append("balance", data.balance);
  if (data?.role === "concierge" && data?.commission > 0)
    form.append("commission", data.commission);

  if (data?.role === "concierge")
    data.promoCodes?.map((promoCode) => {
      form.append("promoCodes", promoCode);
      return promoCode;
    });
  if (data?.role === "concierge" && data?.reference) form.append("reference", data.reference);

  if (data?.role === "curator") {
    form.append("bio", data?.bio);
    data.languages?.map((language) => {
      form.append("languages", language?.value || language);
      return language;
    });
    data.interests?.map((interest) => {
      form.append("interests", interest?.value || interest);
      return interest;
    });
    data.destinations?.map((destination) => {
      form.append("destinations", destination?.value || destination);
      return destination;
    });
    // data.recommendedExperiences?.map((recommendedExperience) => {
    //   form.append("recommendedExperiences", recommendedExperience?.value);
    //   return recommendedExperience;
    // });
    form.append("photo", data?.photo);
    if (Array.isArray(data?.media)) {
      data.media?.map((singleMedia) => {
        form.append("photo", singleMedia);
        return singleMedia;
      });
    } else {
      form.append("media", data?.media);
    }

    form.append("hasLogo", true);
  }
  try {
    const res = await axios.post(`${URL}/users/register`, form, config);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: REGISTER_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Get Users
export const getUsers = (startDate, endDate) =>
  factory.get(`${URL}/users?startDate=${startDate}&endDate=${endDate}`, "GET_USERS", "USER_ERROR");

// get one User
export const getOneUser = (id) => get(`${URL}/users/${id}`, "GET_ONE_USER", "USER_FAIL");

// Update user
export const updateUser = (data, photo) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  if (data.name) {
    data.name = data.name.trim();
  }

  form.append("name", data.name.trim());
  form.append("role", data.role);
  form.append("email", data.email);
  form.append("partner", data.partner?.name);
  form.append("phone", data.phone);
  form.append("dob", data.dob);

  // Check and append photo
  if (photo) {
    form.append("photo", photo);
    form.append("hasLogo", true);
  }
  try {
    const res = await axios.patch(`${URL}/users/updateMe`, form, config);

    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_ERROR,
      payload: err?.response.data.message,
    });
  }
};

export const setUpdateLoadingUsers = () => ({ type: SET_UPDATE_LOADING_USERS });

export const updateSpecificUser = (data, photo) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();
  if (data?.name) form.append("name", data?.name.trim());
  if (data?.email) form.append("email", data.email);
  if (data?.role) form.append("role", data.role);
  if (data?.phone) form.append("phone", data.phone);
  if (data?.role === "curator" && data?.top !== undefined) form.append("top", data?.top);
  if (data?.dob) form.append("dob", data.dob);
  if (data?.role === "partner") {
    form.append("partner", data.partner?._id ? data.partner?._id : data?.partner);
  }
  if (data?.role === "partner")
    data.locations?.map((location) => {
      form.append("locations", location?._id);
      return location;
    });
  if (data?.role === "concierge" && data?.location) form.append("location", data.location);
  if (data?.role === "concierge" && data?.destinations)
    form.append(
      "destinations",
      // eslint-disable-next-line no-nested-ternary
      data.destinations?.id
        ? data.destinations?.id
        : data.destinations?.label
        ? data.destinations?.label
        : data?.destinations
    );
  if (data?.role === "concierge" && data?.balance > 0) form.append("balance", data.balance);
  if (data?.role === "concierge" && data?.commission > 0)
    form.append("commission", data.commission);

  if (data?.role === "concierge")
    data.promoCodes?.map((promoCode) => {
      form.append("promoCodes", promoCode);
      return promoCode;
    });
  if (data?.role === "concierge" && data?.reference) form.append("reference", data.reference);
  if (data?.role === "curator") {
    if (data?.bio) form.append("bio", data?.bio);
    if (data?.languages)
      data.languages?.map((language) => {
        form.append("languages", language?.value || language);
        return language;
      });
    if (data?.interests)
      data.interests?.map((interest) => {
        form.append("interests", interest?.value || interest);
        return interest;
      });
    if (data?.destinations)
      data.destinations?.map((destination) => {
        form.append("destinations", destination?.value || destination);
        return destination;
      });
    // if (data?.recommendedExperiences)
    // data.recommendedExperiences?.map((ticket) => {
    //   form.append("recommendedExperiences", ticket?.value || ticket);
    //   return ticket;
    // });
    if (Array.isArray(data?.media)) {
      if (data.media.length > 0) {
        data.media?.map((singleMedia) => {
          form.append("photo", singleMedia);
          return singleMedia;
        });
      } else {
        form.append("media", data?.media);
      }
    } else if (data?.media !== undefined) {
      form.append("media", data?.media);
    }
  }
  // Check and append photo
  if (photo) {
    form.append("photo", photo);
    form.append("hasLogo", true);
  }
  try {
    const res = await axios.patch(`${URL}/users/${data._id}`, form, config);

    dispatch({
      type: UPDATE_SPECIFIC_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_ERROR,
      payload: err?.response.data.message,
    });
  }
};

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// CLEAR ONE USER
export const clearOneUser = () => ({ type: CLEAR_ONE_USER });

// Set Loading -- Used for login
export const setLoading = () => ({ type: SET_USER_LOADING });
export const setUserLoad = () => ({ type: SET_USER_LOAD });

// Set Loading Users -- used for User pages
export const setLoadingUsers = () => ({ type: SET_USERS_LOADING });

// Get updated filter
export const getUpdatedFilter = (filters) => ({ type: GET_UPDATED_FILTER, payload: filters });

// get clear filters
export const clearFilters = () => ({ type: CLEAR_FILTER });

// Deactivate
export const deactivate = (id) => async (dispatch) => {
  try {
    const response = await axios.patch(`${URL}/users/deactivate/${id}`, {});

    dispatch({
      type: DEACTIVATE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_ERROR,
      paload: err,
    });
  }
};

// Activate
export const activate = (id) => async (dispatch) => {
  try {
    const response = await axios.patch(`${URL}/users/activate/${id}`, {});

    dispatch({
      type: ACTIVATE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_ERROR,
      paload: err,
    });
  }
};

export const checkEmail = (data) =>
  factory.post({ email: data.email }, `${URL}/users/checkEmail`, "CHECK_EMAIL", "CHECK_EMAIL_FAIL");
