import checkout from "./form";

const {
  formField: { title, introText, curator, travelStyle, days },
} = checkout;

const initialValues = {
  // partner page
  [title.name]: "",
  [introText.name]: "",
  [curator.name]: null,
  [travelStyle.name]: "",
  [days.name]: 1,
};

export default initialValues;
