import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { name, email, address, logo, disclaimerText, primaryDestination, destinations },
} = checkout;

const validations = [
  Yup.object().shape({
    [logo.name]: Yup.string().required(logo.errorMsg).nullable(),
    [email.name]: Yup.array().of(
      Yup.string()
        .email(email.invalidMsg)
        .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, email.invalidMsg)
    ),
    [name.name]: Yup.string()
      .matches(/([A-Za-z])/, name.invalidMsg)
      .required(name.errorMsg),

    [disclaimerText.name]: Yup.object().when("disclaimer", {
      is: true,
      then: () =>
        Yup.object()
          .test("editorState", disclaimerText.errorMsg, (value) => {
            if (value && value?.blocks && value?.blocks[0]?.text?.length) {
              return true;
            }
            return false;
          })
          .required("This field is required."),
    }),
  }),
  Yup.object().shape({
    [address.name]: Yup.string()
      .matches(/([A-Za-z\u0600-\u06FF])/, address.invalidMsg)
      .required(address.errorMsg),
    [primaryDestination.name]: Yup.string().required(primaryDestination.errorMsg),
    [destinations.name]: Yup.array()
      .min(1, destinations?.invalidMsg)
      .required(destinations?.errorMsg),
  }),
];

export default validations;
