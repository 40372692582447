const form = {
  formId: "new-partner-form",
  formField: {
    // partner page
    name: {
      name: "name",
      label: "Name *",
      type: "text",
      errorMsg: "Name is required!",
      invalidMsg: "Name should have at least 1 letter!",
    },
    contactEmail: {
      name: "contactEmail",
      label: "Contact Email",
      type: "email",
      errorMsg: "Email address is required!",
      invalidMsg: "Your email address is invalid (email@email.com)!",
    },
    contactName: {
      name: "contactName",
      label: "Contact Name",
      type: "text",
    },
    contactPhone: {
      name: "contactPhone",
      label: "Contact Phone",
      // type: "text",
      errorMsg: "Contact Phone is required!",
      invalidMsg: "Phone number is not valid / should start with (00) or (+)!",
    },
    website: {
      name: "website",
      label: "Website",
      type: "text",
      errorMsg: "website is required!",
      invalidMsg: "Please enter a valid contact website! (http://www.url.com)!",
    },
    licence: {
      name: "licence",
      label: "Licence",
      type: "text",
      errorMsg: "licence is required!",
      invalidMsg: "Should not be zero! ",
    },
    commissionNumber: {
      name: "commissionNumber",
      label: "Commission %",
      type: "number",
    },
    top: {
      name: "top",
      label: "Top",
      //   type: "text",
    },
    address: {
      name: "address",
      label: "Company Address",
      type: "text",
    },
    logo: {
      name: "logo",
      label: "Logo *",
      type: "text",
      errorMsg: "Logo is required!",
    },
  },
};

export default form;
