/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
import axios from "axios";
import { ADD_BANNER, BANNER_FAIL, CLEAR_ERRORS, SET_BANNER_LOADING, UPDATE_BANNER } from "./Types";
// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Get All Banners
export const getBanners = () =>
  factory.get(`${URL}/banners?sort=name&dir=asc`, "GET_ALL_BANNERS", "BANNER_FAIL");

// get One banner
export const getOneBanner = (id) =>
  factory.get(`${URL}/banners/${id}`, "GET_ONE_BANNER", "BANNER_FAIL");

// Get City Banner
export const getCityBanners = (city) =>
  factory.get(`${URL}/banners?city=${city}`, "GET_BANNERS", "BANNER_FAIL");

//   Add Banner
export const addBanner = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();
  // if (image) form.append('images', image);

  form.append("title", data?.title);
  form.append("position", data?.position?.value);

  if (image)
    image?.map((file) => {
      form.append("image", file);
      return file;
    });

  try {
    const res = await axios.post(`${URL}/banners`, form, config);

    dispatch({
      type: ADD_BANNER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BANNER_FAIL,
      payload: err?.response.data.message,
    });
  }
};

export const updateBanner = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  if (data?.title) form.append("title", data?.title);
  if (data?.position) form.append("position", data?.position?.value);

  if (image)
    image?.map((file) => {
      form.append("image", file);
      return file;
    });

  try {
    const res = await axios.patch(`${URL}/banners/${data._id}`, form, config);

    dispatch({
      type: UPDATE_BANNER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BANNER_FAIL,
      payload: err?.response.data.message,
    });
  }
};

//   Remove Banner
export const deleteBanner = (id) =>
  factory.deleteById(id, `${URL}/banners`, "REMOVE_BANNER", "BANNER_FAIL");

//   disable Banner
export const disableBannerToTrue = (val) =>
  factory.patch(
    { disabled: true, position: val?.position?.value },
    `${URL}/banners/${val?._id}`,
    "REMOVE_BANNER",
    "BANNER_FAIL"
  );

export const disableBannerToFalse = (val) =>
  factory.patch(
    { disabled: false, position: val?.position?.value },
    `${URL}/banners/${val?._id}`,
    "REMOVE_BANNER",
    "BANNER_FAIL"
  );

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_BANNER_LOADING });
