// Material Dashboard 2 PRO React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// Material Dashboard 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { size } = typography;

const { borderWidth } = borders;
const { light, text } = colors;

const tableCell = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
      fontSize: `${size.sm} !important`,
    },
    body: {
      fontSize: `${size.sm} !important`,
      color: `${text.main} !important`,
    },
  },
};

export default tableCell;
