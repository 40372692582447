/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, createRef, useState } from "react";

import MaterialTablePicker from "components/MaterialTablePicker";

import { useDispatch, useSelector } from "react-redux";

// Store
import setAlert from "store/actions/alertActions";

import {
  deletePromotion,
  updatePromotion,
  getLocationPromotions,
  clearErrors,
  deleteSinglePromotion,
  setPromotionEmpty,
} from "store/actions/promotionActions";
import { Grid } from "@mui/material";
import Footer from "components/Footer";
import DashboardNavbar from "components/DashboardNavbar";
import DashboardLayout from "layouts/DashboardLayout";
import { getUserPartnerLocations, setLoading, setLocation } from "store/actions/locationAction";

import LocationUserPartner from "components/LocationUserPartner";
import { getPdf, getCsv } from "store/actions/exportAction";
import form from "../new-promotion/schemas/form";

// Store

const Promotions = () => {
  const { location } = form.formField;

  const tableRef = createRef();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.promotions.loading);
  const added = useSelector((state) => state.promotions.added);
  const error = useSelector((state) => state.promotions.error);
  const user = useSelector((state) => state.users.user);
  const promotion = useSelector((state) => state.promotions.promotion);
  const locations = useSelector((state) => state.locations.locations);
  const [onChangeLocationID, setOnChangeLocationID] = useState(false);
  const [locationId, setLocationId] = useState(locations[0]?._id);

  const deletePromotionFn = (id) => {
    dispatch(deletePromotion(id));
  };

  const updatePromotionFn = (data) => {
    const updatedData = { _id: data._id, title: data.title };
    dispatch(updatePromotion(updatedData));
  };

  const handleChange = (option, val) => {
    if (promotion?.location?._id) dispatch(deleteSinglePromotion());

    tableRef.current.onQueryChange();
    setOnChangeLocationID(true);
    setLocationId(val?._id);
    dispatch(setLocation(val?._id));
    getLocationPromotions(val?._id);
  };

  useEffect(() => {
    dispatch(setPromotionEmpty());
    if (user?.role === "partner") {
      dispatch(setLoading());
      dispatch(getUserPartnerLocations());
    }
  }, []);

  useEffect(() => {
    if (locations?.length > 0 && !promotion?.location?._id) {
      setLocationId(locations[0]?._id);
    }
    // eslint-disable-next-line
  }, [locations]);

  // to add use Effect for set locaton ID when navigating back form edit-promotion
  useEffect(() => {
    if (promotion?.location?._id && locationId !== promotion?.location?._id) {
      setLocationId(promotion?.location?._id);
      dispatch(setLocation(locationId || promotion?.location?._id));
      getLocationPromotions(locationId || promotion?.location?._id);
    }
  }, [promotion?.location?._id, locationId]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  const columns = ["Title", "Recommendation", "Location Name", "Start Date", "End Date", "Source"];
  const partnerColumns = ["Title", "Recommendation", "Start Date", "End Date", "Source"];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item xs={4}>
          {user?.role === "partner" && locations.length > 0 && (
            <LocationUserPartner
              defaultLocationId={locationId}
              handleChange={handleChange}
              locations={locations || {}}
              location={location}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {((user.role === "partner" && locationId) || user.role === "admin") && (
            <MaterialTablePicker
              key={added}
              screen="PromotionsList"
              title=""
              columns={user?.role === "admin" ? columns : partnerColumns}
              tableRef={tableRef}
              asyncTable
              sortField="title"
              searchField="promotions"
              exportMenuField="promotions"
              idSynonymName="_id"
              onRowClickURL={`/${user.role}/promotions`}
              currentUrl="promotions"
              asyncURL={
                user.role === "admin"
                  ? `${process.env.REACT_APP_API}/promotions?`
                  : onChangeLocationID
                  ? `/v2/locations/${locationId}/promotions?`
                  : promotion?.location?._id && promotion?.location?._id !== locationId
                  ? `/v2/locations/${promotion?.location?._id}/promotions?`
                  : `/v2/locations/${locationId}/promotions?`
              }
              addUrl="promotions/new-promotion"
              actionsName="Promotion"
              editableUpdate={updatePromotionFn}
              editableDelete={deletePromotionFn}
              isLoading={loading}
              updateRoleAction
              deleteRoleAction
              editableRole={user.role === "admin"}
              actionsRole={user.role === "admin" || user.role === "partner"}
              getPdf={(value, query) => {
                if (value) {
                  if (user?.role === "partner") {
                    dispatch(
                      getPdf(
                        value,
                        `locations/${locationId}/promotions/createPdf${query}&pdf=true&fields=title,recommendation,location,startDate,endDate,source`,
                        "Promotions"
                      )
                    );
                  } else {
                    dispatch(
                      getPdf(
                        value,
                        `stats/pdf${query}&fields=title,recommendation,location,startDate,endDate,source`,
                        "Promotions"
                      )
                    );
                  }
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for PDF export.");
                }
              }}
              getCsv={(value, query) => {
                if (value) {
                  if (user?.role === "partner") {
                    dispatch(
                      getCsv(
                        `${process.env.REACT_APP_API}/locations/${locationId}/promotions/csv${query}&csv=true&fields=title,recommendation,location,startDate,endDate,source,placeName`,
                        "Promotions"
                      )
                    );
                  } else {
                    dispatch(
                      getCsv(
                        value,
                        `stats/csv${query}&fields=title,recommendation,location,startDate,endDate,source`,
                        "Promotions"
                      )
                    );
                  }
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for CSV export.");
                }
              }}
            />
          )}
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default Promotions;
