/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import PropTypes from "prop-types";

const InterestsCuratorPicker = ({ interests, interestsV, setFieldValue, ...rest }) => {
  const interestsList = [
    "Sports",
    "Fashion & Beauty",
    "Health & Wellness",
    "Food",
    "Nightlife",
    "Adventures",
    "Arts",
    "Culture",
    "Rural Experiences",
  ];

  return (
    <Autocomplete
      id="interests"
      multiple
      filterSelectedOptions
      //   renderTags={() => null}
      options={interestsList}
      onChange={(event, value) => {
        if (value) {
          setFieldValue("interests", value);
        } else {
          setFieldValue("interests", []);
        }
      }}
      value={interestsV}
      getOptionLabel={(option) => option || ""}
      // isOptionEqualToValue={(option, value) => option._id === value._id}
      // isOptionEqualToValue={(option) => option}
      renderInput={(params) => (
        <FormField
          {...params}
          {...rest}
          label={interests?.label}
          name={interests?.name}
          onChange={() => null}
          // value={interestsV}
        />
      )}
    />
  );
};

// typechecking props for InterestsCuratorPicker
InterestsCuratorPicker.propTypes = {
  interests: PropTypes.instanceOf(Object).isRequired,
  interestsV: PropTypes.instanceOf(Array).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default InterestsCuratorPicker;
