const form = {
  formId: "new-experience-form",
  formField: {
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
    },
    method: {
      name: "method",
      label: "Method",
      type: "text",
    },
    code: {
      name: "code",
      label: "Discount Code",
      type: "text",
      errorMsg: "Discount Code is required!",
    },
    type: {
      name: "type",
      label: "Type",
      type: "text",
    },
    value: {
      name: "value",
      label: "Value",
      type: "number",
      errorMsg1: "Percentage is required!",
      errorMsg2: "Fixed amount is required!",
    },
    appliesTo: {
      name: "appliesTo",
      label: "Applies To",
      type: "text",
    },
    minimumType: {
      name: "minimumType",
      label: "Minimum Type",
      type: "text",
    },
    minimumValue: {
      name: "minimumValue",
      label: "Minimum Value",
      type: "number",
    },
    eligibility: {
      name: "eligibility",
      label: "Eligibility",
      type: "text",
    },
    destinations: {
      name: "destinations",
      label: "Destinations *",
      type: "text",
      errorMsg: "Destinations is required!",
    },
    locations: {
      name: "locations",
      label: "Locations",
      type: "text",
      errorMsg: "Locations is required!",
    },

    tickets: {
      name: "tickets",
      label: "Tickets",
      type: "text",
      errorMsg: "Tickets is required!",
    },
    startDate: {
      name: "startDate",
      label: "Start date",
      type: "text",
      //   errorMsg: "Date is required!",
    },
    endDate: {
      name: "endDate",
      label: "End date",
      type: "text",
      //   errorMsg: "Date is required!",
    },
  },
};

export default form;
