import {
  SET_REPORT_LOADING,
  HEADER_INFO_SUCCESS,
  REPORT_ADD_SUCCESS,
  INVOICE_ADD_SUCCESS,
  REPORT_FAIL,
  INVOICE_FAIL,
  CLEAR_ERRORS,
  CLEAR_INVOICE,
  CLEAR_HEADER_AND_REPORTS,
  PRODUCTION_REPORTS_ADD_SUCCESS,
  CART_REPORTS_ADD_SUCCESS,
  TRAFFIC_REPORTS_BY_COUNTRY_ADD_SUCCESS,
  GET_DROPLIST_TRAFFIC_REPORTS,
  AVERAGE_CART_VALUE_SUCCESS,
} from "../actions/Types";

const initialState = {
  loading: false,
  reports: [],
  error: null,
  added: false,
  report: null,
  invoices: [],
  headerInfo: {},
  productionReports: {},
  averageCartValue: 0,
  cartReports: [],
  trafficReportsByCountry: [],
  droplistTrafficReportPartner: [],
  droplistTrafficReportCountry: [],
  droplistTrafficReportCity: [],
  droplistTrafficReportLocation: [],
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        reports: [...state.reports, action.payload.data],
        added: true,
        report: null,
        productionReports: {},
      };
    case INVOICE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        reports: [],
        invoices: [...state.reports, action.payload.data.data],
        added: true,
        report: null,
      };
    case CLEAR_INVOICE:
      return {
        ...state,
        loading: false,
        error: null,
        reports: [],
        invoices: [],
        added: true,
        report: null,
      };
    case HEADER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        added: true,
        report: null,
        headerInfo: action.payload.data,
        productionReports: {},
      };

    case REPORT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        productionReports: {},
      };
    case INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_REPORT_LOADING:
      return {
        ...state,
        loading: true,
        added: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case AVERAGE_CART_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        averageCartValue: action.payload.data.averageCartAmount,
      };
    case CLEAR_HEADER_AND_REPORTS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
        headerInfo: {},
        reports: [],
        productionReports: {},
        trafficReportsByCountry: [],
        droplistTrafficReport: {},
        droplistTrafficReportCountry: [],
        droplistTrafficReportPartner: [],
        droplistTrafficReportCity: [],
        droplistTrafficReportLocation: [],
      };
    case PRODUCTION_REPORTS_ADD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
        headerInfo: {},
        reports: [],
        productionReports: action.payload,
      };
    case CART_REPORTS_ADD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
        headerInfo: {},
        reports: [],
        cartReports: action.payload,
        productionReports: [],
      };
    case TRAFFIC_REPORTS_BY_COUNTRY_ADD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        added: true,
        headerInfo: {},
        reports: [],
        trafficReportsByCountry: action.payload,
      };
    case GET_DROPLIST_TRAFFIC_REPORTS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
        headerInfo: {},
        reports: [],
        trafficReportsByCountry: [],
        droplistTrafficReportCountry: action.payload.country,
        droplistTrafficReportPartner: action.payload.partner,
        droplistTrafficReportCity: action.payload.city,
        droplistTrafficReportLocation: action.payload.locationName,
      };

    default:
      return state;
  }
};
export default variable;
