import React from "react";
import { Body, Container, Html, Text } from "@react-email/components";

import { defaultProps, propTypes } from "./Proptypes";

import FooterSection from "../../FooterSection";
import LogoSection from "../../LogoSection/index";
import ReservationCard from "../Components/ReservationCard";

const ClientAutoConfirmation = ({ data }) => (
  <Html>
    <Body
      style={{
        display: "flex",
        color: "#222D29",
        alignItems: "center",
        fontFamily: "Helvetica",
        justifyContent: "center",
        backgroundColor: "#f7fafc",
      }}
    >
      <Container
        style={{
          maxWidth: "600px",
        }}
      >
        <LogoSection />

        <Text
          style={{
            fontSize: "24px",
            fontWeight: "400",
            lineHeight: "26.5px",
            padding: "30px 32px",
          }}
        >
          A guest has just made a reservation at your restaurant
        </Text>

        <div style={{ padding: "0px 24px", marginBottom: "34px" }}>
          <ReservationCard data={data} />
        </div>

        <FooterSection hasSocialLinks={false} />
      </Container>
    </Body>
  </Html>
);

ClientAutoConfirmation.defaultProps = defaultProps;
ClientAutoConfirmation.propTypes = propTypes;

export default ClientAutoConfirmation;
