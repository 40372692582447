/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";

const LocationReportPicker = ({
  setFieldValue,
  locationReportPage,
  handleChange,
  locations,
  loading,
  location,
}) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  // eslint-disable-next-line no-unused-vars
  const getOptionStyle = (base, { isFocused, isSelected }) => ({
    ...base,
    fontSize: "15px",
    backgroundColor: isFocused ? "#DDEBFF" : "white",
    color: "black",
  });

  return (
    <div>
      <SelectF
        onMenuOpen={document.body}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        closeMenuOnScroll={(e) => !e.target?.classList?.contains("select__menu-list")}
        className="basic-single"
        classNamePrefix="select"
        value={location?.name ? location : null}
        isLoading={loading}
        isClearable
        onChange={(option) => {
          handleChange(option);

          if (location) setFieldValue("location", option || {});
          else {
            setFieldValue("location", option || "");
          }
        }}
        isSearchable
        getOptionValue={(option) => `${option.value}`}
        getOptionLabel={(option) => `${option.name}`}
        name="location"
        id="location"
        options={locations}
        placeholder={locationReportPage ? "Select a Location" : "All"}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      />
    </div>
  );
};
// Setting default props
LocationReportPicker.defaultProps = {
  loading: false,
  handleChange: () => {},
  locationReportPage: false,
};

LocationReportPicker.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  locations: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  locationReportPage: PropTypes.bool,
};

export default LocationReportPicker;
