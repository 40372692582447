/* eslint-disable no-underscore-dangle */

import { useEffect, useRef, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";

// NewUser page components
import { addPartner, clearErrors, setLoading } from "store/actions/partnerAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import setAlert from "store/actions/alertActions";
import { scrollToTop, useHandleError } from "components/helper";

// NewUser layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import PartnerInfo from "./components/PartnerInfo";
import ContactInfo from "./components/ContactInfo";
import Review from "./components/Review";

function getSteps() {
  return ["Partner Info", "Contact info", "Review"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <PartnerInfo formData={formData} />;
    case 1:
      return <ContactInfo formData={formData} />;
    case 2:
      return <Review formData={formData} />;
    default:
      return null;
  }
}

const NewPartner = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // state
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  // selector
  const error = useSelector((state) => state.partners.error);

  // useEffect
  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    setSteps(getSteps("admin"));
  }, []);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    scrollToTop(); // Scroll to the top
  };

  const submitForm = async (values, actions) => {
    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    dispatch(addPartner(values, values?.logo));
    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
      navigate("/admin/partners");
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      scrollToTop(); // Scroll to the top
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={steps[activeStep] === "Review" ? undefined : currentValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                          setFieldValue,
                        })}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <div>
                            <MDButton
                              onClick={() => navigate("/admin/partners")}
                              rel="noreferrer"
                              variant="outlined"
                              color="dark"
                              sx={{ marginRight: "8px" }}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "add" : "next"}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewPartner;
