import checkout from "./form";

const {
  formField: {
    name,
    website,
    address,
    contactName,
    contactEmail,
    contactPhone,
    licence,
    commissionNumber,
    top,
    logo,
  },
} = checkout;

const initialValues = {
  // partner page
  [name.name]: "",
  [contactEmail.name]: "",
  [website.name]: "",
  [commissionNumber.name]: 50,
  [contactName.name]: "",
  [address.name]: "",
  [contactPhone.name]: "",
  [top.name]: false,
  [licence.name]: 1,
  [logo.name]: "",
};

export default initialValues;
