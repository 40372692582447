/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";
import { checkUser } from "store/actions/userActions";
import Loading from "pages/loading";
// Images
import LokaleeIcon from "assets/images/Lokalee-logo.svg";
import LokaleeMiniIcon from "assets/images/Lokalee-icon-mini.svg";
// Material Dashboard 2 PRO React examples
import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

import routes from "admin.routes";

const AdminRoute = ({ children }) => {
  const [controller, dispatch] = useMaterialUIController();
  const isAuthenticated = useSelector((state) => state.users.isAuthenticated);
  const user = useSelector((state) => state.users.user);
  const loading = useSelector((state) => state.users.loading);
  const { miniSidenav } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const dispatchRedux = useDispatch();
  useEffect(() => {
    if (!isAuthenticated) {
      dispatchRedux(checkUser());
    }
  }, []);

  return isAuthenticated && user.role === "admin" && !loading ? (
    <>
      <Sidenav
        color="primaryC"
        brand={miniSidenav ? LokaleeMiniIcon : LokaleeIcon}
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Configurator />
      {children}
    </>
  ) : !loading ? (
    <Navigate to="/" />
  ) : (
    <Loading />
  );
};
// Setting default props
AdminRoute.defaultProps = {
  children: {},
};

AdminRoute.propTypes = {
  children: PropTypes.instanceOf(Object),
};

export default AdminRoute;
