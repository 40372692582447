/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Material UI components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TranslateIcon from "@mui/icons-material/Translate";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useHandleError } from "components/helper";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicTranslate from "components/DynamicTranslate";
// import CardsList from "components/CardsList";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import {
  updateFacility,
  clearErrors,
  getOneFacility,
  updateFacilityTranslations,
  setLoading,
} from "store/actions/facilityActions";
import setAlert from "store/actions/alertActions";
// Schemas
import * as Yup from "yup";
import validations from "../new-facility/schemas/validations";

import form from "../new-facility/schemas/form";
import initialValues from "../new-facility/schemas/initialValues";
import FacilityDetail from "./components/FacilityDetail";

const EditFacility = () => {
  const { formId, formField } = form;
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   const loading = useSelector((state) => state.facilities.loading);
  const facility = useSelector((state) => state.facilities.facility);
  //   const facilities = useSelector((state) => state.facilities.facilities);
  const error = useSelector((state) => state.facilities.error);
  const added = useSelector((state) => state.facilities.added);
  const user = useSelector((state) => state.users.user);
  //   const [tabValue, setTabValue] = useState(0);

  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [image, _setImage] = useState(facility?.image);
  const locationSchemaValidation = Yup.object().shape({
    location: Yup.object().required("Please select a location!"),
  });
  const [facilityPage, setFacilityPage] = useState(true);
  const [initialState, setInitialState] = useState(initialValues);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [translateItems, setTranslateItems] = useState([]);

  const [searchParams] = useSearchParams();
  const source = searchParams.get("source");

  const detailTabs = [
    { id: 1, title: "DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "TRANSLATE", iconComponent: TranslateIcon },
  ];

  const { facility_id } = useParams();

  useEffect(() => {
    if (facility_id) {
      dispatch(getOneFacility(facility_id));
    }
  }, [facility_id, added]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (facility?.image) {
      _setImage(facility?.image);
    }
  }, [facility?.image]);

  useEffect(() => {
    if (facility) {
      const arTranslation = facility?.translations?.ar;

      const arr = [
        {
          id: 1,
          name: "title",
          title: "Title",
          value: arTranslation?.title || "",
          default: "",
          label: "Title",
        },
        {
          id: 2,
          name: "description",
          title: "Description",
          value: arTranslation?.description || "",
          default: "",
          label: "Description",
        },
        {
          id: 3,
          name: "ctaTitle",
          title: "Call to action Label",
          value: arTranslation?.ctaTitle || "",
          default: "",
          label: "Call to action Label",
        },
      ];
      setTranslateItems(arr);
    }
  }, [facility]);

  useEffect(() => {
    if (facility) {
      const manipulated = {};
      manipulated.title = facility?.title ? facility?.title : "";
      manipulated.location = facility?.location ? facility?.location : {};
      manipulated.image = facility?.image ? facility?.image : "";
      manipulated.file = facility?.file ? facility?.file : "";
      manipulated.ctaLink = facility?.ctaLink ? facility?.ctaLink : "";
      manipulated.ctaTitle = facility?.ctaTitle ? facility?.ctaTitle : "";
      manipulated.description = facility?.description ? facility?.description : "";
      manipulated.subHeader = facility?.subHeader ? facility?.subHeader : "";
      manipulated.openHours = facility?.openHours ? facility?.openHours : "";
      manipulated._id = facility?._id;

      setSelectedLocation(facility?.location);
      setSelectedFile(facility?.file);
      setInitialState(manipulated);
    }
  }, [facility]);

  const handleLocationChange = (value) => {
    if (value && value?._id) {
      setSelectedLocation(value);
    } else {
      setSelectedLocation({});
    }
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm = async (values) => {
    const {
      title = "",
      location = "",
      //   image = "",
      file = "",
      ctaLink = "",
      ctaTitle = "",
      description = "",
      subHeader = "",
      openHours = "",
    } = values;

    const baseValues = { _id: values?._id };
    let additionalValues = {};

    additionalValues = {
      title,
      location,
      //   image,
      file,
      ctaLink,
      ctaTitle,
      description,
      subHeader,
      openHours,
    };

    const updatedValues = { ...baseValues, ...additionalValues };
    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    dispatch(updateFacility(updatedValues, values?.image));
    setFacilityPage(true);
    setImgDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      if (source === "location") {
        navigate(-1);
      } else {
        url = `/admin/otherFacilities?`;
        navigate(url);
      }
    } else {
      navigate(-1);
    }
  };

  const handleSubmitTranslate = (values) => {
    dispatch(updateFacilityTranslations(values, facility?._id));
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return (
          <DynamicTranslate
            items={translateItems}
            handleSubmit={handleSubmitTranslate}
            id={facility?._id}
          />
        );

      default:
        return null;
    }
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneFacility(facility._id));
    }
  };

  function getStepContent(type, formData) {
    if (type === "Information") {
      return (
        <FacilityDetail
          type={type}
          selectedLocation={selectedLocation}
          selectedFile={selectedFile}
          handleLocationChange={handleLocationChange}
          formData={formData}
          detailPage={facilityPage}
          setDetailPage={setFacilityPage}
          handleEdit={() => {
            formData.resetForm();
            setFacilityPage(false);
            setImgDetailPage(true);
          }}
        />
      );
    }
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar hide={user.role === "partner"} />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          {" "}
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={validations[0]?.concat(locationSchemaValidation)}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      user={facility}
                      image={image}
                      imgDetailPage={imgDetailPage}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      imageType="image"
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          stateName="facilities"
                          handleEdit={() => {
                            setImgDetailPage(false);
                            setFacilityPage(true);
                          }}
                          handleClose={() => {
                            _setImage(facility?.image);

                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);

                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <>
                        {["Information"].map((item, index) => (
                          <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                            <MDBox p={2}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item xs={12}>
                                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    {getStepContent(item, {
                                      values,
                                      touched,
                                      formField,
                                      errors,
                                      setFieldValue,
                                      resetForm,
                                    })}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </Card>
                        ))}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditFacility;
