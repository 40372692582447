/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "components/FormField";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCuisinesFeatures } from "store/actions/recommendationActions";

const FeaturesPicker = ({ features, featuresV, setFieldValue, ...rest }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);

  const allFeatures = useSelector((state) => state.recommendations.features);
  const featuresVObjects = featuresV.map((feature) => ({ name: feature, label: feature }));

  useEffect(() => {
    dispatch(getCuisinesFeatures());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (allFeatures?.length > 0) {
      setList(allFeatures?.map((t) => ({ name: t, label: t })));
    }
  }, [allFeatures]);

  return (
    <Autocomplete
      id="features"
      multiple
      filterSelectedOptions
      options={list}
      onChange={(event, value) => {
        if (value) {
          const selectedFeatures = value.map((feature) => feature.name);
          setFieldValue("features", selectedFeatures);
        } else {
          setFieldValue("features", "");
        }
      }}
      value={featuresVObjects}
      getOptionLabel={(option) => option?.label || ""}
      filterOptions={(options) => options.filter((option) => !featuresV.includes(option.name))}
      // isOptionEqualToValue={(option, value) => option._id === value._id}
      renderInput={(params) => (
        <FormField
          {...params}
          {...rest}
          label={features?.label}
          name={features?.name}
          onChange={() => null}
          // value={featuresV}
        />
      )}
    />
  );
};

// typechecking props for FeaturesPicker
FeaturesPicker.propTypes = {
  features: PropTypes.instanceOf(Object).isRequired,
  // featuresV: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  featuresV: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default FeaturesPicker;
