/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-cycle */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import FormField from "components/FormField";
import StopsTypePicker from "components/AutoComplete/StopsTypePicker";
import TicketsPicker from "components/AsyncSelect/TicketsPicker";
import VenuesPicker from "components/AsyncSelect/VenuesPicker";
import UploadImage from "pages/content/locations/new-location/components/Sliders/UploadImage";
import { useDispatch, useSelector } from "react-redux";
import DynamicTabs from "components/DynamicTabs";
import DataTable from "components/Tables/DataTable";
import { deleteStop, getOneItinerary } from "store/actions/itineraryActions";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Danger from "components/Danger";
import StopsList from "pages/content/experiences/edit-experience/components/StopsList";

const ItineraryStops = ({
  formData,
  id,
  noTable,
  editPage,
  handleSlotChange,
  handleTicketChange,
  selectedTickets,
  handleMediaChange,
  tabValue,
  setTabValue,
}) => {
  const dispatch = useDispatch();
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { slot, description, type, ctaTitle, ctaLabel, ctaUrl, venues, tickets, media, video } =
    formField;
  const {
    slot: slotV,
    description: descriptionV,
    type: typeV,
    ctaTitle: ctaTitleV,
    ctaLabel: ctaLabelV,
    ctaUrl: ctaUrlV,
    venues: venuesV,
    media: mediaV,
    image: imageV,
    video: videoV,
  } = values;

  const [content, setContent] = useState(null);

  const itinerary = useSelector((state) => state.itineraries.itinerary);

  useEffect(() => {
    if (id) {
      dispatch(getOneItinerary(id));
    }
  }, [id]);

  const slotOptions = [
    { value: "morning", label: "Morning" },
    { value: "afternoon", label: "Afternoon" },
    { value: "evening", label: "Evening" },
    { value: "all day", label: "All day" },
  ];

  const mediaOptions = [
    { value: "none", label: "None" },
    { value: "image", label: "Image" },
    { value: "video", label: "Video" },
  ];

  const [stopTabs, setStopTabs] = useState([]);

  useEffect(() => {
    if (itinerary?.days) {
      const daysCount = itinerary.days;
      const tabs = Array.from({ length: daysCount }, (_, index) => ({
        id: index + 1,
        title: `Day ${index + 1}`,
      }));
      setStopTabs(tabs);
    }
  }, [itinerary]);

  useEffect(() => {
    if (typeV === "tickets") {
      setContent(
        <Grid item xs={12}>
          <TicketsPicker
            tickets={tickets}
            ticketsV={
              selectedTickets?.length > 0 && selectedTickets[0]?._id?.length > 0
                ? selectedTickets?.map((item) => ({
                    label: item?.title,
                    value: item?._id,
                  }))
                : selectedTickets
            }
            setFieldValue={setFieldValue}
            handleChange={handleTicketChange}
          />
          {errors?.tickets && <Danger validation={errors?.tickets} />}
        </Grid>
      );
    } else if (typeV === "venues") {
      setContent(
        <Grid item xs={12}>
          <VenuesPicker venues={venues} venuesV={venuesV} setFieldValue={setFieldValue} />
          {errors?.venues && <Danger validation={errors?.venues} />}
        </Grid>
      );
    } else {
      setContent(
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <FormField
              type={ctaTitle?.type}
              label={ctaTitle?.label || "CTA Title"}
              name={ctaTitle?.name || "ctaTitle"}
              //   value={ctaTitleV}
              placeholder={ctaTitle?.placeholder}
              error={errors.ctaTitle && touched.ctaTitle}
              success={ctaTitleV?.length > 0 && !errors.ctaTitle}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              type={ctaLabel?.type}
              label={ctaLabel?.label || "CTA Label"}
              name={ctaLabel?.name || "ctaLabel"}
              //   value={ctaLabelV}
              placeholder={ctaLabel?.placeholder}
              error={errors.ctaLabel && touched.ctaLabel}
              success={ctaLabelV?.length > 0 && !errors.ctaLabel}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={ctaUrl?.type}
              label={ctaUrl?.label || "CTA URL"}
              name={ctaUrl?.name || "ctaUrl"}
              //   value={ctaUrlV}
              placeholder={ctaUrl?.placeholder}
              error={errors.ctaUrl && touched.ctaUrl}
              success={ctaUrlV?.length > 0 && !errors.ctaUrl}
            />
          </Grid>
        </Grid>
      );
    }
  }, [typeV, errors]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (newValue >= 0 && newValue < itinerary?.days) {
      //   const selectedDay = `Day ${newValue + 1}`;
      //   setTypeVal(selectedDay.toUpperCase());
    }
  };

  const handleDeleteStop = (stopId) => {
    dispatch(deleteStop(itinerary._id, stopId));
  };

  const [state, setState] = useState({
    columns: [
      { Header: "Day number", accessor: "dayNb", width: "55%" },
      { Header: "Slot", accessor: "slot" },
      { Header: "Item", accessor: "item" },
      { Header: "", accessor: "delete" },
    ],
    rows: [],
  });

  useEffect(() => {
    if (itinerary?.stops && itinerary?.stops.length > 0 && itinerary?.stops[0]?._id) {
      const transformedData = itinerary.stops?.map((stop) => {
        const dayNb = `Day ${stop.day}`;
        const slotValue = stop.slot || ""; // Rename inner slot variable
        let items = "";

        if (stop.type === "Ticket") {
          items = stop.ids?.map((ticket) => ticket.title).join(", ");
        } else if (stop.type === "Venue") {
          items = stop.ids?.map((venue) => venue.title).join(", ");
        } else {
          items = stop.ctaTitle || "";
        }

        return {
          dayNb,
          slot: slotValue,
          item: items,
          delete: (
            <IconButton onClick={() => handleDeleteStop(stop._id)} color="error" size="small">
              <DeleteIcon />
            </IconButton>
          ),
        };
      });

      setState({ ...state, rows: transformedData });
    }
  }, [itinerary?.stops]);

  return (
    <MDBox>
      {!editPage ? (
        <MDBox lineHeight={0}>
          <MDTypography variant="h5">Stops</MDTypography>
        </MDBox>
      ) : null}
      {editPage ? (
        <StopsList
          stops={values?.stops || []}
          slotOptions={slotOptions}
          numberOfDays={values?.days}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3} sx={{ width: "100%", overflow: "auto" }}>
            <Grid item xs={12} md="auto">
              <MDBox>
                <DynamicTabs
                  detailTabs={stopTabs}
                  tabValue={tabValue}
                  handleSetTabValue={handleSetTabValue}
                />
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DynamicRadioGroup
                groupLabel={slot?.label || "Slot"}
                name={slot?.name || "slot"}
                value={slotV}
                options={slotOptions}
                setFieldValue={setFieldValue}
                handleChange={handleSlotChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type={description?.type}
                label={description?.label || "Description"}
                name={description?.name || "description"}
                value={descriptionV}
                placeholder={description?.placeholder}
                error={errors.description && touched.description}
                success={descriptionV?.length > 0 && !errors?.description}
              />
            </Grid>
            <Grid item xs={12}>
              <StopsTypePicker type={type} typeV={typeV} setFieldValue={setFieldValue} />
            </Grid>
            {content}
            <Grid item xs={12}>
              <DynamicRadioGroup
                groupLabel={media?.label || "Media"}
                name={media?.name || "media"}
                value={mediaV}
                options={mediaOptions}
                setFieldValue={setFieldValue}
                handleChange={handleMediaChange}
              />
            </Grid>
            {mediaV === "video" || mediaV === "image" ? (
              <Grid item xs={12}>
                {mediaV === "video" ? (
                  <FormField
                    type={video?.type}
                    label={video?.label}
                    name={video?.name}
                    value={videoV || values?.video}
                    placeholder={video.placeholder}
                    error={errors.video && touched.video}
                    success={videoV?.length > 0 && !errors.video}
                  />
                ) : (
                  <UploadImage
                    key={imageV[0]}
                    defaultImage={imageV[0]}
                    setFieldValue={setFieldValue}
                    fieldName="image"
                  />
                )}
              </Grid>
            ) : null}
          </Grid>

          {!noTable && (
            <MDBox py={5}>
              <DataTable
                table={state}
                showTotalEntries={false}
                isSorted={false}
                entriesPerPage={false}
              />
            </MDBox>
          )}
        </MDBox>
      )}
    </MDBox>
  );
};

ItineraryStops.defaultProps = {
  editPage: false,
  handleSlotChange: () => {},
  handleTicketChange: () => {},
  selectedTickets: [],
  handleMediaChange: () => {},
  noTable: false,
};

ItineraryStops.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  id: PropTypes.string.isRequired,
  editPage: PropTypes.bool,
  handleSlotChange: PropTypes.func,
  handleTicketChange: PropTypes.func,
  selectedTickets: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  handleMediaChange: PropTypes.func,
  tabValue: PropTypes.number.isRequired,
  setTabValue: PropTypes.func.isRequired,
  noTable: PropTypes.bool,
};

export default ItineraryStops;
