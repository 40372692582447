/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";
import { createRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import setAlert from "store/actions/alertActions";
import {
  removeTrend,
  clearErrors,
  getDestinationsTrends,
} from "store/actions/uniqueExperienceActions";

const UniqueExperiencesList = ({ list, data, loading, selectedDestinations }) => {
  const dispatch = useDispatch();
  const tableRef = createRef();

  const user = useSelector((state) => state.users.user);
  const error = useSelector((state) => state.trends.error);
  const added = useSelector((state) => state.trends.added);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);
  useEffect(() => {
    if (added) dispatch(getDestinationsTrends(selectedDestinations));
  }, [added]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const deleteUniqueExperienceFn = (id) => {
    const uniqueExpToBeDeleted = tableData.find((trend) => trend._id === id);
    if (uniqueExpToBeDeleted) {
      if (uniqueExpToBeDeleted.type === "item") {
        dispatch(
          removeTrend(true, {
            _id: id,
          })
        );
      } else {
        dispatch(
          removeTrend(false, {
            _id: id,
          })
        );
      }
    }
  };

  return (
    <MDBox>
      <MaterialTablePicker
        key={tableData?.length}
        screen={list.screen}
        title={list.title}
        tableRef={tableRef}
        onSearchChange
        columns={list.columns}
        filteringOptions={false}
        data={tableData}
        isLoading={loading}
        isExportable={false}
        editableDelete={(oldData) => deleteUniqueExperienceFn(oldData)}
        deleteRoleAction
        currentUrl="uniqueExperiences"
        addUrl="uniqueExperiences/new-uniqueExperience"
        editableRole={user.role === "admin"}
        isRowClickable={false}
        // actionsOnClick={() => handleClickOpen()}
        actionsRole={user.role === "admin"}
      />
    </MDBox>
  );
};

// Setting default props
UniqueExperiencesList.defaultProps = {
  data: [],
  list: {},
  selectedDestinations: {},
};

// typechecking props for UniqueExperiencesList
UniqueExperiencesList.propTypes = {
  list: PropTypes.instanceOf(Object),
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  selectedDestinations: PropTypes.instanceOf(Object),
  loading: PropTypes.bool.isRequired,
};

export default UniqueExperiencesList;
