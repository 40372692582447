import { PDF_FAIL, PDF_SUCCESS, CSV_FAIL, CSV_SUCCESS, SET_EXPORT_LOADING } from "../actions/Types";

const initialState = {
  loading: false,
};

const variable = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPORT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PDF_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pdf: action.payload,
      };
    case CSV_FAIL:
      return {
        ...state,
        loading: false,
        loadingUsers: false,
        error: action.payload,
      };
    case CSV_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingUsers: false,
        error: null,
        csv: action.payload,
      };

    default:
      return state;
  }
};
export default variable;
