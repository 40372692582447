/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider, Grid } from "@mui/material";
import FormField from "components/FormField";
// import Danger from "components/Danger";
// import MDTimePicker from "components/MDTimePicker";
import { v4 as uuidv4 } from "uuid"; // Import UUID library
// import DynamicRadioGroup from "components/DynamicRadioGroup";
import DaysPicker from "components/AutoComplete/DaysPicker";
import TimesPicker from "components/AutoComplete/TimesPicker";
import CurrencyPicker from "components/AutoComplete/CurrencyPicker";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButtonLoading";
import AvailabilitySchedulePanel from "components/AvailabilitySchedulePanel";
import { useFormikContext } from "formik";
import Danger from "components/Danger";

const DynamicPriceSchedules = ({
  // timeFrom,
  startDate,
  addSchedule,
  setAddSchedule,
  endDate,
  // timeTo,
  // handleStartDateChange,
  // handleTimeFrom,
  // add,
  minQty,
  maxQty,
  retailPrice,
  netPrice,
  scheduleOrOptionV,

  //   ageBands,
  ageBandsV,
  setFieldValue,
  daysV,
  days,
  times,
  timesV,
  availabilitiesV,
  handleTimesChange,
  handleDaysChange,
  selectedCurrency,
  handleCurrencyChange,
  handleAddSchedule,
}) => {
  const { touched, errors, values, handleChange } = useFormikContext();

  //   const [isOpen, setIsOpen] = useState(true);\
  // const [selectedRadio, setSelectedRadio] = useState(null);
  const [startDateProm, setStartDateProm] = useState("");
  const [endDateProm, setEndDateProm] = useState("");

  // const helperTextStyle = {
  //   fontSize: "0.875rem",
  //   color: "black",
  // };
  const startDateKey = uuidv4();

  const daysList = [
    { value: "MONDAY", name: "Monday" },
    { value: "TUESDAY", name: "Tuesday" },
    { value: "WEDNESDAY", name: "Wednesday" },
    { value: "THURSDAY", name: "Thursday" },
    { value: "FRIDAY", name: "Friday" },
    { value: "SATURDAY", name: "Saturday" },
    { value: "SUNDAY", name: "Sunday" },
  ];

  // const handleRadioChange = (val) => {
  //   setSelectedRadio(val);
  // };

  // const radioOptions = [
  //   {
  //     value: "start_times",
  //     label: "Start times",
  //     helperText:
  //       "Your experience begins at a set time. Example: You offer a morning tour at 9am and an afternoon tour at 3pm.",
  //   },
  //   {
  //     value: "opening_hours",
  //     label: "Opening hours",
  //     helperText:
  //       "Travelers can access your experience at any point during the opening hours. Example: Your ticket admits to a museum that is open between 9am and 5pm.",
  //   },
  //   {
  //     value: "coordinated_start_times",
  //     label: "Coordinated start times",
  //     helperText:
  //       "Your experience could begin at any time during this time period, but you coordinate the specific timing with travelers. Example: You offer a transport service that may start anytime between 9-11am.",
  //   },
  //   {
  //     value: "none",
  //     label: "None of the above",
  //     helperText: "",
  //   },
  // ];

  const handlePricesChange = (val, type, index) => {
    if (values?.ageBands) {
      const pricesArray = values.ageBands.map((ageBand, i) => ({
        ageBand,
        maxTravelers:
          type === "maxTravelers" && i === index
            ? val !== ""
              ? parseInt(val, 10)
              : undefined
            : values[`maxQty_${i}`],
        minTravelers:
          type === "minTravelers" && i === index
            ? val !== ""
              ? parseInt(val, 10)
              : undefined
            : values[`minQty_${i}`],
        netPrice:
          type === "netPrice" && i === index
            ? val !== ""
              ? parseInt(val, 10)
              : undefined
            : values[`netPrice_${i}`],
        retailPrice:
          type === "retailPrice" && i === index
            ? val !== ""
              ? parseInt(val, 10)
              : undefined
            : values[`retailPrice_${i}`],
      }));

      setFieldValue("pricesObject", pricesArray);
    }
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h6">Schedule Date:</MDTypography>
      </MDBox>
      <Grid container spacing={2}>
        {/* <Grid item xs={5}>
          <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
            From *
          </Typography>{" "} */}
        {/* <MDTimePicker
            key={timeFrom}
            input={{
              type: "text",
              // label: "From",
              name: "timeFrom",
              variant: "standard",
              width: "100%",
              value: timeFrom || undefined,
            }}
            // onChange={(e, time) => handleTimeFrom(e, time)}
            onClose={(e, time) => {
              handleTimeFrom(e, time);
            }}
          />
          {add && timeFrom === null && <Danger validation=" From Time is Required" />} */}
        <Grid item xs={6}>
          <MDDatePicker
            key={`${startDateKey}-${endDateProm}`} // Ensure a unique key
            input={{
              type: startDate.type,
              label: startDate.label,
              name: startDate.name,
              variant: "standard",
              width: "100%",
              value: startDateProm || "",
            }}
            onChange={(event, val) => {
              if (val !== null && val !== "") {
                setFieldValue("startDate", val);
                setStartDateProm(val);
              } else {
                setFieldValue("startDate", "");
                setStartDateProm("");
              }
              setAddSchedule(false);
            }}
            inputFormat="dd/MM/yyyy"
            success={values?.startDate?.length > 0 && !errors.startDate}
            options={{
              minDate: new Date(),
            }}
          />
          {(!values?.startDate || values?.startDate?.length === 0) && addSchedule && (
            <Danger validation="Start Date is required!" />
          )}
        </Grid>

        <Grid item xs={6}>
          <MDDatePicker
            key={`${startDateKey}-${startDateProm}`}
            input={{
              type: endDate.type,
              label: endDate.label,
              name: endDate.name,
              variant: "standard",
              width: "100%",
              value: endDateProm || "",
            }}
            onChange={(event, val) => {
              if (val !== null && val !== "") {
                setFieldValue("endDate", val);
                setEndDateProm(val);
              } else {
                setFieldValue("endDate", "");
                setEndDateProm("");
              }
            }}
            inputFormat="dd/MM/yyyy"
            error={errors.endDate && touched.endDate}
            success={values?.endDate?.length > 0}
            options={{
              minDate: new Date(),
            }}
          />
          {/* </Grid> */}
        </Grid>
        {/* <Grid item xs={12}>
          <DynamicRadioGroup
            value={selectedRadio}
            options={radioOptions}
            name="timing"
            handleChange={handleRadioChange}
            setFieldValue={setFieldValue}
            groupLabel="Timing:"
            disabled={false}
            helperTextStyle={helperTextStyle}
          />
        </Grid> */}
      </Grid>
      {ageBandsV.length > 0 && (
        <Grid container spacing={1} mt={3}>
          {ageBandsV.map((ageBand, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={2} style={{ marginTop: "16px" }}>
                <MDTypography variant="h6" color="text" fontWeight="regular">
                  {ageBand}
                </MDTypography>
              </Grid>
              <Grid item xs={2}>
                <FormField
                  type={minQty.type}
                  label={minQty.label}
                  name={`${minQty.name}_${index}`}
                  // value={minQtyV[index]}
                  // defaultValue={minQtyV[index]}
                  value={values[`minQty_${index}`] || ""}
                  placeholder={minQty.placeholder}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const value = parseFloat(e.target?.value);

                    // Ensure the value is positive or set to 0
                    if (inputValue === "" || (!Number.isNaN(value) && value >= 0)) {
                      handleChange(e);
                      handlePricesChange(e.target?.value, "minQty", index);
                    } else {
                      // Optionally reset the input to 0 if a negative value is entered
                      e.target.value = 0;
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormField
                  type={maxQty.type}
                  label={maxQty.label}
                  name={`${maxQty.name}_${index}`}
                  // value={maxQtyV[index]}
                  // defaultValue={maxQtyV[index]}
                  value={values[`maxQty_${index}`] || ""}
                  placeholder={maxQty.placeholder}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const value = parseFloat(e.target?.value);

                    // Ensure the value is positive or set to 0
                    if (inputValue === "" || (!Number.isNaN(value) && value >= 0)) {
                      handleChange(e);
                      handlePricesChange(e.target?.value, "maxQty", index);
                    } else {
                      // Optionally reset the input to 0 if a negative value is entered
                      e.target.value = 0;
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormField
                  type={retailPrice.type}
                  label={retailPrice.label}
                  name={`${retailPrice.name}_${index}`}
                  // value={retailPriceV[index]}
                  // defaultValue={retailPriceV[index]}
                  value={values[`retailPrice_${index}`] || ""}
                  placeholder={retailPrice.placeholder}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const value = parseFloat(e.target?.value);

                    // Ensure the value is positive or set to 0
                    if (inputValue === "" || (!Number.isNaN(value) && value >= 0)) {
                      handleChange(e);
                      handlePricesChange(value, "retailPrice", index);
                    } else {
                      // Optionally reset the input to 0 if a negative value is entered
                      e.target.value = 0;
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormField
                  type={netPrice.type}
                  label={netPrice.label}
                  name={`${netPrice.name}_${index}`}
                  // value={netPriceV[index]}
                  // defaultValue={netPriceV[index]}
                  value={values[`netPrice_${index}`] || ""}
                  placeholder={netPrice.placeholder}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const value = parseFloat(e.target?.value);

                    // Ensure the value is positive or set to 0
                    if (inputValue === "" || (!Number.isNaN(value) && value >= 0)) {
                      handleChange(e);
                      handlePricesChange(e.target?.value, "netPrice", index);
                    } else {
                      // Optionally reset the input to 0 if a negative value is entered
                      e.target.value = 0;
                    }
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
      {addSchedule && <Danger validation="All fields are required!" />}
      <Grid item style={{ marginTop: 3 }} xs={12}>
        <DaysPicker
          key={daysV?.length}
          handleDaysChange={handleDaysChange}
          isObj
          daysList={daysList}
          days={days}
          daysV={daysV}
          setFieldValue={setFieldValue}
          success={daysV && !errors.days}
        />
        {daysV?.length === 0 && addSchedule && (
          <Danger validation="Please select at least 1 day!" />
        )}
      </Grid>

      <Grid item style={{ marginTop: 3 }} xs={12}>
        <TimesPicker
          key={timesV?.length}
          handleTimesChange={handleTimesChange}
          isObj
          times={times}
          timesV={timesV}
          setFieldValue={setFieldValue}
          success={timesV && !errors.times}
        />
        {timesV?.length === 0 && addSchedule && (
          <Danger validation="Please select at least 1 time!" />
        )}
      </Grid>

      <Grid item xs={12}>
        <Grid item xs={12} mt={2}>
          <MDButton
            key={availabilitiesV?.length}
            variant="contained"
            color="secondary"
            onClick={() => {
              setAddSchedule(true);
              if (timesV?.length > 0 && daysV?.length > 0 && values?.startDate?.length > 0) {
                // checkValidation();
                handleAddSchedule();
                setStartDateProm("");
                setEndDateProm("");
                setAddSchedule(false);
              }
            }}
          >
            Add schedule
          </MDButton>
        </Grid>
        <Divider
          sx={{ height: "1px", width: "100%", backgroundColor: "gray", margin: "30px 0 0 0" }}
        />
        <Grid container alignItems="end" sx={{ marginBottom: "30px" }}>
          <Grid item xs={12}>
            {values?.availabilities?.bookableItems?.length > 0 || availabilitiesV?.length > 0 ? (
              <AvailabilitySchedulePanel
                setFieldValue={setFieldValue}
                availabilitiesAdded={values?.availabilitiesAdded}
                scheduleOrOption={scheduleOrOptionV}
              />
            ) : (
              <MDBox sx={{ marginTop: "30px" }}>
                <MDTypography variant="h5">No Availability Found!</MDTypography>
              </MDBox>
            )}
          </Grid>
          {errors?.availabilities && values?.availabilities?.length === 0 && (
            <Danger validation={errors?.availabilities} />
          )}
        </Grid>
        <MDBox
          style={{
            marginTop: "8px",
            backgroundColor: "white",
          }}
        >
          <MDTypography variant="h6" color="text" fontWeight="regular">
            Currency *
          </MDTypography>
          <CurrencyPicker
            currency={values?.defaultCurrencyCode || selectedCurrency}
            handleSelectCurrency={handleCurrencyChange}
            setFieldValue={setFieldValue}
          />
          {errors.defaultCurrencyCode && <Danger validation={errors.defaultCurrencyCode} />}
        </MDBox>
      </Grid>
    </MDBox>
  );
};
DynamicPriceSchedules.defaultProps = {
  startDate: "",
  endDate: "",
  // add: false,
  minQty: {},
  maxQty: {},
  retailPrice: {},
  netPrice: {},
  //   ageBands: {},
  ageBandsV: [],
  daysV: [],
  days: {},
  setFieldValue: () => {},
  handleDaysChange: () => {},
  times: {},
  timesV: [],
  scheduleOrOptionV: "schedule",
  availabilitiesV: [],
  handleTimesChange: () => {},
  // handleRadioChange: () => {},
  selectedCurrency: {},
  handleCurrencyChange: () => {},
  handleAddSchedule: () => {},
  setAddSchedule: () => {},
  addSchedule: false,
};
DynamicPriceSchedules.propTypes = {
  endDate: PropTypes.string,

  startDate: PropTypes.string,
  // add: PropTypes.bool,
  minQty: PropTypes.instanceOf(Object),
  maxQty: PropTypes.instanceOf(Object),
  scheduleOrOptionV: PropTypes.string,
  retailPrice: PropTypes.instanceOf(Object),
  netPrice: PropTypes.instanceOf(Object),
  //   ageBands: PropTypes.instanceOf(Object),
  ageBandsV: PropTypes.instanceOf(Array),
  daysV: PropTypes.instanceOf(Array),
  setFieldValue: PropTypes.func,
  days: PropTypes.instanceOf(Object),
  timesV: PropTypes.instanceOf(Array),
  availabilitiesV: PropTypes.instanceOf(Array),
  times: PropTypes.instanceOf(Object),
  handleTimesChange: PropTypes.func,
  handleDaysChange: PropTypes.func,
  selectedCurrency: PropTypes.instanceOf(Object),
  handleCurrencyChange: PropTypes.func,
  handleAddSchedule: PropTypes.func,
  addSchedule: PropTypes.bool,
  setAddSchedule: PropTypes.func,

  // handleRadioChange: PropTypes.func,
};

export default DynamicPriceSchedules;
