/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, logo, openHours, location, recommendation, bookingEmail },
} = checkout;
// const isEmptyOrNoId = (field) => !field || !field._id || field._id.length === 0;
const validations = [
  // validation for Ticket Info page
  Yup.object().shape({
    [logo.name]: Yup.string().required(logo.errorMsg).nullable(),
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),

    locRec: Yup.object().shape(
      {
        location: Yup.object().when("recommendation", {
          is: (rec) => !rec || !rec._id || rec?._id?.length === 0,
          then: () => Yup.object().required(location.errorMsg),
        }),
        recommendation: Yup.object().when("location", {
          is: (loc) => !loc || !loc._id || loc?._id?.length === 0,
          then: () => Yup.object().required(recommendation.errorMsg),
        }),
      },
      ["recommendation", "location"]
    ),
    [bookingEmail.name]: Yup.string()
      .email(bookingEmail.invalidMsg)
      .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, bookingEmail.invalidMsg)
      .required(bookingEmail.errorMsg),
  }),

  // validations for Date and time Page
  Yup.object().shape({
    [openHours.name]: Yup.array().min(1, openHours.invalidMsg).required(),
  }),
];

export default validations;
