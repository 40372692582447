const form = {
  formId: "new-transportationService-form",
  formField: {
    // transportationService page
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    location: {
      name: "location",
      label: "Location *",
      errorMsg: "Location is required!",
      invalidMsg: "Please select a location!",
    },
    logoName: {
      name: "logoName",
      label: "Icons *",
      type: "text",
      errorMsg: "Icons is required!",
      invalidMsg: "Please select an Icon!",
    },
    logo: {
      name: "logo",
      label: "Icons ",
      type: "text",
    },
  },
};

export default form;
