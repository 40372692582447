import React from "react";
import PropTypes from "prop-types";

const WidgetDisplay = ({ htmlString }) => {
  const iframeRef = React.useRef();

  React.useEffect(() => {
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument;
      iframeDocument.open();
      iframeDocument.write(htmlString);
      iframeDocument.close();
    }
  }, [htmlString]);

  return (
    <iframe
      ref={iframeRef}
      title="Widget Display"
      style={{ border: "none", width: "100%", height: "500px" }}
    />
  );
};

WidgetDisplay.propTypes = {
  htmlString: PropTypes.string.isRequired,
};

export default WidgetDisplay;
