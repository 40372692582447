import PropTypes from "prop-types";

// Default props
export const defaultProps = {
  token: "https://lokalee.app",
  user: { name: "Lokalee" },
  data: {
    recommendation: {
      title: "Lake boat ride with Dubai Fountain show Standard price",
      geoLocationData: {
        address:
          "Restaurant Village Four Seasons Resort - Jumeirah Beach Rd - Jumeirah 2 - Dubai - United Arab Emirates",
      },
      logo: "https://email-assets.lokalee.app/email/emailImages/fullWidth-cover.png",
    },
    time: "08:00",
    date: "2 Apr 2024",
    guests: 2,
    cancellationPolicy: {
      description: "Cancellation policy description",
    },
  },
};

// PropTypes validation
export const propTypes = {
  token: PropTypes.string,
  data: PropTypes.shape({
    recommendation: PropTypes.shape({
      title: PropTypes.string.isRequired,
      geoLocationData: PropTypes.shape({
        address: PropTypes.string.isRequired,
      }),
      logo: PropTypes.string.isRequired,
    }).isRequired,
    time: PropTypes.string,
    date: PropTypes.string,
    guets: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cancellationPolicy: PropTypes.shape({
      description: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};
