import fallbackCardImg from "../../assets/images/fallbackImages/fallback-cardList-neutral.png";

export default function imageReturn(data) {
  const largestImage =
    data?.coverImage?.variants &&
    data.coverImage?.variants.reduce(
      (prev, current) =>
        prev.height * prev.width > current.height * current.width ? prev : current,
      {} // Providing an initial value as an empty object
    );

  if (data?.coverImage) {
    return largestImage?.url || fallbackCardImg; // Add fallback in case largestImage is empty
  }
  if (data.logo) {
    return data.logo;
  }
  if (data.image) {
    return data.image;
  }
  if (data.images?.length > 0) {
    return data.images[0];
  }
  if (data.cover_image_url) {
    return data.cover_image_url;
  }
  if (data.stops?.length > 0) {
    return data.stops?.length > 0 && data?.stops[0]?.image
      ? data?.stops[0]?.image
      : fallbackCardImg;
  }
  return fallbackCardImg;
}
