/* eslint-disable no-undef */
/* eslint-disable import/order */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from "react";
import { Autocomplete, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  sortMenu,
  setLoading,
  resetMenus,
  saveMenuType,
  getGlobalMenus,
  getLocationMenus,
  getDestinationMenus,
} from "store/actions/menuActions";
import { getLocations } from "store/actions/locationAction";

import MDBox from "components/MDBox";
import Footer from "components/Footer";
import MDInput from "components/MDInput";
import DynamicTabs from "components/DynamicTabs";
import MDTypography from "components/MDTypography";
import DashboardLayout from "layouts/DashboardLayout";
import MenuMaterialTable from "./components/MenusList";
import DashboardNavbar from "components/DashboardNavbar";
import DestinationsPicker from "components/AutoComplete/DestinationsPicker";

const detailTabs = [
  { id: 1, title: "Global Menu" },
  { id: 2, title: "Destinations Menu" },
  { id: 3, title: "Location Menu" },
];
const data = [
  {
    title: "Global Menu",
    screen: "GlobalMenusList",
    columns: ["Title", "Order", "Option", "Has Items", "Expandable"],
  },
  {
    title: "Destinations Menu",
    screen: "DestinationsMeusList",
    columns: ["Title", "Order", "Option", "Has Items", "Expandable"],
  },
  {
    title: "Location Menu",
    screen: "LocationMenusList",
    columns: ["Title", "Order", "Option", "Has Items", "Expandable"],
  },
];

const AllMenus = () => {
  /* *** Constants *** */
  const dispatch = useDispatch();

  /* *** Selectors *** */
  const loading = useSelector((state) => state.menus.loading);
  const reOrderMenu = useSelector((state) => state.menus.added);
  const cityLoading = useSelector((state) => state.cities.loading);
  const globalMenus = useSelector((state) => state.menus.globalMenus);
  const locations = useSelector((state) => state.locations.locations);
  const savedMenuSearch = useSelector((state) => state.menus.menuType);
  const locationMenus = useSelector((state) => state.menus.locationMenus);
  const destinationMenus = useSelector((state) => state.menus.destinationsMenus);

  /* *** States *** */
  const [tabValue, setTabValue] = useState(0);
  const [menusData, setMenuData] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedDestinations, setSelectedDestinations] = useState([]);

  useEffect(() => {
    const { type = "global", destinationSearch = [], locationSearch = [] } = savedMenuSearch;
    const tabMap = { global: 0, destinations: 1, location: 2 };
    setTabValue(tabMap[type] || 0);

    if (type === "destinations" && destinationSearch.length > 0) {
      setSelectedDestinations(destinationSearch);
      handleDestinationsChange(destinationSearch);
    } else if (type === "location" && locationSearch.length > 0) {
      setSelectedLocations(locationSearch);
      handleLocationChange(locationSearch);
    } else if (globalMenus.length === 0) {
      dispatch(setLoading());
      dispatch(getLocations());
      dispatch(getGlobalMenus());
    } else {
      setMenuData(globalMenus);
    }

    return () => {
      const url = window.location.href;

      if (!url.includes("menu")) {
        dispatch(saveMenuType({ type: "global", destinationSearch: [], locationSearch: [] }));
      }
    };
  }, []);
  useEffect(() => {
    if (reOrderMenu) {
      dispatch(sortMenu(handleMenuIndexing(globalMenus)));
    }

    const menuDataMap = {
      0: globalMenus,
      1: destinationMenus,
      2: locationMenus,
    };
    setMenuData(menuDataMap[tabValue] || []);
  }, [tabValue, globalMenus, locationMenus, destinationMenus, reOrderMenu]);

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
    dispatch(resetMenus());

    setSelectedLocations([]);
    setSelectedDestinations([]);

    const menuTypeMap = {
      0: { type: "global", destinationSearch: [], locationSearch: [] },
      1: { type: "destinations", destinationSearch: [], locationSearch: [] },
      2: { type: "location", destinationSearch: [], locationSearch: [] },
    };

    dispatch(saveMenuType(menuTypeMap[newValue] || menuTypeMap[0]));

    if (newValue === 0) {
      dispatch(setLoading());
      dispatch(getGlobalMenus());
    }
  };
  const handleDestinationsChange = (destinationChosen = []) => {
    setSelectedDestinations(destinationChosen);

    if (destinationChosen.length > 0) {
      const menuDestinations = destinationChosen.map((destination) => destination.value).join(",");

      dispatch(setLoading());
      dispatch(
        saveMenuType({
          type: "destinations",
          destinationSearch: destinationChosen,
          locationSearch: [],
        })
      );
      dispatch(getDestinationMenus(menuDestinations));
    } else {
      setMenuData([]);
      dispatch(saveMenuType({ type: "global", destinationSearch: [], locationSearch: [] }));
    }
  };
  const handleLocationChange = (locationChosen = []) => {
    setSelectedLocations(locationChosen);

    if (locationChosen.length > 0) {
      const menuLocations = locationChosen.map((location) => location._id).join(",");

      dispatch(setLoading());
      dispatch(
        saveMenuType({
          type: "location",
          locationSearch: locationChosen,
          destinationSearch: [],
        })
      );
      dispatch(getLocationMenus(menuLocations));
    } else {
      setMenuData([]);
      dispatch(saveMenuType({ type: "global", destinationSearch: [], locationSearch: [] }));
    }
  };
  const handleMenuIndexing = (mainMenu) =>
    mainMenu.map((item, index) => ({
      id: item?._id || 0,
      order: index,
    }));

  const handlePickerBox = () => {
    switch (tabValue) {
      case 1:
        return (
          <>
            <MDTypography variant="h6" color="text" fontWeight="regular">
              Select a Destination
            </MDTypography>

            <MDBox
              style={{
                marginTop: "8px",
                borderRadius: "8px",
                backgroundColor: "transparent",
              }}
            >
              <Grid item xs={12} sx={{ mt: "16px" }}>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <DestinationsPicker
                    setFieldValue={() => {}}
                    destinationsV={selectedDestinations}
                    handleChange={handleDestinationsChange}
                    destinations={{ name: "destinations", Label: "Destination" }}
                  />
                </div>
              </Grid>
            </MDBox>
          </>
        );
      case 2:
        return (
          <>
            <MDTypography variant="h6" color="text" fontWeight="regular">
              Select a Location
            </MDTypography>

            <MDBox
              style={{
                marginTop: "8px",
                borderRadius: "8px",
                backgroundColor: "white",
              }}
            >
              <Autocomplete
                multiple
                options={locations}
                loading={cityLoading}
                value={selectedLocations}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <MDInput {...params} label="Locations" />}
                onChange={(_, value) => {
                  handleLocationChange(value);
                }}
              />
            </MDBox>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container spacing={3}>
        <Grid item xs={12} md="auto">
          <MDBox>
            <DynamicTabs
              tabValue={tabValue}
              detailTabs={detailTabs}
              handleSetTabValue={handleTabChange}
            />
          </MDBox>

          <MDBox style={{ marginTop: "16px" }}>{handlePickerBox()}</MDBox>
        </Grid>

        <Grid item xs={12}>
          {(tabValue === 0 || selectedDestinations.length > 0 || selectedLocations.length > 0) && (
            <MenuMaterialTable
              data={menusData}
              loading={loading}
              tabValue={tabValue}
              list={data[tabValue]}
              location={selectedLocations}
              selectedLocations={selectedLocations}
              selectedDestinations={selectedDestinations}
            />
          )}
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default AllMenus;
