const form = {
  formId: "new-offer-form",
  formField: {
    // voucher page
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    description: {
      name: "description",
      label: "Description *",
      type: "text",
      errorMsg: "Description is required!",
    },
    recommendation: {
      name: "recommendation",
      label: "Recommendation *",
      //   type: "text",
      errorMsg: "Recommendation is required!",
    },
    location: {
      name: "location",
      label: "Location",
      // type: "text",
    },
    logo: {
      name: "logo",
      label: "Logo *",
      type: "text",
      errorMsg: "Logo is required!",
    },
    // additional information
    openEnded: {
      name: "openEnded",
      label: "Open Ended",
      type: "boolean",
    },
    limit: {
      name: "limit",
      label: "Limit",
      type: "boolean",
    },
    dayRestriction: {
      name: "dayRestriction",
      //   label: "Day Restriction",
      type: "boolean",
    },
    type: {
      name: "type",
      //   label: "Value *",
      type: "text",
    },
    value: {
      name: "value",
      label: "Value *",
      type: "number",
      errorMsg1: "Percentage is required!",
      errorMsg2: "Value is required!",
    },
    maxUsage: {
      name: "maxUsage",
      label: "Max usage/user",
      type: "number",
    },
    startDate: {
      name: "startDate",
      label: "Start date",
      type: "text",
      //   errorMsg: "Date is required!",
    },
    endDate: {
      name: "endDate",
      label: "End date",
      type: "text",
      //   errorMsg: "Date is required!",
    },
    // quantity: {
    //   name: "quantity",
    //   //   label: "Quantity",
    //   type: "text",
    // },
    quantityPerDay: {
      name: "quantityPerDay",
      label: "Quantity per day",
      type: "number",
    },
    quantity: {
      name: "quantity",
      label: "Quantity",
      type: "number",
      //   invalidMsg: "please select at least one day",
    },
  },
};

export default form;
