/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import DetailList from "components/DetailList";
import OpeningHours from "components/OpeningHours";
import Danger from "components/Danger";

const DateAndTimeInfo = ({ formData, editPage, detailPage }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { openHours } = formField;
  const { openHours: openHoursV } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Date And Time Info </MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            openHours: values?.openHours,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container>
            <Grid item xs={12}>
              <OpeningHours key={openHours} setFieldValue={setFieldValue} openHours={openHoursV} />
              {openHoursV?.length === 0 ? (
                <MDBox sx={{ marginTop: "30px" }}>
                  <MDTypography variant="h5">No Days Found!</MDTypography>
                  {errors?.openHours && touched?.openHours && (
                    <Danger validation={errors?.openHours} />
                  )}
                </MDBox>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};
DateAndTimeInfo.defaultProps = {
  editPage: false,
  detailPage: true,
};
DateAndTimeInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default DateAndTimeInfo;
