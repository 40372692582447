/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { MuiColorInput } from "mui-color-input";
import CustomAdornmentButton from "./CustomAdornmentButton";

const ColorPicker = ({ option, value, setFieldValue }) => {
  const colorInputRef = useRef(null); // Create a ref for the MuiColorInput

  const handleChange = (newValue) => {
    setFieldValue(option.name, newValue);
  };

  return (
    <MuiColorInput
      ref={colorInputRef} // Assign the ref to the MuiColorInput
      value={value}
      isAlphaHidden
      onChange={handleChange}
      size="small"
      variant="outlined"
      format="hex"
      label={option.label}
      PopoverProps={{
        PaperProps: {
          style: { backgroundColor: "white" }, // Apply background color here
        },
      }}
      Adornment={(props) => (
        <CustomAdornmentButton {...props} selectedColor={value} onClick={props.onClick} />
      )}
    />
  );
};

ColorPicker.propTypes = {
  option: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ColorPicker;
