import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "store/actions/categoryActions";

const CategoryRecFilter = ({ onFilterChanged, columnDef }) => {
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.categories.categories);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleChange = (e, value) => {
    if (!value) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(value);
    }
    const val = value?.name;
    onFilterChanged(columnDef.tableData.id, val);
  };

  useEffect(() => {
    dispatch(getCategories());
    categories.forEach((category) => {
      if (columnDef.tableData.filterValue && category.name === columnDef.defaultFilter) {
        setSelectedCategory(category);
      }
    });
  }, []);

  useEffect(() => {
    if (!columnDef?.tableData?.filterValue) {
      setSelectedCategory(null);
    }
  }, [columnDef]);

  return (
    <table>
      <thead>
        <tr>
          <th aria-label="autocomplete">
            <Autocomplete
              onChange={handleChange}
              id="categories"
              options={categories}
              value={selectedCategory}
              getOptionLabel={(option) => option.name || ""}
              style={{ width: 190 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputProps={{
                    style: {
                      borderColor: "#00850D", // Change this to your desired border color
                    },
                  }}
                />
              )}
            />
          </th>
        </tr>
      </thead>
    </table>
  );
};

CategoryRecFilter.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
  columnDef: PropTypes.instanceOf(Object).isRequired,
};

export default CategoryRecFilter;
