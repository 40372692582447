/* eslint-disable import/no-extraneous-dependencies */
/**
=========================================================
* LCM Dashboard V2
=========================================================

* Product Page: https://www.lcm.lokalee.app
* Copyright 2023 Lokalee (https://www.lokalee.app)

 =========================================================
*/

import { useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

import { Provider } from "react-redux";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
// import Router from "./views/Router";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import store from "./Store";
import Alerts from "./layouts/Alert";
import Router from "./views/Router";

TimeAgo.addDefaultLocale(en);

// eslint-disable-next-line react/function-component-definition
export default function App() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <Provider store={store} data-test="component-app">
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <Alerts />
          <Router />
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
}
