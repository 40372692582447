/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Grid, Icon, Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DetailList from "components/DetailList";
import { useState } from "react";
import MDButton from "components/MDButton";
import Danger from "components/Danger";
import { deleteLocationSlider } from "store/actions/locationAction";
import { deleteCitySlider } from "store/actions/cityActions";
import { useDispatch } from "react-redux";
import SliderReview from "components/SliderReview";
import FormField from "components/FormField";
import SliderDialog from "./SliderDialog";

const Sliders = ({ formData, editPage, detailPage, id, cityBool }) => {
  const { formField, values, errors, touched } = formData;
  const { slider, slideDuration } = formField;
  const { slider: sliderV, slideDuration: slideDurationV } = values;
  const dispatch = useDispatch();

  // State
  const [open, setOpen] = useState({ value: false, type: "add" });
  const [sliderId, setSliderId] = useState("");

  // UseEffect
  // useEffect(() => {
  //   if (formData?.values?.startDate) {
  //     setStartDateProm(formData?.values?.startDate);
  //   }
  //   if (formData?.values?.endDate) {
  //     setEndDateProm(formData?.values?.endDate);
  //   }
  // }, [formData?.values?.startDate, formData?.values?.endDate]);

  // const handleAddSlider = () => {
  //   dispatch(addLocationSlide());
  //   // Title input is not empty, add the slider
  //   const currentSliders = values.sliders;
  //   const updatedSliders = [...currentSliders];
  //   setFieldValue("sliders", updatedSliders);
  //   setAddSlider(false); // Set addSlider back to false

  //   // setStartDateProm("");
  //   // setEndDateProm("");
  // };

  // Function to handle slider deletion
  const handleDeleteSlider = (index) => {
    const updatedSliders = [...sliderV];
    updatedSliders.splice(index, 1); // Remove the slider at the specified index

    dispatch(cityBool ? deleteCitySlider(id, index) : deleteLocationSlider(id, index));
  };
  const onClose = () => {
    setOpen({ value: false, type: "add" });
  };

  return (
    <MDBox>
      {detailPage && editPage ? (
        // <>
        <DetailList
          data={{
            slideDuration: values?.slideDuration,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type={slideDuration.type}
                label={slideDuration.label}
                name={slideDuration.name}
                value={slideDurationV}
                placeholder={slideDuration.placeholder}
                error={errors?.slideDuration && touched?.slideDuration}
                success={slideDurationV?.length > 0 && !errors?.slideDuration}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}

      <MDBox mt={1.625}>
        <div style={{ textAlign: "end" }}>
          <MDTypography variant="caption">Add your slider</MDTypography>
          <MDButton
            variant="text"
            color="dark"
            iconOnly
            onClick={() => setOpen({ value: true, type: "add" })}
            style={{ padding: 0 }}
          >
            <Tooltip title="Add slider" placement="top">
              <Icon>add</Icon>
            </Tooltip>
          </MDButton>
        </div>
        <SliderDialog
          cityBool={cityBool}
          open={open.value}
          onClose={onClose}
          id={id}
          data={
            sliderId && sliderId?.length > 0 && open.value && open.type === "edit"
              ? sliderV.filter((obj) => obj?._id === sliderId)[0]
              : []
          }
        />
      </MDBox>

      {sliderV?.length > 0 ? (
        sliderV?.map((item) => (
          <SliderReview
            key={item?._id}
            item={item}
            handleDelete={() => handleDeleteSlider(item?._id)}
            handleClick={() => {
              setOpen({ value: true, type: "edit" });
              setSliderId(item?._id);
            }}
          />
        ))
      ) : (
        <>
          <MDBox style={{ width: "100%", textAlign: "center" }}>
            <MDTypography variant="body2">No Slider added yet</MDTypography>
          </MDBox>
          {errors?.slider && sliderV?.length === 0 && <Danger validation={slider.errorMsg} />}
        </>
      )}
      {/* </> */}
    </MDBox>
  );
};
Sliders.defaultProps = {
  editPage: false,
  detailPage: true,
  id: "",
  cityBool: false,
};
// typechecking props for Sliders
Sliders.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  id: PropTypes.string,
  cityBool: PropTypes.bool,
};

export default Sliders;
