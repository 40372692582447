/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& label": {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 0.9,
    height: "-webkit-fill-available",
  },
  "& .MuiInputLabel-shrink": {
    lineHeight: 1.5,
    fontSize: "1rem",
    fontWeight: 400,
  },
  "& .css-1yoptbl-MuiInputBase-root-MuiInput-root:before, &:hover": {
    borderColor: "#D2D2D2!important",
    borderWidth: "1px !important",
  },
});
// eslint-disable-next-line react/function-component-definition
export default function AutocompleteEmail({
  setFieldValue,
  email,
  emailV,
  labelName,
  error,
  ...rest
}) {
  return (
    <div data-test="component-autocomplete-email">
      <Autocomplete
        id="email"
        open={false}
        options={[]}
        multiple
        freeSolo
        defaultValue={
          emailV && emailV.length !== 0
            ? emailV.filter((value) => value.trim() !== "") // Filter out empty strings or whitespace-only strings
            : undefined
        }
        value={
          typeof emailV === "string"
            ? [emailV]
            : emailV?.length !== 0 && emailV.some((value) => value.trim() !== "")
            ? emailV
            : []
        }
        onChange={(_event, newValue) => {
          setFieldValue(
            "email",
            newValue?.map((e) => e.toLowerCase().trim())
          );
        }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            {...rest}
            name={email.name}
            variant="standard"
            label={labelName || email.label}
            margin="normal"
            fullWidth
            error={error} // Utilize the error prop here
            helperText={error ? "Invalid email" : ""} // Customize helper text based on error
          />
        )}
      />
    </div>
  );
}

AutocompleteEmail.defaultProps = {
  emailV: [],
  labelName: "",
  error: false,
};

AutocompleteEmail.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  email: PropTypes.instanceOf(Object).isRequired,
  emailV: PropTypes.instanceOf(Array),
  labelName: PropTypes.string,
  error: PropTypes.bool,
};
