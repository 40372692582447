const fontFamilies = [
  // Sans-Serif Fonts
  "Arial",
  "Verdana",
  "Helvetica",
  "Tahoma",
  "Trebuchet MS",
  "Arial Black",
  "Impact",
  "Gill Sans",
  "Lucida (sans-serif)",
  "Calibri",
  "Candara",
  "Century Gothic",
  "Franklin Gothic",
  "Segoe UI",
  "Inherit",

  //   Serif Fonts
  "Times New Roman",
  "Georgia",
  "Garamond",
  "Baskerville",
  "Bodoni MT / Bodoni 72",
  "Palatino",
  "Cambria",
  "Goudy Old Style",
  "Perpetua",
  "Rockwell",

  //   Monospace Fonts
  "Courier New",
  "Lucida (monospace)",
  "Consolas",
  "Andalé Mono",
  "Dejavu Sans",

  //   Cursive and Fantasy Fonts
  "Brush Script MT",
  "Comic Sans MS (cursive)",
  "Bradley Hand (cursive)",
  "Luminari (fantasy)",
  "Copperplate Gothic",

  //   Other Fonts
  "Lucida Bright",
  "Lucida Sans",
  "Microsoft Sans Serif",
  "Optima",
  "Roboto",
  "Open Sans",
  "Serif",
  "Sans-Serif",
  "Monospace",
  "Cursive",
  "Fantasy",
  "Lato",
  "Slabo 27px",
  "Oswald",
  "Source Sans Pro",
  "Montserrat",
  "Raleway",
  "PT Sans",
  "Roboto Condensed",
  "Poppins",
  "Noto Sans",
  "Ubuntu",
  "Merriweather",
  "Playfair Display",
  "Lora",
  "Nunito",
  "Rubik",
  "Muli",
  "Work Sans",
  "Fira Sans",
  "Titillium Web",
  "Barlow",
  "Arimo",
  "Cabin",
  "Noto Serif",
  "Bitter",
  "Exo 2",
  "Mukta",
  "Karla",
  "Hind",
  "Heebo",
  "Fjalla One",
  "Comfortaa",
  "Varela Round",
  "Quicksand",
];

export default fontFamilies;
