/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import { getAllDestinations, setDestinationsLoading } from "store/actions/destinationsActions";

const PrimaryDestinationPicker = ({
  setFieldValue,
  destinationsObj,
  destination,
  selectedDestination,
  handleChange,
  ...rest
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDestinationsLoading());
    dispatch(getAllDestinations());

    // eslint-disable-next-line
  }, []);
  // Handle updating selected primary destination when a destination is removed
  useEffect(() => {
    if (
      selectedDestination &&
      !destinationsObj.find((dest) => dest.label === selectedDestination.label)
    ) {
      handleChange(null); // Clear selected primary destination if it's removed from destinations list
    }
  }, [destinationsObj]);
  return (
    <div>
      <Autocomplete
        disableClearable
        onChange={(event, option) => {
          if (option) {
            setFieldValue("primaryDestination", option?.value);
            handleChange(option);
          } else {
            setFieldValue("primaryDestination", "");
            handleChange(option);
          }
        }}
        id="destinations"
        defaultValue={selectedDestination}
        placeholder={
          selectedDestination?.destinationName
            ? selectedDestination?.destinationName
            : selectedDestination?.label || "Search for a Destination"
        }
        options={destinationsObj}
        value={selectedDestination}
        getOptionLabel={(option) => option?.destinationName || option?.label || ""}
        isOptionEqualToValue={(option, value) =>
          option?.label === value?.destinationName || value?.label
        }
        renderInput={(params) => (
          <FormField
            {...params}
            {...rest}
            label={destination?.label}
            name={destination.name}
            onChange={() => null}
          />
        )}
      />
    </div>
  );
};

PrimaryDestinationPicker.defaultProps = {
  destination: {},
  handleChange: () => {},
  destinationsObj: [],
  selectedDestination: {},
};

PrimaryDestinationPicker.propTypes = {
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  destinationsObj: PropTypes.instanceOf(Object),
  destination: PropTypes.instanceOf(Object),
  selectedDestination: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default PrimaryDestinationPicker;
