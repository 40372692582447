/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select";
import { capitalizeFirstLetter } from "components/helper";

const ServiceJobsCompanyPicker = ({
  handleCompanyChange,
  setJobCompany,
  companyV,
  setFieldValue,
}) => {
  // Transform status options into the required format
  const companyOptions = [
    { label: "Sky Software", value: "sky" },
    { label: "Message Box", value: "messagebox" },
  ];

  return (
    <div>
      <AsyncSelect
        options={companyOptions} // Use static options in the correct format
        onChange={(selectedOption) => {
          if (selectedOption) {
            setFieldValue("company", selectedOption.value.toLowerCase()); // Set the selected value
            setJobCompany(selectedOption.label.toLowerCase()); // Set the selected label
            handleCompanyChange(selectedOption.label.toLowerCase()); // Handle any change event
          } else {
            setFieldValue("company", "");
            setJobCompany("");
          }
        }}
        value={
          companyV
            ? { label: capitalizeFirstLetter(companyV), value: companyV.toLowerCase() }
            : null
        }
        placeholder="Select a company"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor, // Change border color on focus
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow, // Optional: Add a shadow for better visual
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor, // Maintain border color on hover
            },
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem", // Customize the selected option text size
          }),
        }}
      />
    </div>
  );
};

// Setting default props
ServiceJobsCompanyPicker.defaultProps = {
  companyV: "",
  setJobCompany: () => {},
  handleCompanyChange: () => {},
};

// Prop-types for ServiceJobsCompanyPicker
ServiceJobsCompanyPicker.propTypes = {
  companyV: PropTypes.string,
  setJobCompany: PropTypes.func,
  handleCompanyChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
};

export default ServiceJobsCompanyPicker;
