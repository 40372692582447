import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#4caf50",
    "&:hover": {
      backgroundColor: alpha("#4caf50", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#4caf50",
  },
}));

export default GreenSwitch;
