import {
  CLEAR_ITEM_QUOTE,
  CANCEL_ITEM_REQUEST,
  CANCEL_ITEM_SUCCESS,
  CANCEL_ITEM_FAILURE,
  FETCH_ITEM_QUOTE_REQUEST,
  FETCH_ITEM_QUOTE_SUCCESS,
  FETCH_ITEM_QUOTE_FAILURE,
  FETCH_CANCEL_REASONS_REQUEST,
  FETCH_CANCEL_REASONS_SUCCESS,
  FETCH_CANCEL_REASONS_FAILURE,
} from "../actions/Types";

const initialState = {
  quotes: {},
  itemQuotesError: null,
  itemQuotesLoading: false,

  reasons: [],
  reasonError: null,
  reasonLoading: false,

  refunded: false,
  refundedError: null,
  refundedLoading: false,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CANCEL_REASONS_REQUEST:
      return {
        ...state,
        reasonLoading: true,
        reasonError: null,
      };
    case FETCH_CANCEL_REASONS_SUCCESS:
      return {
        ...state,
        reasonLoading: false,
        reasons: action.payload.data,
      };
    case FETCH_CANCEL_REASONS_FAILURE:
      return {
        ...state,
        reasonLoading: false,
        reasonError: action.payload,
      };

    case CLEAR_ITEM_QUOTE:
      return {
        ...state,
        quotes: {},
      };
    case FETCH_ITEM_QUOTE_REQUEST:
      return {
        ...state,
        itemQuotesLoading: true,
        itemQuotesError: null,
      };
    case FETCH_ITEM_QUOTE_SUCCESS:
      return {
        ...state,
        itemQuotesLoading: false,
        quotes: action.payload.data,
      };
    case FETCH_ITEM_QUOTE_FAILURE:
      return {
        ...state,
        itemQuotesLoading: false,
        itemQuotesError: action.payload,
      };

    case CANCEL_ITEM_REQUEST:
      return {
        ...state,
        refundedLoading: true,
        refundedError: null,
      };
    case CANCEL_ITEM_SUCCESS:
      return {
        ...state,
        refunded: action.payload.data,
        refundedLoading: false,
      };
    case CANCEL_ITEM_FAILURE:
      return {
        ...state,
        refundedLoading: false,
        refundedError: action.payload,
      };

    default:
      return state;
  }
};

export default orderReducer;
