import checkout from "./form";

const {
  formField: { location },
} = checkout;

const initialValues = {
  [location.name]: "",
};

export default initialValues;
