/** 
  All of the routes for the LCM Dashboard are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "pages/dashboards/analytics";
import ProfileOverview from "pages/profile/profile-overview";
// import NewUser from "pages/users/new-user";

// // Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Pages
import Recommendations from "pages/content/recommendations/all-recommendations";
import Attractions from "pages/content/attractions/all-attractions";
// import Tickets from "pages/items/old-tickets/all-old-tickets";
import Promotions from "pages/items/promotions/all-promotions";
import AllLocations from "pages/content/locations/all-locations";
import NewLocation from "pages/content/locations/new-location";
import EditLocation from "pages/content/locations/edit-location";
import NewRecommendation from "pages/content/recommendations/new-recommendation";
import EditAttraction from "pages/content/attractions/edit-attraction";
import EditRecommendation from "pages/content/recommendations/edit-recommendation";
import LocationReports from "pages/reports/locationReports/all-locationReports";
import ProductionReports from "pages/reports/productionReports/all-productionReports";
// import NewTicket from "pages/items/old-tickets/new-old-ticket";
// import EditTicket from "pages/items/old-tickets/edit-old-ticket";
import NewPromotion from "pages/items/promotions/new-promotion";
import EditPromotion from "pages/items/promotions/edit-promotion";
import Vouchers from "pages/items/vouchers/all-vouchers";
import NewVoucher from "pages/items/vouchers/new-voucher";
import EditVoucher from "pages/items/vouchers/edit-voucher";
import Filters from "pages/filters";
import EditFacility from "pages/content/otherFacilities/edit-facility";
import EditWidget from "pages/views/transportationServices/edit-transportationService";
import Tickets from "pages/items/tickets/all-tickets";
import NewTicket from "pages/items/tickets/new-ticket";
import EditTicket from "pages/items/tickets/edit-ticket";

const routes = [
  {
    type: "collapse",
    name: "USER FULL NAME",
    key: "user-full-name",
    noCollapse: true,
    icon: <MDAvatar alt="User Avatar" size="sm" />,
    route: "/profile/my-profile",
    component: <ProfileOverview />,
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    noCollapse: true,
    component: <Analytics />,
    route: "/dashboards",
  },

  //   // content group list
  {
    type: "collapse",
    name: "Content",
    key: "content",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      //       // Location
      {
        name: "Locations",
        key: "locations",
        route: "/locations",
        component: <AllLocations />,
      },
      {
        name: "New Location",
        type: "hidden",
        key: "new-location",
        route: "/locations/new-location",
        component: <NewLocation />,
      },
      {
        type: "hidden",
        name: "Edit Location",
        key: "edit-location",
        route: "/locations/:location_id",
        component: <EditLocation />,
      },
      //       // Recommendation
      {
        name: "Recommendations",
        key: "recommendations",
        route: "/recommendations",
        component: <Recommendations />,
      },
      {
        name: "New Recommendation",
        type: "hidden",
        key: "new-recommendation",
        route: "/recommendations/new-recommendation",
        component: <NewRecommendation />,
      },
      {
        type: "hidden",
        name: "Edit Recommendation",
        key: "edit-recommendation",
        route: "/recommendations/:recommendation_id",
        component: <EditRecommendation />,
      },
      //       // Attraction
      {
        name: "Attractions",
        key: "attractions",
        route: "/attractions",
        component: <Attractions />,
      },
      {
        type: "hidden",
        name: "Edit Attraction",
        key: "edit-attraction",
        route: "/attractions/:attraction_id",
        component: <EditAttraction />,
      },
      {
        type: "hidden",
        name: "Edit Facility",
        key: "edit-facility",
        route: "/otherFacilities/:facility_id",
        component: <EditFacility />,
      },
      {
        name: "New Transportation Service",
        type: "hidden",
        key: "edit-transportation-service",
        route: "/widgets/:widget_id",
        component: <EditWidget />,
      },
    ],
  },

  //   // items group list
  {
    type: "collapse",
    name: "Items",
    key: "items",
    icon: <Icon fontSize="medium">local_activity</Icon>,
    collapse: [
      {
        name: "Tickets",
        key: "tickets",
        route: "/tickets",
        component: <Tickets />,
      },
      {
        name: "New Ticket",
        type: "hidden",
        key: "new-ticket",
        route: "/tickets/new-ticket",
        component: <NewTicket />,
      },
      {
        name: "Edit Ticket",
        type: "hidden",
        key: "edit-ticket",
        route: "/tickets/:ticket_id",
        component: <EditTicket />,
      },
      {
        name: "Promotions",
        key: "promotions",
        route: "/promotions",
        component: <Promotions />,
      },
      {
        name: "New Promotion",
        type: "hidden",
        key: "new-promotion",
        route: "/promotions/new-promotion",
        component: <NewPromotion />,
      },
      {
        name: "Edit Promotion",
        type: "hidden",
        key: "edit-promotion",
        route: "/promotions/:promotion_id",
        component: <EditPromotion />,
      },

      {
        name: "Vouchers",
        key: "vouchers",
        route: "/vouchers",
        component: <Vouchers />,
      },
      {
        name: "New Voucher",
        type: "hidden",
        key: "new-voucher",
        route: "/vouchers/new-voucher",
        component: <NewVoucher />,
      },
      {
        name: "Edit Voucher",
        type: "hidden",
        key: "edit-voucher",
        route: "/vouchers/:voucher_id",
        component: <EditVoucher />,
      },
    ],
  },

  // reports group list
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="medium">summarize</Icon>,
    collapse: [
      {
        name: "Location Reports",
        key: "location-reports",
        route: "/location-reports",
        component: <LocationReports />,
      },
      {
        name: "Production Reports",
        key: "production-reports",
        route: "/production-reports",
        component: <ProductionReports />,
      },
    ],
  },

  //   // settings group list
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="medium">settings_applications</Icon>,
    collapse: [
      {
        name: "Filters",
        key: "filters",
        route: "/account/filters",
        component: <Filters />,
      },
    ],
  },
];

export default routes;
