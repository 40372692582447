/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
// import { getDroplistTrafficReports, setLoading } from "store/actions/reportActions";
// import { useDispatch } from "react-redux";

const PartnerReportPicker = ({ setFieldValue, handleChange, partners, loading, partner }) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });
  //   const dispatch = useDispatch();

  //   useEffect(() => {
  //     dispatch(setLoading());
  //     dispatch(getDroplistTrafficReports());
  //     // eslint-disable-next-line
  //   }, []);
  return (
    <div>
      <SelectF
        onMenuOpen={document.body}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        closeMenuOnScroll={(e) => !e.target?.classList?.contains("select__menu-list")}
        className="basic-single"
        classNamePrefix="select"
        value={partner?.name ? partner : null}
        isLoading={loading}
        // isDisabled={isDisabled}
        isClearable
        onChange={(option) => {
          handleChange(option);

          if (partner) setFieldValue("partner", option || "");
          else {
            setFieldValue("partner", option || "");
          }
        }}
        isSearchable
        getOptionValue={(option) => `${option.value}`}
        getOptionLabel={(option) => `${option.name}`}
        name="partner"
        id="partner"
        options={partners}
        placeholder="Select a Partner"
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      />
    </div>
  );
};
// Setting default props
PartnerReportPicker.defaultProps = {
  loading: false,
  handleChange: () => {},
};

PartnerReportPicker.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  partners: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool,
  partner: PropTypes.instanceOf(Object),
};

export default PartnerReportPicker;
