/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";
import debounce from "lodash/debounce";
import { sortAscAlphabetically } from "components/helper";

const ParentsPicker = ({ parents, setFieldValue, parentsV, typeVal }) => {
  const filterRec = (data) =>
    data.map((tagData) => ({
      label: tagData.title,
      value: tagData._id,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/tags?`;
      if (typeVal) url += `type=${typeVal}&`;
      if (inputValue) url += `title=${inputValue}`;
      url += "&sort=title&dir=asc";
      setTimeout(() => {
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            const sortedOptions = sortAscAlphabetically(filterRec(result.data));
            resolve(sortedOptions);
          })
          .catch((error) => {
            console.log(error.message);
          });
      }, 1000);
    });

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    promiseOptions(inputValue)
      .then((options) => {
        callback(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, 500); // Adjust the delay time (in milliseconds) as needed

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {parents.label}
      </Typography>

      <AsyncSelect
        key={parentsV}
        isMulti
        cacheOptions
        defaultOptions
        defaultValue={parentsV.length > 0 ? parentsV : null}
        placeholder="Search for a Parent"
        menuPosition="fixed"
        loadOptions={debouncedLoadOptions}
        onChange={(options) => {
          if (!options || options.length === 0) {
            setFieldValue("parents", []);
          } else {
            setFieldValue("parents", options);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor, // Change border color on focus
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow, // Optional: Add a shadow for better visual
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor, // Maintain border color on hover
            },
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

// Setting default props
ParentsPicker.defaultProps = {
  setFieldValue: () => {},
  parentsV: [],
  typeVal: "",
};

ParentsPicker.propTypes = {
  parents: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func,
  typeVal: PropTypes.string,
  parentsV: PropTypes.instanceOf(Array),
};

export default ParentsPicker;
