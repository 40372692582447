/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, Icon, ImageList, ImageListItem } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";

const SliderReview = ({ handleDelete, item, handleClick }) => {
  const {
    title,
    subTitle,
    ctaLabel,
    ctaUrl,
    startDate,
    endDate,
    image,
    mobileImage,
    slideDuration,
  } = item;

  const handleDeleteClick = (event) => {
    event.stopPropagation(); // Stop event propagation to the parent (card)
    handleDelete(event); // Call handleDelete with the event
  };

  return (
    <Card
      key={item?.title}
      sx={{ height: "100%", padding: "24px", marginTop: "12px", cursor: "pointer" }}
      onClick={() => handleClick()}
    >
      <Grid container>
        <Grid item xs={12} justifyContent="end">
          <button
            type="button"
            onClick={handleDeleteClick}
            style={{
              float: "right",
              cursor: "pointer",
              background: "none",
              border: "none",
              fontSize: "large",
            }}
          >
            <Icon>delete</Icon>
          </button>
        </Grid>
        {title && (
          <Grid item xs={12}>
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              Title:&nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {title}
            </MDTypography>
          </Grid>
        )}
        {subTitle && (
          <Grid item xs={12}>
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              SubTitle:&nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {subTitle}
            </MDTypography>
          </Grid>
        )}
        {ctaLabel && (
          <Grid item xs={12}>
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              Banner Label:&nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {ctaLabel}
            </MDTypography>
          </Grid>
        )}
        {ctaUrl && (
          <Grid item xs={12}>
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              Banner Url:&nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {ctaUrl}
            </MDTypography>
          </Grid>
        )}
        {startDate && (
          <Grid item xs={12}>
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              Start Date:&nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {startDate.split("T")[0]}
            </MDTypography>
          </Grid>
        )}
        {endDate && (
          <Grid item xs={12}>
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              End Date:&nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {endDate.split("T")[0]}
            </MDTypography>
          </Grid>
        )}
        {slideDuration && (
          <Grid item xs={12}>
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              Duration:&nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {slideDuration}
            </MDTypography>
          </Grid>
        )}
        {image?.length > 0 && (
          <Grid item xs={6}>
            <ImageList>
              <ImageListItem sx={{ "& img": { height: "164px!important" } }}>
                <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  Desktop Image:&nbsp;
                </MDTypography>
                <img src={`${image}`} srcSet={`${image}`} alt="images" loading="lazy" />
              </ImageListItem>
              {mobileImage?.length > 0 && (
                <ImageListItem sx={{ "& img": { height: "164px!important" } }}>
                  <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                    Mobile Image:&nbsp;
                  </MDTypography>
                  <img
                    src={`${mobileImage}`}
                    srcSet={`${mobileImage}`}
                    alt="mobileImage"
                    loading="lazy"
                  />
                </ImageListItem>
              )}
            </ImageList>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
SliderReview.defaultProps = {
  item: {},
  handleDelete: () => {},
  handleClick: () => {},
};
// typechecking props for SliderReview
SliderReview.propTypes = {
  item: PropTypes.instanceOf(Object),
  handleDelete: PropTypes.func,
  handleClick: PropTypes.func,
};

export default SliderReview;
