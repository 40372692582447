/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Material UI components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
// import CardsList from "components/CardsList";
import DynamicHeader from "components/DynamicHeader";
import DynamicTranslate from "components/DynamicTranslate";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";
import { useHandleError } from "components/helper";

// Mui Icons
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import TranslateIcon from "@mui/icons-material/Translate";

// Redux Actions
import {
  updateWidget,
  clearErrors,
  getOneWidget,
  updateWidgetTranslations,
  setLoading,
} from "store/actions/widgetActions";
import setAlert from "store/actions/alertActions";
// Schemas
import * as Yup from "yup";
import validations from "../new-transportationService/schemas/validations";
import form from "../new-transportationService/schemas/form";
import initialValues from "../new-transportationService/schemas/initialValues";
import WidgetDetail from "./components/TransportationServiceDetail";
import ElementsList from "./components/ElementsList";

const EditWidget = () => {
  const { formId, formField } = form;
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const loading = useSelector((state) => state.widgets.loading);
  const widget = useSelector((state) => state.widgets.widget);
  // const widgets = useSelector((state) => state.widgets.widgets);
  const error = useSelector((state) => state.widgets.error);
  const added = useSelector((state) => state.widgets.added);
  const user = useSelector((state) => state.users.user);
  const [tabValue, setTabValue] = useState(0);

  const [transportationServiceDetailPage, setTransportationServicePage] = useState(true);
  const [initialState, setInitialState] = useState(initialValues);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedIcon, setSelectedIcon] = useState({});
  const [translateItems, setTranslateItems] = useState([]);
  const locationSchemaValidation = Yup.object().shape({
    location: Yup.object().required("Please select a location!"),
  });
  const { widget_id } = useParams();

  const [searchParams] = useSearchParams();
  const source = searchParams.get("source");

  const detailTabs = [
    { id: 1, title: "WIDGET DETAILS", iconComponent: AssignmentIndIcon },
    { id: 2, title: "ELEMENTS", iconComponent: EmojiTransportationIcon },
    { id: 3, title: "TRANSLATE", iconComponent: TranslateIcon },
  ];

  useEffect(() => {
    if (widget) {
      const arTranslation = widget?.translations?.ar;

      const arr = [
        {
          id: 1,
          name: "title",
          title: "Title",
          value: arTranslation?.title || "",
          default: "",
          label: "title",
        },
      ];
      setTranslateItems(arr);
    }
  }, [widget]);

  useEffect(() => {
    // dispatch(setLoading());
    if (widget_id) {
      dispatch(getOneWidget(widget_id));
    }
  }, [widget_id, added]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (widget) {
      const manipulated = {};
      // setOriginalTitle(experience.title);
      manipulated.title = widget?.title ? widget?.title : "";
      manipulated.location = widget?.location ? widget?.location : {};
      manipulated._id = widget._id;
      manipulated.logoName = widget?.logoName;
      manipulated.logo = widget.logo ? widget.logo : "";
      manipulated.elements = widget.elements ? widget.elements : [];

      setInitialState(manipulated);
      setSelectedIcon({ name: widget?.logoName, value: widget.logo });
    }
  }, [widget]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm = async (values) => {
    const { title = "", location = {}, logoName = "" } = values;

    const baseValues = { _id: values?._id };
    let additionalValues = {};
    if (!transportationServiceDetailPage) {
      additionalValues = { title, location, logoName };
    }
    const updatedValues = { ...baseValues, ...additionalValues };

    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    dispatch(updateWidget(updatedValues, values?.logo));
    setTransportationServicePage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneWidget(widget_id));
    }
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      if (source === "location") {
        navigate(-1);
      } else {
        url = `/admin/widgets?`;
        navigate(url);
      }
    } else {
      navigate(-1);
    }
  };

  const handleSubmitTranslate = (values) => {
    dispatch(updateWidgetTranslations(values, widget?._id));
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return <ElementsList selectedWidget_id={widget?._id} />;

      case 2:
        return (
          <DynamicTranslate
            items={translateItems}
            handleSubmit={handleSubmitTranslate}
            id={widget?._id}
          />
        );
      default:
        return null;
    }
  };

  const handleLocationChange = (value) => {
    if (!value || !value?._id) {
      setSelectedLocation({});
    } else {
      setSelectedLocation(value);
    }
  };

  const handleIconChange = (value) => {
    if (!value || !value?.name) {
      setSelectedIcon({});
    } else {
      setSelectedIcon(value);
    }
  };
  function getStepContent(type, formData) {
    if (type === "transportationServiceInfo") {
      return (
        <WidgetDetail
          type={type}
          formData={formData}
          detailPage={transportationServiceDetailPage}
          setDetailPage={setTransportationServicePage}
          handleLocationChange={handleLocationChange}
          selectedLocation={selectedLocation}
          locationObj={widget?.location}
          handleIconChange={handleIconChange}
          selectedIcon={selectedIcon}
          iconsObj={{ value: widget?.logo, name: widget?.logoName }}
          handleEdit={() => {
            formData.resetForm();
            setTransportationServicePage(false);
          }}
          setSelectedLocation={setSelectedLocation}
        />
      );
    }
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar hide={user.role === "partner"} />
      <MDBox py={3} mb={20}>
        <Grid item xs={12} sm={12} md={6}>
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validations[0]?.concat(locationSchemaValidation)}
            onSubmit={handleSubmit}
            innerRef={(f) => {
              formRef.current = f;
            }}
          >
            {({ values, errors, touched, setFieldValue, resetForm }) => (
              <Form id={formId} autoComplete="off">
                <MDBox>
                  <DynamicHeader
                    user={widget}
                    detailTabs={detailTabs}
                    handleSetTabValue={handleSetTabValue}
                    tabValue={tabValue}
                    noImg
                    handleNavigation={() => handleNavigation(user?.role)}
                  />
                  {tabValue === 0 ? (
                    <>
                      {["transportationServiceInfo"].map((item, index) => (
                        <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                          <MDBox p={2}>
                            <Grid container spacing={3} alignItems="center">
                              <Grid item xs={12}>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                  {getStepContent(item, {
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                    setFieldValue,
                                    resetForm,
                                  })}
                                </MDBox>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </Card>
                      ))}
                    </>
                  ) : (
                    handleDetailData()
                  )}
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditWidget;
