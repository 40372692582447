/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Section, Text, Img } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import PropTypes from "prop-types";

const DiscoverAlternativePlacesSection = ({ alternativeItems, noViewMore }) => (
  <Tailwind>
    <Section className="text-left bg-white px-8 py-8 text-black mb-6 rounded-md">
      <Text className="text-[25px] leading-[26.5px] font-bold mb-4">
        Discover alternative places that might interest you
      </Text>
      {!noViewMore && (
        <div className="text-[10px] leading-[10px] font-bold text-right underline mt-4 pr-2">
          <a
            href="https://lokalee.app/"
            className="text-black underline text-[10px] leading-[10px] font-bold"
          >
            VIEW MORE
          </a>
        </div>
      )}
      <div className="flex space-x-4">
        {alternativeItems.map((item) => (
          <div className="w-1/3 p-2  rounded-md" key={item.slug}>
            <a href={`https://www.lokalee.app/tours/${item.slug}`}>
              <Img
                src={item.coverImage}
                alt={item.title}
                className="w-[162px] h-[165px] rounded-md object-cover"
              />
            </a>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Img
                    src="https://email-assets.lokalee.app/email/emailImages/atomRating-star.png"
                    alt="Star"
                    className="mb-1 w-[16px] h-[16px] mr-1"
                  />
                  <Text className="text-xs">
                    {item.reviews.combinedAverageRating.toFixed(1)} ({item.reviews.totalReviews})
                  </Text>
                </div>
              </div>
              <Text className="mt-2 font-semibold text-left">{item.title}</Text>
            </div>
          </div>
        ))}
      </div>
    </Section>
  </Tailwind>
);

DiscoverAlternativePlacesSection.defaultProps = {
  // noViewMore: false,
  alternativeItems: [
    {
      coverImage: "https://email-assets.lokalee.app/email/emailImages/fullWidth-cover.png",
      reviews: {
        combinedAverageRating: 4.7,
        totalReviews: 100,
      },
      title: " Write the headline here maximum line of two",
    },
    {
      coverImage: "https://email-assets.lokalee.app/email/emailImages/fullWidth-cover.png",
      reviews: {
        combinedAverageRating: 4.7,
        totalReviews: 100,
      },
      title: " Write the headline here maximum line of two",
    },
    {
      coverImage: "https://email-assets.lokalee.app/email/emailImages/fullWidth-cover.png",
      reviews: {
        combinedAverageRating: 4.7,
        totalReviews: 100,
      },
      title: " Write the headline here maximum line of two",
    },
  ],
};
DiscoverAlternativePlacesSection.propTypes = {
  noViewMore: PropTypes.bool.isRequired,
  alternativeItems: PropTypes.arrayOf(
    PropTypes.shape({
      coverImage: PropTypes.string,
      reviews: PropTypes.shape({
        combinedAverageRating: PropTypes.number,
        totalReviews: PropTypes.number,
      }),
      title: PropTypes.string,
    })
  ),
};
export default DiscoverAlternativePlacesSection;
