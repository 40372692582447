// Libraries
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Custom component
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const SavedButton = ({ handleSave }) => {
  const [saved, setSaved] = useState(false);
  const [bc, setBC] = useState(false);

  const showNotification = () => {
    if (!bc) {
      setBC(true);
      setTimeout(() => {
        setBC(false);
        setSaved(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (saved) {
      showNotification();
    }
  }, [saved]);

  return (
    <div>
      {saved ? (
        <MDTypography
          variant="button"
          fontWeight="medium"
          color="info"
          style={{ fontSize: "12px" }}
        >
          Saved!
        </MDTypography>
      ) : (
        <MDButton
          variant="outlined"
          color="dark"
          size="small"
          onClick={(val) => {
            handleSave(val);
            setSaved(true);
          }}
        >
          Save
        </MDButton>
      )}
    </div>
  );
};

SavedButton.propTypes = {
  handleSave: PropTypes.func.isRequired,
};

export default SavedButton;
