/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
// import axios from "axios";
import { CLEAR_ERRORS, GET_UPDATED_DESTINATION_ID, SET_TRENDS_LOADING } from "./Types";
// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

export const getDestinationsTrends = (destinations) => {
  const destinationValues = destinations.map((destination) => destination.value).join(",");
  return factory.get(
    `${URL}/trends?destinations=${destinationValues}`,
    "GET_TRENDS",
    "TRENDS_FAIL"
  );
};

export const addTrend = (isItem, data) =>
  factory.patch(
    data,
    isItem ? `${URL}/tickets/${data._id}?` : `${URL}/recommendations/${data._id}`,
    "ADD_TREND",
    "TRENDS_FAIL"
  );

export const removeTrend = (isItem, data) =>
  factory.patch(
    data,
    isItem
      ? `${URL}/tickets/${data._id}?removeTrend=true `
      : `${URL}/recommendations/${data._id}/removeTrend`,
    isItem ? "REMOVE_TREND_ITEM" : "REMOVE_TREND_RECOMMENDATION",
    "TRENDS_FAIL"
  );

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading -- Used for login
export const setLoading = () => ({ type: SET_TRENDS_LOADING });

// Get updated cityId type
export const getUpdatedDestinationId = (des) => ({
  type: GET_UPDATED_DESTINATION_ID,
  payload: des,
});
